import { Stack } from "@mui/material";
import { FC, useEffect } from "react";
import { ContentTone } from "../brand-kit/server/brand-kit-service";

import { Atom, useAtomValue, useSetAtom } from "jotai";
import { Loadable } from "jotai/vanilla/utils/loadable";
import { UUID } from "../../utils/uuid";
import { contentRefineAtoms } from "../ai-refine/store";
import { ChannelName } from "../channels/types";
import { Template } from "../generic/atoms/types/template";
import { TemplateContent } from "./template-content";
import { TemplateImages } from "./template-images";

export interface TemplatePreviewProps {
  loadableTemplateAtom: Atom<Loadable<Template | null>>;
  contentTone: ContentTone | "custom";
  onContentToneChange: (value: ContentTone | "custom") => void;
  onBrandToneChange: (value: UUID) => void;
  onChannelChange?: (value: ChannelName) => void;
  onImageSelect: (image: number) => void;
  store: "discover" | "generate";
  brandTone?: UUID;
  designHuddle?: boolean;
}

export const TemplatePreview: FC<TemplatePreviewProps> = ({
  loadableTemplateAtom,
  contentTone,
  onContentToneChange,
  onBrandToneChange,
  onImageSelect,
  onChannelChange,
  store,
  brandTone,
  designHuddle = false,
}) => {
  const templateLoader = useAtomValue(loadableTemplateAtom);

  const setContentRefineAtoms = useSetAtom(contentRefineAtoms);

  useEffect(() => {
    if (templateLoader.state === "hasData" && templateLoader.data) {
      setContentRefineAtoms({
        store,
        content: templateLoader.data.content,
      });
    }
  }, [templateLoader, contentTone, setContentRefineAtoms, store]);

  return templateLoader.state === "hasData" && templateLoader.data ? (
    <Stack gap={2} mt={2}>
      <TemplateImages loadableTemplateAtom={loadableTemplateAtom} onSelect={onImageSelect} mb={2} />
      <Stack direction="row" gap={4} justifyContent="space-evenly">
        <TemplateContent
          loadableTemplateAtom={loadableTemplateAtom}
          contentTone={contentTone}
          brandTone={brandTone}
          designHuddle={designHuddle}
          onContentToneChange={onContentToneChange}
          onBrandToneChange={onBrandToneChange}
          onChannelChange={onChannelChange}
        />
      </Stack>
    </Stack>
  ) : null;
};
