import { SupabaseService } from "../../../server/supabase/base-supabase-service";
import { Database } from "../../../server/supabase/types/database-definitions";

export type Asset = NonNullable<Database["public"]["Tables"]["digital_asset"]["Row"]>;
export type AssetUpsert = (Database["public"]["Tables"]["digital_asset"] & {
  [k: string]: never;
})["Insert"];

export class SupabaseDigitalAssetService extends SupabaseService<"digital_asset"> {
  table = "digital_asset" as const;
}
