import { useSupabaseLoader } from "../../../server/supabase/hooks";
import { SupabaseSegmentService } from "../server/supabase-segment-service";

export const useSegmentSaveAllowed = (): ReturnType<typeof useSupabaseLoader<boolean>> => {
  return useSupabaseLoader(async ({ supabase }) => {
    const { data, error } = await new SupabaseSegmentService(supabase).segmentSaveAllowed();

    if (error) {
      console.error(error);
      return true; // Better to allow, then have it fail in RLS, than block a real attempt
    }

    return data;
  }, []);
};
