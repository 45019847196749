import { Link } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { UUID } from "../../../utils/uuid";
import { MomentStatus } from "../../moment-card/types";

type ProgramTab = {
  value: string;
  label: string;
  showCreate: boolean;
  emptyStateMessage?: string | React.JSX.Element;
  status?: MomentStatus[];
};

export const useProgramTabs = (programId?: UUID): ProgramTab[] => {
  const { t } = useTranslation();
  return [
    {
      value: "active",
      label: "Published and Drafts",
      showCreate: true,
      emptyStateMessage: (
        <Trans i18nKey={"programDraftsEmpty"}>
          There are no Published or Draft Moments.{" "}
          {programId !== undefined && (
            <>
              <Link href={`/moments?programId=${programId}`}>Create a new Moment</Link> or{" "}
              <Link href={`/discover`}>Discover one from our Library</Link>
            </>
          )}
        </Trans>
      ),
      status: ["active", "draft"],
    },
    {
      value: "finished",
      label: "Finished",
      showCreate: false,
      emptyStateMessage: t(
        "There are no finished Moments. A Moment is finished when it has completed all its sends.",
      ),
      status: ["finished"],
    },
    {
      value: "archived",
      label: "Archived",
      showCreate: false,
      emptyStateMessage: t("There are no archived Moments."),
      status: ["archived"],
    },
  ];
};
