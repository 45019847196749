import { atomFamily, atomWithDefault } from "jotai/utils";
import { isEqual } from "lodash-es";
import { getTokenAtom } from "../../../server/auth/store";
import { authHeader } from "../../../utils/auth-header";
import { gptModelAtom } from "../../generate/store";
import { selfDriving } from "../../self-driving/self-driving";

export const layoutConfigurationFamily = atomFamily(
  ({
    formatId,
    answers,
    imgNum,
  }: {
    formatId: string;
    answers: { answer: string }[];
    imgNum: string;
  }) =>
    atomWithDefault(async (get) => {
      const { getToken } = get(getTokenAtom);
      const gptModel = get(gptModelAtom);
      const token = await getToken();

      if (!token || !gptModel) return;

      const { data, error } = await selfDriving.POST(
        "/multiformat/{formatId}/image/{imgNum}",
        {
          body: {
            description: answers[0].answer
          },
          params: {
            path: { formatId, imgNum },
            query: { model: gptModel },
          },
          ...authHeader(token),
        },
      );

      if (error) {
        throw error;
      }

      return data;
    }),
  isEqual,
);

