import { ChannelName } from "./types";

export function prettyChannelName(name: ChannelName): string {
  if (name === undefined || name === null) {
    return "";
  }

  if (name === "twilio") {
    return "SMS";
  }

  return name
    .split("_")
    .map((word) => {
      return word[0].toUpperCase() + word.substring(1);
    })
    .join(" ");
}
