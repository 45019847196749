import { useSupabaseLoader } from "../../../server/supabase/hooks";
import { UUID } from "../../../utils/uuid";
import { Account } from "../../generic/server/supabase-account-service";
import { SupabaseAnalyticsDataService } from "../server/supabase-person-service";

type AnalyticsData = {
  visitor: {
    id?: UUID;
    email: string | null;
    role: string | null;
    jobTitle: string | null;
    firstName: string | null;
    lastName: string | null;
    department: string | null;
    workCountry: string | null;
    isManager: boolean | null;
    timezone: string | null;
    actingOnBehalfOf: string | null;
    auth0Id: string;
    auth0Email: string | null;
  };
  account: {
    id?: string;
    createdAt: string | null;
    accountName: string | null;
  };
};

export function useAnalyticsData(
  userId: string,
  account: Account,
  role: string,
): ReturnType<typeof useSupabaseLoader<AnalyticsData | null>> {
  return useSupabaseLoader(
    async ({ supabase, account_id }) => {
      const { data, error } = await new SupabaseAnalyticsDataService(supabase).getAll(account_id, {
        filter: [
          { key: "person_auth0_id", operator: "eq", value: userId },
          { key: "account_id", operator: "eq", value: account_id },
        ],
        order: [{ column: "id" }],
      });

      if (error || data.length > 1) {
        console.error("Error fetching analytics data", error, data);
        return null;
      }

      const datum = data[0];

      return {
        visitor: {
          id: datum?.id ?? undefined,
          email: datum?.email ?? null,
          role,
          jobTitle: datum?.person_job_title ?? null,
          firstName: datum?.person_first_name ?? null,
          lastName: datum?.person_last_name ?? null,
          department: datum?.person_department ?? null,
          workCountry: datum?.person_work_country ?? null,
          isManager: datum?.person_is_manager ?? null,
          timezone: datum?.person_timezone ?? null,
          actingOnBehalfOf: account.business_name,
          auth0Id: userId,
          auth0Email: datum?.person_auth0_email ?? null,
        },
        account: {
          id: account.business_name,
          createdAt: account.created_at,
          accountName: account.name,
        },
      };
    },
    [userId, role, account],
  );
}
