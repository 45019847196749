import { MenuItem, TextField } from "@mui/material";
import { FC } from "react";
import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Permissions } from "../../server/supabase-person-service";
import { PermissionsFormType } from "../../types";

export const PermissionTypeSelect: FC<{
  control: Control<PermissionsFormType>;
  existing?: Permissions;
}> = ({ control }) => {
  const { t } = useTranslation();

  return (
    <Controller
      name="type"
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          label={t("Permission Type")}
          select
          fullWidth
          sx={{ mt: 2 }}
          helperText={error?.message}
          error={!!error}
          {...field}
        >
          <MenuItem value="super_admin">{t("Super Admin")}</MenuItem>
          <MenuItem value="contributor">{t("Contributor")}</MenuItem>
        </TextField>
      )}
    />
  );
};
