import {
  PostgrestMaybeSingleResponse,
  PostgrestResponse,
  PostgrestSingleResponse,
} from "@supabase/postgrest-js";
import {
  BaseSupabaseService,
  SupabaseService,
  SupabaseServiceView,
} from "../../../server/supabase/base-supabase-service";
import { Database } from "../../../server/supabase/types/database-definitions";
import { UUID } from "../../../utils/uuid";
import { SlackManifest } from "../../integrations/types";

export type CustomEmoji = Database["public"]["Tables"]["custom_emoji"]["Row"];
export type SlackAlias = Database["public"]["Tables"]["slack_alias"]["Row"];
export type SlackAliasWithDefault = Database["public"]["Views"]["slack_alias_with_default"]["Row"];
export type SlackAliasDefault = Database["public"]["Tables"]["slack_alias_default"]["Row"];

export class SupabaseSlackAppService extends SupabaseService<"slack_app"> {
  table = "slack_app" as const;
}

export class SupabaseSlackAliasWithDefaultService extends SupabaseServiceView<"slack_alias_with_default"> {
  table = "slack_alias_with_default" as const;

  async setDefault(alias_id: UUID | null): Promise<PostgrestResponse<SlackAliasWithDefault>> {
    if (alias_id)
      return this.log_errors(await this.client.rpc("slack_alias_set_default", { alias_id }));

    return this.log_errors(await this.client.rpc("slack_alias_remove_default"));
  }
}

export class SupabaseSlackAliasService extends SupabaseService<"slack_alias"> {
  table = "slack_alias" as const;
}

export class SupabaseSlackAliasDefaultService extends SupabaseService<"slack_alias_default"> {
  table = "slack_alias_default" as const;

  async getSingle(): Promise<PostgrestMaybeSingleResponse<SlackAliasDefault>> {
    return this.log_errors(await this.client.from(this.table).select().single());
  }
}

export class SupabaseSuperAdminSlackService extends BaseSupabaseService<"super_admin"> {
  async createSlackManifest(
    manifest: SlackManifest,
  ): Promise<
    PostgrestSingleResponse<
      Database["super_admin"]["Functions"]["create_slack_manifest"]["Returns"]
    >
  > {
    return await this.client.rpc("create_slack_manifest", { manifest });
  }
}
