import { CircularProgress, Tooltip } from "@mui/material";
import { FC, useState } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useSupabase } from "../../../../../server/supabase/hooks";
import { XCircle } from "../../../../../styles/icons/x-circle";
import { toISO8601 } from "../../../../../utils/iso8601";
import {
  SlackAliasWithDefault,
  SupabaseSlackAliasService,
  SupabaseSlackAliasWithDefaultService,
} from "../../../../slack/server/slack-supabase-service";

export const DeleteAction: FC<{ alias: SlackAliasWithDefault; onDelete: () => void }> = ({
  alias,
  onDelete,
}) => {
  const { t } = useTranslation();
  const [deleting, setDeleting] = useState(false);

  useSupabase(
    async ({ supabase }) => {
      if (!deleting || !alias.id) return;

      if (alias.default) {
        const { error: err } = await new SupabaseSlackAliasWithDefaultService(supabase).setDefault(
          null,
        );
        if (err) {
          toast.error(t("Unable to remove default alias"));
          setDeleting(false);
          return;
        }
      }

      const { error } = await new SupabaseSlackAliasService(supabase).update(alias.id, {
        deleted_at: toISO8601(new Date()),
      });
      if (error) toast.error(t("Unable to delete alias"));
      else {
        toast.success(t("Alias deleted"));
        onDelete();
      }

      setDeleting(false);
    },
    [deleting, alias.id, alias.default, onDelete, t],
  );

  const linked: SlackAliasWithDefault["linked_moments"] = alias.linked_moments || [];

  const active =
    linked.filter(({ status }) => ["active", "draft", "paused"].includes(status)).length > 0;

  return (
    <>
      <Tooltip
        title={t(
          active
            ? "Unable to delete this alias as it is associated with active Moments"
            : "Delete this alias",
        )}
        placement="top"
        sx={{ cursor: "pointer", mr: 1 }}
      >
        {deleting ? (
          <CircularProgress size="small" />
        ) : (
          <XCircle
            fontSize="small"
            color={active ? "disabled" : "info"}
            onClick={active ? undefined : () => setDeleting(true)}
          />
        )}
      </Tooltip>
    </>
  );
};
