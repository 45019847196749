import { useCallback } from "react";
import { TemplateCustomizationObject } from "./brand-info-customizations/types";

type createDesignHuddleContentProject = (props: {
  templateCode: string;
  customizations: TemplateCustomizationObject;
}) => Promise<string>;

export const useCreateDesignHuddleProject = (): createDesignHuddleContentProject => {
  return useCallback(async ({ templateCode, customizations }) => {
    return new Promise((resolve, reject) => {
      DSHDLib.createProject({ template_code: templateCode, customizations }, (err, response) => {
        if (err) {
          console.error(err);
          reject(new Error("Failed to create project"));
        }
        resolve(response.project_id);
      });
    });
  }, []);
};
