import { $isElementNode, LexicalEditor, LexicalNode } from "lexical";

export function exportHtml(editor: LexicalEditor, node: LexicalNode): string {
  const container = document.createElement("div");
  $appendNodesToHTML(editor, node, container);

  return container.innerHTML;
}

function $appendNodesToHTML(
  editor: LexicalEditor,
  currentNode: LexicalNode,
  parentElement: HTMLElement | DocumentFragment,
): boolean {
  const shouldExclude = $isElementNode(currentNode) && currentNode.excludeFromCopy("html");
  const children = $isElementNode(currentNode) ? currentNode.getChildren() : [];

  const { element, after } = currentNode.exportDOM(editor);

  if (!element || !("append" in element)) return false;

  const fragment = document.createDocumentFragment();

  children.forEach((childNode) => $appendNodesToHTML(editor, childNode, fragment));

  if (!shouldExclude) {
    element.append(fragment);
    parentElement.append(element);

    if (after) {
      const newElement = after.call(currentNode, element);
      if (!("replaceWith" in element)) return false;
      if (newElement) element.replaceWith(newElement);
    }
  } else {
    parentElement.append(fragment);
  }

  return true;
}
