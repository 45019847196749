import { FC, ReactNode, useCallback, useContext } from "react";
import { useSupabaseCallback } from "../../../../server/supabase/hooks";
import { MomentCardDragAndDropContext } from "../../../../utils/contexts/moment-card-drag-and-drop-context";
import { UUID, uuidv4 } from "../../../../utils/uuid";
import { Account } from "../../../generic/server/supabase-account-service";
import { SupabaseMomentExportService } from "../../../moment/server/export-moment-service";
import { MomentCardDropData } from "../../types/moment-card-drop-data";

export const Draggable: FC<{
  account: Pick<Account, "id" | "name"> | undefined;
  momentId: UUID | undefined;
  children: ReactNode;
}> = ({ account, momentId, children }) => {
  const { loaded, postMessage } = useContext(MomentCardDragAndDropContext);
  const exportMoment = useSupabaseCallback(
    async ({ supabase }) => {
      if (!momentId) return;
      const { data } = await new SupabaseMomentExportService(supabase).get(momentId);
      return data?.moment && account
        ? ({
            sourceAccount: account,
            moment: data.moment,
          } as MomentCardDropData)
        : undefined;
    },
    [momentId, account],
  );

  const handleDragStart = useCallback(
    async (event: React.DragEvent<HTMLDivElement>) => {
      const dragId = uuidv4();
      event.dataTransfer.setData("moment_card_copy", dragId);

      const data = await exportMoment();
      loaded && postMessage(dragId, data);
    },
    [loaded, postMessage, exportMoment],
  );

  if (!account || !momentId || !loaded) return children;

  return (
    <div
      data-testid="draggable-moment-card"
      draggable={true}
      onDragStart={(e) => void handleDragStart(e).catch(console.error)}
    >
      {children}
    </div>
  );
};
