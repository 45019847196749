import { Select } from "@mui/material";
import { FC } from "react";
import { UnreachableCaseError } from "../../../../utils/errors/unreachable";
import { UsableAccountPersonField } from "../../server/supabase-segment-field-service";
import { SegmentCondition } from "../../types";
import { AutocompleteOpSelect } from "./autocomplete";
import { BooleanSelect } from "./boolean";
import { DateOpSelect } from "./date";

export const OperationSelect: FC<{
  condition: SegmentCondition;
  handleFieldChange: (value: SegmentCondition) => void;
  disabled?: boolean;
  fieldType?: UsableAccountPersonField["field_type"];
}> = ({ condition, handleFieldChange, disabled, fieldType }) => {
  switch (fieldType) {
    case "TEXT":
    case "COUNTRY":
      return (
        <AutocompleteOpSelect
          condition={condition}
          handleFieldChange={handleFieldChange}
          disabled={disabled}
          fieldType={fieldType}
        />
      );
    case "MONTH_DAY":
    case "DATE":
      return (
        <DateOpSelect
          condition={condition}
          handleFieldChange={handleFieldChange}
          disabled={disabled}
        />
      );
    case "BOOLEAN":
      return (
        <BooleanSelect
          condition={condition}
          handleFieldChange={handleFieldChange}
          disabled={disabled}
        />
      );
    case "ADDRESS":
    case undefined:
      return <Select disabled={true} value="" variant="standard"></Select>;
    default:
      throw new UnreachableCaseError();
  }
};
