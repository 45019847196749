import { Typography, TypographyProps } from "@mui/material";
import { FC } from "react";

interface EllipsisTextProps extends TypographyProps {
  text: string;
  lines?: number;
}
export const EllipsisText: FC<EllipsisTextProps> = ({ text, lines, ...props }) => (
  <Typography
    sx={{
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: lines,
      LineClamp: lines,
      WebkitBoxOrient: "vertical",
    }}
    {...props}
  >
    {text}
  </Typography>
);
