import { useDeepCompareEffect } from "@react-hookz/web";
import { DependencyList, useCallback, useEffect, useState } from "react";
import { UseSupabaseLoaderState } from "../../supabase/hooks/types";

export type UseApiLoaderResponse<T> = UseSupabaseLoaderState<T> & { reload: () => void };
/**
 * An Effect for loading data from API with a reload handler
 */
export function useApiLoader<T>(
  callback: () => Promise<T>,
  deps: DependencyList,
): UseApiLoaderResponse<T> {
  const [state, setState] = useState<UseSupabaseLoaderState<T>>({
    data: null,
    loading: true,
    error: null,
  });
  useEffect(() => {
    if (!state.loading) return;
    callback()
      .then((data) => setState({ data, loading: false, error: null }))
      .catch((error) => setState({ data: null, loading: false, error: error as Error }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.loading]); // Do not add callback

  const reload = useCallback(() => setState({ data: null, loading: true, error: null }), []);

  useDeepCompareEffect(reload, deps);

  return { ...state, reload };
}
