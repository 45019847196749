import { Stack, StackProps } from "@mui/material";
import { Atom, useAtomValue } from "jotai";
import { Loadable } from "jotai/vanilla/utils/loadable";
import { FC, ReactElement, useEffect, useState } from "react";
import { LoadableTemplateImage, Template } from "../generic/atoms/types/template";
import { TemplateCarouselImage } from "./template-carousel-image";
import { TemplateImagesCarousel } from "./template-images-carousel";

export const TemplateImages: FC<
  {
    loadableTemplateAtom: Atom<Loadable<Template | null>>;
    onSelect?: (n: number) => void;
    tooltip?: ReactElement;
    carousel?: boolean;
  } & Omit<StackProps, "onSelect">
> = ({ loadableTemplateAtom, onSelect, carousel = false, ...props }) => {
  const [images, setImages] = useState<LoadableTemplateImage[]>([]);

  const template = useAtomValue(loadableTemplateAtom);

  useEffect(() => {
    if (template?.state === "hasData") {
      setImages(template.data?.images ?? []);
    }
  }, [template]);

  const selectedImage = template?.state === "hasData" ? template.data?.selectedImage : undefined;

  const numImages = images ? images.length : 0;

  if (carousel)
    return (
      <TemplateImagesCarousel
        images={images}
        selectedImage={selectedImage}
        onSelect={onSelect}
        {...props}
      />
    );

  return (
    <Stack direction={"row"} gap={2} {...props}>
      {(images ?? (Array(numImages).fill(undefined) as undefined[])).map((ii, i) => (
        <TemplateCarouselImage
          key={`img-${i}`}
          image={ii}
          selected={selectedImage === i}
          onClick={() => onSelect?.(i)}
          sx={{
            opacity: selectedImage === i ? 1 : 0.8,
            width: "90px",
            height: "90px",
          }}
        />
      ))}
    </Stack>
  );
};
