import { atom } from "jotai";
import { atomFamily, atomWithDefault } from "jotai/utils";
import { getTokenAtom } from "../../server/auth/store";
import { brandingAtom } from "../brand-kit/store";
import { brandToneAtom, debugLabel, gptModelAtom, imagePreviewURLFamily } from "../generate/store";

import { loadable } from "jotai/utils";
import { filter, isEqual, omit, sortBy } from "lodash-es";
import { authHeader } from "../../utils/auth-header";
import { ISO8601_DATE, fromISO8601_DATE } from "../../utils/iso8601";
import { notEmpty } from "../../utils/not-empty";
import { brandKitStyles } from "../brand-kit/hooks/use-brand-kit";
import { BrandTone } from "../brand-kit/server/brand-tone-service";
import { Project } from "../design-huddle/types";
import { getPageNumber } from "../design-huddle/utils";
import {
  LoadableTemplateContent,
  Template,
  TemplateContent,
  TemplateImage,
} from "../generic/atoms/types/template";
import { selfDriving } from "../self-driving/self-driving";
import {
  sequenceTemplateBrandingFamily,
  sequenceTemplateChannelFamily,
  sequencesAtom,
  showSequencesAtom,
} from "../sequence/store";
import {
  libraryAtom,
  libraryQueryAtom,
  libraryTemplateFamily,
  libraryTemplatesAtom,
} from "./library/store";
import { LibraryProgram, LibraryQuery, LibraryTemplate } from "./library/types";
import { atlasV2Search } from "./server/atlas-search";
import { DiscoverTemplateImageQuery } from "./types";

export const unstableAtlasSearchAtom = atom(false);

/* a design huddle project to override a specific image on the template */
export const discoverTemplateDHProjectFamily = atomFamily(
  ({ slug, pageNumber }: { slug?: string; pageNumber?: number }) =>
    debugLabel(`discoverTemplateDHProjectFamily${slug}/${pageNumber}`, atom<Project | null>(null)),
  isEqual,
);

/* design huddle allows the user to change the page, so if they are editing page 1 they can change the page to 2 
   it would be better if either
   a) we could restrict which pages are available in the project, but the design huddle api doesn't allow this
   b) we could use a single project, but some projects have more than 5 pages and we don't have a way of representing this on the front-end
  */
export const discoverTemplateImagePageOverrideFamily = atomFamily(
  ({ slug, pageNumber }: { slug?: string; pageNumber?: number }) =>
    debugLabel(
      `discoverTemplateImagePageOverrideFamily/${slug}/${pageNumber}`,
      atom<number | null>(null),
    ),
  isEqual,
);

export const discoverTemplateImageFamily = atomFamily(
  ({ slug, img, style }: DiscoverTemplateImageQuery) =>
    debugLabel(
      `discoverTemplateImageFamily/${slug}/${JSON.stringify(img)}/${style.value}`,

      atomWithDefault<TemplateImage | null>((get) => {
        if (!img) {
          return null;
        }

        const brandInfoSelected = get(brandingAtom);
        const pageNumber = getPageNumber({ ...brandInfoSelected, branding_type: style });

        const dhProject = get(discoverTemplateDHProjectFamily({ slug, pageNumber }));
        const pageNumberOverride = get(
          discoverTemplateImagePageOverrideFamily({ slug, pageNumber }),
        );

        const info = {
          template_code: dhProject ? dhProject.project_id : img.template_code,
          page_number: pageNumberOverride ?? pageNumber,
          header: dhProject ? undefined : img.header,
          subheader: dhProject ? undefined : img.subheader,
          unsplashExtra: undefined,
          stableDiffusionImage: false,
          dhProject,
        };

        const url = get(loadable(imagePreviewURLFamily(info)));

        return { ...info, url, style };
      }),
    ),
  isEqual,
);

export const discoverContentFamily = atomFamily(
  ({
    template,
    contentKey,
    customTone,
  }: {
    template: LibraryTemplate | null;
    contentKey: keyof LibraryTemplate["content"];
    customTone?: BrandTone;
  }) =>
    debugLabel(
      `discoverContentFamily/${template?.slug}-${contentKey}${customTone ? "-" + customTone.name : ""}`,
      atomWithDefault((get): TemplateContent | null | Promise<TemplateContent | null> => {
        if (!template) {
          return null;
        }

        if (contentKey === "custom" && customTone) {
          return get(customToneContentFamily({ customTone, template }));
        }

        const content = template.content[contentKey];

        if (!content) {
          return null;
        }

        return {
          type: "static" as const,
          data: { response: { content } },
        };
      }),
    ),
  isEqual,
);

export const customToneContentFamily = atomFamily(
  ({ customTone, template }: { customTone: BrandTone; template: LibraryTemplate }) =>
    debugLabel(
      `customToneContentFamily/${template.slug}/${customTone.name}`,
      atom(async (get): Promise<TemplateContent | null> => {
        const gptModel = get(gptModelAtom);
        const token = await get(getTokenAtom).getToken();

        if (!template || !gptModel || !token) return null;

        const { data, error } = await selfDriving.POST("/discover/customise", {
          body: {
            professional: template.content["professional"],
            casual: template.content["casual"],
            direct: template.content["direct"],
            toneSummary: customTone.summary,
          },
          params: {
            query: { model: gptModel },
          },
          ...authHeader(token),
        });

        if (error || !data) throw new Error(error.error || "Request failed");

        return {
          type: "static" as const,
          data: { response: { content: data.response } },
        };
      }),
    ),
  isEqual,
);

export const discoverTemplateAtom = atom<Template | null>((get) => {
  const query = get(libraryQueryAtom);
  return get(discoverTemplateFamily(query));
});

export const discoverTemplateFamily = atomFamily(
  (query: LibraryQuery | null) =>
    debugLabel(
      `discoverTemplateFamily/${JSON.stringify(query)}`,
      atom<Template | null>((get) => {
        if (query === null) return null;
        const template = get(libraryTemplateFamily(query));
        const branding = get(brandingAtom);

        // Discover does not support custom branding
        if (branding.branding_type.type === "custom") {
          console.error("Discover does not support custom branding", branding);
          return null;
        }

        // slug isn't unique
        const sequenceBranding = get(sequenceTemplateBrandingFamily(template?.slug));
        const brandTone = get(brandToneAtom);
        const customTone = sequenceBranding?.brandTone ?? brandTone;

        if (!template) return null;

        const professional: LoadableTemplateContent = get(
          loadable(discoverContentFamily({ template, contentKey: "professional" })),
        );
        const casual: LoadableTemplateContent = get(
          loadable(discoverContentFamily({ template, contentKey: "casual" })),
        );
        const direct: LoadableTemplateContent = get(
          loadable(discoverContentFamily({ template, contentKey: "direct" })),
        );
        const custom: LoadableTemplateContent | null = customTone
          ? get(loadable(discoverContentFamily({ template, contentKey: "custom", customTone })))
          : null;

        const channel = get(sequenceTemplateChannelFamily(template.slug))?.channel_name ?? null;

        return {
          ...template,
          content: {
            professional,
            casual,
            direct,
            ...(custom ? { custom } : null),
          },
          subject_line: template?.content.email_subject,
          images: template
            ? brandKitStyles.map((style) =>
                get(
                  loadable(
                    discoverTemplateImageFamily({
                      slug: template.slug,
                      img: template.img,
                      style: { type: "standard", value: style },
                    }),
                  ),
                ),
              )
            : [],
          selectedImage: brandKitStyles.indexOf(branding.branding_type.value),
          channel,
        };
      }),
    ),
  isEqual,
);

const parseSendTime = (sendTime: string): number => {
  const extractedNumber = parseInt(sendTime, 10);

  return (
    extractedNumber *
    (sendTime.includes("before") ? -1 : 1) *
    (sendTime.includes("week") ? 7 : 1) *
    (sendTime.includes("month") ? 30 : 1) *
    (sendTime.includes("year") ? 365 : 1)
  );
};

function getSortValue(template: LibraryTemplate): Date | number {
  return template.date !== null
    ? fromISO8601_DATE(template.date)
    : parseSendTime(template.recommendations.send_time);
}

const programFamily = atomFamily(
  ({ program_slug, name }: { program_slug?: string; name?: string }) =>
    debugLabel(
      `programFamily/${program_slug}`,
      atom((get): LibraryProgram | null => {
        const { programs } = get(libraryAtom);

        const program = programs?.find((p) => p.slug === program_slug || p.name === name);

        if (!program) {
          console.error(`Program not found: ${program_slug ? program_slug : name}`);
          return null;
        }

        const templates = sortBy(
          program?.templates
            .filter(
              ({ end_date }) =>
                !["holidays", "deib"].includes(program.slug) ||
                fromISO8601_DATE(end_date ?? ("2179-04-24" as ISO8601_DATE)) >= new Date(),
            )
            .map(
              (template): LibraryTemplate => ({
                ...template,
                program: omit(program, ["templates"]),
              }),
            )
            .sort((a, b) => (getSortValue(a) > getSortValue(b) ? 1 : -1)),
        );

        return {
          ...program,
          templates: templates ?? [],
        };
      }),
    ),
  isEqual,
);

export const discoverProgramSlugAtom = atom<string | null>(null);

export const discoverProgramAtom = debugLabel(
  `discoverProgramAtom`,
  loadable(
    atom((get) => {
      const program_slug = get(discoverProgramSlugAtom);
      if (!program_slug) return "NOT_FOUND" as const;

      const program = get(programFamily({ program_slug }));
      if (!program) return "NOT_FOUND" as const;

      return program;
    }),
  ),
);

export const trendingTemplatesAtom = debugLabel(
  `trendingTemplates`,
  atom((get) => {
    const libraryTemplates = get(libraryTemplatesAtom);

    return sortBy(
      filter(
        libraryTemplates,
        (template) => template.trending !== undefined && template.trending !== "",
      ),
      (template) => template.trending?.length,
    ).reverse();
  }),
);

export const upcomingTemplatesAtom = debugLabel(
  `upcomingTemplates`,
  atom((get) => {
    const libraryTemplates = get(libraryTemplatesAtom);
    const upcoming = libraryTemplates.filter((template) => {
      const now = new Date();

      const startDate = template.date ? new Date(template.date) : null;
      const endDate = template.end_date ? new Date(template.end_date) : null;

      return (
        !!(startDate && startDate > now) ||
        !!(startDate && endDate && startDate < now && endDate > now)
      );
    });
    return sortBy(upcoming, (template) => template.date && new Date(template.date).getTime());
  }),
);

export const topTenTemplatesAtom = debugLabel(
  `topTenTemplates`,
  atom((get) => {
    const libraryTemplates = get(libraryTemplatesAtom);
    const topTen = libraryTemplates.filter((template) => template.top !== null);
    return sortBy(
      filter(topTen, (template) => template.top !== null),
      (template) => template.top,
    );
  }),
);

export const discoverSearchQuery = atom<string | null>(null);

export const discoverSearchResultsAtom = debugLabel(
  `discoverSearchResults`,
  loadable(
    atom(async (get) => {
      const query = get(discoverSearchQuery);

      const token = await get(getTokenAtom).getToken();
      const showSequences = get(showSequencesAtom);

      if (!query || query === "" || !token) return null;

      const unstable = get(unstableAtlasSearchAtom);

      const results = await atlasV2Search(query, token, unstable);

      const libraryTemplates = get(libraryTemplatesAtom);
      const sequences = get(sequencesAtom);

      if (!results) return libraryTemplates;

      return results
        .map((result) => {
          let template;

          if (!showSequences && result.sequence !== undefined) {
            return null;
          }

          if (result.sequence !== undefined) {
            template = sequences.find((s) => s.slug === result.sequence);
          } else {
            template = libraryTemplates.find(
              (t) => t.program.slug === result.program && t.slug === result.slug,
            );
          }

          if (!template) {
            console.error(`Template not found: ${result.program}/${result.slug}`);
            return null;
          }

          return template;
        })
        .filter(notEmpty);
    }),
  ),
);

export const discoverFixedProgramNamesAtom = atomWithDefault<string[]>(() => []);
export const discoverFixedProgramsAtom = atom<LibraryProgram[]>((get) => {
  const fixedPrograms = get(discoverFixedProgramNamesAtom);
  const library = get(libraryAtom);
  if (library.programs.length === 0) return [];

  const programs: LibraryProgram[] = fixedPrograms
    .filter((name) => name !== undefined)
    .map((name) => get(programFamily({ name })))
    .filter((p): p is LibraryProgram => p !== null);
  return programs ?? [];
});
