import { partial } from "lodash-es";
import { UUID } from "../../../utils/uuid";
import yup, { yupUUID } from "../../../utils/yup";

export const permissionsSchema = yup
  .object({
    person_ids: yup
      .array(yupUUID.required())
      .required("Please select employee(s).")
      .min(1, "Please select employee(s).")
      .default([]),
    type: yup
      .string()
      .oneOf(["super_admin", "contributor", ""])
      .required("Please select a type.")
      .default(""),
    read_only_programs: yup.array(yupUUID.required()).default([]),
    editable_programs: yup.array(yupUUID.required()).default([]),
    email_from_deny_list: yup.array(yupUUID.required()).default([]),
    segment_deny_list: yup.array(yupUUID.required()).default([]),
  })
  .test("editable_has_programs", "Please select a program.", partial(testHasPrograms, "editable"))
  .test(
    "read_only_has_programs",
    "Please select a program.",
    partial(testHasPrograms, "read_only"),
  );

function testHasPrograms(
  type: "read_only" | "editable",
  data: {
    type: "contributor" | "super_admin" | "";
    read_only_programs: UUID[];
    editable_programs: UUID[];
  },
  context: yup.TestContext,
): boolean | yup.ValidationError {
  if (data.type === "contributor") {
    if (data.read_only_programs.length === 0 && data.editable_programs.length === 0) {
      return context.createError({
        path: `${type}_programs`,
        message: "Please select editable and/or read-only programs.",
      });
    }
  }
  return true;
}

export type PermissionsFormType = yup.InferType<typeof permissionsSchema>;
export type PermissionsLimits = { contributors: number; super_admins: number };

export type ProcessingState =
  | "not started"
  | "uploading"
  | "complete"
  | "error"
  | "warning"
  | "warning-acked"
  | "invalid-file";

export type CsvUploadState = {
  processingState: ProcessingState;
  remoteInfo?: RemoteInfo;
  filename?: string;
  onUpload: () => Promise<void>;
  onComplete: () => void;
};

type Message = {
  columns: string[];
  message: string;
};

export type CsvLineResult = {
  line: number;
  messages: Message[];
};

type SummaryInfo = {
  new: number;
  updates: number;
  removed: number;
};

export type RemoteInfo = {
  warnings: CsvLineResult[];
  errors: CsvLineResult[];
  exception?: string;
  summary?: SummaryInfo;
};

export type ValidPerson = {
  account_id: UUID;
  person_id: UUID;
  person_text_data: { key: string; value: string }[];
};
