import { ReactNode } from "react";
import { SeverityPill, SeverityPillColor } from "../generic/components/severity-pill";
import { MomentStatus } from "./types";

export const statusToPill = (status: MomentStatus): ReactNode => {
  const valueMap: { [_: string]: SeverityPillColor } = {
    draft: "warning",
    paused: "disabled",
    active: "error",
    finished: "info",
    archived: "disabled",
  } as const;

  return (
    <SeverityPill color={valueMap[status]} data-testid={status + "-pill"}>
      {status}
    </SeverityPill>
  );
};
