import Papa from "papaparse";
import { useCallback, useMemo, useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDialog } from "../../../utils/hooks/use-dialog";

type EmailDialogState = {
  valid: string[];
  invalid: string[];
  duplicates: Record<string, string[]>;
  error?: string;
};

export const useSegmentValueFieldCsvHelper = ({
  options,
  allOptions,
  disabled,
  setOptions,
}: {
  options: string[];
  allOptions: string[];
  disabled: boolean;
  setOptions: (options: string[]) => void;
}): {
  dropzoneState: ReturnType<typeof useDropzone>;
  parsing: boolean;
  invalidEmailsDialog: ReturnType<typeof useDialog<EmailDialogState>>;
  handleEntries: (entries: string[]) => void;
  exportCsvData: string;
} => {
  const { t } = useTranslation();
  const [parsing, setParsing] = useState(false);

  const invalidEmailsDialog = useDialog<EmailDialogState>();

  const exportCsvData = useMemo(
    () => `data:text/csv;charset=utf-8,${Papa.unparse(options.map((x) => [x]))}`,
    [options],
  );

  const { handleOpen } = invalidEmailsDialog;

  const handleEntries = useCallback(
    (entries: string[]) => {
      const normalisedOptions = allOptions.reduce(
        (acc, option) => {
          acc[option.toLowerCase().trim()] = option;
          return acc;
        },
        {} as Record<string, string>,
      );

      const { valid, invalid, duplicates } = entries.reduce(
        (acc, entry, index) => {
          const value = normalisedOptions[entry.toLowerCase().trim()];
          if (value) {
            if (acc.valid.includes(value)) {
              acc.duplicates[value] = [...(acc.duplicates[value] || []), entry];
            } else {
              acc.valid.push(value);
            }
          } else {
            // Assume it's the header row and skip
            if (index !== 0) acc.invalid.push(entry);
          }

          return acc;
        },
        { valid: [], invalid: [], duplicates: {} } as {
          valid: string[];
          invalid: string[];
          duplicates: Record<string, string[]>;
        },
      );

      return invalid.length > 0 ? handleOpen({ valid, invalid, duplicates }) : setOptions(valid);
    },
    [allOptions, setOptions, handleOpen],
  );

  const handleUpload = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      if (rejectedFiles.length > 0) {
        toast.error(t("Please upload only CSV files."));
        return;
      }

      if (acceptedFiles.length !== 1) {
        toast.error(t("Please upload only one file."));
        return;
      }

      setParsing(true);

      Papa.parse<string[]>(acceptedFiles[0], {
        header: false,
        skipEmptyLines: true,
        complete: (results) => {
          // If any row has more than 1 column, we consider it invalid
          if (results.data.some((x) => x.length > 1)) {
            setParsing(false);
            return handleOpen({
              valid: [],
              invalid: [],
              duplicates: {},
              error: t("The CSV must contain only a single column per row."),
            });
          }

          handleEntries(results.data.map((x) => x[0]));

          setParsing(false);
        },
        error: (err) => {
          setParsing(false);
          console.error(err);
          toast.error(t("Failed to parse the file."));
        },
      });
    },
    [t, handleEntries, handleOpen],
  );

  const dropzoneState = useDropzone({
    onDrop: handleUpload,
    accept: { "text/csv": [".csv"] },
    maxFiles: 1,
    noClick: true,
    noKeyboard: true,
    disabled,
  });

  return {
    dropzoneState,
    parsing,
    invalidEmailsDialog,
    exportCsvData,
    handleEntries,
  };
};
