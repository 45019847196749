import { CheckCircle } from "@mui/icons-material";
import { Box } from "@mui/material";
import { ReactElement } from "react";
import { Database } from "../../../server/supabase/types/database-definitions";
import { MomentCardMoment } from "../types";

type MomentIconMoment =
  | Database["public"]["Views"]["calendar_with_triggered"]["Row"]
  | MomentCardMoment;

export const stripedBar = (
  moment: MomentIconMoment,
  styleProps?: React.CSSProperties,
): ReactElement => {
  const color2 = "rgba(0,0,0,0)";
  const theme_colour = moment.program?.theme_colour || "";
  return (
    <Box
      className="variable striped"
      sx={{
        backgroundImage: `linear-gradient(-45deg, ${theme_colour} 25%, ${color2} 25%, ${color2} 50%, ${theme_colour} 50%, ${theme_colour} 75%, ${color2} 75%, ${color2} 100%)`,
        backgroundSize: "48.57px 50.57px",
        minWidth: "100%",
        color: color2,
        ...styleProps,
      }}
    >
      .
    </Box>
  );
};

export const fullBar = (
  moment: MomentIconMoment,
  styleProps?: React.CSSProperties,
): ReactElement => (
  <Box
    className="variable active"
    sx={{
      backgroundColor: moment.program?.theme_colour,
      minWidth: "100%",
      color: moment.program?.theme_colour,
      ...styleProps,
    }}
  >
    .
  </Box>
);

export const hollowCircle = (
  moment: MomentIconMoment,
  styleProps?: React.CSSProperties,
): ReactElement => (
  <Box
    className={`specific ${moment.status ?? ""}`}
    sx={{
      height: "15px",
      width: "15px",
      borderRadius: "50%",
      border: `3px solid ${moment.program?.theme_colour || ""}`,
      display: "inline-block",
      ...styleProps,
    }}
  ></Box>
);

export const tickedCircle = (
  moment: MomentIconMoment,
  styleProps?: React.CSSProperties,
): ReactElement => (
  <CheckCircle
    className="specific finished"
    sx={{ color: moment.program?.theme_colour, width: "19px", height: "19px", ...styleProps }}
  />
);

export const fullCircle = (
  moment: MomentIconMoment,
  styleProps?: React.CSSProperties,
): ReactElement => (
  <Box
    className="specific active"
    sx={{
      height: "15px",
      width: "15px",
      backgroundColor: moment.program?.theme_colour,
      borderRadius: "50%",
      display: "inline-block",
      ...styleProps,
    }}
  ></Box>
);
