import { useSupabaseLoader } from "../../../server/supabase/hooks";
import { Asset, SupabaseDigitalAssetService } from "../../assets/server/digital-asset-service";

export const useAssets = (): ReturnType<typeof useSupabaseLoader<Asset[]>> => {
  return useSupabaseLoader(async ({ supabase, account_id }) => {
    const { data, error } = await new SupabaseDigitalAssetService(supabase).getAll(account_id, {
      order: [{ column: "created_at" }],
      filter: [{ key: "deleted_at", operator: "is", value: null }],
    });
    if (error) throw new Error(error.message);
    return data;
  }, []);
};
