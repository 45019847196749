import { Workers, serviceURL } from "../../utils/service-url";
import { ApiResponse, ApiService } from "./client";

export abstract class WorkerApiService extends ApiService {
  abstract module: Workers;

  async request<T>(method: string, path: string, body?: unknown): Promise<ApiResponse<T>> {
    const response = await fetch(`${serviceURL(this.module)}${path}`, {
      method,
      body: body ? JSON.stringify(body) : null,
      headers: this.headers(),
    });
    const data = [204, 401, 403].includes(response.status)
      ? undefined
      : ((await response.json()) as T & { error: string });
    return {
      error: response.ok ? undefined : data?.error || "Unknown error",
      data: response.ok ? data : undefined,
    };
  }
}
