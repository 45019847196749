import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { FC, Fragment, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Trans, useTranslation } from "react-i18next";
import { config } from "../../../../config";
import { useApiServiceCallback } from "../../../../server/api/client";
import { usePermissions } from "../../../../server/auth/use-permissions";
import { useSupabase, useSupabaseCallback } from "../../../../server/supabase/hooks";
import { Database } from "../../../../server/supabase/types/database-definitions";
import { ClipboardList } from "../../../../styles/icons/clipboard-list";
import { Refresh } from "../../../../styles/icons/refresh";
import { XCircle } from "../../../../styles/icons/x-circle";
import { removeItemFromArray, updateItemInArray } from "../../../../utils/immutable-arrays";
import { Scrollbar } from "../../../generic/components/scrollbar";
import { SeverityPill } from "../../../generic/components/severity-pill";
import { CustomEmailDomainsService } from "../client/custom-email-domains-service";
import { SupabaseCustomEmailDomainService } from "../server/supabase-custom-email-domain-service";
import { customEmailDomainSchema } from "../types";

export const EmailDomainConfigurationTable: FC<{
  defaultCustomDomains?: Database["public"]["Tables"]["custom_email_domain"]["Row"][];
}> = ({ defaultCustomDomains }) => {
  const { t } = useTranslation();
  const { ce_admin } = usePermissions();

  const [cnameDialogCustomEmailDomain, setCnameDialogCustomEmailDomain] = useState<
    Database["public"]["Tables"]["custom_email_domain"]["Row"] | null
  >(null);
  const [customDomains, setCustomDomains] = useState<
    Database["public"]["Tables"]["custom_email_domain"]["Row"][]
  >(defaultCustomDomains ?? []);

  useSupabase(
    async ({ supabase, account_id }) => {
      if (defaultCustomDomains) return;

      const supabaseDomainService = new SupabaseCustomEmailDomainService(supabase);
      const { data } = await supabaseDomainService.getAll(account_id, {
        order: [{ column: "id" }],
      });
      data && setCustomDomains(data);
    },
    [defaultCustomDomains],
  );

  const updateCustomDomain = (
    index: number,
    updates: Partial<Database["public"]["Tables"]["custom_email_domain"]["Row"]>,
  ): void => {
    setCustomDomains((prevArray) => updateItemInArray(prevArray, index, updates));
  };

  return (
    <Scrollbar>
      {customDomains.length === 0 && <NoCustomDomainsMessage />}
      {ce_admin ? (
        <AddCustomEmailDomain
          onAdd={(domain) => setCustomDomains((prevState) => [...prevState, domain])}
        />
      ) : (
        <Alert severity="info" sx={{ mt: 2, mb: 2 }}>
          <Typography variant="body2">
            {customDomains.length === 0
              ? t("Please contact support to configure a custom domain")
              : t("Please contact support to configure an additional custom domain")}
          </Typography>
        </Alert>
      )}
      {customDomains.length > 0 && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="65%">{t("Domain")}</TableCell>
                <TableCell width="15%">{t("Status")}</TableCell>
                <TableCell align="right">{t("Actions")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customDomains.map((customEmailDomain, index) => {
                return (
                  <Fragment key={customEmailDomain.id}>
                    <TableRow hover key={customEmailDomain.id}>
                      <TableCell width="65%">
                        <Typography color="textSecondary" variant="body2">
                          {customEmailDomain.domain}
                        </Typography>
                      </TableCell>
                      <TableCell width="15%">
                        <SeverityPill color={customEmailDomain.verified ? "success" : "info"}>
                          {customEmailDomain.verified ? t("Active") : t("Pending")}
                        </SeverityPill>
                      </TableCell>
                      <TableCell align="right">
                        {!customEmailDomain.verified && (
                          <RefreshButton
                            customEmailDomain={customEmailDomain}
                            onVerified={() => updateCustomDomain(index, { verified: true })}
                          />
                        )}
                        <Tooltip
                          title={t("View CNAME Records for {{domain}}", {
                            domain: customEmailDomain.domain,
                          })}
                          placement="right"
                        >
                          <IconButton
                            data-analytics-id="email-domain-table-view-cname"
                            onClick={() => setCnameDialogCustomEmailDomain(customEmailDomain)}
                          >
                            <ClipboardList fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        {!customEmailDomain.verified && (
                          <DeleteButton
                            customEmailDomain={customEmailDomain}
                            onDelete={() =>
                              setCustomDomains((prevArray) => removeItemFromArray(prevArray, index))
                            }
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  </Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {cnameDialogCustomEmailDomain && (
        <CNameRecordsDialog
          customEmailDomain={cnameDialogCustomEmailDomain}
          onClose={() => setCnameDialogCustomEmailDomain(null)}
        />
      )}
    </Scrollbar>
  );
};

const NoCustomDomainsMessage: FC = () => {
  const { t } = useTranslation();
  return (
    <Alert severity="warning" sx={{ mt: 2 }}>
      <Typography variant="body2">
        {t(
          "You do not have any custom domains configured. By default all emails will be sent from '{{default}}'",
          { default: config.defaultEmailFrom },
        )}
      </Typography>
    </Alert>
  );
};

const RefreshButton: FC<{
  customEmailDomain: Database["public"]["Tables"]["custom_email_domain"]["Row"];
  onVerified: () => void;
}> = ({ customEmailDomain, onVerified }) => {
  const { t } = useTranslation();
  const [refreshing, setRefreshing] = useState(false);

  const handleCheckStatus = useApiServiceCallback(
    CustomEmailDomainsService,
    async (
      { apiService },
      emailDomain: Database["public"]["Tables"]["custom_email_domain"]["Row"],
    ): Promise<void> => {
      setRefreshing(true);

      const { data } = await apiService.refreshVerifiedStatus(emailDomain.id);

      if (data) {
        if (data.verified) {
          toast.success(t("{{domain}} has been verified.", { domain: emailDomain.domain }));
          onVerified();
        } else {
          toast(t("{{domain}} has not yet been verified.", { domain: emailDomain.domain }), {
            icon: "⚠️",
          });
          setRefreshing(false);
        }
      } else {
        toast.error(
          t("Something went wrong when refreshing status for {{domain}}", {
            domain: emailDomain.domain,
          }),
        );
        setRefreshing(false);
      }
    },
    [onVerified, t],
  );

  return (
    <Tooltip
      title={t("Refresh Status for {{domain}}", {
        domain: customEmailDomain.domain,
      })}
      placement="right"
    >
      {refreshing ? (
        <IconButton data-analytics-id="email-domain-table-refreshing">
          <CircularProgress size="small" />
        </IconButton>
      ) : (
        <IconButton
          data-analytics-id="email-domain-table-refresh"
          onClick={() => {
            void handleCheckStatus(customEmailDomain).catch(console.error);
          }}
        >
          <Refresh fontSize="small" />
        </IconButton>
      )}
    </Tooltip>
  );
};

const DeleteButton: FC<{
  customEmailDomain: Database["public"]["Tables"]["custom_email_domain"]["Row"];
  onDelete: () => void;
}> = ({ customEmailDomain, onDelete }) => {
  const { t } = useTranslation();
  const [removing, setRemoving] = useState(false);

  const handleDelete = useSupabaseCallback(
    async (
      { supabase },
      emailDomain: Database["public"]["Tables"]["custom_email_domain"]["Row"],
    ): Promise<void> => {
      setRemoving(true);

      const error = await new SupabaseCustomEmailDomainService(supabase).delete(emailDomain.id);

      if (!error) {
        toast.success(t("{{domain}} deleted successfully.", { domain: emailDomain.domain }));
        onDelete();
      } else {
        toast.error(
          t("Something went wrong when deleting {{domain}}", { domain: emailDomain.domain }),
        );
        setRemoving(false);
      }
    },
    [onDelete, t],
  );
  return (
    <Tooltip title={t("Delete {{domain}}", { domain: customEmailDomain.domain })} placement="right">
      {removing ? (
        <IconButton data-analytics-id="email-domain-table-deleting">
          <CircularProgress size="small" />
        </IconButton>
      ) : (
        <IconButton
          data-analytics-id="email-domain-table-delete"
          onClick={() => {
            void handleDelete(customEmailDomain).catch(console.error);
          }}
        >
          <XCircle fontSize="small" />
        </IconButton>
      )}
    </Tooltip>
  );
};

const CNameRecordsDialog: FC<{
  customEmailDomain?: Database["public"]["Tables"]["custom_email_domain"]["Row"];
  onClose: () => void;
}> = ({ customEmailDomain, onClose }) => {
  const { t } = useTranslation();

  if (!customEmailDomain) return null;

  const cnameRecords = customEmailDomain.cname_records;

  return (
    <Dialog open={true} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>{t("CNAME Records to Add")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Trans>
            To complete the verification process, you have to add all of the following records to
            the DNS configuration for your domain. When we detect these records in the DNS
            configuration for your domain, the status for the domain changes to &quot;Active&quot;.
            This process is usually complete within 72 hours.
          </Trans>
        </DialogContentText>
        <Scrollbar>
          <Table sx={{ mt: 2 }}>
            <TableHead>
              <TableRow>
                <TableCell>{t("Name/Host")}</TableCell>
                <TableCell>{t("Type")}</TableCell>
                <TableCell>{t("Record value")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cnameRecords.map((token) => {
                return (
                  <Fragment key={token}>
                    <TableRow hover key={token}>
                      <TableCell>
                        {token}._domainkey.{customEmailDomain.domain}
                      </TableCell>
                      <TableCell>CNAME</TableCell>
                      <TableCell>{token}.dkim.amazonses.com</TableCell>
                    </TableRow>
                  </Fragment>
                );
              })}
            </TableBody>
          </Table>
        </Scrollbar>
      </DialogContent>
      <DialogActions>
        <Button data-analytics-id="email-domain-table-cname-dialog-close" onClick={onClose}>
          {t("Close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const AddCustomEmailDomain: FC<{
  onAdd: (domain: Database["public"]["Tables"]["custom_email_domain"]["Row"]) => void;
}> = ({ onAdd }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [saving, setSaving] = useState(false);

  const { handleSubmit, control } = useForm({
    defaultValues: { domain: "" },
    resolver: yupResolver(customEmailDomainSchema),
  });

  const onSubmit = useSupabaseCallback(
    async ({ supabase }, values: { domain: string }): Promise<void> => {
      setSaving(true);

      const service = new SupabaseCustomEmailDomainService(supabase);

      const { data, error } = await service.insert({ cname_records: [], domain: values.domain });

      setSaving(false);

      if (error || !data) {
        toast.error(
          t("Something went wrong when adding {{domain}}", {
            domain: values.domain,
          }),
        );
        return;
      }

      toast.success(
        t("{{domain}} added successfully.", {
          domain: values.domain,
        }),
      );
      onAdd(data);
      setOpen(false);
    },
    [onAdd, t],
  );

  const handleClickOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    // Prevent closing the dialog if we're saving
    if (!saving) {
      setOpen(false);
    }
  };

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <form id="create-domain-form" onSubmit={handleSubmit(onSubmit)}>
      <Button
        data-analytics-id="email-domain-table-add-domain"
        color="primary"
        size="large"
        sx={{ mt: 3, mb: 4 }}
        variant="contained"
        onClick={handleClickOpen}
      >
        {t("Add Domain")}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t("Add Domain")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Trans>
              Verify an entire domain. When you verify a new domain, we provide a set of DNS
              records. You have to add these records to the DNS configuration of the domain.
            </Trans>
          </DialogContentText>
          <Controller
            name="domain"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                margin="dense"
                id="domain"
                label={t("Domain")}
                type="text"
                fullWidth
                variant="standard"
                value={field.value}
                placeholder="example.com"
                onChange={field.onChange}
                helperText={error?.message}
                error={Boolean(error)}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button
            data-analytics-id="email-domain-add-domain-dialog-add"
            disabled={saving}
            type="submit"
            form="create-domain-form"
          >
            {saving ? <CircularProgress size={20} /> : t("Add")}
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
};
