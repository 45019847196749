import { ThemeProviderProps } from "@emotion/react";
import { ThemeProvider as MuiThemeProvider } from "@mui/material";
import { ReactElement, ReactNode } from "react";
import { ThemeConfig, createTheme } from ".";

interface Props {
  mode: ThemeConfig["mode"];
  children: ReactNode;
}
const ThemeProvider = ({ children, mode }: Props): ReactElement<ThemeProviderProps> => {
  return <MuiThemeProvider theme={createTheme({ mode })}>{children}</MuiThemeProvider>;
};

export default ThemeProvider;
