import createClient from "openapi-fetch";
import { paths } from "../../../api-engine/definitions/iconfinder-definitions";
import { serviceURL } from "../../utils/service-url";

export const iconfinder = createClient<paths>({
  baseUrl: serviceURL("iconfinder"),
  headers: {
    "Content-Type": "application/json",
  },
});
