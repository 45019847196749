import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { FC } from "react";

export const SequenceCardTitle: FC<{ title: string; date: string }> = ({ title, date }) => {
  return (
    <Stack spacing={0}>
      <Typography
        variant="body2"
        fontWeight={500}
        role="link"
        lineHeight={1.2}
        fontSize={12}
        sx={{
          WebkitLineClamp: "2",
          WebkitBoxOrient: "vertical",
          display: "-webkit-box",
          overflow: "hidden",
          width: "100%",
          paddingRight: "0.5em",
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="body2"
        color="text.secondary"
        fontSize={10}
        sx={{
          WebkitLineClamp: "1",
          WebkitBoxOrient: "vertical",
          display: "-webkit-box",
          overflow: "hidden",
          width: "100%",
          paddingRight: "0.5em",
        }}
      >
        {date}
      </Typography>
    </Stack>
  );
};
