import { Alert, AlertTitle, Divider, LinearProgress, Tab, Tabs, Tooltip } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Connections } from "../../modules/employees/components/connections/connections";
import { CsvUpload } from "../../modules/employees/components/csv-upload";
import { EmployeeDataGridWrapper } from "../../modules/employees/components/employee-data-grid-wrapper";
import { HRISIntegrationCard } from "../../modules/employees/components/hrisintegration-card";
import { Permissions } from "../../modules/employees/components/permissions/permissions";
import { useEmployeeData } from "../../modules/employees/hooks/use-employee-data";
import { SupabaseAccountEmployeeSyncConfigService } from "../../modules/generic/server/supabase-account-service";
import { Page } from "../../modules/layout/components/page";
import { PageContent } from "../../modules/layout/components/page-content";
import { PageHeader } from "../../modules/layout/components/page-header";
import { PageHeading } from "../../modules/layout/components/page-heading";
import { usePermissions } from "../../server/auth/use-permissions";
import { useFlags } from "../../server/optimizely";
import { useSupabase, useSupabaseCallback } from "../../server/supabase/hooks";
import { Database } from "../../server/supabase/types/database-definitions";

const DefaultPage: FC<{ syncType?: string | null }> = ({ syncType }) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (syncType === undefined) return;
    navigate(`/manage/employees/${(syncType || "HRIS").toLocaleLowerCase()}`);
  });
  return null;
};

const ManageEmployeesPage: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { super_admin } = usePermissions();
  const {
    flags: { send_to_connection },
    flagsLoaded,
  } = useFlags("send_to_connection");
  const [syncType, setSyncType] =
    useState<Database["public"]["Tables"]["account_employee_sync_config"]["Row"]["sync_type"]>();
  const [error, setError] = useState<boolean>(false);

  useSupabase(async ({ supabase }) => {
    const { data: accData, error: accError } = await new SupabaseAccountEmployeeSyncConfigService(
      supabase,
    ).maybeGet();
    if (accError) return setError(true);
    setSyncType(accData?.sync_type || null);
  }, []);

  const changeSyncType = useSupabaseCallback(
    async (
      { supabase, account_id },
      sync_type: Database["public"]["Tables"]["account_employee_sync_config"]["Row"]["sync_type"],
    ) => {
      setSyncType(sync_type);
      await new SupabaseAccountEmployeeSyncConfigService(supabase).upsert({
        account_id,
        sync_type,
      });
    },
    [],
  );

  const { data: employees, loading: employeeDataLoading, reload } = useEmployeeData();

  return (
    <Page title="Manage Employees | ChangeEngine">
      <PageHeader display="flex">
        <PageHeading
          heading={t("Manage Your Employees")}
          subheading={t("Upload and update from your employee database")}
        />
      </PageHeader>
      <PageContent>
        <>
          {error && (
            <Alert severity="error">
              <AlertTitle>{t("Something went wrong")}</AlertTitle>
            </Alert>
          )}
          <Tabs
            indicatorColor="primary"
            scrollButtons="auto"
            textColor="primary"
            value={flagsLoaded && location.pathname.split("/").slice(-1)[0].toUpperCase()}
            variant="scrollable"
            sx={{ mt: 3 }}
          >
            {["HRIS", "CSV"].map((method) => {
              const disabled = ![method, null, undefined].includes(syncType);
              return (
                <Tab
                  key={method}
                  style={{ pointerEvents: "auto" }}
                  label={
                    !disabled ? (
                      method
                    ) : (
                      <Tooltip
                        title={t(
                          `${
                            method === "HRIS" ? "CSV" : "HRIS"
                          } employee management is configured, to modify employee sync method please contact support`,
                        )}
                      >
                        <span>{method}</span>
                      </Tooltip>
                    )
                  }
                  value={method}
                  disabled={disabled}
                  sx={{ ml: 2 }}
                  component={Link}
                  to={disabled ? "#" : method.toLocaleLowerCase()}
                />
              );
            })}
            {super_admin && (
              <Tab
                style={{ pointerEvents: "auto" }}
                label={t("Permissions")}
                value="PERMISSIONS"
                sx={{ ml: 2 }}
                component={Link}
                to="permissions"
              />
            )}
            {send_to_connection && (
              <Tab
                style={{ pointerEvents: "auto" }}
                label={t("Connections")}
                value="CONNECTIONS"
                sx={{ ml: 2 }}
                component={Link}
                to="connections"
              />
            )}
          </Tabs>
          <Divider sx={{ mb: 3 }} />
          <Routes>
            <Route path="/" element={<DefaultPage syncType={syncType} />} />
            <Route
              path="hris"
              element={
                <HRISIntegrationCard
                  onConnect={async () => {
                    await changeSyncType("HRIS");
                  }}
                />
              }
            />
            <Route
              path="csv"
              element={
                <CsvUpload
                  onUpload={async () => {
                    await changeSyncType("CSV");
                  }}
                  onComplete={reload}
                />
              }
            />
            <Route path="permissions" element={<Permissions />} />
            <Route path="connections" element={<Connections />} />
          </Routes>
          <Divider sx={{ mt: 3, mb: 3 }} />
          <Routes>
            <Route
              path="hris"
              element={
                employeeDataLoading ? (
                  <LinearProgress />
                ) : (
                  <EmployeeDataGridWrapper
                    employeeData={employees ?? { data: [], keys: [] }}
                    type="hris"
                  />
                )
              }
            />
            <Route
              path="csv"
              element={
                employeeDataLoading ? (
                  <LinearProgress />
                ) : (
                  <EmployeeDataGridWrapper
                    employeeData={employees ?? { data: [], keys: [] }}
                    type="csv"
                  />
                )
              }
            />
          </Routes>
        </>
      </PageContent>
    </Page>
  );
};

export default ManageEmployeesPage;
