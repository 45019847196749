import { FC, ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { config } from "../../../../config";
import { useFlag } from "../../../../server/optimizely";
import { useSupabaseCallback } from "../../../../server/supabase/hooks";
import { useDialog } from "../../../../utils/hooks/use-dialog";
import { UUID } from "../../../../utils/uuid";
import { useAccount } from "../../../generic/hooks/use-account";
import { SupabaseMomentService } from "../../../moment/server/supabase-moment-service";
import { MomentCardDropData } from "../../types/moment-card-drop-data";
import { CopyDialog } from "./moment-card-copy-dialog";

export const MomentCardDropArea: FC<{ children: ReactNode }> = ({ children }) => {
  const { t } = useTranslation();
  const dialog = useDialog<
    ({ status: "ready" } & MomentCardDropData) | { status: "loading" | "error" }
  >();
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const pipeRef = useRef<HTMLIFrameElement>(null);
  const { account: destinationAccount } = useAccount();
  const [drag_and_drop] = useFlag("drag_and_drop");

  const messageCallback = useCallback(
    (e: MessageEvent<{ type: "moment_exported" } & { data?: MomentCardDropData }>) => {
      if (
        import.meta.env.VITE_SERVICE_ENVIRONMENT !== "local" &&
        !e.origin.endsWith("changeengine.com")
      ) {
        return;
      }
      if (e.data.type === "moment_exported" && dialog.open) {
        dialog.handleOpen(e.data.data ? { ...e.data.data, status: "ready" } : { status: "error" });
      }
    },
    [dialog],
  );

  useEffect(() => {
    if (!iframeLoaded || !drag_and_drop) return;
    window.addEventListener("message", messageCallback);
    return () => window.removeEventListener("message", messageCallback);
  }, [iframeLoaded, drag_and_drop, messageCallback]);

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>): void => {
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>): void => {
    event.preventDefault();
    const dragId = event.dataTransfer.getData("moment_card_copy");
    pipeRef.current?.contentWindow?.postMessage(
      { type: "moment_drop", dragId },
      { targetOrigin: config.auth0.base_url },
    );
    dialog.handleOpen({ status: "loading" });
  };

  const handleConfirm = useSupabaseCallback(
    async ({ supabase }, data?: { name: string }) => {
      if (dialog.data?.status !== "ready" || !dialog.data) return;

      if (data) {
        dialog.data.moment.title = data.name;
      }

      const { data: momentId, error } = await new SupabaseMomentService(supabase).importMoment(
        dialog.data.moment,
        dialog.data.moment?.id as UUID,
      );

      if (error) toast.error(t("Failed to copy Moment"));
      if (momentId)
        toast.success(
          <span>
            {t("Moment")}{" "}
            <Link to={`/moments/${momentId as string}`}>{dialog.data.moment.title}</Link>{" "}
            {t("copied successfully.")}
          </span>,
          { duration: 5000 },
        );
    },
    [dialog, t],
  );

  if (!drag_and_drop) return children;

  return (
    <>
      <div id="drop-area" onDragOver={handleDragOver} onDrop={handleDrop}>
        {children}
      </div>
      {dialog.data && destinationAccount && (
        <CopyDialog
          open={dialog.open}
          handleClose={dialog.handleClose}
          momentExport={dialog.data}
          destinationAccount={destinationAccount}
          onConfirm={handleConfirm}
        />
      )}
      <iframe
        title="pipe"
        ref={pipeRef}
        src={`${config.auth0.base_url}/moment-card-copy`}
        height="0"
        style={{ visibility: "hidden" }}
        onLoad={() => setIframeLoaded(true)}
      ></iframe>
    </>
  );
};
