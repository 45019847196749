import { useAtom } from "jotai";
import { useFlags } from "../../../server/optimizely";
import { useSupabase } from "../../../server/supabase/hooks";
import { atlasV2LibraryAtom } from "../library/store";
import { SupabaseAtlasService } from "../server/supabase-atlas-service";

export const useDiscoverLibraryLoader = (): void => {
  const [atlasV2Library, setAtlasV2Library] = useAtom(atlasV2LibraryAtom);
  const {
    flags: { sequences },
    flagsLoaded,
  } = useFlags("sequences");

  useSupabase(
    async ({ supabase }) => {
      if (atlasV2Library && Object.values(atlasV2Library).some((l) => l.length !== 0)) return;

      if (!flagsLoaded) return;

      const { data, error } = await new SupabaseAtlasService(supabase).getAtlasV2Library(sequences);

      if (error) throw error;

      setAtlasV2Library(data);
    },
    [sequences, flagsLoaded, atlasV2Library, setAtlasV2Library],
    "atlas",
  );
};
