import { Card, CardContent, LinearProgress, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UUID } from "../../../utils/uuid";
import { useSegments } from "../../segment/server/use-segments";
import { EmployeeData } from "../hooks/use-employee-data";
import { EmployeeDataGrid } from "./employee-data-grid";

export const EmployeeDataGridWrapper: FC<{
  employeeData: EmployeeData;
  type: "hris" | "csv";
}> = ({ employeeData, type }) => {
  const { t } = useTranslation();
  const [defaultAudience, setDefaultAudience] = useState<UUID | null | "LOADING">("LOADING");

  const { data: audiences, loading: audiencesLoading } = useSegments({
    is_recipient_type: true,
    include_deleted: false,
  });

  useEffect(() => {
    if (audiencesLoading) return setDefaultAudience("LOADING");

    setDefaultAudience(
      type === "hris"
        ? (audiences?.find((x) => x.name === "Current employees and upcoming new hires")?.id ??
            audiences?.find((x) => x.name === "Current employees")?.id ??
            null)
        : null,
    );
  }, [audiences, audiencesLoading, type]);

  if (audiencesLoading || defaultAudience === "LOADING") return <LinearProgress />;

  return (
    <>
      <Typography variant="h6">{t("View/Download employees")}</Typography>
      <Typography variant="subtitle2" color="text.secondary">
        {t("View and download a copy of your employee data")}
      </Typography>
      <Card>
        <CardContent>
          <EmployeeDataGrid
            employeeData={employeeData}
            type={type}
            audiences={audiences ?? []}
            defaultAudience={defaultAudience}
          />
        </CardContent>
      </Card>
    </>
  );
};
