import { ChannelName } from "../modules/channels/types";

export const channelToFormat = (channel: NonNullable<ChannelName>): "email" | "chat" | "sms" => {
  switch (channel) {
    case "work_email":
    case "personal_email":
      return "email";
    case "teams":
    case "slack":
      return "chat";
    case "twilio":
      return "sms";
  }
};
