import { Button, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { Trans } from "react-i18next";

export const EmptyState: FC = () => {
  return (
    <Stack gap={3} textAlign="center" maxWidth="md" mx="auto" alignItems="center">
      <svg xmlns="http://www.w3.org/2000/svg" width="130px" viewBox="0 0 24 24">
        <defs>
          <linearGradient id="Gradient1">
            <stop className="stop1" offset="8.45%" />
            <stop className="stop2" offset="106.53%" />
          </linearGradient>
        </defs>
        <style>
          {`
            .stop1 {
                stop-color: #00B6EF;
            }
            .stop2 {
                stop-color: #DF1C8E;
            }
            `}
        </style>
        <path
          d="M9 13h10l-3.45-4.5l-2.3 3l-1.55-2zm-6 8q-.825 0-1.412-.587T1 19V6h2v13h17v2zm4-4q-.825 0-1.412-.587T5 15V4q0-.825.588-1.412T7 2h5l2 2h7q.825 0 1.413.588T23 6v9q0 .825-.587 1.413T21 17zm0-2h14V6h-7.825l-2-2H7zm0 0V4z"
          fill="url(#Gradient1)"
        />
      </svg>
      <Trans>
        <Typography variant="h5">You haven&apos;t saved any content yet</Typography>
        <Typography variant="body1">
          When you create content with ChangeEngine, you&apos;ll be able to save it here to reuse at
          any time. Use the &quot;Save&quot; button on the edit screen.
        </Typography>
        <Typography variant="body2" color="text.secondary">
          (Excludes Moments, which you can find on the home page or in &quot;Sent Moments&quot;)
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="large"
          href="/create-content"
          data-analytics-id="content-library-empty-state-cta"
        >
          Create Content
        </Button>
      </Trans>
    </Stack>
  );
};
