import { BlurLinearOutlined } from "@mui/icons-material";
import { Button, Tooltip } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import { isEmpty, uniqWith } from "lodash-es";
import { FC } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useSupabaseCallback } from "../../../server/supabase/hooks";
import { Database } from "../../../server/supabase/types/database-definitions";
import { useDialog } from "../../../utils/hooks/use-dialog";
import { UUID } from "../../../utils/uuid";
import yup from "../../../utils/yup";
import { CrudDataGrid } from "../../generic/components/crud-data-grid/crud-data-grid";
import {
  RelatedMoments,
  RelatedMomentsDialog,
} from "../../generic/components/crud-data-grid/related-moments-dialog";
import { Columns, Row } from "../../generic/components/crud-data-grid/types";
import { SupabaseAccountPersonFieldService } from "../server/supabase-account-person-field-service";
import { eventKeyValidator } from "../types";

type EventRow = Database["public"]["Views"]["events_with_related_moments_person"]["Row"] & {
  id: UUID;
  moments: { id: UUID; title: string }[];
};

export const EventCrudDataGridPerson: FC<{
  events: Database["public"]["Views"]["events_with_related_moments_person"]["Row"][];
}> = ({ events }) => {
  const { t } = useTranslation();
  const dialog = useDialog<RelatedMoments>();

  const processedEvents: EventRow[] = uniqWith(events, (currentVal, otherVal) => {
    return currentVal.key === otherVal.key;
  }).map((datum) => ({
    ...datum,
    id: datum.id!,
    key: datum.key,
    moments: datum.moments as { id: UUID; title: string }[],
  }));

  const onUpdate = useSupabaseCallback(
    async ({ supabase }, event: EventRow) => {
      const { data, error } = await new SupabaseAccountPersonFieldService(supabase).update(
        event.id,
        {
          allow_initial: event.allow_initial,
          allow_repeats: event.allow_repeats,
        },
      );

      if (!data || error) {
        toast.error(t("Something went wrong"));
        return;
      }
      toast.success(t("Saved"));
      return event;
    },
    [t],
  );

  const onAdd = useSupabaseCallback(
    async ({ supabase }, event: EventRow) => {
      const { data, error } = await new SupabaseAccountPersonFieldService(supabase).insert({
        key: `event_${event.key}`,
        display_name: event.key ?? "",
        merge_sample: "December 25th",
        connection_merge_sample: "December 25th",
        merge_field: true,
        connection_merge_field: false,
        channel_merge_field: true,
        segmentation_field: false,
        allow_initial: event.allow_initial,
        allow_repeats: event.allow_repeats,
        field_type: "DATE",
      });
      if (!data || error) {
        toast.error(t("Something went wrong"));
        return;
      }
      toast.success(t("Saved"));
      return {
        ...event,
        id: data.id,
      };
    },
    [t],
  );

  const columns: Columns<EventRow> = {
    key: {
      headerName: t("Key/Merge Tag"),
      flex: 2,
      editable: true,
      options: {
        validator: async (value): Promise<string | undefined> => {
          return await eventKeyValidator
            .validate(value)
            .then(() => undefined)
            .catch((err: yup.ValidationError) => err.errors.join("\n"));
        },
      },
    },
    allow_initial: {
      headerName: t("Allow Initial"),
      type: "boolean",
      editable: true,
    },
    allow_repeats: {
      headerName: t("Allow Repeats"),
      type: "boolean",
      editable: true,
    },
    moments: {
      headerName: t("Related Moments"),
      editable: false,
      renderCell: (params) => {
        const numMoments = params.row.moments?.length ?? 0;
        return (
          <Button
            data-analytics-id="person-event-table-related-moments"
            onClick={() =>
              dialog.handleOpen({ title: params.row.key || "", moments: params.row.moments })
            }
            disabled={numMoments === 0}
          >
            {t("{{count}} moments", { count: numMoments })}
          </Button>
        );
      },
    },
    events: {
      headerName: t("Related People"),
      editable: false,
      type: "number",
    },
  };

  return (
    <>
      <CrudDataGrid<Row<EventRow>>
        initialRows={processedEvents}
        columns={columns}
        initialData={{
          key: "",
          allow_initial: true,
          allow_repeats: true,
        }}
        onUpdate={onUpdate}
        onAdd={onAdd}
        gridOverrides={{
          isCellEditable: (params) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const row: Row<EventRow> = params.row;
            if (row.options?.preventEditing) return false;
            // Only allow setting `key` on new fields or those without a value,
            // no editing of existing keys.
            if (params.colDef?.field === "key") return Boolean(row.isNew || isEmpty(row.key));
            return true;
          },
        }}
        modifyActions={(actions, row) => [
          ...actions,
          ...(row
            ? [
                <GridActionsCellItem
                  key={`timeline${row.id}`}
                  icon={
                    <Tooltip title={t("View Timeline")}>
                      <BlurLinearOutlined />
                    </Tooltip>
                  }
                  label="timeline"
                  onClick={() => {
                    row.key && window.open(`/timeline/${row.key}`, "_blank");
                  }}
                  disabled={row.moments ? row.moments.length === 0 : true}
                />,
              ]
            : []),
        ]}
      />
      <RelatedMomentsDialog subjectNamedInTitle="event" dialog={dialog} />
    </>
  );
};
