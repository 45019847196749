import { Box, BoxProps } from "@mui/material";
import { FC } from "react";

export const SequenceIcon: FC<BoxProps> = ({ sx = [] }) => {
  return (
    <Box
      sx={{
        height: "1.4em",
        display: "block",
        ...(sx instanceof Array ? sx : [sx]),
      }}
    >
      <svg height="100%" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.4121 3.95604C12.4121 5.86476 10.8648 7.41209 8.95604 7.41209C7.04732 7.41209 5.5 5.86476 5.5 3.95604C5.5 2.04732 7.04732 0.5 8.95604 0.5C10.8648 0.5 12.4121 2.04732 12.4121 3.95604Z"
          stroke="#828DF8"
        />
        <path
          d="M17.4121 11.956C17.4121 13.8647 15.8648 15.4121 13.956 15.4121C12.0473 15.4121 10.5 13.8647 10.5 11.956C10.5 10.0473 12.0473 8.5 13.956 8.5C15.8648 8.5 17.4121 10.0473 17.4121 11.956Z"
          stroke="#828DF8"
        />
        <path
          d="M7.41209 11.956C7.41209 13.8648 5.86472 15.4121 3.95604 15.4121C2.04737 15.4121 0.5 13.8648 0.5 11.956C0.5 10.0473 2.04737 8.5 3.95604 8.5C5.86472 8.5 7.41209 10.0473 7.41209 11.956Z"
          stroke="#828DF8"
        />
        <path d="M5.5 9L7 7" stroke="#828DF8" />
        <path d="M12.5 9L11 7" stroke="#828DF8" />
      </svg>
    </Box>
  );
};
