import { Box } from "@mui/material";
import { FC, ReactNode } from "react";

export const TimelinePill: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Box
      component={"span"}
      sx={{
        borderRadius: "4px",
        backgroundColor: "#666",
        px: 1,
        py: 0.2,
        color: "white",
        fontWeight: "bold",
      }}
    >
      {children}
    </Box>
  );
};
