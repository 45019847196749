import { Sms } from "@mui/icons-material";
import { SxProps, Theme } from "@mui/material";
import { FC } from "react";
import { PersonalEmailIcon, WorkEmailIcon } from "../../../styles/icons/email";
import { SlackIcon } from "../../../styles/icons/slack";
import { TeamsIcon } from "../../../styles/icons/teams";
import { ChannelName } from "../types";

interface ChannelIconProps {
  channel?: ChannelName;
  fontSize?: "inherit" | "large" | "medium" | "small";
  sx?: SxProps<Theme>;
}

export const ChannelIcon: FC<ChannelIconProps> = ({ channel, fontSize = "large", sx = {} }) => {
  switch (channel) {
    case "personal_email":
      return (
        <PersonalEmailIcon
          data-testid={`personal_email-icon`}
          fontSize={fontSize}
          sx={{ paddingRight: "2px", ...sx }}
        />
      );
    case "work_email":
      return (
        <WorkEmailIcon
          data-testid={`work_email-icon`}
          fontSize={fontSize}
          sx={{ paddingRight: "2px", ...sx }}
        />
      );
    case "slack":
      return <SlackIcon data-testid="slack-icon" fontSize={fontSize} sx={sx} />;
    case "teams":
      return <TeamsIcon data-testid="teams-icon" fontSize={fontSize} sx={sx} />;
    case "twilio":
      return <Sms data-testid="twilio-icon" color="primary" fontSize={fontSize} sx={sx} />;
    default:
      return null;
  }
};

export function channelNameMap(channel: ChannelName): string {
  switch (channel) {
    case "personal_email":
      return "Personal Email";
    case "work_email":
      return "Work Email";
    case "slack":
      return "Slack";
    case "teams":
      return "Teams";
    case "twilio":
      return "Twilio";
    default:
      return "";
  }
}
