import { Database } from "../../../server/supabase/types/database-definitions";
import { INTERVAL, ISO8601 } from "../../../utils/iso8601";
import { UUID } from "../../../utils/uuid";
import { ChannelName } from "../../channels/types";

export type Program = Database["public"]["Tables"]["program"]["Row"] & {
  icon: Database["public"]["Tables"]["icon"]["Row"];
};

export const isProgram = (program: unknown): program is UseMomentProperties["program"] => {
  return (
    (program as Program)?.id !== undefined &&
    (program as Program)?.title !== undefined &&
    (program as Program)?.theme_colour !== undefined &&
    (program as Program)?.theme_icon !== undefined
  );
};

export type MomentStatus = Exclude<Database["public"]["Views"]["moments"]["Row"]["status"], null>;
export type MomentCardMoment = Database["public"]["Views"]["moments"]["Row"] & {
  segment?: Database["public"]["Tables"]["segment"]["Row"] | null;
  program?: Program | null;
  schedule?: Database["public"]["Views"]["schedule_when_v2"]["Row"] | null;
};

export type UseMomentProperties = {
  id: UUID | null;
  account_id: UUID | null;
  title: string | null;
  status: MomentStatus | null;
  channel: ChannelName | null;
  valid_on_save: boolean | null;
  immediate: boolean | null;
  cover_image_path: string | null;
  timezone: string | null;
  published_at: ISO8601 | null;
  target_channel: boolean | null;
  notify_channel: boolean | null;
  target_manager: boolean | null;
  target_connection_type: string | null;
  use_lexical_editor: boolean | null;
  only_additional_recipients: boolean | null;
  additional_recipients_json: { display?: string | null; destination: string }[] | null;

  program?: {
    id: UUID;
    title: string;
    theme_colour: string;
    theme_icon: string;
    order: number | null;
  } | null;

  segment?: {
    id: UUID | null;
    name: string | null;
  } | null;

  schedule?: {
    id: UUID | null;
    when: ISO8601 | null;
    event_key: string | null;
    interval: INTERVAL | null;
  } | null;

  sequence?: {
    account_id: UUID;
    created_at: ISO8601;
    id: UUID;
    name: string;
    thumbnail: string | null;
  } | null;
};
