import { Autocomplete, Box, Chip, TextField } from "@mui/material";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { propertyNotEmpty } from "../../../../utils/not-empty";
import { SupabaseSegment } from "../../../segment/server/supabase-segment-service";
import { PermissionsFormType } from "../../types";

export const SegmentDenyListSelect: FC<{
  segments: SupabaseSegment[];
}> = ({ segments }) => {
  const { t } = useTranslation();

  const allowedSegments = segments
    .filter(propertyNotEmpty("name"))
    .map((s) => ({ title: s.name, id: s.id }));

  const { control } = useFormContext<PermissionsFormType>();

  return (
    <Controller
      name={"segment_deny_list"}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          multiple={true}
          value={allowedSegments.filter((s) => field.value?.includes(s.id))}
          options={[{ title: t("Select All"), id: "All" }].concat(allowedSegments)}
          renderOption={(renderProps, option) => (
            <Box component="li" {...renderProps} key={option.id}>
              {option.title}
            </Box>
          )}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip {...getTagProps({ index })} key={option.id} label={option.title} />
            ))
          }
          renderInput={(params) => (
            <TextField
              label={t("Restricted Segments")}
              {...params}
              sx={{ mt: 2 }}
              helperText={
                error?.message ??
                t("Create/edit segment will be disabled for users with restricted segments")
              }
              error={!!error}
              InputProps={{
                ...params.InputProps,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore https://github.com/mui/material-ui/issues/20160#issuecomment-600277849
                "data-testid": "segment-deny-list-select",
              }}
            />
          )}
          getOptionLabel={(option) => option.title}
          onChange={(_, v) => {
            const value = v.map((s) => s.id);
            field.onChange(value.includes("All") ? allowedSegments.map((p) => p.id) : value);
          }}
        />
      )}
    />
  );
};
