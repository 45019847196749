import { FC, useEffect } from "react";
import { config } from "../../config";

export const DesignHuddleLogin: FC = () => {
  useEffect(() => {
    DSHDLib.configure({
      domain: config.design_huddle.domain,
      client_id: config.design_huddle.client_id,
      visitor: true,
    });
  }, []);

  return null;
};
