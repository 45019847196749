import { MomentFormMoment } from "../types/moment-form-moment";

export type MomentEditStatus = "new" | "draft" | "published";

export const getMomentEditStatus = (moment: MomentFormMoment): MomentEditStatus => {
  const isNewMoment = moment.status === "draft" && moment.title === "";

  if (isNewMoment) return "new";

  switch (moment.status) {
    case "draft":
      return "draft";
    case "active":
    case "paused":
    case "finished":
    case "archived":
      return "published";
  }
};
