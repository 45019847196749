import { LinearProgress } from "@mui/material";
import { FC, PropsWithChildren } from "react";
import { UUID } from "../../utils/uuid";
import { ProgramListView } from "../program-list-view/program-list-view";
import { ClientFilter, useMoments } from "./hooks/use-moments";
import { MomentGrid } from "./moment-grid";

export type MomentsProps = {
  programId?: UUID;
  showCreate?: boolean;
  view?: "grid" | "list";
  groupByProgram?: boolean;
  filter?: ClientFilter;
};

export const Moments: FC<MomentsProps & PropsWithChildren> = ({
  programId,
  filter,
  showCreate,
  view = "list",
  groupByProgram = false,
  children,
}) => {
  const {
    data: moments,
    error,
    load: refresh,
  } = useMoments({
    filters: {
      ...(programId ? { program: programId } : {}),
    },
    clientFilter: filter,
  });

  if (!programId) return <div>Program not found</div>;
  if (error) return <div>Error</div>;
  if (!moments) return <LinearProgress />;

  return (
    <>
      {view === "list" && (
        <>
          {moments.length === 0 && children}
          {moments.length > 0 && (
            <ProgramListView
              moments={moments}
              groupByProgram={groupByProgram}
              columns={["title", "status", "when", "segment", "channel", "actions"]}
              reloadMoments={() => void refresh().catch(console.error)}
              wrapInCard={false}
            >
              {children}
            </ProgramListView>
          )}
        </>
      )}
      {view === "grid" && (
        <MomentGrid moments={moments} programId={programId} showCreate={showCreate}>
          {children}
        </MomentGrid>
      )}
    </>
  );
};
