import { useMountEffect } from "@react-hookz/web";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

const EmployeeFieldsPage: FC = () => {
  const navigate = useNavigate();

  useMountEffect(() => navigate("/manage/employee_data/merge_tags", { replace: true }));

  return <></>;
};

export default EmployeeFieldsPage;
