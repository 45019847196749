import { ApiResponse, ApiService } from "../../../server/api/client";

export type SyncStatus = "DISABLED" | "SYNCING" | "PARTIALLY_SYNCED" | "FAILED" | "DONE";

export type SyncStatusResponse = {
  model_name: string | null;
  last_sync_start: string | null;
  next_sync_start: string | null;
  status: SyncStatus;
  is_initial_sync: boolean;
};

export class HRISIntegrationService extends ApiService {
  module = "integration/hris";

  async getLocalToken(): Promise<ApiResponse<{ link_token: string }>> {
    return await this.get("local-token");
  }
}
