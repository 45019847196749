import { Box, Skeleton } from "@mui/material";
import { Loadable } from "jotai/vanilla/utils/loadable";
import { FC, RefObject, useCallback, useEffect, useRef, useState } from "react";
import { preloadImage } from "../../utils/image";
import { Dimensions } from "../design-huddle/types";

export type EditHandler = () => void;

const useContainerDimensions = (
  myRef: RefObject<HTMLDivElement>,
  ratio: number,
  hidden: boolean,
): { width: number; height: number } => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const getDimensions = (): Dimensions => ({
      width: myRef.current?.offsetWidth || 0,
      height: myRef.current?.offsetHeight || 0,
    });

    const handleResize = (): void => {
      setDimensions(getDimensions());
    };

    if (myRef.current) {
      setDimensions(getDimensions());
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [myRef, ratio, hidden]);

  return dimensions;
};

export const ContentPreviewImage: FC<{
  image: Loadable<Promise<string | null>>;
  ratio: number;
  onEdit: EditHandler;
  hidden: boolean;
}> = ({ image, onEdit, ratio, hidden }) => {
  const [imageUrl, setImageUrl] = useState<string | undefined>();
  const ref = useRef<HTMLDivElement>(null);

  const dimensions = useContainerDimensions(ref, ratio, hidden);

  const launchProject = useCallback(async () => {
    return new Promise(onEdit);
  }, [onEdit]);

  useEffect(() => {
    if (image.state === "loading") {
      setImageUrl(undefined);
    }

    if (image.state === "hasData" && image.data) {
      preloadImage(image.data)
        .then(() => setImageUrl(image.data ?? undefined))
        .catch(() => {
          console.log("Image preview failed");
        });
    }
  }, [image]);

  if (image.state === "hasError") {
    return null;
  }

  return (
    <Box
      ref={ref}
      data-testid="create-content-preview-image"
      data-state={image.state}
      sx={[
        (theme) => ({
          ...(hidden ? { display: "none" } : {}),
          borderStyle: "solid",
          borderWidth: 2,
          borderColor: "transparent",
          position: "relative",
          "&:hover": {
            borderColor: theme.palette.primary.main,
          },
          backgroundImage: `url(${image.state === "hasData" && imageUrl ? imageUrl : ""})`,
          boxSizing: "content-box",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundOrigin: "content-box",
          ...(image &&
            image.state === "hasData" &&
            image.data && {
              cursor: "pointer",
            }),
          height: dimensions.width * ratio + "px",
        }),
      ]}
      role={image.state === "hasData" ? "button" : undefined}
      onClick={image.state === "hasData" ? () => void launchProject() : undefined}
    >
      {(image.state === "loading" || !imageUrl) && (
        <Skeleton
          sx={{ width: "100%", height: "100%" }}
          data-testid="skeleton"
          variant="rectangular"
        />
      )}
    </Box>
  );
};
