import { useSupabaseLoader } from "../../../server/supabase/hooks";
import { SupabaseSegmentInvalidRecipientTypeKeysService } from "./supabase-segment-service";

export function useSegmentInvalidRecipientTypeKeys({
  recipient_type_key,
}: {
  recipient_type_key?: string | null;
}): ReturnType<typeof useSupabaseLoader<string[]>> {
  return useSupabaseLoader(
    async ({ supabase, account_id }) => {
      if (!recipient_type_key) return [];

      const { data, error } = await new SupabaseSegmentInvalidRecipientTypeKeysService(supabase)
        .getAllQuery()
        .eq("account_id", account_id)
        .eq("recipient_type_key", recipient_type_key)
        .single();

      if (error || !data) return [];
      return data.invalid_recipient_type_keys ?? [];
    },
    [recipient_type_key],
  );
}
