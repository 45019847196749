import { Stack } from "@mui/material";
import { FC, useMemo } from "react";
import { useFlags } from "../../../server/optimizely";
import { fromISO8601_DATE } from "../../../utils/iso8601";
import { useBrandTones } from "../../brand-kit/hooks/use-brand-tones";
import { BrandInfoSelected } from "../../brand-kit/types";
import { TemplateType } from "../../generic/atoms/types/template";
import { sequencesAtom } from "../../sequence/store";
import { useFixedProgramCarousels } from "../hooks/use-fixed-program-carousels";
import { LibraryTemplate } from "../library/types";
import { topTenTemplatesAtom, trendingTemplatesAtom, upcomingTemplatesAtom } from "../store";
import { DiscoverTemplate } from "../types";
import { DiscoverCardBadge } from "./discover-card-badge";
import { DiscoverCarousel } from "./discover-carousel";

export const programNameGetter = (t: DiscoverTemplate): string | null => {
  if ("program" in t) return t.program?.name;
  else return null;
};

export const DiscoverCarousels: FC<{
  brandKitSelections?: BrandInfoSelected;
}> = ({ brandKitSelections }) => {
  const {
    flags: { sequences },
  } = useFlags("sequences");

  const { data: brandTones, loading } = useBrandTones();
  const brandTone = useMemo(() => brandTones?.find((b) => b.is_default), [brandTones]);

  const fixedProgramCarousels = useFixedProgramCarousels({
    brandKitSelections,
    brandTone,
    loading,
  });

  return (
    <Stack gap={4} pt={4}>
      {sequences && (
        <DiscoverCarousel
          title="Sequences"
          loading={loading}
          templatesAtom={sequencesAtom}
          brandKitSelections={brandKitSelections}
          subheadingGetter={programNameGetter}
        />
      )}
      <DiscoverCarousel
        title="Popular Moments"
        loading={loading}
        templatesAtom={trendingTemplatesAtom}
        brandTone={brandTone}
        brandKitSelections={brandKitSelections}
        Badge={DiscoverCardBadge}
        subheadingGetter={programNameGetter}
      />
      <DiscoverCarousel
        title="Upcoming Moments"
        loading={loading}
        templatesAtom={upcomingTemplatesAtom}
        brandTone={brandTone}
        brandKitSelections={brandKitSelections}
        subheadingGetter={programNameGetter}
      />
      <DiscoverCarousel
        title="Top 10 Moments"
        loading={loading}
        templatesAtom={topTenTemplatesAtom}
        brandTone={brandTone}
        brandKitSelections={brandKitSelections}
        Badge={DiscoverCardBadge}
        subheadingGetter={programNameGetter}
      />
      {fixedProgramCarousels}
    </Stack>
  );
};

export const programSubheadingOverrides: {
  [key: string]: (t: DiscoverTemplate) => string | null;
} = {
  holidays: (t) => (t.type === TemplateType.moment ? t.geo : null),
};

const parseSendTime = (sendTime: string): number => {
  const extractedNumber = parseInt(sendTime, 10);

  return (
    extractedNumber *
    (sendTime.includes("before") ? -1 : 1) *
    (sendTime.includes("week") ? 7 : 1) *
    (sendTime.includes("month") ? 30 : 1) *
    (sendTime.includes("year") ? 365 : 1)
  );
};

export function getSortValue(template: LibraryTemplate): Date | number {
  return template.date !== null
    ? fromISO8601_DATE(template.date)
    : parseSendTime(template.recommendations.send_time);
}
