import {
  Components,
  PaletteColorOptions,
  ThemeOptions,
  TypeBackground,
  TypeText,
  alpha,
} from "@mui/material";

import { SidebarColorOptions, StatusColorOptions } from ".";
import { neutral } from "./base-theme-options";

// Colors

const background: TypeBackground = {
  default: "#0B0F19",
  paper: neutral[900],
  active: "rgba(255,255,255, 0.12)",
  contrast: "#0C0F19",
  baseTrack: "#177DDC",
  switchTrack: "rgba(255,255,255,.35)",
  sidebar: neutral[900],
  drawer: "#0B0F19",
  selected: alpha("#909BEF", 0.2),
};

const sidebar: SidebarColorOptions = {
  hover: "rgba(255, 255, 255, 0.12)",
  divider: "#2D3748",
};

const divider = "#4D5768";

const primary: PaletteColorOptions = {
  main: "#7582EB",
  light: "#909BEF",
  dark: "#515BA4",
  contrastText: neutral[900],
};

const secondary: PaletteColorOptions = {
  main: "#10B981",
  light: "#3FC79A",
  dark: "#0B815A",
  contrastText: neutral[900],
};

const success: PaletteColorOptions = {
  main: "#14B8A6",
  light: "#43C6B7",
  dark: "#0E8074",
  contrastText: neutral[900],
};

const info: PaletteColorOptions = {
  main: "#2196F3",
  light: "#64B6F7",
  dark: "#0B79D0",
  contrastText: neutral[900],
};

const warning: PaletteColorOptions = {
  main: "#FFB020",
  light: "#FFBF4C",
  dark: "#B27B16",
  contrastText: neutral[900],
};

const error: PaletteColorOptions = {
  main: "#D14343",
  light: "#DA6868",
  dark: "#922E2E",
  contrastText: neutral[900],
};

const disabled: PaletteColorOptions = {
  main: neutral[400],
  light: neutral[400],
  dark: neutral[400],
  contrastText: "#FFFFFF",
};

const text: TypeText = {
  primary: "#EDF2F7",
  secondary: "#A0AEC0",
  disabled: "rgba(255, 255, 255, 0.48)",
  discreet: neutral[400],
  sidebar: "#EDF2F7",
};

export const momentStatus: StatusColorOptions = {
  draft: warning.main,
  archived: disabled.main,
  active: error.main,
  finished: info.main,
  paused: disabled.main,
};

const PageHeader: Components["PageHeader"] = {
  styleOverrides: {
    background: "linear-gradient(0deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.8)), #7582EB",
  },
};

export const darkThemeOptions: ThemeOptions & {
  components: {
    MuiDataGrid: unknown;
  };
} = {
  components: {
    PageHeader,
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: background.paper,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: background.default,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: neutral[500],
          color: "#FFFFFF",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          "&.MuiChip-filledDefault": {
            backgroundColor: neutral[800],
            "& .MuiChip-deleteIcon": {
              color: neutral[500],
            },
          },
          "&.MuiChip-outlinedDefault": {
            borderColor: neutral[700],
            "& .MuiChip-deleteIcon": {
              color: neutral[700],
            },
          },
          "&.MuiChip-clickable:hover": {
            backgroundColor: neutral[600],
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          "&::placeholder": {
            opacity: 1,
            color: text.secondary,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: divider,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderColor: divider,
          borderStyle: "solid",
          borderWidth: 1,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderColor: divider,
          borderStyle: "solid",
          borderWidth: 1,
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: neutral[700],
        },
        track: {
          backgroundColor: neutral[500],
          opacity: 1,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${divider}`,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: neutral[800],
          ".MuiTableCell-root": {
            color: neutral[300],
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          "& .MuiDataGrid-cell:hover": {
            overflow: "visible!important",
          },
          "& .MuiDataGrid-cellContent:hover": {
            zIndex: 999,
            overflow: "visible",
            paddingLeft: "1em",
            paddingRight: "1em",
            display: "block",
            top: "2em",
            color: info.contrastText,
            background: info.main,
            textAlign: "center",
            borderRadius: "8px",
            boxShadow: "4px 3px 10px #7582EB",
          },
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          "& .MuiToggleButtonGroup-grouped:not(:first-of-type).Mui-selected, & .Mui-selected": {
            border: "1px solid",
            borderColor: `${primary.main}80`,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          ":hover": {
            backgroundColor: "rgba(255, 255, 255, 0.08)",
            color: primary.main,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        textPrimary: {
          ":hover": {
            backgroundColor: `${primary.light}20`,
            color: primary.light,
          },
        },
      },
    },
  },
  palette: {
    action: {
      active: neutral[400],
      hover: "rgba(255, 255, 255, 0.08)",
      selected: "rgba(255, 255, 255, 0.12)",
      disabledBackground: "rgba(255, 255, 255, 0.12)",
      disabled: "rgba(255, 255, 255, 0.26)",
    },
    background,
    divider,
    error,
    info,
    mode: "dark",
    neutral,
    primary,
    secondary,
    success,
    text,
    warning,
    disabled,
    sidebar,
    status: momentStatus,
  },
  shadows: [
    "none",
    "0px 1px 2px rgba(0, 0, 0, 0.24)",
    "0px 1px 2px rgba(0, 0, 0, 0.24)",
    "0px 1px 4px rgba(0, 0, 0, 0.24)",
    "0px 1px 5px rgba(0, 0, 0, 0.24)",
    "0px 1px 6px rgba(0, 0, 0, 0.24)",
    "0px 2px 6px rgba(0, 0, 0, 0.24)",
    "0px 3px 6px rgba(0, 0, 0, 0.24)",
    "0px 4px 6px rgba(0, 0, 0, 0.24)",
    "0px 5px 12px rgba(0, 0, 0, 0.24)",
    "0px 5px 14px rgba(0, 0, 0, 0.24)",
    "0px 5px 15px rgba(0, 0, 0, 0.24)",
    "0px 6px 15px rgba(0, 0, 0, 0.24)",
    "0px 7px 15px rgba(0, 0, 0, 0.24)",
    "0px 8px 15px rgba(0, 0, 0, 0.24)",
    "0px 9px 15px rgba(0, 0, 0, 0.24)",
    "0px 10px 15px rgba(0, 0, 0, 0.24)",
    "0px 12px 22px -8px rgba(0, 0, 0, 0.24)",
    "0px 13px 22px -8px rgba(0, 0, 0, 0.24)",
    "0px 14px 24px -8px rgba(0, 0, 0, 0.24)",
    "0px 20px 25px rgba(0, 0, 0, 0.24)",
    "0px 25px 50px rgba(0, 0, 0, 0.24)",
    "0px 25px 50px rgba(0, 0, 0, 0.24)",
    "0px 25px 50px rgba(0, 0, 0, 0.24)",
    "0px 25px 50px rgba(0, 0, 0, 0.24)",
  ],
};
