import { ChevronRight } from "@mui/icons-material";
import { Box, Button, ButtonBase, Collapse, Link, LinkProps, SvgIcon } from "@mui/material";
import { FC, ReactNode, forwardRef, useCallback, useState } from "react";
import { ChevronDown } from "../../../../styles/icons/chevron-down";

type ItemType = {
  active?: () => boolean;
  children?: ReactNode;
  depth?: number;
  disabled?: boolean;
  external?: boolean;
  icon?: ReactNode;
  endIcon?: ReactNode;
  label?: ReactNode;
  open?: boolean;
  title: string;
  onClick?:
    | { type: "href"; value: string }
    | { type: "function"; value: () => void; ref?: React.Ref<HTMLButtonElement> };
};

export const Item: FC<ItemType> = ({
  active,
  children,
  depth = 0,
  disabled,
  external,
  icon,
  label,
  open,
  onClick,
  title,
  endIcon,
}) => {
  // Icons can be defined at top level only, deep levels have bullets instead of actual icons.
  const startIcon =
    depth === 0 ? (
      icon
    ) : (
      <Box
        sx={{
          alignItems: "center",
          display: "center",
          height: 20,
          justifyContent: "center",
          width: 20,
        }}
      >
        <Box
          sx={{
            backgroundColor: "var(--nav-item-icon-color)",
            borderRadius: "50%",
            height: 4,
            opacity: 0,
            width: 4,
            ...(active && {
              backgroundColor: "var(--nav-item-icon-active-color)",
              height: 6,
              opacity: 1,
              width: 6,
            }),
          }}
        />
      </Box>
    );

  const offset = depth === 0 ? 0 : (depth - 1) * 16;

  return (
    <li>
      {children ? (
        <ItemWithChildren
          active={active}
          depth={depth}
          disabled={disabled}
          icon={startIcon}
          open={open}
          title={title}
          offset={offset}
        />
      ) : (
        <ItemWithoutChildren
          active={active}
          depth={depth}
          disabled={disabled}
          icon={startIcon}
          offset={offset}
          title={title}
          label={label}
          onClick={onClick}
          external={external}
          endIcon={endIcon}
        />
      )}
    </li>
  );
};

const ItemWithoutChildren: FC<
  Pick<
    ItemType,
    | "disabled"
    | "active"
    | "depth"
    | "icon"
    | "title"
    | "label"
    | "onClick"
    | "external"
    | "endIcon"
  > & {
    offset: number;
  }
> = ({ active, depth = 0, disabled, icon, offset, title, label, onClick, external, endIcon }) => {
  const linkProps = !onClick
    ? {}
    : onClick.type === "function"
      ? {
          component: Button,
          onClick: onClick.value,
          ref: onClick.ref,
        }
      : external
        ? {
            defaultComponent: "a",
            href: onClick.value,
            target: "_blank",
          }
        : {
            component: LinkNoUnderline,
            href: onClick.value,
          };

  const isActive = active ? active() : false;

  return (
    <ButtonBase
      disabled={disabled}
      sx={{
        alignItems: "center",
        borderRadius: 1,
        display: "flex",
        justifyContent: "flex-start",
        pl: `${16 + offset}px`,
        pr: "16px",
        py: "6px",
        textAlign: "left",
        width: "100%",
        ...(isActive && {
          ...(depth === 0 && {
            backgroundColor: "var(--nav-item-active-bg)",
          }),
        }),
        "&:hover": {
          backgroundColor: "var(--nav-item-hover-bg)",
        },
      }}
      {...linkProps}
    >
      {icon && (
        <Box
          component="span"
          sx={{
            alignItems: "center",
            color: "var(--nav-item-icon-color)",
            display: "inline-flex",
            justifyContent: "center",
            mr: 2,
            ...(isActive && {
              color: "var(--nav-item-icon-active-color)",
            }),
          }}
        >
          {icon}
        </Box>
      )}
      <Box
        component="span"
        sx={{
          color: "var(--nav-item-color)",
          flexGrow: 1,
          fontFamily: (theme) => theme.typography.fontFamily,
          fontSize: depth > 0 ? 13 : 14,
          fontWeight: depth > 0 ? 500 : 600,
          lineHeight: "24px",
          whiteSpace: "nowrap",
          ...(isActive && {
            color: "var(--nav-item-active-color)",
          }),
          ...(disabled && {
            color: "var(--nav-item-disabled-color)",
          }),
        }}
      >
        {title}
      </Box>
      {label && (
        <Box component="span" sx={{ ml: 2 }}>
          {label}
        </Box>
      )}
      {endIcon && (
        <SvgIcon
          sx={{
            color: "var(--nav-item-chevron-color)",
            fontSize: 16,
            ml: 2,
          }}
        >
          {endIcon}
        </SvgIcon>
      )}
    </ButtonBase>
  );
};

const ItemWithChildren: FC<
  Pick<ItemType, "open" | "disabled" | "children" | "active" | "depth" | "icon" | "title"> & {
    offset: number;
  }
> = ({ children, open: openInitial, disabled, offset, active, depth = 0, icon, title }) => {
  const [open, setOpen] = useState(openInitial);

  const handleToggle = useCallback((): void => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  const isActive = active ? active() : false;

  return (
    <>
      <ButtonBase
        disabled={disabled}
        onClick={handleToggle}
        sx={{
          alignItems: "center",
          borderRadius: 1,
          display: "flex",
          justifyContent: "flex-start",
          pl: `${16 + offset}px`,
          pr: "16px",
          py: "6px",
          textAlign: "left",
          width: "100%",
          ...(isActive && {
            ...(depth === 0 && {
              backgroundColor: "var(--nav-item-active-bg)",
            }),
          }),
          "&:hover": {
            backgroundColor: "var(--nav-item-hover-bg)",
          },
        }}
      >
        {icon && (
          <Box
            component="span"
            sx={{
              alignItems: "center",
              color: "var(--nav-item-icon-color)",
              display: "inline-flex",
              justifyContent: "center",
              mr: 2,
              ...(isActive && {
                color: "var(--nav-item-icon-active-color)",
              }),
            }}
          >
            {icon}
          </Box>
        )}
        <Box
          component="span"
          sx={{
            color: "var(--nav-item-color)",
            flexGrow: 1,
            fontFamily: (theme) => theme.typography.fontFamily,
            fontSize: depth > 0 ? 13 : 14,
            fontWeight: depth > 0 ? 500 : 600,
            lineHeight: "24px",
            whiteSpace: "nowrap",
            ...(isActive && {
              color: "var(--nav-item-active-color)",
            }),
            ...(disabled && {
              color: "var(--nav-item-disabled-color)",
            }),
          }}
        >
          {title}
        </Box>
        <SvgIcon
          sx={{
            color: "var(--nav-item-chevron-color)",
            fontSize: 16,
            ml: 2,
          }}
        >
          {open ? <ChevronDown /> : <ChevronRight />}
        </SvgIcon>
      </ButtonBase>
      <Collapse in={open} sx={{ mt: 0.5 }}>
        {children}
      </Collapse>
    </>
  );
};

const LinkNoUnderline = forwardRef<HTMLAnchorElement, LinkProps>(({ ...props }, refForwarded) => (
  <Link {...props} key={props.key} ref={refForwarded} underline="none" />
));
LinkNoUnderline.displayName = "LinkNoUnderline";
