/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { SupabaseService } from "../../../server/supabase/base-supabase-service";
import { Database } from "../../../server/supabase/types/database-definitions";
import { UUID } from "../../../utils/uuid";

export type PopulatedProgram = Database["public"]["Views"]["populated_programs"]["Row"];
export type Program = Database["public"]["Tables"]["program"]["Row"];

export const programsOrder: { column: keyof Database["public"]["Tables"]["program"]["Row"] }[] = [
  { column: "order" },
  { column: "title" },
];

export class SupabaseProgramService extends SupabaseService<"program"> {
  table = "program" as const;

  async getAllPopulated() {
    return this.log_errors(await this.client.from("populated_programs").select("*"));
  }

  async getPopulated(id: UUID) {
    return this.log_errors(
      await this.client.from("populated_programs").select("*").eq("id", id).single(),
    );
  }

  async getPopulatedByTitle(title: string) {
    return this.log_errors(
      await this.client
        .from("populated_programs")
        .select("*")
        .is("visible", true)
        .like("title", title)
        .single(),
    );
  }
}
