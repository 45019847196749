import { Avatar, AvatarProps } from "@mui/material";
import { split } from "lodash-es";
import { FC } from "react";

function stringToHex(string: string): string {
  // Convert the string to a hex color
  let hash = 0;

  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += ("00" + value.toString(16)).slice(-2);
  }

  return color;
}

interface StringAvatarProps extends AvatarProps {
  name: string;
}

export const StringAvatar: FC<StringAvatarProps> = ({ name, ...props }) => {
  const { sx: defaultSx, ...rest } = props;

  return (
    <Avatar sx={{ ...(defaultSx || {}), bgcolor: stringToHex(name) }} {...rest}>
      {split(name, " ")
        .map((n) => n[0])
        .join("")}
    </Avatar>
  );
};
