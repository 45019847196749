declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace Intl {
    type Key = "timeZone";
    function supportedValuesOf(input: Key): string[];
  }
}

export function userTimeZone(): string {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function supportedTimeZones(): string[] {
  return Intl.supportedValuesOf("timeZone").filter((t) => t.includes("/") && !t.includes("Etc"));
}
