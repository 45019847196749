import { GetTokenSilentlyOptions } from "@auth0/auth0-react";
import { PostgrestClient } from "@supabase/postgrest-js";
import { Database } from "../types/database-definitions";
import { OrgUser, SupabaseHandlerArg, SupabaseSchemaName } from "./types";

export const getSupabase = <Database, SchemaName extends SupabaseSchemaName<Database>>(
  url: string,
  key: string,
  access_token: string,
  schema = "public" as SchemaName,
): PostgrestClient<Database, SchemaName> =>
  new PostgrestClient(`${url}/rest/v1`, {
    headers: {
      apikey: key,
      Authorization: `Bearer ${access_token}`,
    },
    schema,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    fetch: (...args) => fetch(...args),
  });

export async function withSupabaseToken<SchemaName extends SupabaseSchemaName<Database> = "public">(
  user: OrgUser | undefined,
  isLoading: boolean,
  getToken: (options?: GetTokenSilentlyOptions) => Promise<string>,
  loginWithRedirect: () => Promise<void>,
  schema: SchemaName,
): Promise<SupabaseHandlerArg<SchemaName> | undefined> {
  if (isLoading) return;
  if (!user || !user["http://changeengine.com/supabase_url"]) {
    await loginWithRedirect();
    return;
  }
  // We use the AccessToken here because it has the same lifetime as the Supabase token.
  const auth0Token = JSON.parse(window.atob((await getToken()).split(".")[1])) as {
    "http://changeengine.com/sb_token": string;
  };

  return {
    supabase: getSupabase<Database, SchemaName>(
      user["http://changeengine.com/supabase_url"],
      user["http://changeengine.com/supabase_anon_key"],
      auth0Token["http://changeengine.com/sb_token"],
      schema,
    ),
    account_id: user["http://changeengine.com/account_id"],
    org_id: user.org_id,
    token: auth0Token["http://changeengine.com/sb_token"],
  };
}
