import { Box, BoxProps, styled, Typography } from "@mui/material";
import { FC, MouseEventHandler, useState } from "react";
import { ProgramIcon } from "../programs/components/program-icon";
import { ValidProgram } from "./types";

export interface ProgramCardProps extends BoxProps {
  program: ValidProgram;
}

const Wrapper = styled(Box)(`
flex-grow: 1;
  cursor: pointer;
  min-width: 170px;
  max-width: 200px;
`);

const IconWrapper = styled(Box)(`
  height: 0;
  padding-bottom: 80%;
  position: relative;
  text-align: center;
  border-radius: 50%;
  box-shadow: 0 0 5px 5px #1e1e1e10;
  margin: 10%;
`);

const IconPositioner = styled(Box)(`
  position: absolute;
  top: 25%;
  left: 25%;
  width: 50%;
  height: 50%;
`);

const useHover = (): {
  active: boolean;
  onMouseOver: MouseEventHandler;
  onMouseOut: MouseEventHandler;
} => {
  const [hovered, setHovered] = useState(false);
  const handleMouseOver: MouseEventHandler = (): void => setHovered(true);
  const handleMouseOut: MouseEventHandler = (): void => setHovered(false);
  return {
    active: hovered,
    onMouseOver: handleMouseOver,
    onMouseOut: handleMouseOut,
  };
};

export const ProgramCard: FC<ProgramCardProps> = ({
  program: { theme_colour: color, id, title, icon_value: icon },
  ...props
}) => {
  const handleClick: MouseEventHandler = (e): void => {
    e.preventDefault();
    window.location.href = `/tabbed-programs/${id}`;
  };

  const { active, ...wrapperHoverProps } = useHover();

  return (
    <Wrapper onClick={handleClick} {...wrapperHoverProps} {...props}>
      <IconWrapper bgcolor={active ? "background.active" : "background.paper"}>
        {icon && (
          <IconPositioner>
            <ProgramIcon
              data={icon}
              sx={{
                color,
                top: 0,
                left: 0,
                position: "absolute",
                fontSize: "calc(16vw)",
                width: "100%",
                height: "100%",
              }}
            />
          </IconPositioner>
        )}
      </IconWrapper>
      <Box textAlign="center">
        <Typography
          variant="h6"
          sx={{ textDecoration: active ? "underline !important" : "none", wordWrap: "break-word" }}
        >
          {title}
        </Typography>
      </Box>
    </Wrapper>
  );
};
