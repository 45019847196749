import { CircularProgress } from "@mui/material";
import { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { redirect, useParams } from "react-router-dom";
import { useAsset } from "../../modules/assets/hooks/use-asset";
import { useUpdateAssetCallback } from "../../modules/assets/hooks/use-save-asset-callback";
import { useDesignHuddle } from "../../modules/design-huddle/use-design-huddle";
import { EditContent, SaveAssetHandler } from "../../modules/edit-content/edit-content";
import { LoadingModal } from "../../modules/edit-content/loading-modal";
import { Page } from "../../modules/layout/components/page";
import { useFlag } from "../../server/optimizely";
import { UUID } from "../../utils/uuid";

const AssetPage: FC = () => {
  const { t } = useTranslation();

  const [multiFormat, , multiFormatLoaded] = useFlag("multi_format");
  const { id } = useParams() as { id: UUID };
  const { data: asset, loading, error } = useAsset(id);

  const designHuddleReady =
    useDesignHuddle(asset ? [asset.project_id] : []) || asset?.project_id === "dummy";

  const [ready, setReady] = useState(false);
  const handleReady = useCallback(() => setReady(true), []);

  const updateAsset = useUpdateAssetCallback();

  const handleSaveAsset: SaveAssetHandler = useCallback(
    (opts) => {
      if (!asset) return Promise.reject(new Error("Asset not found"));
      return updateAsset({
        asset,
        ...opts,
      });
    },
    [asset, updateAsset],
  );

  if (!asset) return null;
  if (multiFormatLoaded && !multiFormat) redirect("/404");
  if (loading) return <CircularProgress />;
  if (error) {
    console.error(error);
    return (
      <div>{t("Sorry, we encountered a problem trying to load this page. Please try again.")}</div>
    );
  }

  return (
    <Page title={t(asset.title)} sx={{ height: "100%" }}>
      {designHuddleReady && (
        <EditContent
          title={asset.title}
          projectId={asset.project_id}
          page={0}
          onReady={handleReady}
          onSave={handleSaveAsset}
        />
      )}
      {asset.project_id !== "dummy" && <LoadingModal open={!ready || !designHuddleReady} />}
    </Page>
  );
};

export default AssetPage;
