import { yupResolver } from "@hookform/resolvers/yup";
import { LightbulbOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Dialog, DialogProps, Stack, Typography } from "@mui/material";
import { ErrorBoundary } from "@sentry/react";
import { useAtomValue, useSetAtom } from "jotai";
import { loadable, useResetAtom } from "jotai/utils";
import { omit } from "lodash-es";
import { FC, useCallback, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Basic } from "unsplash-js/dist/methods/photos/types";
import yup from "../../utils/yup";
import { imageAtom } from "../generate/store";
import { ImageRefineAtoms } from "../generic/atoms/types/refine";
import { ImagePreviewData } from "../generic/atoms/types/template";
import { CeTextInput } from "../generic/components/form-fields/ce-text-field";
import { FormStack } from "../generic/components/form-fields/form-stack";
import { AiRefineImagePreview } from "./ai-refine-image-preview";
import { usePreviewImageUrl } from "./hooks/use-preview-image-url";
import { imagePhotosFamily, imageRefineAtomsAtom } from "./store";

interface AiRefineImageModalProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
}

export const imageSchema = yup.object({
  header: yup.string().required().max(60),
  subheader: yup.string().max(60),
});

type FormData = Pick<ImagePreviewData, "header" | "subheader">;

export const AiRefineImageModal: FC<AiRefineImageModalProps> = (props) => {
  const refineAtoms = useAtomValue(imageRefineAtomsAtom);

  if (!refineAtoms) return null;
  return <AiRefineImageModalInner {...props} refineAtoms={refineAtoms} />;
};

const AiRefineImageModalInner: FC<AiRefineImageModalProps & { refineAtoms: ImageRefineAtoms }> = ({
  onClose,
  refineAtoms,
  ...props
}) => {
  const { t } = useTranslation();
  const num = useAtomValue(imageAtom);

  const setTemplateImage = useSetAtom(refineAtoms.templateImageFamily({ num }));
  const imagePreviewDataLoader = useAtomValue(loadable(refineAtoms.imagePreviewDataFamily(num)));
  const setImagePreviewData = useSetAtom(refineAtoms.imagePreviewDataFamily(num));
  const resetImagePreviewData = useResetAtom(refineAtoms.imagePreviewDataFamily(num));

  const imagePreviewLoader = useAtomValue(refineAtoms.imagePreviewFamily(num));
  const imagePreview = imagePreviewLoader.state === "hasData" ? imagePreviewLoader.data : null;

  const { url, loading } = usePreviewImageUrl(imagePreview);

  const methods = useForm<FormData>({
    resolver: yupResolver(imageSchema),
  });

  useEffect(() => {
    imagePreviewDataLoader.state === "hasData" &&
      imagePreviewDataLoader.data &&
      methods.reset(imagePreviewDataLoader.data);
  }, [imagePreviewDataLoader, methods]);

  const handlePreview = useCallback(
    (data: FormData | { Photo: Basic }) => {
      if (imagePreviewDataLoader.state === "hasData" && imagePreviewDataLoader.data) {
        setImagePreviewData(Promise.resolve({ ...imagePreviewDataLoader.data, ...data }));
      }
    },
    [imagePreviewDataLoader, setImagePreviewData],
  );

  const handleClose = useCallback(() => {
    resetImagePreviewData();
    imagePreviewDataLoader.state === "hasData" &&
      imagePreviewDataLoader.data &&
      methods.reset(imagePreviewDataLoader.data);
    onClose();
  }, [imagePreviewDataLoader, methods, onClose, resetImagePreviewData]);

  const handleSubmit = useCallback(
    (data: FormData) => {
      if (imagePreviewDataLoader.state === "hasData" && imagePreviewDataLoader.data) {
        setTemplateImage(
          Promise.resolve({
            chatId: imagePreviewDataLoader.data.chatId,
            state: "hasData",
            response: omit({ ...imagePreviewDataLoader.data, ...data }, "chatId"),
            prompt: [],
          }),
        );
        handleClose();
      }
    },
    [imagePreviewDataLoader, handleClose, setTemplateImage],
  );

  const handleSelectImage = useCallback(
    (image: Basic) => {
      imagePreviewDataLoader.state === "hasData" &&
        imagePreviewDataLoader.data &&
        handlePreview({ Photo: image });
    },
    [handlePreview, imagePreviewDataLoader],
  );

  return (
    <Dialog maxWidth="xl" onClose={handleClose} {...props}>
      <FormProvider {...methods}>
        <form
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={methods.handleSubmit(handleSubmit)}
        >
          <Box
            display="flex"
            flexDirection="column"
            gap={2}
            p={3}
            maxHeight={"calc(80vh)"}
            minWidth={800}
          >
            <Box display="flex" gap={2} alignItems={"center"}>
              <Typography variant="h6" flexGrow={1}>
                {t("Refine image")}
              </Typography>
            </Box>
            <ErrorBoundary
              fallback={
                <Typography variant="body1">
                  {t("Something went wrong. Please try again or select another image.")}
                </Typography>
              }
            >
              <Box sx={{ display: "flex", gap: 2 }}>
                <AiRefineImagePreview url={url ?? ""} loading={loading} />
                <FormStack minWidth={400}>
                  <CeTextInput
                    name="header"
                    label={t("Header")}
                    {...(methods.formState.errors.header && {
                      helperText: methods.formState.errors.header.message?.toString(),
                    })}
                  />
                  {imagePreviewDataLoader.state === "hasData" &&
                    (imagePreviewDataLoader.data?.subheader ||
                      imagePreviewDataLoader.data?.subheader === "") && (
                      <CeTextInput
                        name="subheader"
                        label={t("Subheader")}
                        {...(methods.formState.errors.subheader && {
                          helperText: methods.formState.errors.subheader.message?.toString(),
                        })}
                      />
                    )}
                  {imagePreview && !imagePreview.stableDiffusionImage ? (
                    <ImageSelector onClick={handleSelectImage} />
                  ) : (
                    <Box
                      sx={(theme) => ({
                        padding: theme.spacing(2),
                        border: `1px solid ${theme.palette.grey[200]}`,
                        bgcolor: theme.palette.grey[50],
                        display: "flex",
                        color: theme.palette.common.black,
                        gap: 2,
                        alignItems: "center",
                      })}
                    >
                      <LightbulbOutlined fontSize="small" />
                      <Typography variant="body2">
                        {t(
                          "You'll be able to change this Moment's imagery in the 'Message' section on the next step",
                        )}
                      </Typography>
                    </Box>
                  )}
                </FormStack>
              </Box>

              <Stack spacing={2} direction="row" justifyContent={"right"} mt={4}>
                <Button
                  data-analytics-id="ai-refine-image-modal-cancel"
                  color="secondary"
                  variant="outlined"
                  onClick={handleClose}
                >
                  {t("Cancel")}
                </Button>
                <LoadingButton
                  data-analytics-id="ai-refine-image-modal-preview"
                  variant="outlined"
                  onClick={() => handlePreview(methods.getValues())}
                  loading={loading}
                >
                  {t("Preview")}
                </LoadingButton>
                <Button
                  data-analytics-id="ai-refine-image-modal-apply"
                  variant="contained"
                  type="submit"
                >
                  {t("Apply Changes")}
                </Button>
              </Stack>
            </ErrorBoundary>
          </Box>
        </form>
      </FormProvider>
    </Dialog>
  );
};

const ImageSelector: FC<{ onClick: (image: Basic) => void }> = ({ onClick }) => {
  const num = useAtomValue(imageAtom);
  const imagesLoader = useAtomValue(imagePhotosFamily(num));
  const images = imagesLoader.state === "hasData" ? imagesLoader.data : null;

  return (
    <Box display="grid" gap={2} maxWidth="400" gridTemplateColumns="repeat(5, 100px)">
      {images?.map((image) => (
        <Box
          data-analytics-id="ai-refine-image-modal-image-selector"
          key={image.id}
          onClick={() => onClick(image)}
          role="button"
          aria-label={image.description || image.alt_description || image.links.download_location}
          sx={(theme) => ({
            padding: 0,
            borderRadius: `${theme.shape.borderRadius}px`,
            border: `2px solid ${theme.palette.background.paper}`,
            "&:hover": { borderColor: theme.palette.primary.main },
            backgroundImage: `url(${image.urls.thumb})`,
            backgroundSize: "cover",
            width: "100px",
            height: "100px",
            cursor: "pointer",
          })}
        />
      ))}
    </Box>
  );
};
