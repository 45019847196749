import { createContext, FC, ReactNode, useState } from "react";

type SidebarProviderProps = {
  children?: ReactNode;
};

export const SidebarContext = createContext<{
  programsDirty: boolean;
  setProgramsDirty: (dirty: boolean) => void;
}>({
  programsDirty: true,
  setProgramsDirty: () => undefined,
});

export const SidebarProvider: FC<SidebarProviderProps> = ({ children }) => {
  const [programsDirty, setProgramsDirty] = useState<boolean>(true);

  const { Provider } = SidebarContext;
  return <Provider value={{ programsDirty, setProgramsDirty }}>{children}</Provider>;
};
