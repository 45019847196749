import { PostgrestClient } from "@supabase/postgrest-js";
import { config } from "../../../config";
import { Database } from "../../../server/supabase/types/database-definitions";
import { UUID, uuidv4 } from "../../../utils/uuid";
import { SupabaseCustomEmailService } from "../../channels/email/server/supabase-custom-email-service";
import { EmailMoment } from "../types";
import { SupabaseEmailMomentService } from "./supabase-email-moment-service";
import { SupabaseUnlayerDesignLoader } from "./supabase-unlayer-design-loader";

export class EmailMomentLoader {
  private readonly supabase: PostgrestClient<Database>;
  constructor(supabase: PostgrestClient<Database>) {
    this.supabase = supabase;
  }

  async load(id: UUID): Promise<
    | {
        data: EmailMoment;
        error?: never;
      }
    | {
        data?: never;
        error: string;
      }
  > {
    const [{ data, error, status }, { data: defaultAddress }] = await Promise.all([
      new SupabaseEmailMomentService(this.supabase).get(id),
      new SupabaseCustomEmailService(this.supabase).getDefault(),
    ]);

    if (status === 406) {
      return {
        data: await this.loadNew(defaultAddress?.address ?? undefined),
      };
    }

    if (error || !data) return { error: error?.message || "Unable to load Email Moment" };

    return {
      data: {
        ...data,
        from_address: data.from_address || defaultAddress?.address || config.defaultEmailFrom,
        subject: data.subject ?? "",
        body: data.body ?? "",
        design: new SupabaseUnlayerDesignLoader(this.supabase).loadExisting(data),
        newDesign: false,
      },
    };
  }

  async loadNew(from_address?: string): Promise<EmailMoment> {
    const customEmailService = new SupabaseCustomEmailService(this.supabase);
    return {
      id: uuidv4(),
      subject: "",
      body: "",
      design: await new SupabaseUnlayerDesignLoader(this.supabase).loadNew(),
      newDesign: true,
      from_address:
        from_address ||
        (await customEmailService.getDefault()).data?.address ||
        config.defaultEmailFrom,
    };
  }
}
