export type EmailChannelName = "work_email" | "personal_email";
export type ChatChannelName = "slack" | "teams";
export type TextChannelName = "twilio";
export type ChannelName = EmailChannelName | ChatChannelName | TextChannelName | null;

export const Channels = {
  work_email: "work_email" as EmailChannelName,
  personal_email: "personal_email" as EmailChannelName,
  slack: "slack" as ChatChannelName,
  teams: "teams" as ChatChannelName,
  twilio: "twilio" as TextChannelName,
};

export const isEmailChannel = (channel: ChannelName): channel is EmailChannelName => {
  return channel === Channels.work_email || channel === Channels.personal_email;
};
