import { useAtom } from "jotai";
import { atomWithStorage, RESET } from "jotai/utils";
import { useEffect } from "react";

const historyAtom = atomWithStorage<string[]>("release-notes-seen", []);

export const useReleaseNotes = (): void => {
  const [, setHistory] = useAtom(historyAtom);
  // Wipe the storage for everyone
  useEffect(() => {
    setHistory(RESET);
  }, [setHistory]);
};
