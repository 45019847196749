/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { SupabaseService } from "../../../server/supabase/base-supabase-service";
import { Database, Json } from "../../../server/supabase/types/database-definitions";

export type Sequence = Database["public"]["Tables"]["sequence"]["Row"];

export class SupabaseSequenceService extends SupabaseService<"sequence"> {
  table = "sequence" as const;

  async importSequence(data: Json) {
    return await this.client.rpc("import_sequence", { data });
  }
}
