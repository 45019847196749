import { Alert, AlertTitle, LinearProgress } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSupabaseLoader } from "../../../../server/supabase/hooks";
import { ConnectionsDataGrid } from "../../../merge-tag/components/connections-data-grid";
import { UnfoundConnectionsGrid } from "../../../merge-tag/components/unfound-connections-data-grid";
import {
  SupabaseConnectionKeysService
} from "../../../merge-tag/server/supabase-connections-key-service";
import {
  SupabaseUnfoundConnectionsService
} from "../../../merge-tag/server/supabase-unfound-connections-service";

export const Connections: FC = () => {
  const { t } = useTranslation();

  const {
    data: keysData,
    error: keysError,
    loading: keysLoading,
  } = useSupabaseLoader(async ({ supabase, account_id }) => {
    const { data, error } = await new SupabaseConnectionKeysService(supabase).getAll(account_id, {
      order: [{ column: "key" }],
    });
    if (error) throw error;
    if (!data) throw new Error("No key data found");
    return data;
  }, []);

  const {
    data: unfoundData,
    error: unfoundError,
    loading: unfoundLoading,
  } = useSupabaseLoader(async ({ supabase, account_id }) => {
    const { data, error } = await new SupabaseUnfoundConnectionsService(supabase).getAll(
      account_id,
      {
        order: [
          { column: "key" },
          { column: "connection_reference" },
          { column: "subject_work_email" },
        ],
      },
    );
    if (error) throw error;
    if (!data) throw new Error("No unfound data found");
    return data;
  }, []);

  return (
    <>
      {(unfoundError || keysError) && (
        <Alert severity="error" sx={{ my: 2 }}>
          <AlertTitle>{t("Something went wrong")}</AlertTitle>
          {unfoundError && <div>{unfoundError.message}</div>}
          {keysError && <div>{keysError.message}</div>}
        </Alert>
      )}
      {keysLoading && unfoundLoading && <LinearProgress />}
      {keysData && <ConnectionsDataGrid connectionKeys={keysData} />}
      {/* Only show if it has more than 0 */}
      {!!unfoundData?.length && (
        <UnfoundConnectionsGrid unfoundConnections={unfoundData} />
      )}
    </>
  );
};
