import { Stack } from "@mui/material";
import { FC } from "react";

export const SequenceWrapper: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <Stack height="100%" overflow="hidden" direction="column">
      {children}
    </Stack>
  );
};
