import yup from "../../../utils/yup";

export const emailLogoSchema = yup.object({
  src: yup.string().required("Add a logo to the header"),
  align: yup.string().oneOf(["left", "center", "right"]).default("center"),
  alt: yup.string().nullable(),
  proportionOfMaxWidth: yup.number().default(1),
});

export const headerTextSchema = yup.object({
  value: yup.string().nullable().default(""),
  align: yup.string().nullable().oneOf(["left", "center", "right"]).default("center"),
  color: yup.string().nullable(),
});

export const emailHeaderSchema = yup.object({
  fullWidth: yup.boolean().default(false),
  padding: yup.number().default(14),
  backgroundColor: yup.string().default("#ffffff"),
  logo: emailLogoSchema.nullable().default(null),
  text: headerTextSchema.nullable().default(null),
  hide_image_url_from_notification: yup.boolean().default(false),
});

export type LogoSchema = yup.InferType<typeof emailLogoSchema>;
export type EmailHeaderSchema = yup.InferType<typeof emailHeaderSchema>;
export type HeaderTextSchema = yup.InferType<typeof headerTextSchema>;
export type EmailHeaderProperties = yup.InferType<typeof emailHeaderSchema>;
