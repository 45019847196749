import {
  Alert,
  AlertTitle,
  Box,
  Card,
  CardContent,
  CircularProgress,
  InputLabel,
  Link,
  Tooltip,
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { ChannelIcon } from "../../../modules/channels/components/channel-icon";
import { Columns, Row } from "../../../modules/generic/components/crud-data-grid/types";
import { Page } from "../../../modules/layout/components/page";
import { PageContent } from "../../../modules/layout/components/page-content";
import { PageHeader } from "../../../modules/layout/components/page-header";
import { PageHeading } from "../../../modules/layout/components/page-heading";
import { MomentCardActions } from "../../../modules/moment-card/components/moment-card-actions";
import { Program } from "../../../modules/moment-card/types";
import { MomentFilter } from "../../../modules/moment-filter/components/moment-filter";
import { useClientFilteredMoments } from "../../../modules/moment-filter/hooks/use-client-filtered-moments";
import {
  defaultSortOptionMap,
  useMomentSort,
} from "../../../modules/moment/moment-sort/hooks/use-moment-sort";
import { MomentSort } from "../../../modules/moment/moment-sort/moment-sort";
import {
  MomentCardMomentActivityStats,
  SupabaseMomentsSentStatsService,
} from "../../../modules/moment/server/supabase-moment-service";
import { Search } from "../../../modules/search/search";
import { formatDatetimeShort, fromISO8601 } from "../../../utils/iso8601";

const MomentsHistory = (): ReactElement => {
  const { t } = useTranslation();
  const { moments, error: hasError } = useClientFilteredMoments<MomentCardMomentActivityStats>(
    (s, a) => new SupabaseMomentsSentStatsService(s).getAll(a, { order: [{ column: "id" }] }),
  );
  const sortMoments = useMomentSort();
  const cols: Columns<MomentCardMomentActivityStats> = {
    title: {
      headerName: "Title",
      flex: 4,
      renderCell: ({ row: { title, id } }) => <Link href={`/moments/${id!}`}>{title}</Link>,
    },
    program: {
      headerName: "Program",
      flex: 3,
      valueFormatter: (value) => (value as Program)?.title ?? "-",
    },
    attime: {
      headerName: "When",
      flex: 2,
      valueFormatter: (value) => (value ? formatDatetimeShort(fromISO8601(value)) : "-"),
    },
    sends: {
      headerName: "Sent",
      flex: 2,
    },
    unique_clicks: {
      headerName: "Clicks",
      flex: 2,
    },
    opens: {
      headerName: "Opens",
      flex: 2,
      renderCell: (params) =>
        params.row.channel !== "personal_email" && params.row.channel !== "work_email" ? (
          <Tooltip title={t("Opens are only recorded for email")}>
            <span>-</span>
          </Tooltip>
        ) : params.row.opens ? (
          params.row.opens.toString()
        ) : (
          "0"
        ),
    },
    reactions: {
      headerName: "Reactions",
      flex: 2,
    },
    channel: {
      headerName: "Channel",
      flex: 2,
      renderCell: ({ value }) => <ChannelIcon channel={value} />,
    },
    id: {
      headerName: "Actions",
      flex: 2,
      renderCell: ({ row }) => {
        return <MomentCardActions moment={row} small switchable={false} />;
      },
    },
  };

  return (
    <Page title="Sent Moments | ChangeEngine">
      <PageHeader>
        <PageHeading heading={t("Sent Moments")} />
      </PageHeader>
      <PageContent>
        <Box display="flex">
          <Box display="flex" alignItems="center" flexGrow={1}>
            <InputLabel>{t("Sort by:")}</InputLabel>
            <MomentSort />
          </Box>
          <Box display="flex">
            <Search />
            <MomentFilter displayStatusOptions={false} displayEventOptions={false} />
          </Box>
        </Box>
        <Card>
          <CardContent>
            {hasError && (
              <Alert severity="error">
                <AlertTitle>{t("Something went wrong")}</AlertTitle>
              </Alert>
            )}
            {moments === undefined ? (
              <CircularProgress />
            ) : (
              <DataGridPro<Row<MomentCardMomentActivityStats>>
                columns={Object.entries(cols).map(([field, v]) => ({
                  field,
                  sortable: false,
                  ...v,
                }))}
                rows={sortMoments(moments, {
                  ...defaultSortOptionMap,
                  schedule: { ...defaultSortOptionMap.schedule, order: "desc" },
                }).map((v) => ({ isNew: false, ...v }))}
                hideFooterSelectedRowCount
                autoHeight
                disableColumnMenu
                pagination
              />
            )}
          </CardContent>
        </Card>
      </PageContent>
    </Page>
  );
};

export default MomentsHistory;
