import { TFunction } from "i18next";
import { Database } from "../../../../server/supabase/types/database-definitions";
import { SegmentCondition } from "../../types";

export type Operator =
  | "IS"
  | "IS NOT"
  | "BEFORE"
  | "AFTER"
  | "ON OR BEFORE"
  | "ON OR AFTER"
  | "ON"
  | "IS BLANK"
  | "IS NOT BLANK"
  | "IS FUTURE"
  | "IS FUTURE OR TODAY"
  | "IS PAST"
  | "IS PAST OR TODAY"
  | "IS TRUE"
  | "IS FALSE";

export type SegmentConditionOperator = "IS" | "BEFORE" | "AFTER" | "ON" | "IS EMPTY";

export type SegmentConditionDefaultOperator =
  | "IS"
  | "BEFORE"
  | "AFTER"
  | "ON"
  | "IS EMPTY"
  | "IS TRUE";

export const filterFieldOperators: Record<
  Database["public"]["Tables"]["account_person_field"]["Row"]["field_type"],
  {
    operators: {
      operator: Operator;
      label: (t: TFunction) => string;
    }[];
    defaultOperator: SegmentConditionDefaultOperator;
  }
> = {
  TEXT: {
    operators: [
      { operator: "IS", label: (t) => t("Is") },
      { operator: "IS NOT", label: (t) => t("Is Not") },
      { operator: "IS BLANK", label: (t) => t("Is Blank") },
      { operator: "IS NOT BLANK", label: (t) => t("Is Not Blank") },
    ],
    defaultOperator: "IS",
  },
  MONTH_DAY: {
    operators: [
      { operator: "BEFORE", label: (t) => t("Before") },
      { operator: "AFTER", label: (t) => t("After") },
      { operator: "ON", label: (t) => t("On") },
      { operator: "IS BLANK", label: (t) => t("Is Blank") },
      { operator: "IS NOT BLANK", label: (t) => t("Is Not Blank") },
    ],
    defaultOperator: "ON",
  },
  DATE: {
    operators: [
      { operator: "BEFORE", label: (t) => t("Before") },
      { operator: "AFTER", label: (t) => t("After") },
      { operator: "ON OR BEFORE", label: (t) => t("On or Before") },
      { operator: "ON OR AFTER", label: (t) => t("On or After") },
      { operator: "ON", label: (t) => t("On") },
      { operator: "IS BLANK", label: (t) => t("Is Blank") },
      { operator: "IS NOT BLANK", label: (t) => t("Is Not Blank") },
      { operator: "IS FUTURE", label: (t) => t("Is in Future") },
      { operator: "IS PAST", label: (t) => t("Is in Past") },
      { operator: "IS FUTURE OR TODAY", label: (t) => t("Is in Future or Today") },
      { operator: "IS PAST OR TODAY", label: (t) => t("Is in Past or Today") },
    ],
    defaultOperator: "ON",
  },
  ADDRESS: {
    operators: [],
    defaultOperator: "IS",
  },
  COUNTRY: {
    operators: [
      { operator: "IS", label: (t) => t("Is") },
      { operator: "IS NOT", label: (t) => t("Is Not") },
      { operator: "IS BLANK", label: (t) => t("Is Blank") },
      { operator: "IS NOT BLANK", label: (t) => t("Is Not Blank") },
    ],
    defaultOperator: "IS",
  },
  BOOLEAN: {
    operators: [
      { operator: "IS TRUE", label: (t) => t("Is True") },
      { operator: "IS FALSE", label: (t) => t("Is False") },
    ],
    defaultOperator: "IS TRUE",
  },
};

export const mapBooleanOperator = (condition: SegmentCondition): Operator => {
  switch (condition.values[0]) {
    case "true":
      return "IS TRUE";
    case "false":
      return "IS FALSE";
    default:
      return "IS TRUE";
  }
};

export const mapDateOperator = (condition: SegmentCondition): Operator => {
  switch (condition.operation) {
    case "IS EMPTY":
      return condition.invert ? "IS NOT BLANK" : "IS BLANK";
    case "BEFORE": {
      if (condition.invert) {
        return condition.values[0] === "TODAY" ? "IS FUTURE OR TODAY" : "ON OR AFTER";
      } else {
        return condition.values[0] === "TODAY" ? "IS PAST" : "BEFORE";
      }
    }
    case "AFTER": {
      if (condition.invert) {
        return condition.values[0] === "TODAY" ? "IS PAST OR TODAY" : "ON OR BEFORE";
      } else {
        return condition.values[0] === "TODAY" ? "IS FUTURE" : "AFTER";
      }
    }
    default:
      return condition.operation;
  }
};

export const mapOperator = (condition: SegmentCondition): Operator => {
  const empty = condition.operation === "IS EMPTY";
  if (condition.invert) {
    return `IS NOT${empty ? " BLANK" : ""}`;
  }

  if (!condition.invert && empty) {
    return "IS BLANK";
  }

  return "IS";
};
