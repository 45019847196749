import { Avatar, Box } from "@mui/material";
import { FC, ReactElement } from "react";
import { ProgramIcon } from "../../programs/components/program-icon";

export type MomentCardBannerProps = {
  cover_image_path?: string;
  title?: string;
  theme_colour?: string;
  theme_icon?: string | ReactElement;
};

export const MomentCardImage: FC<MomentCardBannerProps> = ({
  cover_image_path,
  title,
  theme_colour,
  theme_icon,
}) => {
  return cover_image_path ? (
    <img
      src={cover_image_path}
      alt={title}
      width="100%"
      height="100%"
      draggable={false}
      style={{ objectFit: "cover", objectPosition: "top" }}
    />
  ) : (
    <Box display="flex" paddingTop={5} justifyContent="center" alignItems="center">
      <Avatar
        data-testid="card-program-icon"
        sx={{
          bgcolor: theme_colour,
          width: "60px",
          height: "60px",
          filter: "brightness(110%)",
        }}
      >
        <ProgramIcon
          data={theme_icon}
          sx={{
            color: theme_colour,
            filter: "brightness(135%)",
          }}
        />
      </Avatar>
    </Box>
  );
};
