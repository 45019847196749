import { BookmarkAddOutlined, Download, Edit } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Alert, Button, IconButton, Link, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { FC, useCallback, useState } from "react";
import toast from "react-hot-toast";
import { Trans, useTranslation } from "react-i18next";
import { useToken } from "../../server/auth/use-token";
import { useFlag } from "../../server/optimizely";
import { useDialog } from "../../utils/hooks/use-dialog";
import { Asset } from "../assets/server/digital-asset-service";
import { BrandingBar } from "../branding-bar/branding-bar";
import { DesignHuddleEditorWithBranding } from "../design-huddle/design-huddle-editor-with-branding";
import {
  DesignHuddleExportModal,
  ExportHandler,
} from "../design-huddle/design-huddle-export-modal";
import { Editor, EditorReadyHandler, GetRenderResponse } from "../design-huddle/types";
import { copyImage } from "../design-huddle/utils";
import { CeTextField } from "../generic/components/form-fields/ce-text-field";

export type SaveAssetHandler = (options: {
  title: string;
  thumbnailUrl?: string;
}) => Promise<Asset>;

const EditableField: FC<{
  title: string;
  editable: boolean;
  setEditable: (state: boolean) => void;
  setFilename: (f: string) => void;
  onSave?: SaveAssetHandler;
}> = ({ title, editable, setEditable, setFilename, onSave }) => {
  return (
    <>
      <Typography
        flexGrow={1}
        variant="h5"
        color={editable ? "transparent" : "text.secondary"}
        onClick={() => {
          setEditable(true);
        }}
        sx={{ position: "relative" }}
      >
        {title}
        {editable && (
          <CeTextField
            data-testid="edit-content-edit-name-input"
            sx={{ position: "absolute", left: 0, top: -4, color: "text.secondary" }}
            fullWidth
            variant="editabletext"
            defaultValue={title}
            onBlur={(e) => {
              setFilename(e.target.value);
              void onSave?.({ title: e.target.value });
              setEditable(false);
            }}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            onKeyDown={(e) => {
              if (e.key === "Escape") {
                setEditable(false);
              }
            }}
          />
        )}
      </Typography>
    </>
  );
};

export const EditContent: FC<{
  projectId: string;
  page: number;
  onReady: () => void;
  title: string;
  onSave?: SaveAssetHandler;
}> = ({ projectId, page, onReady, title, onSave }) => {
  const { t } = useTranslation();
  const [assetLibrary] = useFlag("asset_library");

  const [filename, setFilename] = useState(title);
  const exportModal = useDialog();

  const [multiformatBranding] = useFlag("multiformat_branding");
  const [editor, setEditor] = useState<Editor | null>(null);

  const [editable, setEditable] = useState(false);

  const handleExport: ExportHandler = useCallback(({ filename: f }) => {
    setFilename(f);
  }, []);

  const getToken = useToken();

  const [saving, setSaving] = useState(false);

  const handleSave = useCallback(() => {
    if (saving) return;
    const loadingToast = toast.custom(
      <Alert severity="info" variant="outlined" sx={{ bgcolor: "white" }}>
        {t("Saving asset...")}
      </Alert>,
      { duration: 2000 },
    );

    setSaving(true);

    void (async () => {
      if (!editor) {
        await onSave?.({ title: filename });
        toast.dismiss(loadingToast);
        toast.custom(<SuccessToast />, {
          duration: 2000,
        });
        setSaving(false);
        return;
      }

      const { url } = await new Promise<GetRenderResponse>((resolve, reject) => {
        editor.getRender({ max_height: 400, max_width: 400 }, function (err, data) {
          if (err) {
            reject(err);
          } else {
            resolve(data);
          }
        });
      });
      toast.dismiss(loadingToast);
      const response = await copyImage(getToken, url, `asset:thumbnail:${projectId}:${Date.now()}`);

      if (response.success) {
        await onSave?.({ title: filename, thumbnailUrl: response.url });
      } else {
        await onSave?.({ title: filename });
      }

      toast.custom(<SuccessToast />, { duration: 2000 });
      setSaving(false);
    })().catch(() => {
      toast.dismiss(loadingToast);
      toast.custom(
        () => (
          <Alert severity="error" variant="outlined">
            {t("Unable to save content. Please try again later.")}
          </Alert>
        ),
        { duration: 2000 },
      );
    });
  }, [editor, filename, getToken, onSave, projectId, saving, t]);

  const handleReady: EditorReadyHandler = useCallback(
    (e) => {
      setEditor(e);
      onReady();
    },
    [onReady],
  );

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Stack
        data-testid="edit-content"
        direction="row"
        p={2}
        justifyContent="space-between"
        alignItems="center"
        gap={2}
      >
        <Stack ml={2} gap={2} direction="row" alignItems="baseline">
          <EditableField
            title={filename}
            editable={editable}
            setEditable={setEditable}
            setFilename={setFilename}
            onSave={onSave}
          />
          <IconButton
            data-analytics-id="edit-content-edit-name"
            size="small"
            data-testid="edit-content-edit-name-button"
            onClick={() => {
              setEditable(true);
            }}
          >
            <Edit fontSize="small" />
          </IconButton>
        </Stack>
        {multiformatBranding && (
          <Box>
            <BrandingBar compact />
          </Box>
        )}

        <Stack direction="row" gap={2}>
          {assetLibrary && (
            <LoadingButton
              data-analytics-id="edit-content-save-asset"
              variant="outlined"
              size="large"
              startIcon={<BookmarkAddOutlined />}
              onClick={handleSave}
              loading={saving}
            >
              {t("Save")}
            </LoadingButton>
          )}
          <Button
            data-analytics-id="edit-content-export-image"
            variant="contained"
            size="large"
            startIcon={<Download />}
            onClick={exportModal.handleOpen}
          >
            {t("Export")}
          </Button>
        </Stack>
      </Stack>
      <Box flexGrow={1}>
        {projectId !== "dummy" && (
          <DesignHuddleEditorWithBranding
            project_id={projectId}
            page_number={page.toString()}
            hide_right_panel
            onReady={handleReady}
          />
        )}
      </Box>

      <DesignHuddleExportModal
        projectId={projectId}
        page={page}
        dialog={exportModal}
        filename={filename}
        onExport={handleExport}
      />
    </Box>
  );
};

const SuccessToast: FC = () => {
  return (
    <Alert severity="success" variant="outlined">
      <Trans>
        Saved to your <Link href="/content-library">Content Library</Link>
      </Trans>
    </Alert>
  );
};
