import { Autocomplete, TextField } from "@mui/material";
import { FC } from "react";

import { EventKey } from "../../modules/event/server/supabase-event-service";

interface SelectEventProps {
  id?: string;
  eventKeys: EventKey[];
  onChange: (eventKey: string) => void;
  label?: string;
}

export const SelectEvent: FC<SelectEventProps> = ({ id, eventKeys, onChange, label = "" }) => {
  const selectedEvent =
    id && eventKeys.some((e) => e.key === id) ? eventKeys.find((e) => e.key === id) : undefined;

  return eventKeys.length === 0 ? null : (
    <Autocomplete
      sx={{ maxWidth: 300 }}
      value={selectedEvent}
      onChange={(_, v) => onChange(v?.key ?? "")}
      options={eventKeys}
      renderInput={(params) => <TextField label={label} {...params} />}
      getOptionLabel={(option) => option.title}
      disableClearable
    />
  );
};
