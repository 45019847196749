import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { SegmentCondition } from "../../types";
import { filterFieldOperators, mapBooleanOperator } from "./operators";

export const BooleanSelect: FC<{
  condition: SegmentCondition;
  handleFieldChange: (value: SegmentCondition) => void;
  disabled?: boolean;
}> = ({ condition, handleFieldChange, disabled }) => {
  const { t } = useTranslation();

  const onChange = useCallback(
    (event: SelectChangeEvent) =>
      handleFieldChange(
        mapToOperatorAndCondition(event.target.value as "IS TRUE" | "IS FALSE", condition),
      ),
    [handleFieldChange, condition],
  );

  return (
    <>
      <Select
        variant="standard"
        id={`operator-${condition.id}`}
        name={`segment_condition.${condition.id}.operator`}
        label={t("Operator")}
        onChange={onChange}
        labelId="operator-label"
        value={mapBooleanOperator(condition)}
        disabled={disabled}
      >
        {filterFieldOperators.BOOLEAN.operators.map((operatorInfo) => (
          <MenuItem key={operatorInfo.operator} value={operatorInfo.operator}>
            {operatorInfo.label(t)}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

function mapToOperatorAndCondition(
  operator: "IS TRUE" | "IS FALSE",
  condition: SegmentCondition,
): SegmentCondition {
  switch (operator) {
    case "IS TRUE":
      return {
        ...condition,
        operation: "IS",
        values: ["true"],
      };
    case "IS FALSE":
      return { ...condition, operation: "IS", values: ["false"] };
    default:
      throw new Error("Invalid operator");
  }
}
