import PostgrestError from "@supabase/postgrest-js/dist/cjs/PostgrestError";
import { omit } from "lodash-es";
import { BaseSupabaseService } from "../../../server/supabase/base-supabase-service";
import { Database } from "../../../server/supabase/types/database-definitions";
import { ISO8601_DATE } from "../../../utils/iso8601";
import { TemplateType } from "../../generic/atoms/types/template";
import { Library, LibraryProgram, LibrarySequence, LibraryTemplate } from "../library/types";

export type AtlasDiscoverRow = Database["atlas"]["Views"]["discover_templates"]["Row"];

const constructLibraryTemplate = (
  template: NonNullable<Database["atlas"]["Tables"]["discover"]["Row"]["metadata"]>["template"],
  program: Omit<LibraryProgram, "templates">,
): LibraryTemplate => {
  return {
    type: TemplateType.moment,
    id: template.airtable.id,
    slug: template.data.data.slug,
    title: template.data.data.title,
    what: template.data.data.what,
    context: template.data.data.context,
    img: {
      ...omit(template.data.data.img, "id"),
      template_code: template.data.data.img.id,
    },
    content: template.data.data.content,
    recommendations: template.data.data.recommendations,
    date: template.data.data.date ? (template.data.data.date as ISO8601_DATE) : null,
    end_date: template.data.data.end_date ? (template.data.data.end_date as ISO8601_DATE) : null,
    trigger: template.data.data.trigger,
    segment: template.data.data.segment as LibraryTemplate["segment"],
    trending: template.data.data.trending ?? null,
    hero: template.data.data.hero ?? null,
    top: template.data.data.top ?? null,
    geo: template.data.data.geo ?? null,
    program,
  };
};

const constructLibraryProgram = (
  program: NonNullable<Database["atlas"]["Tables"]["discover"]["Row"]["metadata"]>["program"],
): LibraryProgram => {
  return {
    slug: program.data.slug,
    name: program.data.name,
    photo: program.data.photo ?? "",
    color: program.data.color ?? "",
    templates: [],
  };
};

export class SupabaseAtlasService extends BaseSupabaseService<"atlas"> {
  table = "discover_templates" as const;

  static rowsToLibrary(rows: AtlasDiscoverRow[]): Library {
    // group by program

    const programs = rows.reduce(
      (acc, row) => {
        if (!row.metadata || row.metadata.sequence !== undefined) return acc;
        const { program, template } = row.metadata;

        if (!acc[program.data.slug]) {
          acc[program.data.slug] = constructLibraryProgram(program);
        }

        acc[program.data.slug].templates.push(constructLibraryTemplate(template, program.data));
        return acc;
      },
      {} as Record<string, LibraryProgram>,
    );

    const sequences = rows.reduce(
      (acc, row) => {
        if (!row.metadata || row.metadata.sequence === undefined) return acc;

        const { sequence } = row.metadata;

        if (!acc[sequence.data.slug]) {
          const templates = sequence.data.CMS.map((airtableId) => {
            const rowTemplate = rows.find((r) => r.template_id === airtableId);

            if (!rowTemplate || !rowTemplate.metadata) return;

            const { template, program } = rowTemplate.metadata;

            return constructLibraryTemplate(template, program.data);
          }).filter((t): t is LibraryTemplate => t !== undefined);

          acc[sequence.data.slug] = {
            type: TemplateType.sequence,
            id: sequence.airtable.id,
            slug: sequence.data.slug,
            title: sequence.data.name,
            description: sequence.data.description,
            img: { thumbnail: sequence.data.thumbnail },
            templates: templates,
          };
        }

        return acc;
      },
      {} as Record<string, LibrarySequence>,
    );

    return { programs: Object.values(programs), sequences: Object.values(sequences) };
  }

  async getAtlasV2Library(
    showSequences: boolean,
  ): Promise<{ data: Library; error: null } | { data: null; error: PostgrestError }> {
    let query = this.client.from(this.table).select<"*", AtlasDiscoverRow>("*");

    if (!showSequences) {
      query = query.filter("sequence_template", "is", false);
    }

    const { data, error } = await this.getAllFromSelection(null, query, {
      order: [{ column: "template_id" }],
    });

    if (error) return { data, error };

    return { data: SupabaseAtlasService.rowsToLibrary(data), error: null };
  }
}
