import { Box, Card, CardActionArea, CardContent, CardProps, Chip, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { FC } from "react";
import { Asset } from "./server/digital-asset-service";

export const AssetCard: FC<{ asset: Asset } & CardProps> = ({ asset, ...props }) => {
  return (
    <Card variant="outlined" {...props}>
      <CardActionArea>
        <Box
          sx={{
            height: "200px",
            backgroundImage: `url(${asset.thumbnail_url})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
          }}
        ></Box>
        <CardContent>
          <Stack spacing={2}>
            <Typography variant="subtitle1" noWrap textOverflow="ellipsis">
              {asset.title}
            </Typography>
            <Stack direction="row">
              <Chip label={asset.content_type} />
            </Stack>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
