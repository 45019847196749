import { ChevronRight, Folder } from "@mui/icons-material";
import { Drawer, Popover } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { ChevronDown } from "../../../../styles/icons/chevron-down";
import { usePopover } from "../../../../utils/hooks/use-popover";
import { Item } from "./item";
import { ProgramsSelector } from "./programs-selector";

export const Programs: FC = () => {
  const { t } = useTranslation();
  const popover = usePopover<HTMLButtonElement>();
  const { pathname } = useLocation();

  return (
    <>
      <Item
        title={t("Programs")}
        icon={<Folder />}
        onClick={{
          type: "function",
          value: popover.handleToggle,
          ref: popover.anchorRef,
        }}
        open={popover.open}
        endIcon={popover.open ? <ChevronDown /> : <ChevronRight />}
        active={() => pathname.startsWith("/tabbed-programs")}
      />
      {popover.open && (
        <Popover
          anchorEl={popover.anchorRef.current}
          open={popover.open}
          onClose={popover.handleClose}
          sx={{
            flexShrink: 0,
            "& .MuiPopover-paper": {
              top: "-100px !important",
            },
          }}
        >
          <Drawer
            sx={{
              flexShrink: 0,
              ml: "147px",
              "& .MuiDrawer-paper": {
                width: 280,
                ml: "280px",
                boxSizing: "border-box",
                backgroundColor: "background.drawer",
              },
            }}
            variant="persistent"
            anchor="left"
            open={popover.open}
          >
            <ProgramsSelector onSelect={popover.handleClose} />
          </Drawer>
        </Popover>
      )}
    </>
  );
};
