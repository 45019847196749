import { EditorThemeClasses } from "lexical";

export const EDITOR_THEME: EditorThemeClasses = {
  text: {
    bold: "editor-text-bold",
    italic: "editor-text-italic",
    underline: "editor-text-underline",
  },
  table: "editor-table",
  tableCell: "editor-table-cell",
  list: {
    listitem: "editor__listItem",
    nested: {
      listitem: "editor__nestedListItem",
    },
    olDepth: ["editor__ol1", "editor__ol2", "editor__ol3", "editor__ol4", "editor__ol5"],
    ul: "editor__ul",
  },
  blockCursor: "editor__blockCursor",
};
