export type Workers =
  | "employee-engine"
  | "cal"
  | "images"
  | "brandfetch"
  | "huddle"
  | "self-driving"
  | "flags"
  | "atlas-v2"
  | "unsplash"
  | "iconfinder";

export function serviceURL(service: Workers): string {
  if (
    import.meta.env.VITE_SERVICE_ENVIRONMENT === undefined ||
    import.meta.env.VITE_SERVICE_ENVIRONMENT === "local"
  ) {
    return {
      "employee-engine": "http://localhost:9001/",
      cal: "http://localhost:9008/",
      images: "http://localhost:9101/",
      brandfetch: "http://localhost:9108/",
      huddle: "http://localhost:9107/",
      "self-driving": "http://localhost:9109/",
      flags: "http://localhost:9110/",
      "atlas-v2": "http://localhost:9115/",
      unsplash: "http://localhost:9119/",
      iconfinder: "http://localhost:9126/",
    }[service];
  }
  return `https://${service}${import.meta.env.VITE_SERVICE_ENVIRONMENT}.changeengine.com/`;
}
