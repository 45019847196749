import { useAtomValue } from "jotai";
import { FC, useCallback, useEffect, useState } from "react";
import { brandingAtom } from "../brand-kit/store";
import { DesignHuddleEditorProps } from "./brand-info-customizations/types";
import { DesignHuddleEditor } from "./design-huddle-editor";
import { Editor, EditorReadyHandler, ProjectData, ProjectUpdateObject } from "./types";

const updateAccentColorElements = async (
  editor: Editor,
  oldAccentColor: string,
  newAccentColor: string,
): Promise<void> => {
  try {
    const projectData = await new Promise<ProjectData>((resolve, reject) => {
      editor.getProjectData({}, (err, data) => {
        if (err) reject(err);
        resolve(data);
      });
    });

    if (!projectData) {
      throw new Error("No project data found");
    }

    const projectUpdate: ProjectUpdateObject = {
      elements: {},
    };

    for (const page of projectData.pages ?? []) {
      for (const elementId in page.elements) {
        const element = page.elements[elementId];
        if (element.element_classes?.includes("accentcolor")) {
          projectUpdate.elements[elementId] = {
            colors: (element.colors || []).map((color) => {
              return color.toLowerCase() === oldAccentColor.toLowerCase() ? newAccentColor : color;
            }),
          };
        }
      }
    }

    editor.updateElements(projectUpdate);
  } catch (error) {
    console.error("Error updating accent color elements:", error);
  }
};

const updateLogoElements = async (editor: Editor, url: string): Promise<void> => {
  try {
    const projectData = await new Promise<ProjectData>((resolve, reject) => {
      editor.getProjectData({}, (err, data) => {
        if (err) reject(err);
        resolve(data);
      });
    });

    if (!projectData) {
      throw new Error("No project data found");
    }

    const projectUpdate: ProjectUpdateObject = {
      elements: {},
    };

    for (const page of projectData.pages ?? []) {
      for (const elementId in page.elements) {
        const element = page.elements[elementId];
        if (
          element.element_classes &&
          element.element_classes?.filter((className) =>
            ["logo", "symbol", "icon"].includes(className),
          ).length > 0
        ) {
          projectUpdate.elements[elementId] = { url };
        }
      }
    }

    editor.updateElements(projectUpdate);
  } catch (error) {
    console.error("Error updating accent color elements:", error);
  }
};

export const DesignHuddleEditorWithBranding: FC<DesignHuddleEditorProps> = ({
  project_id,
  onReady,
  ...props
}) => {
  const [editor, setEditor] = useState<Editor>();
  const selectedBranding = useAtomValue(brandingAtom);
  const [accentColor, setAccentColor] = useState<string>();
  const [src, setSrc] = useState<string>();

  const handleReady: EditorReadyHandler = useCallback(
    (e) => {
      setEditor(e);
      onReady?.(e);
    },
    [onReady],
  );

  useEffect(() => {
    const newAccentColor = selectedBranding.color?.hex;
    if (newAccentColor && newAccentColor !== accentColor) {
      editor &&
        accentColor &&
        void updateAccentColorElements(editor, accentColor, newAccentColor).catch((error) => {
          console.error("Error updating design:", error);
        });
      setAccentColor(newAccentColor);
    }
  }, [selectedBranding.color, editor, accentColor]);

  useEffect(() => {
    const newSrc = selectedBranding.logo?.src;
    if (newSrc && newSrc !== src) {
      editor &&
        void updateLogoElements(editor, newSrc).catch((error) => {
          console.error("Error updating design:", error);
        });
      setSrc(newSrc);
    }
  }, [selectedBranding.logo, editor, src]);

  return <DesignHuddleEditor {...{ project_id, onReady: handleReady, ...props }} />;
};
