import { PersonWithManager } from "../employees/server/supabase-person-service";

export function formatPersonWithManagerName(person: PersonWithManager): string {
  const preferred_name = person.person_text_data?.find((d) => d.key === "preferred_name")?.value;
  if (preferred_name) return preferred_name;

  const first_name = person.person_text_data?.find((d) => d.key === "first_name")?.value;
  const last_name = person.person_text_data?.find((d) => d.key === "last_name")?.value;

  if (!first_name && !last_name) return "Unknown";

  if (!first_name && last_name) return last_name;

  if (!last_name && first_name) return first_name;

  return `${first_name} ${last_name}`;
}
