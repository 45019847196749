import { Alert, AlertTitle, Box, BoxProps, LinearProgress, Typography } from "@mui/material";
import { memo, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useFlag } from "../../server/optimizely";
import { Carousel } from "../discover/carousel/carousel";
import { CeHelpButton } from "../generic/components/ce-help-button";
import { useAccount } from "../generic/hooks/use-account";
import { MomentCard } from "../moment-card/components/moment-card";
import { MomentCardMomentActivity } from "../moment/server/supabase-moment-service";

interface MomentDashboardSectionProps extends BoxProps {
  title: string;
  help?: string;
  moments?: MomentCardMomentActivity[];
  hasErrors?: boolean;
}

export const MomentDashboardSection = memo(function MomentDashboardSection({
  title,
  moments,
  hasErrors,
  help,
  ...boxProps
}: MomentDashboardSectionProps) {
  const { t } = useTranslation();
  const { account } = useAccount();
  const [drag_and_drop] = useFlag("drag_and_drop");

  const ref = useRef<HTMLElement>(null);

  const items = useMemo(
    () => moments?.map((value, key) => ({ key: key.toString(), value })),
    [moments],
  );

  if (!account) return null;

  return (
    <Box {...boxProps} sx={{ ...boxProps?.sx }} ref={ref}>
      <Typography variant="h5" sx={{ mb: 3, px: 2 }}>
        {title}
        {help && <CeHelpButton content={help} buttonSx={{ mb: 0.5 }} />}
      </Typography>
      {hasErrors && (
        <Alert severity="error">
          <AlertTitle>{t("Something went wrong")}</AlertTitle>
        </Alert>
      )}
      {items === undefined ? (
        <LinearProgress />
      ) : items.length === 0 ? (
        <Alert severity="info">
          <AlertTitle>{`No ${title} moments`}</AlertTitle>
        </Alert>
      ) : (
        <Carousel
          items={items}
          gap={2}
          Item={({ value }) => (
            <MomentCard draggable={drag_and_drop} moment={value.value} account={account} />
          )}
          ItemExtraProps={{}}
          itemWidth="calc(max(min(330px, 20%), 300px))"
        />
      )}
    </Box>
  );
});
