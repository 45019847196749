import { createInstance, useFeature } from "@optimizely/react-sdk";
import { VariableValuesObject } from "@optimizely/react-sdk/dist/client";
import { UserInfo } from "@optimizely/react-sdk/dist/utils";
import { config } from "../../config";
import { serviceURL } from "../../utils/service-url";

export let setOptimizelyUser: (value: UserInfo) => void;
export const optimizelyUser = new Promise<UserInfo>((resolve) => {
  setOptimizelyUser = resolve;
});

export const optimizely = createInstance({
  ...config.optimizely,
  datafileOptions: { urlTemplate: `${serviceURL("flags")}datafiles/%s.json` },
});
const overrides =
  typeof localStorage === "undefined"
    ? {}
    : (JSON.parse(localStorage.getItem("flags") || "{}") as {
        [key: string]: boolean;
      });

export const useFlag = (flag: string): [boolean, VariableValuesObject, boolean, boolean] =>
  flag in overrides
    ? [overrides[flag], {}, true, false]
    : // eslint-disable-next-line react-hooks/rules-of-hooks
      useFeature(flag);

export function useFlags<T extends string>(
  ...flags: T[]
): { flags: Record<T, boolean>; flagsLoaded: boolean } {
  const flagsMap = [] as { flag: T; enabled: boolean; loaded: boolean }[];
  for (const flag of flags) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [enabled, , loaded] = useFlag(flag);
    flagsMap.push({ flag, enabled, loaded });
  }

  return {
    flags: flagsMap.reduce(
      (acc, { flag, enabled }) => ({ ...acc, [flag]: enabled }),
      {} as Record<T, boolean>,
    ),
    flagsLoaded: flagsMap.every(({ loaded }) => loaded),
  };
}

const ls = (): void => {
  const featuresMap = optimizely.getOptimizelyConfig()?.featuresMap;
  if (featuresMap) {
    const features: Record<string, { enabled: boolean; source: number }> = {};
    Object.keys(featuresMap).forEach((flag): void => {
      features[flag] = {
        enabled: flag in overrides ? overrides[flag] : optimizely.isFeatureEnabled(flag),
        source: flag in overrides ? 1 : 0,
      };
    });

    console.table(features);
    console.log("0 = Optimizely, 1 = Override");
  }
};

class FeatureFlags {
  get ls(): void {
    ls();
    return;
  }

  toggle(flag: string): void {
    const enabled = flag in overrides ? overrides[flag] : optimizely.isFeatureEnabled(flag);
    overrides[flag] = !enabled;
    localStorage.setItem("flags", JSON.stringify(overrides));
    ls();
  }

  clear(): void {
    localStorage.setItem("flags", "{}");
  }

  constructor() {
    if (config.Dev) {
      if (window) window.ff = this;
      console.log(`Feature Flags:
        ff.ls: List all feature flags
        ff.toggle('flag'): Toggle a feature flag
        ff.clear(): Clear all feature flag overrides
      `);
    }
  }
}

declare global {
  interface Window {
    ff: FeatureFlags;
  }
}

new FeatureFlags();
