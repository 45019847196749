import { Alert, LinearProgress } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { SupabasePersonService } from "../../modules/employees/server/supabase-person-service";
import { Page } from "../../modules/layout/components/page";
import { PageContent } from "../../modules/layout/components/page-content";
import { PageHeader } from "../../modules/layout/components/page-header";
import { PageHeading } from "../../modules/layout/components/page-heading";
import { PeopleList } from "../../modules/people/components/people-list";
import { usePermissions } from "../../server/auth/use-permissions";
import { useSupabaseLoader } from "../../server/supabase/hooks";
import NotFound from "../not-found";

const RecipientActivityPage: FC = () => {
  const { super_admin } = usePermissions();
  const { t } = useTranslation();

  const {
    data: people,
    loading: peopleLoading,
    error: peopleError,
  } = useSupabaseLoader(
    async ({ supabase, account_id }) => {
      if (!super_admin) return;

      const { data, error } = await new SupabasePersonService(supabase).getAllWithManager(
        account_id,
      );

      if (error || !data) throw error || new Error("No people found");

      return data;
    },
    [super_admin],
  );

  if (!super_admin) return <NotFound />;
  return (
    <Page title={t("Recipient Activity | ChangeEngine")}>
      <PageHeader display="flex">
        <PageHeading heading={t("Recipient Activity")} />
      </PageHeader>
      <PageContent>
        {peopleLoading && <LinearProgress />}
        {peopleError && <Alert severity="error">{peopleError.message}</Alert>}
        {people && people.length > 0 && <PeopleList people={people} />}
      </PageContent>
    </Page>
  );
};

export default RecipientActivityPage;
