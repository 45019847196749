import { Card, CardActionArea, CardContent, CardMedia, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";

export const NewsletterCard: FC = () => {
  const { t } = useTranslation();

  return (
    <Card raised>
      <CardActionArea href="/moments/newsletter" sx={{ height: "100%" }} draggable={false}>
        <CardMedia
          image="https://images.changeengine.com/ce-cards/advanced-email-editor.png"
          alt="Create Email Newsletter"
          component="img"
          height="150px"
          draggable={false}
        />

        <CardContent>
          <Typography variant="h6">{t("Create Newsletter")}</Typography>
          <Typography variant="body1" mt={2}>
            {t("Create structured communications, like newsletters.")}
          </Typography>
          <Typography variant="caption" mt={2} fontStyle="italic">
            ⚠️ {t("Not compatible with our Image Designer or Co-Pilot AI tool.")}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
