import { LinearProgress } from "@mui/material";
import { FC } from "react";
import { toast } from "react-hot-toast";
import { CrudDataGrid } from "../../modules/generic/components/crud-data-grid/crud-data-grid";
import { Row } from "../../modules/generic/components/crud-data-grid/types";
import { Page } from "../../modules/layout/components/page";
import { PageContent } from "../../modules/layout/components/page-content";
import { PageHeader } from "../../modules/layout/components/page-header";
import { PageHeading } from "../../modules/layout/components/page-heading";
import { useSupabaseCallback, useSupabaseLoader } from "../../server/supabase/hooks";
import { propertyNotEmpty } from "../../utils/not-empty";
import CeAdminPageHeader from "./ce-admin-page-header";

const CeAdminDesignHuddleBrandPage: FC = () => {
  const { data, loading, reload } = useSupabaseLoader(
    async ({ supabase }) => {
      const { data: loadedData, error } = await supabase
        .from("account_custom_content_brand")
        .select("*");

      if (error) {
        toast.error("Could not load Design Huddle brands");
        return;
      }

      return loadedData.filter(propertyNotEmpty("id")).filter(propertyNotEmpty("name"));
    },
    [],
    "ce_admin",
  );

  const persist = useSupabaseCallback(
    async ({ supabase }, persistData: NonNullable<typeof data>[0]) => {
      const { id: account_id, name, content_custom_template_brand_brand_id } = persistData;

      const { error } = await supabase.rpc("upsert_account_custom_content_brand", {
        account_id_: account_id,
        brand_id_: content_custom_template_brand_brand_id ?? undefined,
      });

      if (error) {
        toast.error("Could not save Design Huddle brand");
        return;
      }

      toast.success(`Saved Design Huddle brand for account ${name}`);

      reload();

      return persistData;
    },
    [reload],
    "ce_admin",
  );

  return (
    <Page title="Adminstration | ChangeEngine">
      <PageHeader display="flex">
        <PageHeading
          heading={<CeAdminPageHeader />}
          subheading={"Manage Design Huddle Custom Brand IDs"}
        />
      </PageHeader>
      <PageContent>
        {loading && <LinearProgress />}
        {data && (
          <CrudDataGrid<Row<NonNullable<typeof data>[0]>>
            initialRows={data}
            columns={{
              name: {
                headerName: "Account Name",
                flex: 1,
                editable: false,
              },
              content_custom_template_brand_brand_id: {
                headerName: "Custom Brand ID",
                flex: 1,
                type: "number",
                editable: true,
              },
            }}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onUpdate={persist}
          />
        )}
      </PageContent>
    </Page>
  );
};

export default CeAdminDesignHuddleBrandPage;
