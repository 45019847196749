import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./lang/en.json";

const resources = {
  // Adding keys to the en i18n file are only required if using the
  // built-in pluralisation functionality, otherwise using the text as
  // a key works fine
  en: {
    translation: en,
  },
};

void i18n
  .use(initReactI18next)
  .init({
    returnNull: false,
    resources,
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
      skipOnVariables: false,
    },
  })
  .catch(console.error);

export { i18n, resources };
