import { PostgrestResponse } from "@supabase/postgrest-js";
import { SupabaseService } from "../../../server/supabase/base-supabase-service";
import { Database } from "../../../server/supabase/types/database-definitions";
import { UUID } from "../../../utils/uuid";

export type BrandTone = Database["public"]["Tables"]["brand_tone"]["Row"];

export class SupabaseBrandToneService extends SupabaseService<"brand_tone"> {
  table = "brand_tone" as const;

  async setDefault(tone_id: UUID): Promise<PostgrestResponse<BrandTone>> {
    return this.log_errors(await this.client.rpc("set_default_brand_tone", { tone_id }));
  }
}
