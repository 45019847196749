import { Grid2, Grid2Props } from "@mui/material";
import { FC } from "react";
import { PopulatedProgram } from "../programs/server/supabase-program-service";
import { ProgramCard } from "./program-card";
import { ValidProgram } from "./types";

const isValidProgram = (program: PopulatedProgram): program is ValidProgram => {
  return program.id !== null;
};

interface ProgramGridProps extends Grid2Props {
  programs: PopulatedProgram[];
}

export const ProgramGrid: FC<ProgramGridProps> = ({ programs, ...props }) => (
  <Grid2 {...props} spacing={2} container columns={{ xs: 2, sm: 3, md: 4, lg: 6, xl: 8 }}>
    {programs.filter(isValidProgram).map((program) => (
      <Grid2 key={`program-${program.id}`} size={{xs: 1}} minWidth={200}>
        <ProgramCard program={program} />
      </Grid2>
    ))}
  </Grid2>
);
