import { useCallback, useState } from "react";
import { UUID } from "../../../utils/uuid";
import { ChannelName } from "../../channels/types";
import { ClientFilter } from "../../tabbed-programs/hooks/use-moments";

export function useProgramFilters(): {
  filter: ClientFilter | undefined;
  handleChannelFilterChange: (channelName: ChannelName) => void;
  handleSegmentFilterChange: (id: UUID | undefined) => void;
  hasFilter: boolean;
} {
  const [filter, setFilter] = useState<ClientFilter>();

  const handleChannelFilterChange = useCallback((channelName: ChannelName): void => {
    setFilter((oldValue) => ({
      ...oldValue,
      channel: channelName || undefined,
    }));
  }, []);

  const handleSegmentFilterChange = useCallback((id: UUID | undefined): void => {
    setFilter((oldValue) => ({
      ...oldValue,
      segment: id,
    }));
  }, []);

  return {
    filter,
    handleChannelFilterChange,
    handleSegmentFilterChange,
    hasFilter: !!(filter?.channel || filter?.segment),
  };
}
