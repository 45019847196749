import { useSetAtom } from "jotai";
import { useEffect } from "react";
import { channelsAtom } from "../../generate/store";
import { Channel } from "../../generic/atoms/types/template";
import { useAccount } from "../../generic/hooks/use-account";

export const useEnabledChannelsLoader = (): void => {
  const { account } = useAccount();

  const setChannels = useSetAtom(channelsAtom);

  useEffect(() => {
    if (!account) return;

    const { enable_slack, enable_teams } = account;

    setChannels([
      ...(enable_slack ? [{ name: "Slack", channel_name: "slack" }] : []),
      ...(enable_teams ? [{ name: "Teams", channel_name: "teams" }] : []),
      { name: "Email", channel_name: "work_email" },
    ] as Channel[]);
  }, [account, setChannels]);
};
