import { Edit } from "@mui/icons-material";
import { CircularProgress, Tooltip } from "@mui/material";
import { FC, useState } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useSupabase } from "../../../../server/supabase/hooks";
import { XCircle } from "../../../../styles/icons/x-circle";
import { useDialog } from "../../../../utils/hooks/use-dialog";
import { SupabaseCustomEmail } from "../../../channels/email/server/supabase-custom-email-service";
import { Program } from "../../../programs/server/supabase-program-service";
import { SupabaseSegment } from "../../../segment/server/supabase-segment-service";
import {
  Permissions,
  PersonTextDataJson,
  SupabasePermissionsService,
} from "../../server/supabase-person-service";
import { PermissionsLimits } from "../../types";
import { PermissionsForm } from "./permissions-form";

export const PermissionsTableActions: FC<{
  permission: Permissions;
  employees: PersonTextDataJson[];
  capacity: PermissionsLimits;
  programs: Program[];
  segments: SupabaseSegment[];
  custom_emails: SupabaseCustomEmail[];
  refresh: () => void;
}> = ({ permission, employees, capacity, programs, segments, custom_emails, refresh }) => {
  return (
    <>
      <EditAction
        permission={permission}
        employees={employees}
        capacity={capacity}
        programs={programs}
        segments={segments}
        custom_emails={custom_emails}
        onEdit={refresh}
      />
      <DeleteAction permission={permission} onDelete={refresh} />
    </>
  );
};

const DeleteAction: FC<{ permission: Permissions; onDelete: () => void }> = ({
  permission,
  onDelete,
}) => {
  const { t } = useTranslation();
  const [deleting, setDeleting] = useState(false);

  useSupabase(
    async ({ supabase }) => {
      if (!deleting || !permission.id) return;

      const { error } = await new SupabasePermissionsService(supabase).removePermissions(
        permission.id,
      );
      if (error) toast.error(t("Unable to delete"));
      else {
        toast.success(t("Deleted"));
        onDelete();
      }

      setDeleting(false);
    },
    [deleting, permission.id, onDelete, t],
  );

  return (
    <>
      <Tooltip title={t("Delete")} placement="top" sx={{ cursor: "pointer", mr: 1 }}>
        {deleting ? (
          <CircularProgress size="small" />
        ) : (
          <XCircle fontSize="small" color={"info"} onClick={() => setDeleting(true)} />
        )}
      </Tooltip>
    </>
  );
};

const EditAction: FC<{
  permission: Permissions;
  employees: PersonTextDataJson[];
  capacity: PermissionsLimits;
  programs: Program[];
  segments: SupabaseSegment[];
  custom_emails: SupabaseCustomEmail[];
  onEdit: () => void;
}> = ({ permission, employees, capacity, programs, segments, custom_emails, onEdit }) => {
  const { t } = useTranslation();
  const { open, handleOpen, handleClose } = useDialog();

  return (
    <>
      <Tooltip title={t("Edit")} placement="top" sx={{ cursor: "pointer", mr: 1 }}>
        {open ? (
          <CircularProgress size="small" />
        ) : (
          <Edit fontSize="small" color={"info"} onClick={handleOpen} />
        )}
      </Tooltip>
      {open && (
        <PermissionsForm
          open={open}
          close={handleClose}
          employees={employees}
          capacity={capacity}
          programs={programs}
          segments={segments}
          custom_emails={custom_emails}
          existing={permission}
          onComplete={onEdit}
        />
      )}
    </>
  );
};
