export function toSlug(text: null): null;
export function toSlug(text: string): string;
export function toSlug(text: string | null): string | null;

export function toSlug(text: string | null): string | null {
  if (text === null) return text;
  return text
    .trim()
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w.-]+/g, "");
}
