import { Box, Skeleton } from "@mui/material";
import { t } from "i18next";
import { useAtomValue } from "jotai";
import { FC, useCallback, useState } from "react";
import { recipesAtom } from "../../modules/generate/store";
import { Page } from "../../modules/layout/components/page";
import { PageContent } from "../../modules/layout/components/page-content";
import { PageHeader } from "../../modules/layout/components/page-header";
import { PageHeading } from "../../modules/layout/components/page-heading";
import { RecipeGridItem } from "../../modules/recipes/recipe-grid-item";
import { RecipeListItem } from "../../modules/recipes/recipe-list-item";
import { RecipeUserInputModal } from "../../modules/recipes/recipe-user-input-popover";
import { Recipe } from "../../modules/self-driving/types";
import { View, ViewToggle } from "../../modules/tabbed-programs/view-toggle";
import { useFlag } from "../../server/optimizely";
import { usePopover } from "../../utils/hooks/use-popover";
import NotFound from "../not-found";

const RecipesPage: FC = () => {
  const [layout, setLayout] = useState<View>("grid");
  const recipes = useAtomValue(recipesAtom);
  const [selectedRecipe, setSelectedRecipe] = useState<Recipe | null>(null);
  const [ai_recipes] = useFlag("ai_recipes");

  const popover = usePopover();

  const toggleView = useCallback(() => {
    setLayout(layout === "grid" ? "list" : "grid");
  }, [layout]);

  const handleClick = useCallback(
    (recipe: Recipe) => {
      setSelectedRecipe(recipe);
      popover.handleOpen();
    },
    [popover],
  );

  if (!ai_recipes) return <NotFound />;

  return (
    <Page title={`${t("Recipes")} | ChangeEngine`}>
      <PageHeader>
        <PageHeading
          heading={t("Recipes")}
          subheading={t("Use our AI recipes to create Moments in moments")}
        />
      </PageHeader>

      <PageContent>
        <Box display="flex" gap={1} flexDirection="row-reverse" mb={4}>
          <ViewToggle onChange={toggleView} value={layout} />
        </Box>

        {layout === "grid" ? (
          <Box
            width="100%"
            display="grid"
            sx={(theme) => ({
              gap: theme.spacing(3),
            })}
            gridTemplateColumns={{
              xs: "1fr",
              sm: "repeat(2, 1fr)",
              md: "repeat(3, 1fr)",
              lg: "repeat(4, 1fr)",
            }}
          >
            {recipes.state === "hasData" &&
              recipes.data?.map((recipe) => (
                <RecipeGridItem
                  key={recipe.id}
                  recipe={recipe}
                  onClick={handleClick}
                  sx={{ height: "100%" }}
                />
              ))}
            {(recipes.state !== "hasData" || !recipes.data) &&
              Array.from({ length: 12 }).map((_, i) => (
                <Skeleton key={`${i}`} width="100%" height="300px" />
              ))}
          </Box>
        ) : (
          recipes.state === "hasData" &&
          recipes.data?.map((recipe) => (
            <RecipeListItem key={recipe.id} recipe={recipe} onClick={handleClick} />
          ))
        )}
      </PageContent>

      <RecipeUserInputModal
        open={popover.open}
        onClose={popover.handleClose}
        recipe={selectedRecipe}
      />
    </Page>
  );
};

export default RecipesPage;
