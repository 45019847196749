import { useState } from "react";
import { useSupabase } from "../../../server/supabase/hooks";
import { Account, SupabaseAccountService } from "../server/supabase-account-service";

type AccountInfo = {
  account?: Account;
  error?: string;
  loading: boolean;
};

export const useAccount = (): AccountInfo => {
  const [accountInfo, setAccountInfo] = useState<AccountInfo>({
    loading: true,
  });

  useSupabase(async ({ supabase, account_id }) => {
    const { data, error } = await new SupabaseAccountService(supabase).get(account_id);
    if (error) {
      setAccountInfo({ error: error?.message || "unknown error", loading: false });
    }

    setAccountInfo({ account: data ?? undefined, loading: false });
  }, []);
  return accountInfo;
};
