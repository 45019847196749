import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useSupabaseCallback, useSupabaseLoader } from "../../../server/supabase/hooks";
import {
  HrisConfig,
  SupabaseHRISService,
  SupabaseSuperAdminHRISService,
} from "../../integrations/server/supabase-hris-service";

export const useHrisIntegration = (): ReturnType<typeof useSupabaseLoader<HrisConfig | null>> & {
  disableIntegration: () => Promise<void>;
} => {
  const { t } = useTranslation();

  const result = useSupabaseLoader(async ({ supabase }) => {
    const { data } = await new SupabaseHRISService(supabase).maybeGet();
    return data;
  }, []);

  const disconnectHRIS = useSupabaseCallback(
    async ({ supabase }) => {
      await new SupabaseSuperAdminHRISService(supabase).disconnect();
    },
    [],
    "super_admin",
  );

  const { reload } = result;

  const disableIntegration = useSupabaseCallback(
    async ({ supabase }) => {
      await disconnectHRIS();

      const error = await new SupabaseHRISService(supabase).delete();

      if (!error) {
        reload();
        toast.success(t("HRIS successfully disconnected"));
      } else {
        toast.error(t("There was an error disconnecting your HRIS, please contact support."));
      }
    },
    [disconnectHRIS, t, reload],
  );

  return { ...result, disableIntegration };
};
