import { Tag } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import { useAtomValue } from "jotai";
import { FC, RefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useFlag } from "../../server/optimizely";
import { Employees } from "../../styles/icons/employees";
import { EmployeesManager } from "../../styles/icons/employees-manager";
import { ChannelIcon } from "../channels/components/channel-icon";
import { getDateString } from "../discover/carousel/discover-card-content";
import { libraryQueryAtom } from "../discover/library/store";
import {
  LibrarySegment,
  LibraryTemplate,
  isSequenceTemplateQuery,
} from "../discover/library/types";
import { SequenceCardTitle } from "./sequence-card-title";
import { SequenceCardToggle } from "./sequence-card-toggle";
import { SequenceTemplateThumbnail } from "./sequence-template-thumbnail";
import {
  sequenceAtom,
  sequenceTemplateChannelFamily,
  sequenceTemplateSegmentFamily,
  sequenceTemplateSelectedFamily,
} from "./store";

export const DiscoverSequenceBar: FC = () => {
  const { t } = useTranslation();

  const container = useRef<HTMLDivElement>(null);

  const sequence = useAtomValue(sequenceAtom);

  return (
    <Box
      sx={(theme) => ({
        borderRight: `1px solid ${theme.palette.divider}`,
        overflowY: "auto",
      })}
      pr={4}
      py={2}
    >
      <Box sx={{ fontSize: "1.2rem", fontWeight: 500 }} mb={4}>
        {t("Templates")}
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr",
          gridAutoRows: "auto",
        }}
        gap={2}
      >
        {sequence?.templates?.map((template) => (
          <SequenceBarCard key={template.id} template={template} container={container} />
        ))}
      </Box>
    </Box>
  );
};

const SequenceBarCard: FC<{
  template: LibraryTemplate;
  container: RefObject<HTMLDivElement>;
}> = ({ template, container }) => {
  const channel = useAtomValue(sequenceTemplateChannelFamily(template.slug));
  const [visible, setVisible] = useState(false);
  const card = useRef<HTMLDivElement>(null);
  const observer = useRef<IntersectionObserver | null>(null);

  const query = useAtomValue(libraryQueryAtom);
  const sequenceId = useMemo(() => {
    return query ? (isSequenceTemplateQuery(query) ? query.sequence_slug : null) : null;
  }, [query]);

  useEffect(() => {
    if (!observer.current) {
      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            setVisible(true);
          }
        },
        {
          root: container.current,
        },
      );
      observer.current.observe(card.current!);
    }
  }, [template, container, setVisible]);
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    navigate(`/discover/sequence/${sequenceId}/${template.slug}`);
  }, [navigate, sequenceId, template]);

  const active = useMemo(() => {
    return query && isSequenceTemplateQuery(query) && query.template_slug === template.slug;
  }, [query, template.slug]);

  const [, { template_toggle }] = useFlag("sequences");

  const selected = useAtomValue(sequenceTemplateSelectedFamily(template.slug));

  const segment = useAtomValue(sequenceTemplateSegmentFamily(template.slug));

  return (
    <Box
      sx={(theme) => ({
        borderRadius: `${theme.shape.borderRadius}px`,
        borderWidth: "2px",
        borderStyle: "solid",
        borderColor: active ? theme.palette.primary.light : "transparent",
        "& .MuiSwitch-root": {
          display: "none",
        },
        ":hover": {
          borderColor: theme.palette.primary.main,
          "& .MuiSwitch-root": {
            display: "inline-flex",
          },
        },
        overflow: "hidden",
        position: "relative",
      })}
    >
      <Box
        ref={card}
        sx={[
          (theme) => ({
            minHeight: `var(--sb-thumb-width)`,
            boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.2)",
            display: "grid",
            gridTemplateColumns: "var(--sb-thumb-width) 1fr",
            gridAutoRows: "1fr",
            gap: 2,

            pr: 2,
            backgroundColor: theme.palette.action.disabledBackground,

            ":hover": {
              bgcolor: theme.palette.action.hover,
            },
            color: theme.palette.text.secondary,
            transition: "all 0.2s ease-out",
            cursor: "pointer",
            position: "relative",
          }),
          selected &&
            ((theme) => ({
              color: theme.palette.text.primary,
              backgroundColor: theme.palette.background.selected,
            })),
          active && ((theme) => ({ borderColor: theme.palette.primary.main })),
        ]}
        onClick={handleClick}
      >
        <Box
          sx={{
            height: "var(--sb-thumb-width)",
            opacity: selected ? 1 : 0.8,
          }}
        >
          {visible && <SequenceTemplateThumbnail template={template} />}
        </Box>
        <Stack justifyContent="space-between" py={1}>
          <SequenceCardTitle title={template.title} date={getDateString(template)} />
          <Stack direction="row" justifyContent="flex-start">
            {channel && (
              <ChannelIcon
                channel={channel.channel_name}
                fontSize="small"
                sx={{ marginTop: "3px" }}
              />
            )}
            {segment && <SegmentIcon segment={segment} />}
            {template_toggle && (
              <SequenceCardToggle slug={template.slug} sx={{ marginLeft: "auto", p: 0 }} />
            )}
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

const SegmentIcon: FC<{ segment: LibrarySegment }> = ({ segment }) => {
  let icon;

  switch (segment) {
    case "#general":
      icon = <Tag />;
      break;

    case "All employees":
      icon = <Employees />;
      break;

    case "All managers":
    case "Managers of all employees":
      icon = <EmployeesManager />;
      break;

    case "":
      icon = <></>;
      break;
  }

  return <Typography>{icon}</Typography>;
};
