import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export function useSelectedStatusTab(): {
  selectedTab: string;
  handleTabChange: (_: React.SyntheticEvent, newValue: string) => void;
} {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState("active");

  const status = searchParams.get("status");

  useEffect(() => {
    if (!status) {
      setSearchParams(
        (prev) => {
          prev.set("status", "active");
          prev.append("status", "draft");
          prev.append("status", "paused");
          return prev;
        },
        { replace: true },
      );
    }
  }, [status, setSearchParams]);

  useEffect(() => {
    switch (status) {
      case "finished":
      case "paused":
      case "archived":
        setSelectedTab(status);
        break;
      default:
        setSelectedTab("active");
        break;
    }
  }, [status]);

  const handleTabChange = useCallback(
    (_: React.SyntheticEvent, newValue: string): void => {
      searchParams.delete("status");

      switch (newValue) {
        case "finished":
        case "paused":
        case "archived":
          searchParams.set("status", newValue);
          break;
        default:
          searchParams.set("status", "active");
          searchParams.append("status", "draft");
          searchParams.append("status", "paused");
          break;
      }
      setSearchParams(searchParams, { replace: true });
    },
    [searchParams, setSearchParams],
  );

  return { selectedTab, handleTabChange };
}
