export const extractMergeTags = (text: string[]): string[] => {
  const mergeTagRegex = RegExp(/\{{(.*?)\}}/g);
  return text.reduce((prev, curr) => {
    let match: RegExpExecArray | null;
    while ((match = mergeTagRegex.exec(curr ?? "")) !== null) {
      if (!prev.find((x) => match && x === match[1])) {
        prev.push(match[1]);
      }
    }

    return prev;
  }, [] as string[]);
};
