import { useSupabaseLoader } from "../../../server/supabase/hooks";
import { BrandTone, SupabaseBrandToneService } from "../server/brand-tone-service";

export function useBrandTones(): ReturnType<typeof useSupabaseLoader<BrandTone[]>> {
  return useSupabaseLoader(async ({ supabase, account_id }) => {
    const { data, error } = await new SupabaseBrandToneService(supabase).getAll(account_id, {
      order: [{ column: "name", dir: "asc" }],
    });

    if (error) throw error;

    return data;
  }, []);
}
