import { useMountEffect } from "@react-hookz/web";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

const EmailAddressConfigurationPage: FC = () => {
  const navigate = useNavigate();

  useMountEffect(() => navigate("/manage/channels/email", { replace: true }));

  return <></>;
};

export default EmailAddressConfigurationPage;
