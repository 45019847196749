import { Card, CardActionArea, CardContent, CardMedia, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useFlag } from "../../../server/optimizely";

export const CreateFromScratchCard: FC = () => {
  const { t } = useTranslation();
  const [multiFormat, ,] = useFlag("multi_format");
  return (
    <Card raised>
      <CardActionArea href="/moments" sx={{ height: "100%" }} draggable={false}>
        <CardMedia
          image="https://images.changeengine.com/ce-cards/create-from-scratch.png"
          alt="Blank Canvas"
          component="img"
          height="150px"
          draggable={false}
        />

        <CardContent>
          <Typography variant="h6">
            {multiFormat ? t("Moment from Scratch") : t("Create Moment from Scratch")}
          </Typography>
          <Typography variant="body1" mt={2}>
            {t(
              "Create a highly customized Moment using the power of our AI and Image Designer tools",
            )}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
