import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useDialog } from "../../../utils/hooks/use-dialog";
import { BrandKitLogo } from "../server/brand-kit-service";
import { BrandKitManager } from "../types";
import { BrandKitChoiceRow } from "./choice-row";
import { BrandKitFilePicker } from "./file-picker";

export const BrandKitLogoChoiceRow: FC<{
  logos: BrandKitLogo[];
  logoManager: BrandKitManager["logo"];
  selected?: BrandKitLogo;
  selectedText?: string;
  showTitle?: boolean;
  showDefaultIndicator?: boolean;
}> = ({
  logos,
  logoManager,
  selected,
  selectedText,
  showTitle = true,
  showDefaultIndicator = true,
}) => {
  const dialog = useDialog();
  const { t } = useTranslation();

  return (
    <BrandKitChoiceRow
      title={showTitle ? t("Logos") : undefined}
      subtitle={t("We recommended using a square logo with a background color as default.")}
      type="logo"
      existing={logos ?? []}
      selected={selected}
      onClick={logoManager.add && dialog.handleOpen}
      remove={logoManager.remove}
      setSelected={logoManager.setSelected}
      selectedText={selectedText}
      showDefaultIndicator={showDefaultIndicator}
    >
      {logoManager.add && (
        <BrandKitFilePicker
          open={dialog.open}
          handleClose={dialog.handleClose}
          onChange={logoManager.add}
        />
      )}
    </BrandKitChoiceRow>
  );
};
