import {
  DecoratorBlockNode,
  SerializedDecoratorBlockNode,
} from "@lexical/react/LexicalDecoratorBlockNode";
import { addClassNamesToElement } from "@lexical/utils";
import { DOMExportOutput, ElementFormatType, LexicalNode, NodeKey, Spread } from "lexical";
import { serviceURL } from "../../../../utils/service-url";
import { imageURL } from "../../../design-huddle/utils";

export const DefaultProportionOfMaxWidth = 0.6666;
export type SerializedDesignHuddleNode =
  | Spread<
      {
        version: 1;
        type: "design-huddle";
        id?: string;
        account_id: string;
        page_number?: number;
        dimensions?: { height: number; width: number };
        customisations?: DesignHuddleCustomisations;
        cacheBustNumber: number;
        mustBeCloned?: boolean;
      },
      SerializedDecoratorBlockNode
    >
  | Spread<
      {
        version: 2;
        type: "design-huddle";
        id?: string;
        account_id: string;
        page_number?: number;
        proportionOfMaxWidth?: number;
        aspect?: number;
        customisations?: DesignHuddleCustomisations;
        cacheBustNumber: number;
        mustBeCloned?: boolean;
        href?: string;
      },
      SerializedDecoratorBlockNode
    >;

export type DesignHuddleCustomisations = {
  templateCode: string;
  mergeTags: string[];
};

export class DesignHuddleNode extends DecoratorBlockNode {
  __id?: string;
  __account_id: string;
  __cacheBustNumber: number;
  __page_number?: number;
  __proportionOfMaxWidth: number;
  __aspect: number;
  __customisations?: DesignHuddleCustomisations;
  __must_be_cloned?: boolean;
  __url?: string;
  __href?: string;

  static getType(): string {
    return "design-huddle";
  }

  static clone(node: DesignHuddleNode): DesignHuddleNode {
    return new this(
      node.__account_id,
      node.__id,
      node.__page_number,
      node.__proportionOfMaxWidth,
      node.__aspect,
      node.__customisations,
      node.__format,
      node.__key,
      node.__cacheBustNumber,
      node.__must_be_cloned,
      node.__href,
    );
  }

  constructor(
    account_id: string,
    id?: string,
    page_number?: number,
    proportionOfMaxWidth: number = DefaultProportionOfMaxWidth,
    aspect: number = 1,
    customisations?: DesignHuddleCustomisations,
    format?: ElementFormatType,
    key?: NodeKey,
    cacheBustNumber: number = 1,
    mustBeCloned?: boolean,
    href?: string,
  ) {
    super(format, key);

    this.__id = id;
    this.__account_id = account_id;
    this.__page_number = page_number;
    this.__proportionOfMaxWidth = proportionOfMaxWidth;
    this.__aspect = aspect;
    this.__customisations = customisations;
    this.__cacheBustNumber = cacheBustNumber;
    this.__must_be_cloned = mustBeCloned;
    this.__href = href;
  }

  setProjectId(id: string): void {
    const writable = this.getWritable();
    writable.__id = id;
  }

  setHref(href?: string): void {
    const writable = this.getWritable();
    writable.__href = href;
  }

  getHref(): string | undefined {
    return this.__href;
  }

  setPageNumber(page_number?: number): void {
    const writable = this.getWritable();
    writable.__page_number = page_number;
  }

  templateChangeUpdate(customisations?: DesignHuddleCustomisations): void {
    const writable = this.getWritable();
    writable.__customisations = customisations;
    writable.__cacheBustNumber = this.__cacheBustNumber + 1;
  }

  setMustBeCloned(mustBeCloned?: boolean): void {
    const writable = this.getWritable();
    writable.__must_be_cloned = mustBeCloned;
  }

  createDOM(): HTMLElement {
    const dom = document.createElement("span");
    addClassNamesToElement(dom, "decorator-block-node");
    return dom;
  }

  setSize(proportionOfMaxWidth: number, aspect: number): void {
    const writable = this.getWritable();
    writable.__proportionOfMaxWidth = proportionOfMaxWidth;
    writable.__aspect = aspect;
  }

  static importJSON(serializedNode: SerializedDesignHuddleNode): DesignHuddleNode {
    if (serializedNode.version === 1) {
      return new this(
        serializedNode.account_id,
        serializedNode.id,
        serializedNode.page_number,
        Math.min(1, (serializedNode.dimensions?.width ?? 600) / 600),
        (serializedNode.dimensions?.height ?? 400) / (serializedNode.dimensions?.width ?? 600),
        serializedNode.customisations,
        serializedNode.format,
        undefined,
        serializedNode.cacheBustNumber,
        serializedNode.mustBeCloned,
      );
    }
    return new this(
      serializedNode.account_id,
      serializedNode.id,
      serializedNode.page_number,
      serializedNode.proportionOfMaxWidth,
      serializedNode.aspect,
      serializedNode.customisations,
      serializedNode.format,
      undefined,
      serializedNode.cacheBustNumber,
      serializedNode.mustBeCloned,
      serializedNode.href,
    );
  }

  exportJSON(): SerializedDesignHuddleNode {
    return {
      id: this.__id,
      cacheBustNumber: this.__cacheBustNumber,
      account_id: this.__account_id,
      page_number: this.__page_number,
      proportionOfMaxWidth: this.__proportionOfMaxWidth,
      aspect: this.__aspect,
      type: "design-huddle",
      version: 2,
      format: this.__format,
      customisations: this.__customisations,
      mustBeCloned: this.__must_be_cloned,
      href: this.__href,
    };
  }

  decorate(): React.JSX.Element {
    return <></>;
  }

  exportDOM(): DOMExportOutput {
    const dom = document.createElement("img");
    dom.src = imageURL(this.__account_id, this.__id ?? "", this.__cacheBustNumber);
    dom.style.width = `${(this.__proportionOfMaxWidth ?? 1) * 100}%`;
    dom.style.aspectRatio = `${this.__aspect ?? 1}`;
    return {
      element: dom,
    };
  }

  setNewUrl(url: string): void {
    const writable = this.getWritable();
    writable.__url = url;
  }

  getDesignHuddlePreviewUrl(maxWidth?: number): string | null {
    if (!this.__customisations) {
      return null;
    }
    const cacheBustParam = `v=${this.__cacheBustNumber}`;
    const params =
      this.__customisations.mergeTags.length > 0
        ? `?${this.__customisations.mergeTags
            .map((t) => `${t}={{${t}}}`)
            .join("&")}&${cacheBustParam}`
        : `?${cacheBustParam}`;
    const width = maxWidth ? `&width=${Math.floor(maxWidth * this.__proportionOfMaxWidth)}` : "";
    return `${serviceURL("huddle")}template/${this.__customisations.templateCode}/preview${params}${width}`;
  }

  getImageUrl(): string {
    return imageURL(this.__account_id, this.__id!, this.__cacheBustNumber);
  }
}

export function $isDesignHuddleNode(
  node: LexicalNode | null | undefined,
): node is DesignHuddleNode {
  return node instanceof DesignHuddleNode;
}
