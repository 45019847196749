import { Box } from "@mui/material";
import { FC } from "react";
import { TemplateType } from "../../generic/atoms/types/template";
import { DiscoverTemplate } from "../types";

export const DiscoverCardBadge: FC<{
  template: DiscoverTemplate;
  index: number;
}> = ({ template, index }) => {
  if (template.type === TemplateType.sequence) {
    return null;
  }

  return (
    <Box
      sx={[
        (theme) => ({
          position: "absolute",
          top: 10,
          right: 10,
          backgroundColor: "#ffffffdd",
          borderRadius: "5px",
          p: theme.spacing(0.5),
          color: "black",
          boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
          height: "2em",
          textAlign: "center",
          lineHeight: "2em",
          fontWeight: 600,
          width: "2.4em",
          zIndex: 1,
        }),
        template.trending !== null && {
          width: "auto",
        },
      ]}
    >
      {template.trending ? template.trending : index + 1}
    </Box>
  );
};
