import { INTERVAL } from "../../../utils/iso8601";
import yup, { yupInterval, yupISO8601Date, yupUUID } from "../../../utils/yup";

export const eventKeyValidator = yup.string().required("Enter a key");
export type OptionalInterval = INTERVAL | "never";

export const eventSchema = yup.object({
  id: yupUUID.required(),
  key: eventKeyValidator,
  source: yup.string().required(),
  title: yup
    .string()
    .when("source", ([source], schema) =>
      source !== "WEB-DATE" ? schema.required("Enter a title") : schema,
    ),
  date: yupISO8601Date.nullable().required("Select a date"),
  repeat: yupInterval.notRequired(),
});

export const eventKeySchemaShape = {
  key: eventKeyValidator,
  title: yup.string().notRequired(),
  calendar: yup.boolean().required(),
  has_schedule_event: yup.boolean().required(),
  date: yup
    .string()
    .when("calendar", {
      is: (val?: string) => !!val,
      then: () => yupISO8601Date.required("Select a date"),
    })
    .nullable()
    .default(null),
  repeat: yupInterval.nullable().optional(),
  allow_initial: yup.boolean().nullable(),
  allow_repeats: yup.boolean().nullable(),
};

export type Event = yup.InferType<typeof eventSchema>;
