import { Autocomplete, TextField, TextFieldProps } from "@mui/material";
import { Controller, FieldValues, Path, useFormContext } from "react-hook-form";
import { ElementTracking, useElementTracking } from "../../../analytics/analytics";

export const CeDropdown = <TFieldValues extends FieldValues>({
  name,
  label,
  options,
  InputProps = {},
  loading = false,
  analyticsCategory,
}: {
  name: Path<TFieldValues>;
  label: string;
  options: { label: string; value: string }[];
  loading?: boolean;
  onChange?: (value: string) => void;
  InputProps?: TextFieldProps;
} & ElementTracking): React.JSX.Element => {
  const methods = useFormContext<TFieldValues>();
  const analyticsProps = useElementTracking(analyticsCategory, name, "dropdown");

  return (
    <Controller
      name={name}
      control={methods.control}
      render={({ field }) => (
        <Autocomplete
          value={field.value || null}
          onChange={(_, value) => field.onChange(value)}
          options={options.map((option) => option.value)}
          loading={loading}
          getOptionLabel={(option) => options.find((o) => o.value === option)?.label ?? ""}
          renderInput={(params: TextFieldProps) => (
            <TextField {...params} {...InputProps} label={label} placeholder={label} fullWidth {...analyticsProps} />
          )}
        />
      )}
    />
  );
};
