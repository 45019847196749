import { Alert, Box, Typography } from "@mui/material";
import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useAccountLimit } from "../../../../generic/hooks/use-account-limit";
import { SlackAliasTable } from "./alias-table";

export const SlackAlias: FC = () => {
  const { t } = useTranslation();

  const { data: limit, loading } = useAccountLimit("slack_aliases");

  if (loading) return null;

  return (
    <>
      <Typography variant="h6">{t("Add Slack Alias")}</Typography>
      <Typography variant="subtitle2" color="text.secondary">
        <Trans>
          Your Slack bot has a base logo and title which is set up by an Admin, typically your
          company Logo and Name. However, you can create an Alias for your Bot here so that some
          messages look as though they are coming from a different brand or person. For e.g you may
          want to send Holiday Moments from a Holiday Bot. Use the table below to create new
          Aliases.
        </Trans>
      </Typography>
      {limit === 0 && (
        <Alert severity="info" sx={{ mb: 2 }}>
          <Trans>
            Slack aliases are not enabled on your account, contact your Customer Success
            representative for more information
          </Trans>
        </Alert>
      )}
      <Box sx={{ mt: 3 }}>
        <SlackAliasTable aliasLimit={limit ?? 0} />
      </Box>
    </>
  );
};
