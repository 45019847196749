import { ApiResponse, ApiService } from "../../../../server/api/client";
import { Database } from "../../../../server/supabase/types/database-definitions";
import { UUID } from "../../../../utils/uuid";
import { CustomEmailDomain } from "../types/";

export class CustomEmailDomainsService extends ApiService {
  module = "custom-email/domain";

  async add(
    customEmailDomain: CustomEmailDomain,
  ): Promise<ApiResponse<Database["public"]["Tables"]["custom_email_domain"]["Row"]>> {
    return await this.post("", customEmailDomain);
  }

  async refreshVerifiedStatus(customEmailId: UUID): Promise<ApiResponse<{ verified: boolean }>> {
    return await this.get(`${customEmailId}/refresh-verified-status`);
  }
}
