import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";

export const TemplateSubject: FC<{ subject?: string }> = ({ subject }) => {
  const { t } = useTranslation();

  return (
    <Stack direction="row" gap={1}>
      <Typography variant="body2" color="text.secondary">
        {t("Subject")}:
      </Typography>
      <Typography variant="body1">{subject}</Typography>
    </Stack>
  );
};
