import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { FC, useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { UUID } from "../../../../utils/uuid";
import yup from "../../../../utils/yup";
import { CeTextInput } from "../../../generic/components/form-fields/ce-text-field";

export const copyMomentSchema = yup.object({
  name: yup.string().required("Enter the name of the new Moment").label("Name"),
});

export type CopyDialogProps = {
  open: boolean;
  id: UUID;
  title: string | null;
  onClose: () => void;
};

export const CopyDialog: FC<CopyDialogProps> = ({ id, open, title, onClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onSubmit = useCallback(
    (data: { name: string }) => {
      navigate(`/moments/copy/${id}?new_title=${encodeURIComponent(data.name)}`);
    },
    [id, navigate],
  );

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>{t("Copy Moment")}</DialogTitle>
      <CopyMomentDialogForm title={title} onSubmit={onSubmit} onClose={onClose} />
    </Dialog>
  );
};

export const CopyMomentDialogForm: FC<{
  title: string | null;
  onSubmit: (data: { name: string }) => void;
  onClose: () => void;
}> = ({ title, onSubmit, onClose }) => {
  const { t } = useTranslation();

  const methods = useForm({
    defaultValues: { name: `Copy of ${title ?? ""}` },
    resolver: yupResolver(copyMomentSchema),
  });

  return (
    <FormProvider {...methods}>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <DialogContent>
          <CeTextInput name="name" margin="dense" label={t("Name")} fullWidth />
        </DialogContent>
        <DialogActions>
          <Button data-analytics-id="moment-copy-dialog-cancel" color="error" onClick={onClose}>
            {t("Cancel")}
          </Button>
          <Button
            data-analytics-id="moment-copy-dialog-confirm"
            color="primary"
            variant="contained"
            type="submit"
          >
            {t("Make a Copy")}
          </Button>
        </DialogActions>
      </form>
    </FormProvider>
  );
};
