import { TextField, TextFieldProps } from "@mui/material";
import { GridSearchIcon } from "@mui/x-data-grid-pro";
import { FC, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";

export type SearchFieldProps = Omit<TextFieldProps, "onSubmit" | "value"> & {
  onSubmit?: (value: string) => void;
  defaultValue: string;
};

export const SearchField: FC<SearchFieldProps> = ({
  label,
  title,
  onChange,
  onSubmit,
  defaultValue,
  ...textFieldProps
}) => {
  const { t } = useTranslation();

  const formRef = useRef<HTMLFormElement>(null);
  const ref = useRef<HTMLInputElement>(null);

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>): void => {
      e.preventDefault();
      onSubmit?.(ref.current?.value ?? "");
    },
    [onSubmit, ref],
  );

  return (
    <form onSubmit={handleSubmit} ref={formRef}>
      <TextField
        inputRef={ref}
        name="search"
        label={label ?? t("Search")}
        title={title ?? t("Search")}
        fullWidth
        InputProps={{
          endAdornment: (
            <GridSearchIcon
              fontSize="small"
              sx={{ ml: 1.5, cursor: onSubmit ? "pointer" : "initial" }}
              onClick={() => onSubmit?.(ref.current?.value ?? "")}
            />
          ),
        }}
        onChange={onChange}
        defaultValue={defaultValue}
        {...textFieldProps}
      />
    </form>
  );
};
