import { omit } from "lodash-es";
import { FC, useCallback, useEffect } from "react";
import { Login } from "../../modules/pipe/types";
import { LocalStorageService } from "../../utils/local-storage";

const PipePage: FC = () => {
  const messageCallback = useCallback((e: MessageEvent<{ type: "login_add" } & Login>) => {
    const storage = new LocalStorageService();
    if (!e.origin.endsWith("changeengine.com")) return;
    if (e.data.type === "login_add") {
      const logins = JSON.parse(storage.get("logins_v2") ?? "{}") as { [k: string]: Login };
      logins[e.data.id] = omit(e.data, "type");
      storage.set("logins_v2", JSON.stringify(logins));
      e.source?.postMessage(
        {
          type: "logins",
          logins,
        },
        { targetOrigin: e.origin },
      );
    }
  }, []);

  useEffect(() => {
    window.addEventListener("message", messageCallback);
    return () => window.removeEventListener("message", messageCallback);
  }, [messageCallback]);

  return <></>;
};

export default PipePage;
