import { noNullFields } from "../../../server/supabase/base-supabase-service";
import { useSupabaseLoader } from "../../../server/supabase/hooks";
import {
  SupabaseSegmentFieldService,
  UsableAccountPersonField,
} from "../server/supabase-segment-field-service";

export const useSegmentFields = (): ReturnType<
  typeof useSupabaseLoader<UsableAccountPersonField[]>
> => {
  return useSupabaseLoader(async ({ supabase }) => {
    const supabaseService = new SupabaseSegmentFieldService(supabase);
    const { data, error: segmentFieldError } = await supabaseService.getAll(null, {
      order: [{ column: "id" }],
    });
    if (!data || segmentFieldError) throw new Error("Error retrieving segment fields");
    if (!noNullFields(data)) throw new Error("Bad data");

    return data;
  }, []);
};
