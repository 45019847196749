import { atom } from "jotai";
import { atomFamily, atomWithDefault, loadable } from "jotai/utils";
import { getTokenAtom } from "../../server/auth/store";
import { authHeader } from "../../utils/auth-header";
import { uuidv4 } from "../../utils/uuid";
import { brandingAtom } from "../brand-kit/store";
import { TemplateInfo } from "../brand-kit/types";
import { getPageAspect, getPageNumber } from "../design-huddle/utils";
import {
  brandToneAtom,
  debugLabel,
  gptModelAtom,
  imagePreviewURLFamily,
  unsplashSearchFamily,
} from "../generate/store";
import { selfDriving } from "../self-driving/self-driving";

export const amcInitialContextFamily = atomFamily((query: string) =>
  debugLabel(
    `amcInitialContentAtomFamily?query=${query}`,
    atomWithDefault(async (get) => {
      const token = await get(getTokenAtom).getToken();
      const gptModel = get(gptModelAtom);

      if (!token || !query || !gptModel) return null;

      const { data, error } = await selfDriving.POST("/recipes/{recipeId}/initial_context", {
        body: {
          chatId: uuidv4(),
          answers: [
            {
              answer: query,
            },
          ],
        },
        params: {
          path: { recipeId: "default" },
          query: { model: gptModel },
        },
        ...authHeader(token),
      });

      if (error || !data) throw new Error(error.error || "Request failed");

      return data;
    }),
  ),
);

export const amcImageContextFamily = atomFamily((query: string) =>
  debugLabel(
    `amcImageFamily(${query})`,
    atomWithDefault(async (get) => {
      const token = await get(getTokenAtom).getToken();
      const brandTone = get(brandToneAtom);
      const gptModel = get(gptModelAtom);

      const i = await get(amcInitialContextFamily(query));

      if (!query || !token || !i || !gptModel) return null;

      const { data, error } = await selfDriving.POST(
        "/recipes/{recipeId}/follow_up/image/{imgNum}",
        {
          body: {
            chatId: i.chatId,
            ...(brandTone?.use_in_images && { customTone: { summary: brandTone.summary } }),
          },
          params: {
            path: { recipeId: "default", imgNum: "1" },
            query: { model: gptModel },
          },
          ...authHeader(token),
        },
      );

      if (error || !data) throw new Error(error.error || "Request failed");

      return { chatId: data.chatId, response: data.response };
    }),
  ),
);

export const amcImageFamily = atomFamily((query: string) =>
  debugLabel(
    `amcImageFamily(${query})`,
    atomWithDefault(async (get) => {
      const imageContext = await get(amcImageContextFamily(query));
      if (!imageContext) return null;

      const brandInfo = get(brandingAtom);
      const orientation = getPageAspect(brandInfo);
      const pageNumber = getPageNumber(brandInfo);

      const images = await get(
        unsplashSearchFamily({ query: imageContext.response.header, orientation }),
      );
      const image = images?.[pageNumber];

      const info: TemplateInfo = {
        template_code: "AI Templates",
        page_number: pageNumber,
        header: imageContext.response.header,
        subheader: imageContext.response.subheader,
        stableDiffusionImage: false,
        unsplashExtra: image && {
          downloadUrl: image.links.download_location,
          attributionData: {
            url: image.links.html,
            name: image.user.name,
          },
        },
      };

      return {
        ...info,
        url: get(loadable(imagePreviewURLFamily(info))),
        chatId: imageContext.chatId,
      };
    }),
  ),
);

export const amcMomentFamily = atomFamily((query: string) =>
  loadable(
    atom(async (get) => {
      if (!query) return null;
      const initialContext = await get(amcInitialContextFamily(query));
      return {
        initialContext,
        image: get(loadable(amcImageFamily(query))),
        contextQuery: {
          prompt: query,
          recipeId: "default",
          answers: [],
          ...initialContext,
        },
      };
    }),
  ),
);
