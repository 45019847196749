import { useEffect, useState } from "react";

export function usePendo(): typeof window.pendo {
  const [pendo, setPendo] = useState<typeof window.pendo>();

  useEffect(() => {
    if (typeof window.pendo !== "undefined") {
      setPendo(window.pendo);
      return;
    }

    const checkInterval = setInterval(() => {
      if (typeof window.pendo !== "undefined") {
        setPendo(window.pendo);
        clearInterval(checkInterval);
      }
    }, 100); // Check every 100ms

    return () => clearInterval(checkInterval);
  }, []);

  return pendo;
}
