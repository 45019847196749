import { uniq } from "lodash-es";
import { useSupabaseLoader } from "../../../server/supabase/hooks";
import { UseSupabaseLoaderState } from "../../../server/supabase/hooks/types";
import { ISO8601_DATE } from "../../../utils/iso8601";
import { propertyNotEmpty } from "../../../utils/not-empty";
import { UUID } from "../../../utils/uuid";
import { PersonWithManager, SupabasePersonService } from "../server/supabase-person-service";

export type EmployeeData = {
  data: {
    manager_employee_id: string | null;
    timezone: string | null;
    id: UUID | null;
  }[];
  keys: string[];
};

export function useEmployeeData(): UseSupabaseLoaderState<EmployeeData> & {
  reload: () => void;
} {
  return useSupabaseLoader(async ({ supabase, account_id }) => {
    const { data, error: loadError } = await new SupabasePersonService(supabase).getAllWithManager(
      account_id,
    );
    if (loadError || !data) throw loadError;

    const allEmployees = Object.fromEntries(
      data.filter(propertyNotEmpty("id")).map((person) => [person.id, person]),
    );

    const mappedData = data.map((person) => {
      return {
        id: person.id,
        ...textData(person),
        manager_employee_id: person.manager_employee_id,
        timezone: person.timezone,
        ...eventData(person),
        ...connectionData(person, allEmployees),
      };
    });

    const keys: string[] = uniq(
      mappedData.reduce((prev, current) => [...prev, ...Object.keys(current)], [] as string[]),
    );

    return { data: mappedData, keys };
  }, []);
}

const textData = (person: PersonWithManager): { [key: string]: string } =>
  person.person_text_data !== null
    ? person.person_text_data
        .filter(({ source }) => source !== "CALCULATED")
        .reduce((o, e) => Object.assign(o, { [e.key]: e.value }), {})
    : {};

const eventData = (person: PersonWithManager): { [key: string]: ISO8601_DATE } =>
  person.event !== null
    ? person.event
        .filter(({ source }) => source !== "CALCULATED")
        .reduce((o, e) => Object.assign(o, { [`event_${e.key}`]: e.date }), {})
    : {};

const connectionData = (
  person: PersonWithManager,
  employees: Record<string, PersonWithManager>,
): { [key: string]: UUID } =>
  person.person_connection !== null
    ? person.person_connection
        .filter(({ source }) => source !== "CALCULATED")
        .reduce(
          (o, e) =>
            Object.assign(o, {
              [`connection_${e.key}`]: employees[e.connection_id]?.email,
            }),
          {},
        )
    : {};
