export const getOrganization = (searchparams: URLSearchParams): string | undefined =>
  (location.hostname.endsWith(".preview.changeengine.com")
    ? location.hostname.split("-")[0] === "app"
      ? searchparams.get("organization")
      : location.hostname.split("-")[0]
    : location.hostname.endsWith(".changeengine.com")
      ? location.hostname.split(".")[0] === "app"
        ? searchparams.get("organization")
        : location.hostname.split(".")[0]
      : searchparams.get("organization")) ?? undefined;
