import { isEmpty } from "lodash-es";
import validator from "validator";
import * as yup from "yup";
import { INTERVAL, ISO8601, ISO8601_DATE } from "./iso8601";
import { UUID } from "./uuid";

yup.addMethod(yup.string, "domain", function (errorMessage: string) {
  return this.test("test-is-domain", errorMessage, function (value?: string) {
    const { path, createError } = this;
    return (value && validator.isFQDN(value)) || createError({ path, message: errorMessage });
  });
});

yup.addMethod(yup.string, "url_or_merge_tag", function (errorMessage: string) {
  return this.test("test-is-url-or-merge-tag", errorMessage, function (value) {
    const { path, createError } = this;
    const error = createError({ path, message: errorMessage });

    if (!value) return error;

    const hasMergeTag = !!value?.match(/{{[^}}]+}}/g);

    if (hasMergeTag && value?.startsWith("{{")) return true;

    if (!hasMergeTag) return yup.string().url().isValidSync(value) || error;

    try {
      const url = new URL(value);
      return !isEmpty(url.protocol);
    } catch {
      // URL errors if no protocol is provided
      return error;
    }
  });
});

declare module "yup" {
  interface StringSchema<TType, TContext> {
    domain(errorMessage: string): StringSchema<TType, TContext>;
    url_or_merge_tag(errorMessage: string): StringSchema<TType, TContext>;
    validMergeTags(errorMessagePrefix: string): StringSchema<TType, TContext>;
  }
}

export interface TestOptions<T> extends yup.ValidateOptions<T> {
  from?: { value: { [key: string]: unknown } }[];
}

export declare type TestContext<T, TParent> = Omit<yup.TestContext<T>, "parent" | "options"> & {
  parent: TParent;
  options: TestOptions<T>;
};

export declare type TestFunction<
  T = unknown,
  TContext = { [key: string]: unknown },
  TParent = { [key: string]: unknown },
> = (
  this: TestContext<TContext, TParent>,
  value: T,
  context: TestContext<TContext, TParent>,
) => boolean | yup.ValidationError | Promise<boolean | yup.ValidationError>;

export default yup;

export const yupUUID = yup.string<UUID>();
export const yupISO8601Date = yup.string<ISO8601_DATE>();
export const yupISO8601 = yup.string<ISO8601>();
type OptionalInterval = INTERVAL | "never";
export const yupInterval = yup.string<OptionalInterval>();
