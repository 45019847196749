import { TabContext } from "@mui/lab";
import { Box, BoxProps, MenuItem, Popover, Skeleton, Typography, styled } from "@mui/material";
import { Stack } from "@mui/system";
import { Atom, useAtomValue } from "jotai";
import { Loadable } from "jotai/vanilla/utils/loadable";
import { FC, MouseEvent, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { UUID } from "../../utils/uuid";
import { useBrandTones } from "../brand-kit/hooks/use-brand-tones";
import { ContentTone } from "../brand-kit/server/brand-kit-service";
import { ChannelName } from "../channels/types";
import { Markdown } from "../discover/markdown";
import { LoadableTemplateContent, Template } from "../generic/atoms/types/template";
import { TemplateChannel } from "./template-channel";
import { AITemplateContentTabPanel } from "./template-content-tab-panel";
import { TemplateImage } from "./template-image";
import { TemplateSubject } from "./template-subject";
import { TemplateTones } from "./template-tones";

export const TemplateContent: FC<
  {
    loadableTemplateAtom: Atom<Loadable<Template | null>>;
    contentTone: string;
    designHuddle: boolean;
    brandTone?: UUID;
    onContentToneChange?: (value: ContentTone | "custom") => void;
    onBrandToneChange?: (value: UUID) => void;
    onChannelChange?: (channel: ChannelName) => void;
  } & BoxProps
> = ({
  loadableTemplateAtom,
  contentTone,
  brandTone,
  designHuddle,
  onContentToneChange,
  onBrandToneChange,
  onChannelChange,
  ...boxProps
}) => {
  const { t } = useTranslation();
 
  const template = useAtomValue(loadableTemplateAtom);

  const { data: customBrandTones } = useBrandTones();
  const selectedBrandTone = useMemo(
    () => (customBrandTones ? customBrandTones?.find((b) => b.id === brandTone) : null),
    [brandTone, customBrandTones],
  );

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const handleClick = (event: MouseEvent<Element>): void => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  if (!contentTone || !template) {
    return null;
  }

  if (template?.state === "hasError") {
    return <Typography color="error">Error loading template content</Typography>;
  }

  const ready = !(
    template?.state === "loading" ||
    (template?.state === "hasData" && !template.data)
  );

  return (
    <>
      <Box {...boxProps}>
        <TabContext value={contentTone}>
          <Stack gap={2} mt={2}>
            <TemplateSubject subject={ready ? template.data?.subject_line : undefined} />
            <TemplateImage
                img={ready ? template.data?.images[template.data.selectedImage] : undefined}
                designHuddle={designHuddle}
              />

            {onChannelChange && (
              <TemplateChannel
                loadableTemplateAtom={loadableTemplateAtom}
                onChannelChange={onChannelChange}
              />
            )}

            <TemplateTones
              loadableTemplateAtom={loadableTemplateAtom}
              contentTone={contentTone as ContentTone}
              onContentToneChange={onContentToneChange}
              selectedBrandTone={selectedBrandTone}
              customBrandTones={customBrandTones}
              handleClick={handleClick}
            />
          </Stack>
          {selectedBrandTone && (
            <AITemplateContentTabPanel
              sx={{ p: 0 }}
              value={"custom"}
              contentTone="custom"
              title={selectedBrandTone.name}
            >
              {ready && template.data?.content.custom ? (
                <MarkdownContent content={getContent(template.data.content.custom)} p={0} m={0} />
              ) : (
                <SkeletonText />
              )}
            </AITemplateContentTabPanel>
          )}

          <AITemplateContentTabPanel
            sx={{ p: 0 }}
            value="professional"
            contentTone="professional"
            title={t("Professional")}
          >
            {ready && template.data?.content.professional ? (
              <MarkdownContent
                content={getContent(template.data.content.professional)}
                p={0}
                m={0}
              />
            ) : (
              <SkeletonText />
            )}
          </AITemplateContentTabPanel>
          <AITemplateContentTabPanel
            sx={{ p: 0 }}
            value="casual"
            contentTone="casual"
            title={t("Casual")}
          >
            {ready && template.data?.content.casual ? (
              <MarkdownContent content={getContent(template.data.content.casual)} p={0} m={0} />
            ) : (
              <SkeletonText />
            )}
          </AITemplateContentTabPanel>
          <AITemplateContentTabPanel
            sx={{ p: 0 }}
            value="direct"
            contentTone="direct"
            title={t("Direct")}
          >
            {ready && template.data?.content.direct ? (
              <MarkdownContent content={getContent(template.data.content.direct)} p={0} m={0} />
            ) : (
              <SkeletonText />
            )}
          </AITemplateContentTabPanel>
        </TabContext>
      </Box>
      <Popover
        open={anchorEl !== null}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {customBrandTones?.map((customBrandTone) => (
          <MenuItem
            key={customBrandTone.id}
            selected={customBrandTone.id === brandTone}
            onClick={() => {
              setAnchorEl(null);
              onBrandToneChange?.(customBrandTone.id);
            }}
          >
            {customBrandTone.name}
          </MenuItem>
        ))}
      </Popover>
    </>
  );
};

function getContent(template?: LoadableTemplateContent): string | null {
  if (template?.state !== "hasData") return null;

  return template.data?.data.response.content ?? null;
}

const Content = styled(Box)(({ theme }) => ({
  whiteSpace: "pre-line",
  maxWidth: theme.breakpoints.values.md,

  p: {
    margin: theme.spacing(0),
  },
  ul: {
    margin: theme.spacing(0),
  },
  li: {
    margin: theme.spacing(0),
  },
}));

export const MarkdownContent: FC<{ content?: string | null } & Omit<BoxProps, "content">> = ({
  content = null,
  ...boxProps
}) => {
  return (
    <Content p={4} m="0 auto" {...boxProps}>
      {content !== null ? (
        <Markdown content={content} />
      ) : (
        <>
          <Skeleton variant="text" width="50%" sx={{ mb: 2 }} />
          {new Array(6).fill(0).map((_, i) => (
            <Skeleton key={i} variant="text" />
          ))}
          <Skeleton variant="text" width="20%" sx={{ mt: 2 }} />
          <Skeleton variant="text" width="50%" />
        </>
      )}
    </Content>
  );
};

const SkeletonText: FC<{ lines?: number }> = ({ lines = 5 }) => (
  <>
    {new Array(lines).fill(0).map((_, i) => (
      <Skeleton key={i} variant="text" />
    ))}
  </>
);
