import { Card, CardContent, CardHeader } from "@mui/material";
import { FC, ReactElement, ReactNode } from "react";

type Props = {
  title: string;
  subTitle?: string | ReactElement;
  withMargin?: boolean;
  children: ReactNode;
};

export const PageCard: FC<Props> = ({ title, subTitle, withMargin, children }) => {
  const cardProps = withMargin ? { sx: { mt: 3 } } : {};
  return (
    <Card {...cardProps}>
      <CardHeader
        title={title}
        subheader={subTitle}
        sx={{ borderBottom: 2, borderColor: "background.default" }}
      />
      <CardContent>{children}</CardContent>
    </Card>
  );
};
