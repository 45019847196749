import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { CeTextField } from "../generic/components/form-fields/ce-text-field";

export const LongformDialog: FC<{
  open: boolean;
  contentType: string;
  prompt: string;
  onSkip: () => void;
  onContinue: (prompt: string) => void;
  onClose: () => void;
}> = ({ open, contentType, prompt, onSkip, onContinue, onClose }) => {
  const { t } = useTranslation();

  const [value, setValue] = useState(prompt);

  const handleContinue = useCallback(() => {
    onContinue(value);
  }, [onContinue, value]);

  return (
    <Dialog open={open}>
      <DialogTitle>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          {t(`Add more detail for a better ${contentType}`)}
          <IconButton
            onClick={onClose}
            data-analytics-id="longform-prompt-dialog-close"
            title={t("Cancel")}
          >
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Stack gap={3}>
          <Typography color="text.secondary">
            {t(
              `Enter as much context as you can to improve the quality of the ${contentType} we generate. You could even consider pasting in content from a policy document or intranet article.`,
            )}
          </Typography>
          <CeTextField
            label={t(`Describe what your ${contentType} is about`)}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            fullWidth
            multiline
            slotProps={{ htmlInput: { "data-testid": "longform-prompt-dialog-textfield" } }}
            minRows={20}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button data-analytics-id="create-content-longform-prompt-skip" onClick={onSkip}>
          {t("Skip")}
        </Button>
        <Button
          data-analytics-id="create-content-longform-prompt-continue"
          onClick={handleContinue}
        >
          {t("Continue")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
