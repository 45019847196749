import { Box, Card, CardActionArea, Typography } from "@mui/material";
import { FC } from "react";
import { Recipe } from "../self-driving/types";

export const RecipeListItem: FC<{ recipe: Recipe; onClick: (recipe: Recipe) => void }> = ({
  recipe,
  onClick,
}) => (
  <Box key={recipe.id} sx={{ py: 0.5 }} onClick={() => onClick(recipe)}>
    <Card raised>
      <CardActionArea sx={{ borderRadius: 0, py: 2, px: 2 }}>
        <Box m={1}>
          <Typography variant="h6">{recipe.name}</Typography>
          <Typography variant="body1">{recipe.description}</Typography>
        </Box>
      </CardActionArea>
    </Card>
  </Box>
);
