import { useCallback } from "react";
import { useToken } from "../../server/auth/use-token";
import { huddle } from "../huddle/huddle";

export function useGetDesignHuddleAccessToken(): (projects: string[]) => Promise<string> {
  const getToken = useToken();

  return useCallback(
    async (projects) => {
      const response = await huddle.POST("/", {
        body: { projects },
        headers: { Authorization: `Bearer ${await getToken()}` },
      });
      return response.data!.access_token;
    },
    [getToken],
  );
}
