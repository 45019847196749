import { Box } from "@mui/material";
import { FC, useMemo } from "react";
import { EllipsisText } from "../../generic/components/ellipsis-text";

interface MomentCardTitleProps {
  title: string | null;
  themeColour: string;
  programTitle?: string;
  showProgram?: boolean;
}
export const MomentCardTitle: FC<MomentCardTitleProps> = ({
  title,
  themeColour,
  programTitle,
  showProgram,
}) => {
  const accessibleColour = useMemo(() => {
    const red = parseInt(themeColour.substring(1, 3), 16);
    const green = parseInt(themeColour.substring(3, 5), 16);
    const blue = parseInt(themeColour.substring(5, 7), 16);

    return red * 0.299 + green * 0.587 + blue * 0.114 > 186 ? "#000000" : "#ffffff";
  }, [themeColour]);

  return (
    <Box position="absolute" bottom={0} width="100%">
      <Box
        py={showProgram ? 1 : 3}
        px={1}
        bgcolor="rgba(32, 30, 30, 0.7)"
        color="white"
        borderBottom={showProgram ? "none" : `thick solid ${themeColour}`}
      >
        <EllipsisText
          variant="subtitle2"
          fontWeight="bold"
          align="center"
          data-testid="title"
          text={title || ""}
          lines={showProgram ? 1 : 2}
        />
      </Box>
      {showProgram && (
        <Box py={1} px={1} bgcolor={themeColour}>
          <EllipsisText
            variant="subtitle2"
            fontWeight="bold"
            align="center"
            color={accessibleColour}
            text={programTitle || ""}
            data-testid="program.title"
          />
        </Box>
      )}
    </Box>
  );
};
