import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import { formatDate, fromISO8601_DATE } from "../../../utils/iso8601";
import { TemplateType } from "../../generic/atoms/types/template";
import { SequenceIcon } from "../../generic/components/sequence-icon";
import { LibraryTemplate } from "../library/types";
import { DiscoverTemplate } from "../types";

interface DiscoverCardContentProps {
  template: DiscoverTemplate;
  subheadingGetter?: (t: DiscoverTemplate) => string | null;
}

export const DiscoverCardContent: FC<DiscoverCardContentProps> = ({
  template,
  subheadingGetter,
}) => {
  switch (template.type) {
    case TemplateType.sequence:
      return <SequenceContent template={template} subheadingGetter={subheadingGetter} />;
    case TemplateType.moment:
      return <MomentContent template={template} subheadingGetter={subheadingGetter} />;
  }
};

const MomentContent: FC<DiscoverCardContentProps> = ({ template, subheadingGetter }) => {
  return (
    <Stack gap={1}>
      <Typography variant="body2" fontWeight={600}>
        {template.title}
      </Typography>
      {subheadingGetter && (
        <Typography variant="body2" color="neutral.500" fontWeight={500}>
          {subheadingGetter(template)}
        </Typography>
      )}
      {template.type === TemplateType.moment && (
        <Typography variant="body2" color="primary.light" fontWeight={500}>
          {getDateString(template)}
        </Typography>
      )}
    </Stack>
  );
};

const SequenceContent: FC<DiscoverCardContentProps> = ({
  template: sequence,
  subheadingGetter,
}) => {
  return (
    <Stack direction="row" gap={2}>
      <SequenceIcon />
      <Stack gap={1}>
        <Typography variant="body2" fontWeight={600}>
          {sequence.title}
        </Typography>
        {subheadingGetter && (
          <Typography variant="body2" color="neutral.500" fontWeight={500}>
            {subheadingGetter(sequence)}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

export function getDateString(template: LibraryTemplate): string {
  if (template.date === null) return template.recommendations.send_time;

  const startDate = fromISO8601_DATE(template.date);

  const formatted = formatDate(startDate, undefined, { month: "short" });

  if (template.end_date !== null) {
    const endDate = fromISO8601_DATE(template.end_date);
    return startDate.getTime() === endDate.getTime()
      ? formatted
      : `${formatted} - ${formatDate(endDate, undefined, { month: "short" })}`;
  }

  return formatted;
}
