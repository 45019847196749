import { LoadingButton } from "@mui/lab";
import { FC, useCallback } from "react";
import toast from "react-hot-toast";
import { Page } from "../../modules/layout/components/page";
import { PageContent } from "../../modules/layout/components/page-content";
import { PageHeader } from "../../modules/layout/components/page-header";
import { PageHeading } from "../../modules/layout/components/page-heading";
import { useSupabaseCallback } from "../../server/supabase/hooks";
import { useAsyncStateCallback } from "../../utils/hooks/use-async-state-callback";
import CeAdminPageHeader from "./ce-admin-page-header";

const ReindexMomentsPage: FC = () => {
  const { loading, load: reindex_moments } = useAsyncStateCallback(
    useSupabaseCallback<[], boolean, "ce_admin">(
      async ({ supabase }) => {
        const { error, data } = await supabase.rpc("reindex_moments");
        if (error) throw error;

        return !!data;
      },
      [],
      "ce_admin",
    ),
    [],
  );

  const onClick = useCallback(() => {
    void toast.promise(reindex_moments(), {
      loading: "Reindexing moments",
      success: "Moments reindexed",
      error: "Failed to reindex moments",
    });
  }, [reindex_moments]);

  return (
    <Page title={"Administration | ChangeEngine"}>
      <PageHeader>
        <PageHeading heading={<CeAdminPageHeader />} subheading="Reindex Moments" />
      </PageHeader>
      <PageContent>
        <LoadingButton
          data-analytics-id="ce-admin-reindex-moments"
          variant="contained"
          color="primary"
          onClick={onClick}
          loading={loading}
        >
          Reindex Moments
        </LoadingButton>
      </PageContent>
    </Page>
  );
};

export default ReindexMomentsPage;
