import {
  Box,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  BreadcrumbsProps as MuiBreadcrumbsProps,
  Typography,
} from "@mui/material";
import { FC, ReactElement } from "react";

export interface BreadcrumbsProps extends Omit<MuiBreadcrumbsProps, "aria-label"> {
  crumbs: { title: string; to?: string }[];
}

export const Breadcrumbs: FC<BreadcrumbsProps> = ({ crumbs, ...props }) => (
  <MuiBreadcrumbs aria-label="breadcrumb" {...props}>
    {crumbs.map(
      ({ title, to }): ReactElement => (
        <Box key={`crumb-${title}`} lineHeight="1em">
          {to ? (
            <Link variant="body2" href={to}>
              {title}
            </Link>
          ) : (
            <Typography variant="body2" color="text.primary" m={0} p={0}>
              {title}
            </Typography>
          )}
        </Box>
      ),
    )}
  </MuiBreadcrumbs>
);
