import { useEffect, useState } from "react";
import { ImagePreview } from "../../generate/store";

export const usePreviewImageUrl = (
  imagePreview: ImagePreview | null,
): { loading: boolean; url: string | null } => {
  const [loading, setLoading] = useState(true);
  const url = imagePreview?.url.state === "hasData" ? imagePreview.url.data : null;
  useEffect(() => {
    if (url) {
      const img = new Image();
      img.src = url;
      img.onload = () => setLoading(false);
    } else {
      setLoading(true);
    }
  }, [url]);

  return {
    url,
    loading,
  };
};
