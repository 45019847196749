import { useSupabaseLoader } from "../../../server/supabase/hooks";
import { UseSupabaseLoaderState } from "../../../server/supabase/hooks/types";
import { UUID } from "../../../utils/uuid";
import { Asset, SupabaseDigitalAssetService } from "../../assets/server/digital-asset-service";

export const useAsset = (id: UUID | null): UseSupabaseLoaderState<Asset | null> => {
  return useSupabaseLoader(
    async ({ supabase }) => {
      if (!id) return null;
      const { data, error } = await new SupabaseDigitalAssetService(supabase).get(id);
      if (error) throw new Error(error.message);
      return data;
    },
    [id],
  );
};
