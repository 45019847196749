import {
  Box,
  Button,
  Checkbox,
  Chip,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { config } from "../../../../../config";
import { useSupabase, useSupabaseCallback } from "../../../../../server/supabase/hooks";
import { UUID } from "../../../../../utils/uuid";
import {
  SlackAlias,
  SlackAliasWithDefault,
  SupabaseSlackAliasWithDefaultService,
} from "../../../../slack/server/slack-supabase-service";
import { DeleteAction } from "./alias-delete-action";
import { EditAction } from "./alias-edit-action";
import { SlackAliasForm } from "./alias-form";
import { ViewMomentsAction } from "./alias-view-moments-action";

export const SlackAliasTable: FC<{ aliasLimit: number }> = ({ aliasLimit }) => {
  const { t } = useTranslation();

  const [aliases, setAliases] = useState<SlackAliasWithDefault[] | "loading">("loading");
  const [refresh, setRefresh] = useState<boolean>();
  const [openCreate, setOpenCreate] = useState(false);

  useEffect(() => {
    refresh && setAliases("loading");
  }, [refresh]);

  useSupabase(
    async ({ supabase, account_id }) => {
      if (aliases !== "loading") return;

      const { data, error } = await new SupabaseSlackAliasWithDefaultService(supabase).getAll(
        account_id,
        { is: [{ key: "deleted_at", value: null }], order: [{ column: "id" }] },
      );

      if (error || !data) return setAliases([]);

      setAliases(data);
    },
    [aliases],
  );

  const handleDelete = (alias: SlackAliasWithDefault): void => {
    setAliases((existing) => {
      if (existing === "loading") return existing;

      return existing.filter((a) => a.id !== alias.id);
    });
  };

  const handleEdit = (alias: SlackAlias): void => {
    setAliases((existing) => {
      if (existing === "loading") return existing;

      return existing.map((a) => (a.id === alias.id ? { ...a, ...alias } : a));
    });
  };

  const setDefault = useSupabaseCallback(async ({ supabase }, alias_id: UUID | null) => {
    const { data, error } = await new SupabaseSlackAliasWithDefaultService(supabase).setDefault(
      alias_id,
    );

    if (error || !data) return;

    setAliases(data.filter((a) => !a.deleted_at));
  }, []);

  const defaultSelected = aliases === "loading" ? false : !aliases.find((a) => a.default);

  return (
    <>
      {aliases !== "loading" && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            my: 2,
          }}
        >
          <Button
            data-analytics-id="slack-alias-create-new"
            color="primary"
            size="large"
            variant="contained"
            onClick={() => setOpenCreate(true)}
            disabled={aliasLimit === 0 || aliases.length >= aliasLimit}
          >
            {t("Create New Alias")}
          </Button>
          <SlackAliasForm
            onComplete={() => setRefresh(true)}
            open={openCreate}
            close={() => setOpenCreate(false)}
          />

          {aliasLimit > 0 && <Chip label={`${aliases.length}/${aliasLimit}`} />}
        </Box>
      )}

      <TableContainer component={Paper}>
        {aliases === "loading" && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width="55%">{t("Name")}</TableCell>
              <TableCell width="20%">{t("Image")}</TableCell>
              <TableCell width="5%">{t("Default")}</TableCell>
              <TableCell align="right">{t("Actions")}</TableCell>
            </TableRow>
          </TableHead>
          {aliases !== "loading" && (
            <TableBody>
              <TableRow>
                <TableCell>{t(config.slack.defaultBotTitle)}</TableCell>
                <TableCell>{t("See your Slack Org Settings")}</TableCell>
                <TableCell>
                  <Checkbox
                    checked={defaultSelected}
                    disabled={defaultSelected}
                    onChange={() => void setDefault(null).catch(console.error)}
                  />
                </TableCell>
                <TableCell />
              </TableRow>
              {aliases.map((alias) => (
                <TableRow key={alias.id}>
                  <TableCell>{alias.name}</TableCell>
                  <TableCell align="center">
                    {alias.image && <img src={alias.image} alt="preview" height={75} />}
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      checked={!!alias.default}
                      onChange={() =>
                        void setDefault(alias.default ? null : alias.id).catch(console.error)
                      }
                    />
                  </TableCell>
                  <TableCell align="right">
                    <Actions
                      alias={alias}
                      onDelete={() => handleDelete(alias)}
                      onEdit={handleEdit}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  );
};

const Actions: FC<{
  alias: SlackAliasWithDefault;
  onDelete: () => void;
  onEdit: (alias: SlackAlias) => void;
}> = ({ alias, onDelete, onEdit }) => {
  return (
    <>
      <EditAction alias={alias} onComplete={onEdit} />
      <DeleteAction alias={alias} onDelete={onDelete} />
      <ViewMomentsAction alias={alias} />
    </>
  );
};
