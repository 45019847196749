import { useSupabaseCallback } from "../../../server/supabase/hooks";
import { toISO8601 } from "../../../utils/iso8601";
import { UUID } from "../../../utils/uuid";
import { SupabaseDigitalAssetService } from "../server/digital-asset-service";

export const useDeleteAssetCallback = (): ReturnType<typeof useSupabaseCallback> => {
  return useSupabaseCallback(async ({ supabase }, assetId: UUID) => {
    return new SupabaseDigitalAssetService(supabase).update(assetId, {
      deleted_at: toISO8601(new Date()),
    });
  }, []);
};
