import { Add, Cancel, Delete } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
} from "@mui/material";
import {
  DEFAULT_GRID_COL_TYPE_KEY,
  DataGridPro,
  DataGridProProps,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridPreProcessEditCellProps,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridToolbarContainer,
  GridValidRowModel,
  MuiEvent,
  getGridDefaultColumnTypes,
} from "@mui/x-data-grid-pro";
import { findIndex, values } from "lodash-es";
import { ReactNode, SyntheticEvent, useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { removeItemFromArray } from "../../../../utils/immutable-arrays";
import { UUID, uuidv4 } from "../../../../utils/uuid";
import { LeavePageConfirm } from "../leave-page-confirm";
import { EditAction, SaveAction } from "./action-buttons";
import { Column, Columns, Row } from "./types";

interface EditToolbarProps<T extends Row<{ id: UUID }>> {
  setRows: <U extends T[]>(newRows: (oldRows: U) => U) => void;
  setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
  initialData?: Omit<T, "id,isNew">;
  fieldToFocus?: string;
}

function AddRowToolbar<T extends Row<{ id: UUID }>>(props: EditToolbarProps<T>): React.JSX.Element {
  const { setRows, setRowModesModel, initialData, fieldToFocus } = props;

  const handleClick = (): void => {
    const id = uuidv4();
    setRows(<U extends T>(oldRows: U[]): U[] => [
      { ...initialData, id, isNew: true } as U,
      ...oldRows,
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button
        data-analytics-id="crud-data-grid-add-record"
        color="primary"
        startIcon={<Add />}
        onClick={handleClick}
      >
        <Trans>Add record</Trans>
      </Button>
    </GridToolbarContainer>
  );
}

interface Props<T extends GridValidRowModel> {
  initialRows: Omit<T, "isNew">[];
  columns: Columns<T>;
  hideActions?: boolean;
  initialData?: Partial<T>;
  onUpdate?: (
    row: Omit<T, "isNew" | "options">,
  ) => Promise<Omit<T, "isNew" | "options"> | undefined>;
  onAdd?: (row: Omit<T, "isNew" | "options">) => Promise<Omit<T, "isNew" | "options"> | undefined>;
  onDelete?: (row: Row<T>) => Promise<boolean>;
  gridOverrides?: Partial<DataGridProProps<T>>;
  modifyActions?: (actions: React.JSX.Element[], row?: Row<T>) => React.JSX.Element[];
}

export function CrudDataGrid<T extends Row<{ id: UUID }>>(props: Props<T>): React.JSX.Element {
  const {
    initialRows,
    columns,
    initialData,
    onUpdate,
    onAdd,
    onDelete,
    gridOverrides,
    modifyActions,
    hideActions,
  } = props;
  const { t } = useTranslation();
  const [rows, setRows] = useState(initialRows.map((r) => ({ ...r, isNew: false })) as T[]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [saving, setSaving] = useState<UUID[]>([]);

  useEffect(() => {
    setRows(initialRows.map((r) => ({ ...r, isNew: false })) as T[]);
  }, [initialRows]);

  const beforeLeave = useCallback(
    () => values(rowModesModel).some((model) => model.mode === GridRowModes.Edit),
    [rowModesModel],
  );

  const handleRowEditStart = (params: GridRowParams, event: MuiEvent<SyntheticEvent>): void => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: UUID) => () => {
    void (async () => {
      setSaving((prev) => [...prev, id]);

      if (await onDelete?.(rows.find((row) => row.id === id)!)) {
        setRows(rows.filter((row) => row.id !== id));
      }
      setSaving((prev) =>
        removeItemFromArray(
          prev,
          findIndex(prev, (x) => x === id),
        ),
      );
    })().catch(console.error);
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow?.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = useCallback(
    async (newRow: T): Promise<T> => {
      setSaving((prev) => [...prev, newRow.id]);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { isNew, options, ...rowInfo } = newRow;
      const data = isNew ? await onAdd?.(rowInfo) : await onUpdate?.(rowInfo);

      setSaving((prev) =>
        removeItemFromArray(
          prev,
          findIndex(prev, (x) => x === newRow.id),
        ),
      );
      if (data) {
        const updatedRow = { ...data, isNew: false } as T;
        if (rows.find((row) => row.id === updatedRow.id)) {
          setRows(rows.map((row) => (row.id === updatedRow.id ? updatedRow : row)));
        } else {
          setRows([updatedRow, ...rows.filter((row) => row.id !== newRow.id)]);
        }
        return newRow;
      } else {
        setRowModesModel((oldModel) => ({
          ...oldModel,
          [newRow.id]: {
            mode: GridRowModes.Edit,
          },
        }));
      }

      // this prevents the row from being added to the grid in a "view" state
      // and leaves it editable so errors (such as duplicate key) can be corrected by the user.
      throw new Error("Failed to save row");
    },
    [rows, onAdd, onUpdate],
  );

  const columnsWithActions = [
    ...Object.entries(columns).map(([k, v]: [string, Omit<GridColDef, "field">]) =>
      applyValidationCell({ field: k, ...v }),
    ),
    ...(hideActions
      ? []
      : [
          {
            field: "actions",
            type: "actions" as const,
            headerName: "Actions",
            width: 100,
            cellClassName: "actions",
            getActions: ({ id }: { id: UUID }): React.JSX.Element[] => {
              const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
              const currentRow = rows.find((row) => row.id === id);

              let actions: React.JSX.Element[];
              if (isInEditMode) {
                actions = [
                  <SaveAction
                    key={`save${id}`}
                    id={id}
                    onClick={handleSaveClick(id)}
                    isNew={currentRow?.isNew || false}
                    validators={Object.fromEntries(
                      Object.entries(columns).map(
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        ([k, v]: [string, Column<any, T>]) => [k, v.options?.validator],
                      ),
                    )}
                  />,
                  <GridActionsCellItem
                    key={`cancel${id}`}
                    icon={
                      <Tooltip title={t("Cancel")}>
                        <Cancel />
                      </Tooltip>
                    }
                    label="Cancel"
                    className="textPrimary"
                    onClick={handleCancelClick(id)}
                    color="inherit"
                  />,
                ];
              } else {
                actions = saving.includes(id)
                  ? [
                      <GridActionsCellItem
                        key={`saving${id}`}
                        icon={<CircularProgress size={24} />}
                        label="Saving"
                        className="textPrimary"
                      />,
                    ]
                  : [
                      ...(onUpdate
                        ? [
                            <EditAction
                              key={`edit${id}`}
                              onClick={handleEditClick(id)}
                              preventEditing={currentRow?.options?.preventEditing}
                              preventEditingReason={currentRow?.options?.preventEditingReason}
                            />,
                          ]
                        : []),
                      ...(onDelete
                        ? [
                            <GridActionsCellItem
                              key={`delete${id}`}
                              icon={
                                <Tooltip title={t("Archive")}>
                                  <Delete />
                                </Tooltip>
                              }
                              label="Delete"
                              onClick={handleDeleteClick(id)}
                              color="inherit"
                              disabled={currentRow?.options?.preventDeletion}
                            />,
                          ]
                        : []),
                    ];
              }

              return modifyActions ? modifyActions(actions, currentRow) : actions;
            },
          },
        ]),
  ];

  return (
    <StyledBox
      sx={{
        height: "100%",
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
        "& .MuiDataGrid-row--editing > .MuiDataGrid-cell:not(.MuiDataGrid-cell--editable)": {
          color: "text.disabled",
        },
      }}
    >
      <DataGridPro<T>
        rows={rows}
        columns={columnsWithActions}
        autoHeight
        editMode="row"
        rowModesModel={rowModesModel}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={() => null}
        slots={{
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-expect-error
          toolbar: onAdd ? AddRowToolbar : null,
        }}
        slotProps={{
          toolbar: onAdd
            ? {
                setRows,
                setRowModesModel,
                initialData,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                fieldToFocus: Object.values(columns).find((x) => x.editable)?.field,
              }
            : undefined,
        }}
        {...(gridOverrides ?? {})}
      />
      <LeavePageConfirm enabled={beforeLeave}>
        {t("You have unsaved changes, are you sure you wish to leave the page?")}
      </LeavePageConfirm>
    </StyledBox>
  );
}

const applyValidationCell = <T extends GridValidRowModel>(
  column: Column<unknown, T>,
): Column<unknown, T> => {
  const defaults = getGridDefaultColumnTypes();

  const { options, renderEditCell, ...rest } = column;

  const validate = options?.validator
    ? {
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          const error = await options.validator(params.props.value);
          return { ...params.props, error };
        },
      }
    : {};

  return {
    ...rest,
    ...validate,
    renderEditCell: (params) => {
      const cell: ReactNode = renderEditCell
        ? renderEditCell(params)
        : defaults[column.type ?? DEFAULT_GRID_COL_TYPE_KEY].renderEditCell?.(params);

      return cell && params.error ? (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        <StyledTooltip open={!!params.error && params.hasFocus} title={params.error}>
          <Box sx={{ height: "100%", width: "100%" }}>{cell}</Box>
        </StyledTooltip>
      ) : (
        cell
      );
    },
  };
};

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,

    color: theme.palette.error.contrastText,
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  height: 400,
  width: "100%",
  "& .Mui-error": {
    height: "100%",
    width: "100%",
    backgroundColor: `rgb(126,10,15, ${theme.palette.mode === "dark" ? 0 : 0.1})`,
    color: theme.palette.mode === "dark" ? "#ff4343" : "#750f0f",
  },
}));
