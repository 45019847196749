import { useAuth0 } from "@auth0/auth0-react";
import { DependencyList, useCallback } from "react";
import { useToken } from "../../auth/use-token";
import { Database } from "../types/database-definitions";
import { OrgUser, SupabaseHandlerArg, SupabaseSchemaName } from "./types";
import { withSupabaseToken } from "./utils";

/**
 * Create Event handler callback to make best-effort Supabase save if still authenticated.
 * n.b. dose not work well for loading data. Initial renders will skip callback as Auth0 is still initializing.
 */
export function useSupabaseCallback<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends any[],
  U,
  SchemaName extends SupabaseSchemaName<Database> = "public",
>(
  callback: (arg0: SupabaseHandlerArg<SchemaName>, ...args: T) => Promise<U>,
  deps: DependencyList,
  schema: SchemaName = "public" as SchemaName,
): (...args: T) => Promise<U | undefined> {
  const { user, isLoading, loginWithRedirect } = useAuth0<OrgUser>();
  const getToken = useToken();
  return useCallback(
    async (...args) => {
      return withSupabaseToken<SchemaName>(
        user,
        isLoading,
        getToken,
        loginWithRedirect,
        schema,
      ).then((ctx) => ctx && callback(ctx, ...args));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, isLoading, loginWithRedirect, getToken, schema, ...deps],
  );
}
