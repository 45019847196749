import { CircularProgress } from "@mui/material";
import { FC, useMemo } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { uuidv4 } from "../../../utils/uuid";
import { MomentFormMoment } from "../../moment/types/moment-form-moment";
import { UsableAccountPersonField } from "../server/supabase-segment-field-service";
import { useSegmentInvalidRecipientTypeKeys } from "../server/use-segment-invalid-recipient-keys";
import { AudiencesSelect } from "./audience-select";

export const BuildSegmentAudience: FC<{
  disabled?: boolean;
  segmentFields: UsableAccountPersonField[];
  disableAudienceClearable: boolean;
}> = ({ disabled: rootDisabled, segmentFields, disableAudienceClearable }) => {
  const methods = useFormContext<MomentFormMoment>();
  const {
    control,
    getValues,
    trigger,
    formState: { errors },
  } = methods;

  const { append, remove } = useFieldArray({
    control,
    name: "segment.conditions",
  });
  useWatch({
    control,
    name: ["segment.conditions", "segment.operation", "segment.recipient_type_key"],
  });
  const [conditions, recipientTypeKey] = getValues([
    "segment.conditions",
    "segment.recipient_type_key",
  ]);

  const { data: invalidSegmentFields, loading } = useSegmentInvalidRecipientTypeKeys({
    recipient_type_key: recipientTypeKey,
  });

  const disabled = rootDisabled || loading;

  const useableAudiences = useMemo(
    () =>
      segmentFields
        .filter((item) => !invalidSegmentFields?.includes(item.key!))
        .filter((item) => item.key?.startsWith("recipient_type")),
    [segmentFields, invalidSegmentFields],
  );

  const selectedAudience = useMemo(() => {
    if (!conditions) return;
    return conditions.find((condition) => condition.field.startsWith("recipient_type"));
  }, [conditions]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <AudiencesSelect
      options={useableAudiences}
      audience={selectedAudience}
      handleAudienceChange={(value) => {
        if (selectedAudience) {
          const index = conditions.findIndex((c) => c.field === selectedAudience.field);
          if (index >= 0) remove(index);
        }
        if (value) {
          const f = useableAudiences.find((o) => o.display_name === value);
          append({
            id: uuidv4(),
            field: `${f?.key}`,
            operation: "IS",
            values: ["true"],
            invert: false,
          });
        }
        void trigger("segment");
      }}
      disabled={disabled}
      errorMessage={(errors?.segment as { audience?: { message: string } })?.audience?.message}
      disableAudienceClearable={disableAudienceClearable}
    />
  );
};
