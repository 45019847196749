import { CircularProgress } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useToken } from "../../../server/auth/use-token";
import { serviceURL } from "../../../utils/service-url";
import { IconCard } from "../../generic/components/icon-card";
import { CsvUploadState, ProcessingState, RemoteInfo } from "../types";
import { Finished } from "./states/finished";
import { NotStarted } from "./states/not-started";

export const CsvUploadCard: FC<CsvUploadState> = (initialState) => {
  const { t } = useTranslation();

  const [state, setState] = useState<CsvUploadState>(initialState);
  const [lastFile, setLastFile] = useState<File>();
  const setProcessingState = (processingState: ProcessingState): void =>
    setState((prev) => ({ ...prev, processingState }));

  const getToken = useToken();

  const onDrop = useCallback(
    async (file: File): Promise<void> => {
      if (!file || file.type !== "text/csv") {
        return setProcessingState("invalid-file");
      }
      const ack = state.processingState === "warning-acked" ? "?warning=ack" : "";
      setLastFile(file);
      setProcessingState("uploading");
      await state.onUpload();
      try {
        const request = await fetch(`${serviceURL("employee-engine")}direct-upload${ack}`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${await getToken()}`,
          },
          body: file,
        });
        const remoteInfo = (await request.json()) as RemoteInfo;
        setState((prev) => ({ ...prev, remoteInfo }));
        console.log(request.status);
        if (request.status === 200) {
          setProcessingState("complete");
          state.onComplete();
        } else {
          if (remoteInfo.errors?.length > 0 || remoteInfo.exception) {
            setProcessingState("error");
          } else {
            setProcessingState("warning");
          }
        }
      } catch {
        setProcessingState("error");
      }
    },
    [state, getToken],
  );

  useEffect(() => {
    if (state.processingState !== "warning-acked") return;
    if (!lastFile) return;
    void onDrop(lastFile).catch(console.error);
  }, [lastFile, onDrop, state]);

  switch (state.processingState) {
    case "not started":
      return onDrop ? <NotStarted onDrop={(v) => void onDrop(v).catch(console.error)} /> : null;
    case "uploading":
    case "warning-acked":
      return (
        <IconCard
          title={t("Uploading...")}
          icon={{ icon: <CircularProgress />, sx: { bgcolor: "#edf6ee" } }}
        />
      );
    case "complete":
    case "error":
    case "warning":
    case "invalid-file":
      return (
        <Finished
          state={state.processingState}
          remoteInfo={state.remoteInfo}
          setProcessingState={(processingState: ProcessingState) =>
            setState((prev) => ({ ...prev, processingState }))
          }
        />
      );
  }
};
