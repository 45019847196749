import { PostgrestClient } from "@supabase/postgrest-js";
import { EditorState } from "draft-js";
import { config } from "../../../config";
import { Database } from "../../../server/supabase/types/database-definitions";
import { UUID, uuidv4 } from "../../../utils/uuid";
import { ChatMoment } from "../types";
import { SupabaseChatMomentService } from "./supabase-chat-moment-service";

export type ChatMomentWithEditor = ChatMoment & {
  editorState: EditorState;
};

export class ChatMomentLoader {
  private readonly supabase: PostgrestClient<Database>;
  constructor(supabase: PostgrestClient<Database>) {
    this.supabase = supabase;
  }

  async load(id: UUID): Promise<
    | {
        data: ChatMoment;
        error?: never;
      }
    | {
        data?: never;
        error: string;
      }
  > {
    const chatMomentService = new SupabaseChatMomentService(this.supabase);
    const { data, error, status } = await chatMomentService.get(id);
    if (status === 406) {
      // New
      return {
        data: {
          id,
          title: "",
          buttons: [],
          image_url: null,
          slack_alias_id: config.slack.defaultSlackAliasId,
        },
      };
    } else if (error || !data) {
      return { error: error?.message ?? null };
    }
    return {
      data: {
        ...data,
        title: data.title || "",
        design: data.design ?? undefined,
        buttons: data.buttons as ChatMomentWithEditor["buttons"],
        slack_alias_id: data.slack_alias_id ?? config.slack.defaultSlackAliasId,
      },
    };
  }

  loadNew(): Promise<ChatMoment> {
    return Promise.resolve({
      id: uuidv4(),
      title: "",
      buttons: [],
      image_url: null,
      slack_alias_id: config.slack.defaultSlackAliasId,
    });
  }
}
