import { Stack, Switch, Typography } from "@mui/material";
import { ReactElement } from "react";
import { useSettings } from "../../../../utils/hooks/use-settings";

export const ThemeToggle = (): ReactElement => {
  const { settings, saveSettings } = useSettings();
  const { theme: appTheme } = settings;
  const handleToggleTheme = (): void => {
    const newTheme = settings.theme === "dark" ? "light" : "dark";
    saveSettings({ ...settings, theme: newTheme });
  };

  return (
    <Stack direction="row" spacing={1} alignItems="center" onClick={handleToggleTheme}>
      <Typography
        sx={{
          fontSize: "small",
          color: settings.theme === "light" ? "text.primary" : "text.discreet",
          ":hover": {
            color: "text.primary",
          },
          cursor: settings.theme === "light" ? "default" : "pointer",
        }}
      >
        Light
      </Typography>
      <Switch
        size="small"
        checked={appTheme === "dark"}
        inputProps={{ "aria-label": "ant design" }}
      />
      <Typography
        sx={{
          fontSize: "small",
          color: settings.theme === "dark" ? "text.primary" : "text.discreet",
          ":hover": {
            color: "text.primary",
          },
          cursor: settings.theme === "dark" ? "default" : "pointer",
        }}
      >
        Dark
      </Typography>
    </Stack>
  );
};
