import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { Box } from "@mui/material";
import { isNil } from "lodash-es";
import { FC, ReactElement } from "react";
import { sortByISO8601 } from "../../../utils/iso8601";
import { MomentCard } from "../../moment-card/components/moment-card";
import { momentWhen } from "../../moment-card/components/moment-card-when";
import { MomentCardMoment } from "../../moment-card/types";
import { ProgramIcon } from "../../programs/components/program-icon";

export const TimelineEngine: FC<{ moments: MomentCardMoment[] }> = ({ moments }) => {
  const renderTimelineItem = (moment: MomentCardMoment, i: number): ReactElement => (
    <TimelineItem key={i}>
      <TimelineOppositeContent sx={{ m: "auto 0" }}>{momentWhen(moment)}</TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineConnector />
        <TimelineDot sx={{ bgcolor: moment.program?.theme_colour }}>
          <ProgramIcon data={moment.program?.icon.value} title={moment.program?.title} />
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>

      <TimelineContent>
        <Box sx={{ pt: 4 }}>
          <MomentCard draggable={false} moment={moment} />
        </Box>
      </TimelineContent>
    </TimelineItem>
  );
  return (
    <Timeline position="alternate">
      {moments
        .filter((moment) => !isNil(moment.schedule?.dynamic_when))
        .sort((a, b) => sortByISO8601(a.schedule?.dynamic_when, b.schedule?.dynamic_when))
        .map(renderTimelineItem)}
    </Timeline>
  );
};
