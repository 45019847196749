import { Box, Button, Chip, Collapse, TableCell, TableRow, Typography } from "@mui/material";
import { FC, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { propertyNotEmpty } from "../../../../utils/not-empty";
import { SupabaseCustomEmail } from "../../../channels/email/server/supabase-custom-email-service";
import { Program } from "../../../programs/server/supabase-program-service";
import { SupabaseSegment } from "../../../segment/server/supabase-segment-service";
import { Permissions, PersonTextDataJson } from "../../server/supabase-person-service";
import { PermissionsLimits } from "../../types";
import { PermissionsTableActions } from "./permissions-table-actions";

type PermType =
  | "read_only_program"
  | "editable_program"
  | "segment_deny_list"
  | "email_from_deny_list";

export const PermissionsTableRow: FC<{
  permission: Permissions;
  rawPrograms: Program[];
  employees: PersonTextDataJson[];
  capacity: PermissionsLimits;
  programs: { [k: string]: Program };
  segments: SupabaseSegment[];
  custom_emails: SupabaseCustomEmail[];
  refresh: () => void;
}> = ({
  permission,
  rawPrograms,
  employees,
  capacity,
  programs,
  segments,
  custom_emails,
  refresh,
}) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState<PermType>();

  const onClick = useCallback(
    (type: PermType) => () => setOpen((prev) => (prev === type ? undefined : type)),
    [],
  );

  const openData = useMemo(() => {
    if (!open) return [];

    if (open === "segment_deny_list") {
      return permission.segment_deny_list?.map((id) => ({
        id,
        title: segments.find((s) => s.id === id)?.name,
      }));
    }

    if (open === "email_from_deny_list") {
      return permission.email_from_deny_list?.map((id) => ({
        id,
        title: custom_emails.find((s) => s.id === id)?.address,
      }));
    }

    return permission[open]
      ?.map((id) => ({ id, title: programs[id]?.title }))
      .filter(propertyNotEmpty("title"));
  }, [open, permission, programs, segments, custom_emails]);

  return (
    <>
      <TableRow key={permission.id}>
        <TableCell>{permission.email}</TableCell>
        <TableCell>{permission.super_admin ? t("Super Admin") : t("Contributor")}</TableCell>
        <TableCell>
          <Button
            data-analytics-id="permissions-table-row-read-only-programs"
            onClick={onClick("read_only_program")}
            disabled={permission.super_admin ?? false}
          >
            {permission.super_admin ? t("All") : permission.read_only_program?.length || 0}
          </Button>
        </TableCell>
        <TableCell>
          <Button
            data-analytics-id="permissions-table-row-editable-programs"
            onClick={onClick("editable_program")}
            disabled={permission.super_admin ?? false}
          >
            {permission.super_admin ? t("All") : permission.editable_program?.length || 0}
          </Button>
        </TableCell>
        <TableCell>
          <Button
            data-analytics-id="permissions-table-row-segment-deny-list"
            onClick={onClick("segment_deny_list")}
            disabled={permission.super_admin ?? false}
          >
            {permission.super_admin ? t("None") : permission.segment_deny_list?.length || 0}
          </Button>
        </TableCell>
        <TableCell>
          <Button
            data-analytics-id="permissions-table-row-email-from-deny-list"
            onClick={onClick("email_from_deny_list")}
            disabled={permission.super_admin ?? false}
          >
            {permission.super_admin ? t("None") : permission.email_from_deny_list?.length || 0}
          </Button>
        </TableCell>
        <TableCell align="right">
          <PermissionsTableActions
            permission={permission}
            employees={employees}
            capacity={capacity}
            programs={rawPrograms}
            segments={segments}
            custom_emails={custom_emails}
            refresh={refresh}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open !== undefined} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              {open && (
                <>
                  <Typography variant="h6" gutterBottom component="div">
                    {attributeMap[open]?.title}
                  </Typography>
                  {openData?.map(({ id, title }) => <Chip sx={{ m: 1 }} key={id} label={title} />)}
                </>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const attributeMap = {
  read_only_program: { title: "Read-only Programs" },
  editable_program: { title: "Editable Programs" },
  segment_deny_list: { title: "Restricted Segments" },
  email_from_deny_list: { title: "Restricted Senders" },
};
