import { UnlayerDesign, UnlayerDesignBlock } from "../unlayer-email-content/types/unlayer-design";

export function generateUnlayerDesign(
  header: UnlayerDesignBlock[],
  footer: UnlayerDesignBlock[],
  design: UnlayerDesignBlock[],
): UnlayerDesign {
  return {
    body: {
      id: "H1nsQM1miK",

      rows: [...header, ...design, ...footer],
      values: {},
    },
    schemaVersion: 8,
  };
}

export function makeUnlayerImageNode(
  src: string,
  { width, height }: { width: number; height: number },
): UnlayerDesignBlock {
  return {
    id: "57eZhty4TY",
    cells: [1],
    values: {
      _meta: {
        htmlID: "u_row_3",
        htmlClassNames: "u_row",
      },
      anchor: "",
      columns: false,
      padding: "0px",
      hideable: true,
      deletable: true,
      draggable: true,
      selectable: true,
      duplicatable: true,
    },
    columns: [
      {
        id: "vozMmH7drC",
        values: {
          _meta: {
            htmlID: "u_column_1",
            htmlClassNames: "u_column",
          },
          border: {},
          padding: "0px",
          borderRadius: "0px",
          backgroundColor: "#ffffff",
        },
        contents: [
          {
            id: "P0EkapcagM",
            type: "image",
            values: {
              src: {
                url: src,
                width,
                height,
              },
              _meta: {
                htmlID: "u_content_image_1",
                htmlClassNames: "u_content_image",
              },
              action: {
                name: "web",
                values: {
                  href: "",
                  target: "_blank",
                },
              },
              anchor: "",
              altText: "",
              hideable: true,
              deletable: true,
              draggable: true,
              textAlign: "center",
              selectable: true,
              duplicatable: true,
              containerPadding: "10px",
              displayCondition: null,
            },
          },
        ],
      },
    ],
  };
}

export function makeUnlayerTextBlock(text: string): UnlayerDesignBlock {
  return {
    id: "WMcNiBSEJD",
    cells: [1],
    values: {
      _meta: {
        htmlID: "u_row_11",
        htmlClassNames: "u_row",
      },
      anchor: "",
      columns: false,
      padding: "0px",
      hideable: true,
      deletable: true,
      draggable: true,
      selectable: true,
      hideDesktop: false,
      duplicatable: true,
      backgroundColor: "",
      backgroundImage: {
        url: "",
        size: "custom",
        repeat: "no-repeat",
        position: "top-center",
        fullWidth: true,
        customPosition: ["50%", "0%"],
      },
      displayCondition: null,
      columnsBackgroundColor: "",
    },
    columns: [
      {
        id: "efCR0nXLxA",
        values: {
          _meta: {
            htmlID: "u_column_12",
            htmlClassNames: "u_column",
          },
          backgroundColor: "#ffffff",
        },
        contents: [
          {
            id: "iQq3Ue2bj-",
            type: "text",
            values: {
              text,
              _meta: {
                htmlID: "u_content_text_5",
                htmlClassNames: "u_content_text",
              },
              color: "#333333",
              anchor: "",
              fontSize: "14px",
              hideable: true,
              deletable: true,
              draggable: true,
              linkStyle: {
                inherit: true,
                linkColor: "#0000ee",
                linkUnderline: true,
                linkHoverColor: "#0000ee",
                linkHoverUnderline: true,
              },
              textAlign: "left",
              lineHeight: "180%",
              selectable: true,
              hideDesktop: false,
              duplicatable: true,
              containerPadding: "35px 55px 10px",
              displayCondition: null,
            },
            hasDeprecatedFontControls: true,
          },
        ],
      },
    ],
  };
}
