import { Box, styled } from "@mui/material";

export const DiscoverCardActionArea = styled(Box)(({ theme }) => ({
  display: "flex",
  cursor: "pointer",
  flexDirection: "column",
  ":hover": {
    "& .MuiCardMedia-img": {
      transform: "scale(1.1)",
    },
    maxWidth: "auto",
    background: "none",

    "& .Card-content": {
      paddingTop: theme.spacing(4),
      paddingBottom: 0,
    },

    "& .sequence-bg-1": {
      transform: "translateX(4px) translateY(4px)",
    },
    "& .sequence-bg-2": {
      transform: "translateX(-4px) translateY(-4px)",
    },
    "& .sequence-image": {
      "& .MuiCardMedia-img": {
        transform: "translateX(-10px) translateY(-10px)",
      },
    },
  },

  "& .MuiCardMedia-img": {
    transition: "all .3s ease-in-out",
    objectFit: "contain",
  },
  position: "relative",
  zIndex: 2,

  ":before": {
    zIndex: -1,
    content: '""',
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    transition: "all .3s ease-in-out",
  },

  ":hover:before": {
    background: theme.palette.background.active,
    top: "10px",
    transform: "scale(1.1)",
  },

  "&.Card-Sequence:hover:before": {
    background: "none",
  },
}));
