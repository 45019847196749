import { ButtonProps } from "@mui/base";
import { FilterAlt } from "@mui/icons-material";
import { TabPanel as MuiTabPanel, TabPanelProps } from "@mui/lab";
import { Button } from "@mui/material";
import { useAtomValue } from "jotai";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { usePopover } from "../../utils/hooks/use-popover";
import { AiRefineContentModal } from "../ai-refine/ai-refine-content-modal";
import { contentRefineConfigFamily } from "../ai-refine/store";
import { ContentTone } from "../brand-kit/server/brand-kit-service";

export const AITemplateContentTabPanel: FC<
  {
    title: string;
    contentTone: ContentTone | "custom";
  } & TabPanelProps
> = ({ title, contentTone, children, sx = [], ...props }) => {
  const refineModal = usePopover<HTMLDivElement>();

  const contentRefineConfig = useAtomValue(contentRefineConfigFamily(contentTone));

  return (
    <>
      {contentRefineConfig !== null ? (
        <>
          <MuiTabPanel
            data-testid={`${contentTone}-content-tab`}
            sx={[
              (theme) => ({
                backgroundColor: theme.palette.background.paper,
                marginBottom: theme.spacing(6),
                border: "1px solid transparent",
                cursor: "pointer",
                ":hover": {
                  border: "1px dashed #00000066",
                  "& .MuiButton-root": {
                    display: "inline-flex",
                  },
                },
                ...(refineModal.open && { border: "1px dashed #00000066" }),
                position: "relative",
                maxWidth: "50vw",
              }),
              ...(sx instanceof Array ? sx : [sx]),
            ]}
            ref={refineModal.anchorRef}
            onClick={refineModal.handleOpen}
            {...props}
          >
            {children}
            {<RefineButton onClick={refineModal.handleOpen} />}
          </MuiTabPanel>
          <AiRefineContentModal
            title={`Refine Message: ${title}`}
            contentTone={contentTone}
            open={refineModal.open}
            onClose={refineModal.handleClose}
            config={contentRefineConfig}
          />
        </>
      ) : (
        <MuiTabPanel
          {...props}
          sx={(theme) => ({
            backgroundColor: theme.palette.background.paper,
            marginBottom: theme.spacing(6),
          })}
        >
          {children}
        </MuiTabPanel>
      )}
    </>
  );
};

const RefineButton: FC<ButtonProps> = (props) => {
  const { t } = useTranslation();
  return (
    <Button
      data-analytics-id="discover-refine-content"
      {...props}
      sx={{
        display: "none",
        position: "absolute",
        top: "8px",
        right: "8px",
        zIndex: 1,
      }}
      variant="contained"
      aria-label="Refine"
      color="primary"
      startIcon={<FilterAlt />}
    >
      {t("Refine")}
    </Button>
  );
};
