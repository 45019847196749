import { Close, MoreHoriz } from "@mui/icons-material";
import {
  Box,
  BoxProps,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  StackProps,
  Tooltip,
} from "@mui/material";
import { useSetAtom } from "jotai";
import { isEmpty } from "lodash-es";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DialogController } from "../../utils/hooks/use-dialog";
import { UUID } from "../../utils/uuid";
import { useAnalytics } from "../analytics/analytics";
import { useBrandKit } from "../brand-kit/hooks/use-brand-kit";
import { BrandKitImageType, BrandKitStyle } from "../brand-kit/server/brand-kit-service";
import { brandingAtom, brandingImageTypeAtom } from "../brand-kit/store";
import { CompactBrandingBar } from "./branding-bar-compact";
import { DefaultBrandingBar } from "./branding-bar-default";

export interface BrandingBarProps {
  visualStyleSelector?: boolean;
  imageStyleSelector?: boolean;
  compact?: boolean;
  useSelectedLogo?: boolean;
  onLogoChange?: () => void;
  onReset?: () => void;
}

export const BrandingBar: FC<BrandingBarProps & StackProps> = ({
  visualStyleSelector = false,
  compact = false,
  useSelectedLogo = true,
  onLogoChange: onLogoClick,
  onReset,
  ...props
}) => {
  const { gaEvent } = useAnalytics();
  const { loading, brandKit, branding, defaultBranding } = useBrandKit();
  const setSelectedBranding = useSetAtom(brandingAtom);
  const setSelectedImageType = useSetAtom(brandingImageTypeAtom);

  const manager = useMemo(() => {
    if (loading) return undefined;

    return {
      logo: {
        setSelected: (value?: UUID) => {
          onLogoClick?.();
          setSelectedBranding((prev) => ({
            ...prev,
            logo: (brandKit?.choices.logos ?? []).find((logo) => logo.id === value),
          }));
        },
      },
      color: {
        setSelected: (value?: UUID) => {
          gaEvent("discover_branding", { action: "set_color" });

          setSelectedBranding((prev) => ({
            ...prev,
            color: (brandKit?.choices.colors ?? []).find((color) => color.id === value),
          }));
        },
      },
      style: {
        setSelected: (branding_type: BrandKitStyle) => {
          gaEvent("discover_branding", { action: "set_style", value: branding_type.value });

          setSelectedBranding((prev) => ({ ...prev, branding_type }));
        },
      },
      imageType: {
        setSelected: (image_type: BrandKitImageType) => {
          gaEvent("discover_branding", { action: "set_image_type", value: image_type });
          setSelectedImageType(image_type);
        },
      },
      reset: () => {
        setSelectedBranding({
          logo: defaultBranding?.logo ?? undefined,
          color: defaultBranding?.color ?? undefined,
          branding_type: defaultBranding?.branding_type ?? {
            type: "standard",
            value: "bold",
          },
          default_tone: defaultBranding?.default_tone ?? "professional",
        });
        onReset?.();
      },
    };
  }, [
    loading,
    onLogoClick,
    setSelectedBranding,
    brandKit?.choices.logos,
    brandKit?.choices.colors,
    gaEvent,
    setSelectedImageType,
    defaultBranding?.logo,
    defaultBranding?.color,
    defaultBranding?.branding_type,
    defaultBranding?.default_tone,
    onReset,
  ]);

  const hasBranding = !isEmpty(manager && brandKit?.choices);
  if (!manager) return null;

  return compact ? (
    <CompactBrandingBar
      hasBranding={hasBranding}
      manager={manager}
      branding={branding}
      defaultBranding={defaultBranding}
      brandKit={brandKit}
      visualStyleSelector={visualStyleSelector}
      useSelectedLogo={useSelectedLogo}
      onReset={manager?.reset}
      {...props}
    />
  ) : (
    <DefaultBrandingBar
      loading={loading}
      hasBranding={hasBranding}
      manager={manager}
      branding={branding}
      defaultBranding={defaultBranding}
      brandKit={brandKit}
      visualStyleSelector={visualStyleSelector}
      useSelectedLogo={useSelectedLogo}
      onReset={manager?.reset}
      {...props}
    />
  );
};

export const More: FC<
  BoxProps & { dialog: DialogController<unknown>; tooltip?: string; type: "logo" | "colour" }
> = ({ dialog, title, tooltip, type, ...props }) => {
  const { t } = useTranslation();

  return (
    <>
      <Tooltip title={tooltip ?? ""}>
        <Box
          {...props}
          sx={(theme) => ({
            width: "60px",
            height: "60px",
            borderRadius: `${theme.shape.borderRadius}px`,
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: "sidebar.divider",
            "&:hover": {
              borderColor: "text.secondary",
              color: "text.secondary",
              cursor: "pointer",
            },
            color: "primary.main",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            verticalAlign: "middle",
          })}
          onClick={dialog.handleOpen}
        >
          <MoreHoriz fontSize="large" sx={{ width: "auto" }} />
        </Box>
      </Tooltip>
      <Dialog open={dialog.open} onClose={dialog.handleClose}>
        <DialogTitle>
          {title ?? t("More")}
          <IconButton
            data-analytics-id={`branding-bar-more-dialog-${type}-close`}
            aria-label="close"
            onClick={dialog.handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>{props.children}</DialogContent>
      </Dialog>
    </>
  );
};
