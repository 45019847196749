import { Alert } from "@mui/material";
import { ReactElement } from "react";
import { DeepRequired, FieldError, FieldErrorsImpl, Merge } from "react-hook-form";

export type ChildErrorImpl<T> = FieldErrorsImpl<DeepRequired<T>>;

export type ChildError<T> = Merge<FieldError, T>;

export const TopLevelErrorAlert = <T,>({
  errors,
}: {
  errors?: (ChildError<T> | undefined)[];
}): ReactElement => {
  return (
    <>
      {errors &&
        errors.map((error: ChildError<T> | undefined, index: number) => {
          return (
            typeof error?.message === "string" && (
              <Alert severity="error" sx={{ mt: 3 }} key={`alert-${index}`}>
                {error.message}
              </Alert>
            )
          );
        })}
    </>
  );
};
