import { useAtomValue } from "jotai";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { config } from "../../../config";
import { UnsplashService } from "../../../server/api/unsplash";
import { useToken } from "../../../server/auth/use-token";
import { useSupabaseCallback } from "../../../server/supabase/hooks";
import { useAnalytics } from "../../analytics/analytics";
import { useBrandKit } from "../../brand-kit/hooks/use-brand-kit";
import { ContentTone } from "../../brand-kit/server/brand-kit-service";
import { brandingImageTypeAtom } from "../../brand-kit/store";
import { SupabaseCustomEmailService } from "../../channels/email/server/supabase-custom-email-service";
import { ChannelName, isEmailChannel } from "../../channels/types";
import { useCreateDesignHuddleProject } from "../../design-huddle/use-design-huddle-project-create";
import { getPageNumber, imageURL } from "../../design-huddle/utils";
import { toImportMoment } from "../../discover/transform";
import { useEmailDesigns } from "../../email-design/hooks/use-email-design";
import { LoadableTemplateContent } from "../../generic/atoms/types/template";
import { SupabaseMomentService } from "../../moment/server/supabase-moment-service";
import { generatedTemplateAtom } from "../store";

export function useLaunchTemplate(contentTone: ContentTone | "custom"): {
  savable: boolean;
  launching: boolean;
  launch: (channel: ChannelName) => Promise<void>;
} {
  const { gaEvent } = useAnalytics();
  const navigate = useNavigate();

  const [launching, setLaunching] = useState(false);
  const getToken = useToken();
  const { branding } = useBrandKit();
  const image_type = useAtomValue(brandingImageTypeAtom);

  const createProject = useCreateDesignHuddleProject();

  const { data: emailDesigns } = useEmailDesigns();

  const templateLoader = useAtomValue(generatedTemplateAtom);
  const template = templateLoader.state === "hasData" ? templateLoader.data : undefined;

  const img = template?.images[template?.selectedImage];
  const unsavable =
    !template ||
    !img ||
    img.state !== "hasData" ||
    !img.data ||
    template.content?.[contentTone]?.state !== "hasData";

  const launch = useSupabaseCallback(
    async ({ supabase, account_id }, channel: ChannelName) => {
      if (
        !template ||
        !img ||
        img.state !== "hasData" ||
        !img.data ||
        template.content?.[contentTone]?.state !== "hasData"
      )
        return;

      const _template = {
        ...template,
        img: {
          ...template.images[template.selectedImage],
          template_code: img.data.template_code ?? "bad",
        },
        content: {
          email_subject: template.subject_line,
          professional: getContent(template.content?.professional),
          casual: getContent(template.content?.casual),
          direct: getContent(template.content?.direct),
          custom: getContent(template.content?.custom),
        },
      };
      setLaunching(true);

      gaEvent("discover_preview", {
        action: "use_template",
        value: template.slug,
        method: channel ?? "",
      });

      const projectData = await createProject(img.data?.template_code, branding, img.data);
      const projectId = projectData?.project_id ?? null;

      const emailDesignId = emailDesigns?.find((e) => e.is_default)?.id;

      const emailFrom = isEmailChannel(channel)
        ? (await new SupabaseCustomEmailService(supabase).getDefault()).data?.address ||
          config.defaultEmailFrom
        : undefined;

      const { data: account } = await supabase
        .from("account")
        .select("enable_teams")
        .eq("id", account_id)
        .single();

      const momentTemplate = toImportMoment(
        _template,
        contentTone,
        emailDesignId,
        projectId
          ? {
              project_id: projectId,
              account_id,
              page_number: getPageNumber(branding),
              image_type,
            }
          : undefined,
        channel,
        projectId ? imageURL(account_id, projectId) : undefined,
        emailFrom,
        account?.enable_teams,
      );

      const { data, error } = await new SupabaseMomentService(supabase).importMoment(
        momentTemplate,
      );

      setLaunching(false);
      if (error) toast(error.message);
      else {
        if (img.data?.unsplashExtra) {
          void new UnsplashService(await getToken())
            .ackDownload(img.data.unsplashExtra.downloadUrl)
            .catch(console.error);
        }
        navigate(`/moments/${data}`);
      }
    },
    [
      template,
      img,
      contentTone,
      gaEvent,
      createProject,
      branding,
      emailDesigns,
      getToken,
      navigate,
      image_type,
    ],
  );

  return {
    savable: !unsavable,
    launching,
    launch,
  };
}

function getContent(template?: LoadableTemplateContent): string {
  if (template?.state !== "hasData") return "";

  return template.data?.data.response.content ?? "";
}
