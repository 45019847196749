import { Box, Dialog, DialogProps, Typography } from "@mui/material";
import { ErrorBoundary } from "@sentry/react";
import { useAtom, useAtomValue } from "jotai";
import { FC, useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { OmitUnion } from "../../utils/omit-union";
import { ContentTone } from "../brand-kit/server/brand-kit-service";
import { libraryTemplateAtom } from "../discover/library/store";
import { brandToneAtom, queryAtom } from "../generate/store";
import { RefineHistory } from "../generic/atoms/types/refine";
import {
  PromptSuggestion,
  PromptSuggestions,
} from "../message/editor/plugins/co-pilot/co-pilot-prompt-suggestions";
import { RefineContentHistory } from "./ai-refine-content-history";
import { AiRefineInput } from "./ai-refine-input";
import { historyAtomFamily } from "./store";

export const AiRefineContentModal: FC<
  {
    title: string;
    contentTone: ContentTone | "custom";
    config: OmitUnion<RefineHistory, "prompt">;
    onClose: () => void;
  } & DialogProps
> = ({ title, contentTone, config, onClose, ...popoverProps }) => {
  const { t } = useTranslation();

  const historyContainer = useRef<HTMLDivElement>(null);

  const [scrollFade, setScrollFade] = useState(0);
  const handleScroll = useCallback((e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop } = e.currentTarget;
    setScrollFade(Math.min(scrollTop, 20));
  }, []);

  const handleChange = useCallback(() => {
    setTimeout(() => {
      historyContainer.current?.scrollTo?.({
        top: historyContainer.current.scrollHeight,
        behavior: "smooth",
      });
    }, 50);
  }, []);

  const promptSuggestions: PromptSuggestion[] = [
    {
      action: "lengthen",
      label: t("Lengthen"),
    },
    {
      action: "shorten",
      label: t("Shorten"),
    },
    {
      action: "regenerate",
      label: t("Regenerate"),
    },
  ];

  const query = useAtomValue(queryAtom);
  const template = useAtomValue(libraryTemplateAtom);
  const customTone = useAtomValue(brandToneAtom);
  const key = useMemo(() => {
    return {
      contentKey: contentTone,
      ...(contentTone === "custom" && customTone && { customTone }),
      ...(config.store === "generate" ? { query } : { template }),
    };
  }, [config.store, contentTone, customTone, query, template]);
  const [history, setHistory] = useAtom(historyAtomFamily(key));

  const handleUsePromptSuggestion = useCallback(
    (prompt: string) => {
      setHistory([...history, { ...config, prompt }]);
    },
    [setHistory, history, config],
  );

  return (
    <Dialog onClose={onClose} {...popoverProps}>
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        p={2}
        maxHeight={"calc(80vh)"}
        minWidth={600}
      >
        <ErrorBoundary
          fallback={
            <Typography variant="body1">
              {t(
                "It appears Co-Pilot isn't working right now. Try again later, or contact your customer services representative.",
              )}
            </Typography>
          }
        >
          <Box pt={0.5} flex="0 0 auto">
            <Typography variant="h6">{title}</Typography>
          </Box>

          {history.length === 0 && (
            <PromptSuggestions
              onUsePrompt={handleUsePromptSuggestion}
              loading={false}
              suggestions={promptSuggestions}
            />
          )}
          <Box
            flex="1 1 auto"
            mb={2}
            ref={historyContainer}
            onScroll={handleScroll}
            pr={2}
            sx={(theme) => ({
              overflowY: "scroll",
              maskImage: `linear-gradient(to top, ${theme.palette.background.paper} calc(100% - ${scrollFade}px), transparent 100%)`,
            })}
          >
            <RefineContentHistory
              tone={contentTone}
              onChange={handleChange}
              store={config.store}
              onUseSuggestion={onClose}
            />
          </Box>

          <Box flex="0 0 auto">
            <AiRefineInput tone={contentTone} config={config} />
          </Box>
        </ErrorBoundary>
      </Box>
    </Dialog>
  );
};
