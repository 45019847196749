import { useAtomValue } from "jotai";
import { loadable } from "jotai/utils";
import { FC } from "react";
import { getBrandKitStyle } from "../brand-kit/hooks/use-brand-kit";
import { LibraryTemplate } from "../discover/library/types";
import { discoverTemplateImageFamily } from "../discover/store";
import { sequenceTemplateBrandingFamily } from "./store";

export const SequenceTemplateThumbnail: FC<{ template: LibraryTemplate }> = ({ template }) => {
  const branding = useAtomValue(sequenceTemplateBrandingFamily(template.slug));

  const imageLoader = useAtomValue(
    loadable(
      discoverTemplateImageFamily({
        slug: template.slug,
        img: template.img,
        style: { type: "standard", value: getBrandKitStyle(branding ? branding.image : 0) },
      }),
    ),
  );

  const url =
    (imageLoader.state === "hasData" &&
      imageLoader.data?.url.state === "hasData" &&
      imageLoader.data.url.data) ||
    null;

  return url ? (
    <img
      src={url}
      alt={template.title}
      style={{
        width: "100%",
        height: "100%",
        objectFit: "cover",
      }}
    />
  ) : null;
};
