import { BaseSelectProps, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useAtomValue } from "jotai";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { BrandKitImageType } from "../brand-kit/server/brand-kit-service";
import { brandingImageTypeAtom } from "../brand-kit/store";

interface ImageShapeSelectProps extends Omit<BaseSelectProps, "onChange"> {
  onChange: (style: BrandKitImageType) => void;
}

export const ImageShapeSelect: FC<ImageShapeSelectProps> = ({ onChange, ...props }) => {
  const { t } = useTranslation();
  const imageType = useAtomValue(brandingImageTypeAtom);
  const label = t("Image shape");

  return (
    <FormControl>
      <InputLabel htmlFor="image-shape-select">{label}</InputLabel>
      <Select
        id="image-shape-select"
        value={imageType ?? "square"}
        onChange={(e) => {
          const val = e.target.value;
          onChange(val as BrandKitImageType);
        }}
        sx={{ width: "7em" }}
        label={label}
        inputProps={{
          "aria-label": label,
        }}
        {...props}
      >
        <MenuItem value="square">{t("Square")}</MenuItem>
        <MenuItem value="banner">{t("Banner")}</MenuItem>
      </Select>
    </FormControl>
  );
};
