/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  BaseSupabaseService,
  SupabaseService,
} from "../../../server/supabase/base-supabase-service";
import { Database } from "../../../server/supabase/types/database-definitions";

export type HrisConfig = Database["public"]["Tables"]["hris_config"]["Row"];

export class SupabaseHRISService extends SupabaseService<"hris_config"> {
  table = "hris_config" as const;

  async delete() {
    const { error } = await this.client.rpc("delete_hris_config");

    if (error) return error;
  }
}

export class SupabaseSuperAdminHRISService extends BaseSupabaseService<"super_admin"> {
  async getSyncStatus() {
    return await this.client.rpc("merge_sync_status");
  }

  async disconnect() {
    return await this.client.rpc("disconnect_merge");
  }

  async connect(link_token: string) {
    return await this.client.rpc("connect_merge", { link_token });
  }
}
