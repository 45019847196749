import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Page } from "../../modules/layout/components/page";
import { PageContent } from "../../modules/layout/components/page-content";
import { PageHeader } from "../../modules/layout/components/page-header";
import { PageHeading } from "../../modules/layout/components/page-heading";
import { useClientFilteredMoments } from "../../modules/moment-filter/hooks/use-client-filtered-moments";
import { useMomentSort } from "../../modules/moment/moment-sort/hooks/use-moment-sort";
import { SupabaseMomentCardMomentService } from "../../modules/moment/server/supabase-moment-service";
import { ProgramOrchestratorView } from "../../modules/program-orchestrator-view/components/program-orchestrator-view";

const OrchestratorPage: FC = () => {
  const { t } = useTranslation();
  const sortMoments = useMomentSort();

  const { moments } = useClientFilteredMoments((s, a) =>
    new SupabaseMomentCardMomentService(s).getAll(a, { order: [{ column: "id" }] }),
  );

  return (
    <Page title={t("Orchestrator | ChangeEngine")}>
      <PageHeader>
        <PageHeading
          heading={t("Orchestrator")}
          subheading={t("Organise activity across all your Programs")}
        />
      </PageHeader>
      <PageContent>
        {moments && <ProgramOrchestratorView moments={sortMoments(moments)} />}
      </PageContent>
    </Page>
  );
};

export default OrchestratorPage;
