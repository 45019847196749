/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { PostgrestSingleResponse } from "@supabase/postgrest-js";
import {
  SupabaseService,
  SupabaseServiceView,
} from "../../../server/supabase/base-supabase-service";
import { Database } from "../../../server/supabase/types/database-definitions";
import { toISO8601 } from "../../../utils/iso8601";
import { UUID, uuidv4 } from "../../../utils/uuid";
import { Segment } from "../types";

export type personRecipientType = {
  email: string;
  employee_id: string;
  timezone: string;
  display?: string;
};

type SegmentData = Database["public"]["Views"]["segment_data_v2"]["Row"];

type PersonsInSegment =
  (Database["public"]["Functions"]["get_persons_in_segment_v3"]["Returns"][0] & { id?: UUID })[];

export type SupabaseSegment = Database["public"]["Tables"]["segment"]["Row"];

export class SupabaseSegmentService extends SupabaseService<"segment"> {
  table = "segment" as const;

  async segmentSaveAllowed() {
    return this.client.rpc("segment_save_allowed");
  }

  getAllQuery() {
    return this.client.from(this.table).select("*, favorite_segment(id)");
  }

  async getCount(
    segment_id: UUID,
    target_manager: boolean,
    channel?: string,
    target_connection_type?: string | null,
  ): Promise<PostgrestSingleResponse<PersonsInSegment>> {
    const args: {
      segment_id_: UUID;
      target_manager_: boolean;
      channels_?: string[];
      target_connection_type_?: string;
    } = {
      segment_id_: segment_id,
      target_manager_: target_manager,
    };

    if (channel !== undefined) {
      args.channels_ = [channel];
    }

    if (target_connection_type) {
      args.target_connection_type_ = target_connection_type;
    }

    return this.getAllForRPC("get_persons_in_segment_v3", args);
  }

  async getUnsavedCount(
    segment: Segment,
    target_manager: boolean,
    channel?: string,
    target_connection_type?: string | null,
  ) {
    return this.log_errors(
      await this.client
        .rpc(
          "get_persons_in_unsaved_segment_v3",
          {
            operation: segment.operation,
            conditions: segment.conditions,
            target_manager_: target_manager,
            ...(channel ? { channels_: [channel] } : {}),
            ...(target_connection_type ? { target_connection_type_: target_connection_type } : {}),
          },
          {
            count: "exact",
          },
        )
        .select(),
    );
  }

  async getSegmentOnly(segment_id: UUID) {
    return this.log_errors(
      await this.client.from(this.table).select().eq("id", segment_id).single(),
    );
  }

  async getMomentIds(segment_id: UUID) {
    return this.log_errors(
      await this.client.from("moment").select("id").eq("segment_id", segment_id),
    );
  }

  async getDefaultSegment() {
    return this.log_errors(
      await this.client.from("segment_data_v2").select("*").eq("is_default", true).maybeSingle(),
    ) as PostgrestSingleResponse<
      NonNullableFields<
        SegmentData,
        "id" | "name" | "operation" | "conditions" | "is_recipient_type"
      >
    >;
  }

  cloneSegment(segment: SegmentData, newId: UUID) {
    return {
      ...segment,
      id: newId,
      name: "",
      conditions: segment.conditions
        ? segment.conditions.map((c) => {
            return {
              ...c,
              id: uuidv4(),
              segment_id: newId,
            };
          })
        : [],
      created_at: toISO8601(new Date()),
      deleted_at: null,
      is_default: false,
      operation: segment.operation !== null ? segment.operation : "AND",
      is_recipient_type: segment.is_recipient_type !== null ? segment.is_recipient_type : false,
    };
  }

  async getSegmentData(segment_id: UUID) {
    return this.log_errors(
      await this.client.from("segment_data_v2").select("*").eq("id", segment_id).single(),
    ) as PostgrestSingleResponse<
      NonNullableFields<
        SegmentData,
        "id" | "name" | "description" | "operation" | "conditions" | "is_recipient_type"
      >
    >;
  }

  async upsertSegmentData(segment: Segment) {
    return this.log_errors(
      await this.client.rpc("upsert_segment", {
        id: segment.id,
        name: segment.name ?? "",
        description: segment.description ?? "",
        is_recipient_type: segment.is_recipient_type,
        operation: segment.operation,
        conditions: segment.conditions,
      }),
    );
  }

  async archive(segment_id: UUID) {
    return this.log_errors(
      await this.client
        .from(this.table)
        .update({ deleted_at: toISO8601(new Date()) })
        .eq("id", segment_id),
    );
  }

  async unarchive(segment_id: UUID) {
    return this.log_errors(
      await this.client.from(this.table).update({ deleted_at: null }).eq("id", segment_id),
    );
  }

  async unarchiveWithNewName(segment_id: UUID, name: string) {
    return this.log_errors(
      await this.client
        .from(this.table)
        .update({ deleted_at: null, name: name })
        .eq("id", segment_id),
    );
  }
}

export type SegmentWithMoments =
  Database["public"]["Views"]["segments_with_related_moments"]["Row"];

export class SupabaseSegmentsWithMomentsService extends SupabaseServiceView<"segments_with_related_moments"> {
  table = "segments_with_related_moments" as const;
}

export class SupabaseFavoriteSegmentService extends SupabaseService<"favorite_segment"> {
  table = "favorite_segment" as const;

  async unfavorite(accountId: UUID, segmentId: UUID) {
    return await this.client
      .from(this.table)
      .delete()
      .eq("account_id", accountId)
      .eq("segment_id", segmentId);
  }
}

export type SegmentInvalidRecipientTypeKeys =
  Database["public"]["Views"]["segment_invalid_recipient_type_keys"]["Row"];

export class SupabaseSegmentInvalidRecipientTypeKeysService extends SupabaseServiceView<"segment_invalid_recipient_type_keys"> {
  table = "segment_invalid_recipient_type_keys" as const;
}
export type NonNullableAllFields<T> = {
  [P in keyof T]: NonNullable<T[P]>;
};

export type NonNullableFields<T, P extends keyof T> = Omit<T, P> & NonNullableAllFields<Pick<T, P>>;
