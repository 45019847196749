import { atom } from "jotai";
import { atomFamily, atomWithDefault } from "jotai/utils";
import { UUID } from "../../../utils/uuid";
import { debugLabel } from "../../generate/store";
import { SequenceMoment } from "../../moment/server/supabase-moment-service";

export const layoutAtom = atom<"sequence" | "default">("default");

export type SequenceBarMoment = SequenceMoment & { barStatus?: "new" | "archived" };
export const currentSequenceAtom = atom<UUID | null>(null);
export const sequenceMomentsAtom = atomFamily((sequenceId: UUID | null) =>
  debugLabel(`sequenceMomentsAtom-${sequenceId}`, atom<SequenceBarMoment[] | null>([])),
);
export const sequenceMomentAtom = atomFamily((momentId: UUID | null) =>
  debugLabel(
    `sequenceMomentAtom-${momentId}`,
    atomWithDefault<SequenceBarMoment | null>((get) => {
      if (!momentId) return null;

      const sequenceId = get(currentSequenceAtom);
      const moment = get(sequenceMomentsAtom(sequenceId))?.find((m) => m.id === momentId);
      return moment ?? null;
    }),
  ),
);
