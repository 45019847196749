import { useAuth0 } from "@auth0/auth0-react";
import { DependencyList, useCallback } from "react";
import { useToken } from "../../server/auth/use-token";
import { OrgUser } from "../../server/supabase/hooks/types";
import { huddle } from "../huddle/huddle";

export function useDesignHuddleCallback<In, V>(
  projects: string[],
  callback: (params?: In) => Promise<V>,
  deps: DependencyList,
): (params?: In) => Promise<V> {
  const { user } = useAuth0<OrgUser>();
  const getToken = useToken();
  return useCallback(
    async (...args) => {
      const token = await getToken();

      const response = await huddle.POST("/", {
        body: { projects },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const { access_token } = response.data!;
      DSHDLib.configure({ access_token, visitor: false });

      return callback(...args);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [getToken, user, ...deps],
  );
}
