import { Badge, BadgeProps } from "@mui/material";
import { FC } from "react";
import { useFlag } from "../../../../server/optimizely";

export const BetaBadge: FC<BadgeProps> = ({ children, ...rest }) => {
  const [betaFlagDisabled] = useFlag("disable_beta_flag");

  if (betaFlagDisabled) return children;
  return (
    <Badge color="secondary" badgeContent="BETA" {...rest}>
      {children}
    </Badge>
  );
};
