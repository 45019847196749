import { useAtom } from "jotai";
import { mapValues, sortBy } from "lodash-es";
import { UseApiLoaderResponse, useApiLoader } from "../../../server/api/hooks/use-api-loader";
import { useToken } from "../../../server/auth/use-token";
import { authHeader } from "../../../utils/auth-header";
import { selfDriving } from "../../self-driving/self-driving";
import { multiformatAtom } from "../store/multiformat-atom";
import { Multiformat } from "../store/types";

/**
 * If you're using multiformat atoms on your page, you MUST use this hook at the page level.
 * After that, you can use the `multiformatAtom` atom to access the data.
 */
export const useMultiformat = (): UseApiLoaderResponse<Multiformat> => {
  const [multiformat, setMultiformat] = useAtom(multiformatAtom);
  const getToken = useToken();

  return useApiLoader(async () => {
    if (multiformat) return multiformat;

    const { error, data } = await selfDriving.GET("/multiformat", {
      ...authHeader(await getToken()),
    });

    if (error) {
      console.error(error);
      throw new Error("Cannot not get /multiformat");
    }

    const breakdown: (typeof data)["breakdown"] = mapValues(data.breakdown, (items) =>
      sortBy(items, (item) => item.Order ?? Infinity),
    );

    setMultiformat({
      ...data,
      breakdown,
    });

    return data;
  }, [multiformat, getToken, setMultiformat]);
};
