import {
  PostgrestClient,
  PostgrestMaybeSingleResponse,
  PostgrestResponse,
  PostgrestSingleResponse,
} from "@supabase/postgrest-js";
import { SupabaseService } from "../../../server/supabase/base-supabase-service";
import { Database } from "../../../server/supabase/types/database-definitions";
import { UUID } from "../../../utils/uuid";

export type Account = Database["public"]["Tables"]["account"]["Row"];
export type AccountLimit = Database["public"]["Tables"]["account_limit"]["Row"];

export type AdminAccountLimit = Database["ce_admin"]["Views"]["account_limits"]["Row"];
export type AdminAccountLimitKeys = Database["ce_admin"]["Views"]["limit_keys"]["Row"];

export type AccountLimitKey =
  | "slack_aliases"
  | "super_admins"
  | "contributors"
  | "unlayer_fallback"
  | "omit_email_test_indicator"
  | "email_design_templates"
  | "allow_all_and_upcoming_segment"
  | "slack_connections";

export class SupabaseAccountService extends SupabaseService<"account"> {
  table = "account" as const;
}

export class SupabaseAccountEmployeeSyncConfigService extends SupabaseService<"account_employee_sync_config"> {
  table = "account_employee_sync_config" as const;
}

export class SupabaseAccountLimitService extends SupabaseService<"account_limit"> {
  table = "account_limit" as const;

  async getKey(key: AccountLimitKey): Promise<PostgrestMaybeSingleResponse<AccountLimit>> {
    return this.log_errors(
      await this.client.from(this.table).select<"*", AccountLimit>().eq("key", key).maybeSingle(),
    );
  }

  async getKeys(keys: AccountLimitKey[]): Promise<PostgrestResponse<AccountLimit>> {
    return this.log_errors(
      await this.client.from(this.table).select<"*", AccountLimit>().in("key", keys),
    );
  }
}

export class SupabaseDesignHuddleCustomBrandService extends SupabaseService<"content_custom_template_brand"> {
  table = "content_custom_template_brand" as const;
}

export class CeAdminSupabaseAccountService {
  client: PostgrestClient<Database, "ce_admin">;

  constructor(client: PostgrestClient<Database, "ce_admin">) {
    this.client = client;
  }

  async getAccountLimits(): Promise<PostgrestResponse<AdminAccountLimit>> {
    return await this.client.from("account_limits").select<"*", AdminAccountLimit>();
  }

  async getKeys(): Promise<PostgrestResponse<AdminAccountLimitKeys>> {
    return await this.client.from("limit_keys").select<"*", AdminAccountLimitKeys>();
  }

  async setAccountLimits(
    account_id: UUID,
    limits: { [key: string]: number },
    business_name: string,
  ): Promise<PostgrestSingleResponse<undefined>> {
    return this.client.rpc("upsert_account_limits", { account_id, limits, business_name });
  }

  async createAccount(
    data: Database["ce_admin"]["Functions"]["create_account"]["Args"],
  ): Promise<PostgrestSingleResponse<string>> {
    return this.client.rpc("create_account", data);
  }
}
