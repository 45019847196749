import {
  Alert,
  Box,
  Button,
  Divider,
  Link,
  Skeleton,
  Stack,
  StackProps,
  Typography,
} from "@mui/material";
import { useAtomValue } from "jotai";
import { isEqual } from "lodash-es";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { usePermissions } from "../../server/auth/use-permissions";
import { BrandKitColor, BrandKitLogo } from "../brand-kit/server/brand-kit-service";
import { brandingAtom } from "../brand-kit/store";
import { BrandInfoSelected, BrandKitManager } from "../brand-kit/types";
import { BrandColorSelect } from "./brand-color-select";
import { BrandLogoSelect } from "./brand-logo-select";
import { ImageStyle } from "./image-style";

export interface DefaultBrandingBarProps {
  manager?: BrandKitManager;
  branding: unknown;
  defaultBranding?: BrandInfoSelected;
  brandKit?: {
    choices: {
      colors: BrandKitColor[];
      logos: BrandKitLogo[];
    };
  };
  loading: boolean;
  hasBranding: boolean;
  visualStyleSelector?: boolean;
  imageStyleSelector?: boolean;
  useSelectedLogo?: boolean;
  onReset?: () => void;
}

export const DefaultBrandingBar: FC<DefaultBrandingBarProps & StackProps> = ({
  branding,
  defaultBranding,
  brandKit,
  loading,
  hasBranding,
  manager,
  visualStyleSelector = false,
  imageStyleSelector = false,
  useSelectedLogo = true,
  onReset,
  ...props
}) => {
  const { t } = useTranslation();
  const { super_admin } = usePermissions();

  const selectedBranding = useAtomValue(brandingAtom);

  return (
    <Stack
      direction="row"
      gap={1}
      flexWrap="wrap"
      minHeight={"6em"}
      py={2}
      sx={{ pl: "var(--page-px)" }}
      bgcolor="background.default"
      mb={2}
      {...props}
    >
      {loading && (
        <>
          <Typography variant="h5" mr={4} color="text.secondary">
            {t("Brand")}
          </Typography>
          <Box sx={{ flexGrow: 1, display: "flex", gap: 2 }}>
            <Skeleton width="220px" height="60px" sx={{ transform: "none" }} />
            <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
            <Skeleton width="220px" height="60px" sx={{ transform: "none" }} />
            <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
            <Skeleton width="220px" height="60px" sx={{ transform: "none" }} />
          </Box>
        </>
      )}
      {!loading && (
        <>
          <Stack direction="column" mr={4}>
            <Typography variant="h5" mr={4} color="text.secondary">
              {t("Brand")}
            </Typography>
            {hasBranding && super_admin && (
              <Link href="/manage/brand/brand_kit" underline="hover">
                <Typography variant="caption">{t("Edit Brand ")}</Typography>
              </Link>
            )}
          </Stack>

          {manager && hasBranding ? (
            <>
              <ImageStyle
                visualStyleSelector={visualStyleSelector}
                imageTypeSelector={imageStyleSelector}
                manager={manager}
              />
              <BrandColorSelect
                selected={selectedBranding.color}
                colors={brandKit?.choices.colors ?? []}
                manager={manager}
              />
              <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
              <BrandLogoSelect
                selected={selectedBranding.logo}
                logos={brandKit?.choices.logos ?? []}
                manager={manager}
                defaultBranding={defaultBranding}
                useSelectedLogo={useSelectedLogo}
              />
              <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
            </>
          ) : (
            super_admin && (
              <Link href="/manage/brand/brand_kit" underline="hover">
                <Alert severity="info" sx={{ mb: 2 }}>
                  {t("Click here to add your company's logos and colors to create custom images")}
                </Alert>
              </Link>
            )
          )}

          {!loading && hasBranding && !isEqual(defaultBranding, branding) && (
            <>
              <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
              <Button
                data-analytics-id="branding-bar-reset"
                onClick={onReset}
                size="small"
                sx={{ opacity: 0.8, fontWeight: "normal" }}
                data-testid="branding-bar-reset-button"
              >
                {t("Restore Default")}
              </Button>
            </>
          )}
        </>
      )}
    </Stack>
  );
};
