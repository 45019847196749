import { createHeadlessEditor } from "@lexical/headless";
import { LexicalEditor } from "lexical";
import { EDITOR_THEME } from "../theme/theme";
import { EDITOR_NODES_HEADLESS } from "./nodes-headless";

export const createConfiguredEditor = (config: {
  onError?: (error: Error) => void;
  format: "email" | "chat" | "sms";
}): LexicalEditor => {
  return createHeadlessEditor({
    nodes: EDITOR_NODES_HEADLESS[config.format],
    theme: EDITOR_THEME,
    onError: config.onError,
  });
};
