import { Box, BoxProps } from "@mui/material";
import { FC } from "react";
import { MomentCardMoment } from "../types";
import { statusToPill } from "../utils";

export interface MomentPillProps extends BoxProps {
  status: MomentCardMoment["status"];
}
export const MomentPill: FC<MomentPillProps> = ({ status, ...props }) => {
  if (!status || status === "active") return null;
  return <Box {...props}>{statusToPill(status)}</Box>;
};
