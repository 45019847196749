import { WithAuthenticationRequiredOptions, withAuthenticationRequired } from "@auth0/auth0-react";
import "draft-js/dist/Draft.css";
import { ComponentType, FC } from "react";
import NotFound from "./pages/not-found";
import { usePermissions } from "./server/auth/use-permissions";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Props<T extends ComponentType<any>> = {
  Component: T;
  type?: "super_admin" | "ce_admin";
  options?: WithAuthenticationRequiredOptions;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ProtectedRoute = <T extends ComponentType<any>>({
  Component,
  type,
  options = {},
  ...props
}: Props<T>): React.JSX.Element => {
  const AuthenticatedComponent = withAuthenticationRequired(
    withPermissions(Component, type),
    options,
  );
  return <AuthenticatedComponent {...props} />;
};

const withPermissions = <P extends object>(
  Component: ComponentType<P>,
  type?: "super_admin" | "ce_admin",
): FC<P> => {
  return function WithPermissions(props: P): React.JSX.Element {
    const perms = usePermissions();

    if (type && !perms?.[type]) {
      return <NotFound />;
    }

    return <Component {...props} />;
  };
};
