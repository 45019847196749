import {
  Autocomplete,
  Box,
  Link,
  Skeleton,
  TextField,
  TextFieldProps,
  Typography,
  useTheme,
} from "@mui/material";
import { FC, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { UUID } from "../../utils/uuid";
import { BrandTone } from "../brand-kit/server/brand-tone-service";
import { AddButton } from "../event/components/event-selector";

export const GenerateToneSelector: FC<
  {
    loading: boolean;
    brandTones: BrandTone[];
    disabled?: boolean;
    openAddToneDialog?: () => void;
  } & Pick<TextFieldProps, "size">
> = ({ loading, brandTones, disabled, openAddToneDialog, ...textFieldProps }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { control, formState } = useFormContext<{ tone: UUID }>();

  const allTones = useMemo(
    () => [
      ...brandTones,
      ...(openAddToneDialog ? [{ id: "new", name: t("Add another personal tone...") }] : []),
    ],
    [brandTones, t, openAddToneDialog],
  );

  if (loading) return <Skeleton variant="rounded" height={theme.spacing(7)} />;

  if (brandTones.length === 0)
    return (
      <>
        <Typography variant="caption">
          <Trans>Did you know you can </Trans>
        </Typography>
        <Link onClick={openAddToneDialog} component="button" variant="caption" type="button">
          <Trans>add an AI tone</Trans>
        </Link>
        <Typography variant="caption"> to help personalize your Moments</Typography>
      </>
    );

  return (
    <>
      <Controller
        name={"tone"}
        control={control}
        render={({ field }) => (
          <Autocomplete
            disabled={disabled}
            sx={{ minWidth: "300px", flexGrow: 1 }}
            value={brandTones.find((brandTone) => brandTone.id === field.value) ?? null}
            onChange={(_, value) => field.onChange(value?.id)}
            options={allTones}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value?.id}
            renderInput={(params: TextFieldProps) => (
              <TextField
                label={t("What tone do you want to set?")}
                placeholder={t("Tone")}
                fullWidth
                error={!!formState.errors.tone}
                {...params}
                {...textFieldProps}
              />
            )}
            renderOption={(renderProps, option) =>
              option.id === "new" ? (
                <AddButton
                  buttonAction={t("Add another personal tone...")}
                  onClick={() => openAddToneDialog?.()}
                />
              ) : (
                <Box component="li" {...renderProps} key={option.id}>
                  {option.name}
                </Box>
              )
            }
          />
        )}
      />
    </>
  );
};
