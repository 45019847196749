import { Autocomplete, Box, Chip, FormControl, TextField } from "@mui/material";
import { ReactElement } from "react";

interface Props {
  title: string;
  selectedItems: string[];
  options: { [key: string]: string };
  onChange: (selectedItems: string[]) => void;
}

export const ChipSelect = ({ title, options, onChange, selectedItems }: Props): ReactElement => {
  const displayNone = selectedItems.includes("none");

  return (
    <FormControl sx={{ m: 1, width: 300 }}>
      <Autocomplete
        multiple
        disableCloseOnSelect
        size="small"
        id={title + "-chip"}
        options={Object.keys(options).map((val) => val)}
        fullWidth
        value={displayNone ? [] : selectedItems.slice().reverse()}
        getOptionLabel={(optionKey) => options[optionKey]}
        onChange={(_, value, reason) => {
          if (reason === "clear" || value.length === 0) onChange(["none"]);
          else onChange(value.filter((v) => v !== "none"));
        }}
        renderInput={(params) => {
          const allSelected = Object.keys(options).length === selectedItems.length;

          const inputRender = displayNone ? (
            <Box sx={{ height: 105, overflowY: "hidden !important" }}></Box>
          ) : allSelected ? (
            <Chip label={"All"} size="small" />
          ) : (
            params.InputProps.startAdornment
          );
          return (
            <TextField
              {...params}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                style: { height: 100 },
                startAdornment: (
                  <Box sx={{ height: 55, overflowY: "hidden !important" }}>{inputRender}</Box>
                ),
              }}
            />
          );
        }}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip {...getTagProps({ index })} key={option} label={options[option]} />
          ))
        }
      />
    </FormControl>
  );
};
