import {
  DecoratorBlockNode,
  SerializedDecoratorBlockNode,
} from "@lexical/react/LexicalDecoratorBlockNode";
import { addClassNamesToElement } from "@lexical/utils";
import { DOMExportOutput, ElementFormatType, LexicalNode, NodeKey, Spread } from "lexical";
import { ButtonNodeBlock } from "../types/markdown-node-blocks";
export type SerializedButtonListNode = Spread<
  {
    buttons: ButtonNodeBlock[];
    type: "buttonlist";
  },
  SerializedDecoratorBlockNode
>;

export class ButtonListNode extends DecoratorBlockNode {
  __buttons: ButtonNodeBlock[];

  constructor(buttons: ButtonNodeBlock[], format?: ElementFormatType, key?: NodeKey) {
    super(format, key);
    this.__buttons = buttons;
  }

  decorate(): React.JSX.Element {
    return <></>;
  }

  static getType(): string {
    return "buttonlist";
  }

  static clone(node: ButtonListNode): ButtonListNode {
    return new this(node.__buttons, node.__format, node.__key);
  }

  createDOM(): HTMLElement {
    const dom = document.createElement("span");
    addClassNamesToElement(dom, "decorator-block-node");
    return dom;
  }

  exportDOM(): DOMExportOutput {
    const dom = document.createElement("div");
    this.__buttons.forEach((button) => {
      const span = document.createElement("span");
      const buttonEl = document.createElement("a");
      buttonEl.style.appearance = "button";
      buttonEl.style.textDecoration = "none";
      buttonEl.href = button.url;
      buttonEl.textContent = button.text;
      span.append(buttonEl);
      dom.append(span);
    });
    return { element: dom };
  }

  static importJSON(serializedNode: SerializedButtonListNode): ButtonListNode {
    return new this(serializedNode.buttons, serializedNode.format);
  }

  exportJSON(): SerializedButtonListNode {
    return {
      type: "buttonlist",
      buttons: this.__buttons,
      format: this.__format,
      version: 1,
    };
  }

  getChatBlock(): { type: "buttonlist"; buttons: ButtonNodeBlock[] } {
    return { type: "buttonlist", buttons: this.__buttons };
  }

  setButtons(buttons: ButtonNodeBlock[]): void {
    const writable = this.getWritable();
    writable.__buttons = buttons;
  }
}

export function $isButtonListNode(node: LexicalNode | null | undefined): node is ButtonListNode {
  return node instanceof ButtonListNode;
}
