import { Box, SxProps, useTheme } from "@mui/material";
import { useAtomValue } from "jotai";
import { FC, ReactNode } from "react";
import { sidebarWidth } from "../layout/components/sidebar/sidebar";
import { layoutAtom } from "../layout/components/store";
import { SEQUENCE_BAR_WIDTH } from "../sequence/sequence-bar";

export const FixedFooter: FC<{ children: ReactNode; sequenceBarWidth?: number; sx?: SxProps }> = ({
  children,
  sequenceBarWidth = SEQUENCE_BAR_WIDTH,
  sx = [],
}) => {
  const layout = useAtomValue(layoutAtom);

  const theme = useTheme();

  const sideWidth = layout === "sequence" ? sequenceBarWidth : sidebarWidth;

  const width = {
    ...(sideWidth > 0 ? { lg: `calc(100vw - ${sideWidth}px - ${theme.spacing(4)})` } : {}),
    sm: "100%",
  };

  return (
    <Box
      className="fixedFooter--root"
      sx={[
        () => ({
          position: "fixed",
          bottom: 0,
          right: 0,
          width,
          background: `linear-gradient(to bottom, ${theme.palette.background.default}ef 50%, ${theme.palette.background.default}ff)`,
          boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
          zIndex: theme.zIndex.footer,
          height: "4.5em",
        }),
        ...(sx instanceof Array ? sx : [sx]),
      ]}
    >
      <Box
        className="fixedFooter--content"
        sx={{
          margin: `0 auto`,
          px: 4,
          maxWidth: "xl",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
