import { Box, Stack } from "@mui/material";
import { FC } from "react";

export const Section: FC<{ title?: string; children: React.JSX.Element }> = ({
  title,
  children,
}) => {
  return (
    <Stack
      component="ul"
      spacing={0.5}
      sx={{
        listStyle: "none",
        m: 0,
        p: 0,
      }}
    >
      {title && (
        <Box
          component="li"
          sx={{
            color: "var(--nav-section-title-color)",
            fontSize: 12,
            fontWeight: 700,
            lineHeight: 1.66,
            mb: 1,
            ml: 1,
            textTransform: "uppercase",
          }}
        >
          {title}
        </Box>
      )}
      {children}
    </Stack>
  );
};
