import { Chip, ChipProps, SxProps, Theme } from "@mui/material";
import { FC, useState } from "react";

export const HoverChip: FC<
  ChipProps & { hoverProps?: ChipProps } & { defaultProps?: ChipProps }
> = ({ defaultProps, hoverProps, ...props }) => {
  const [hover, setHover] = useState(false);

  const usedProps = hover ? hoverProps : defaultProps;

  return (
    <Chip
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      {...props}
      {...usedProps}
      sx={
        {
          ...(props.sx ?? {}),
          ...(usedProps?.sx ?? {}),
          cursor: "pointer",
        } as SxProps<Theme>
      }
    />
  );
};
