import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  FormControl,
  InputAdornment,
  MenuItem,
  MenuList,
  Popover,
  Stack,
  Tab,
  TextField,
  Typography,
} from "@mui/material";
import { useAtomValue } from "jotai";
import { isEmpty } from "lodash-es";
import { FC, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCustomTemplates } from "../../pages/create-content/use-custom-templates";
import { ChevronDown } from "../../styles/icons/chevron-down";
import { usePopover } from "../../utils/hooks/use-popover";
import { brandingAtom, brandingImageTypeAtom } from "../brand-kit/store";
import { BrandKitManager } from "../brand-kit/types";
import { ImageShapeSelect } from "./image-shape-select";
import { VisualStyleSelect } from "./visual-style-select";

export const CustomStyleSelect: FC<{
  manager: BrandKitManager;
}> = ({ manager }) => {
  const { branding_type: brandKitStyle } = useAtomValue(brandingAtom);
  const imageType = useAtomValue(brandingImageTypeAtom);

  const { loading, data: customTemplates, error } = useCustomTemplates();
  const { t } = useTranslation();
  const popover = usePopover<HTMLDivElement>();
  const [selectedTab, setSelectedTab] = useState<"custom" | "standard">(
    brandKitStyle.type ?? "custom",
  );

  const squares = useMemo(() => customTemplates?.["Square Comms"] ?? [], [customTemplates]);
  const banners = useMemo(() => customTemplates?.["Landscape Comms"] ?? [], [customTemplates]);

  const selectedTemplateName = useMemo(() => {
    if (brandKitStyle.type === "standard") {
      return { square: "Square", banner: "Banner" }[imageType];
    }

    const templates = [...squares, ...banners];
    const template = templates.find(
      (_template) => _template.template.template_code === brandKitStyle.value,
    );
    return template?.name;
  }, [banners, brandKitStyle.type, brandKitStyle.value, imageType, squares]);

  const handleSelectStandard = useCallback(
    (type: "square" | "banner") => () => {
      const imageStyle = brandKitStyle.type === "standard" ? brandKitStyle.value : "bold";
      manager.style.setSelected({ type: "standard", value: imageStyle });
      manager.imageType.setSelected(type);
    },
    [brandKitStyle, manager.style, manager.imageType],
  );

  const handleSelectCustom = useCallback(
    (type: "square" | "banner", template_code: string | null) => () => {
      if (!template_code) return;
      manager.style.setSelected({ type: "custom", value: template_code });
      manager.imageType.setSelected(type);
    },
    [manager.imageType, manager.style],
  );

  if (loading) return null;

  return (
    <Stack direction="row" gap={2}>
      {error || (isEmpty(squares) && isEmpty(banners)) ? (
        <>
          <ImageShapeSelect onChange={manager.imageType.setSelected} />
          <VisualStyleSelect onChange={manager.style.setSelected} />
        </>
      ) : (
        <>
          {brandKitStyle.type === "standard" && (
            <VisualStyleSelect onChange={manager.style.setSelected} />
          )}
          <FormControl>
            <TextField
              id="custom-style-select"
              label={t("Image shape")}
              onClick={popover.handleOpen}
              inputRef={popover.anchorRef}
              value={selectedTemplateName}
              slotProps={{
                input: {
                  sx: {
                    textOverflow: "ellipsis",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <ChevronDown />
                    </InputAdornment>
                  ),
                },
              }}
            />
          </FormControl>
          <Popover
            open={popover.open}
            onClose={popover.handleClose}
            anchorEl={popover.anchorRef.current}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Box
              sx={{
                bgcolor: "background.paper",
              }}
              maxHeight={400}
            >
              <TabContext value={selectedTab}>
                <Box
                  p={1}
                  onClick={(e) => {
                    e.stopPropagation();
                    return false;
                  }}
                >
                  <TabList
                    sx={{ paddingLeft: 1, border: "none" }}
                    onChange={(_, val: "custom" | "standard") => setSelectedTab(val)}
                  >
                    <Tab label="Custom" value="custom" />
                    <Tab label="Standard" value="standard" />
                  </TabList>
                </Box>
                <TabPanel value="custom" sx={{ px: 0, py: 1, minWidth: "300px" }}>
                  <MenuList>
                    {!isEmpty(squares) && (
                      <MenuItem>
                        <Typography
                          variant="caption"
                          color="text.secondary"
                          textTransform="uppercase"
                        >
                          {t("Squares")}
                        </Typography>
                      </MenuItem>
                    )}
                    {squares?.map(({ name, template: { template_code } }) => (
                      <MenuItem
                        key={template_code}
                        onClick={handleSelectCustom("square", template_code)}
                        value={template_code!}
                        role="option"
                        selected={
                          brandKitStyle.type === "custom" && brandKitStyle.value === template_code
                        }
                      >
                        {name}
                      </MenuItem>
                    ))}
                    {!isEmpty(banners) && (
                      <MenuItem>
                        <Typography
                          variant="caption"
                          color="text.secondary"
                          textTransform="uppercase"
                        >
                          {t("Banners")}
                        </Typography>
                      </MenuItem>
                    )}

                    {banners?.map(({ name, template: { template_code } }) => (
                      <MenuItem
                        key={template_code}
                        value={template_code!}
                        role="option"
                        onClick={handleSelectCustom("banner", template_code)}
                        selected={
                          brandKitStyle.type === "custom" && brandKitStyle.value === template_code!
                        }
                      >
                        {name}
                      </MenuItem>
                    ))}
                  </MenuList>
                </TabPanel>
                <TabPanel value="standard" sx={{ px: 0, py: 1, minWidth: "300px" }}>
                  <MenuItem
                    value="square"
                    onClick={handleSelectStandard("square")}
                    role="option"
                    selected={brandKitStyle.type === "standard" && imageType === "square"}
                  >
                    {t("Square")}
                  </MenuItem>
                  <MenuItem
                    value="banner"
                    onClick={handleSelectStandard("banner")}
                    role="option"
                    selected={brandKitStyle.type === "standard" && imageType === "banner"}
                  >
                    {t("Banner")}
                  </MenuItem>
                </TabPanel>
              </TabContext>
            </Box>
          </Popover>
        </>
      )}
    </Stack>
  );
};
