import { format, isValid } from "date-fns";

enum ISO8601_ {
  _ = "",
}
export type ISO8601 = string & ISO8601_;
enum ISO8601_TIME_ {
  _ = "",
}
export type ISO8601_TIME = string & ISO8601_TIME_;
enum ISO8601_DATE_ {
  _ = "",
}
export type ISO8601_DATE = string & ISO8601_DATE_;
enum INTERVAL_ {
  _ = "",
}
export type INTERVAL = string & INTERVAL_;

export function toISO8601(date: Date): ISO8601 {
  return date.toISOString() as ISO8601;
}

export function fromISO8601(date: ISO8601): Date {
  return new Date(date);
}

export function toISO8601_DATE(date: Date | null): ISO8601_DATE | null;
export function toISO8601_DATE(date: null): null;
export function toISO8601_DATE(date: Date): ISO8601_DATE;

export function toISO8601_DATE(date: Date | null): ISO8601_DATE | null {
  // Note intentionally ignores TZ.
  // This simplifies MUI' datepicker and other JS libraries truncating dates to midnight
  // then giving different dates as the TZ moves midnight into the day before.
  return date && isValid(date)
    ? (`${date.getFullYear().toString().padStart(4, "0")}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}` as ISO8601_DATE)
    : null;
}

export function fromISO8601_DATE(date: ISO8601_DATE): Date;
export function fromISO8601_DATE(date: null): null;

export function fromISO8601_DATE(date: ISO8601_DATE | null): Date | null {
  // This creates the Date in the local TZ (even though that is meaningless for dates/JS is just broken.).
  return date === null ? null : new Date(`${date}T00:00:00`);
}

export function formatDatetime(
  date: Date,
  includeTimezone?: string | null,
  overrides?: Intl.DateTimeFormatOptions,
): string {
  return (
    date
      .toLocaleString(undefined, {
        weekday: "short",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        hourCycle: "h12",
        minute: "numeric",
        timeZone: includeTimezone ?? undefined,
        ...(overrides ?? {}),
      })
      .replace(" at ", " ") + (includeTimezone ? ` (${includeTimezone})` : "")
  );
}

export function formatDatetimeShort(date: Date, includeTimezone?: string): string {
  return (
    date
      .toLocaleString(undefined, {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        hourCycle: "h12",
        minute: "numeric",
        timeZone: includeTimezone ?? undefined,
      })
      .replace(" at ", " ") + (includeTimezone ? ` (${includeTimezone})` : "")
  );
}

export function formatDate(
  date: Date,
  includeTimezone?: string,
  overrides?: Intl.DateTimeFormatOptions,
): string {
  return date.toLocaleString(undefined, {
    weekday: "short",
    year: "numeric",
    month: "long",
    day: "numeric",
    timeZone: includeTimezone ?? undefined,
    ...(overrides ?? {}),
  });
}

const dayOfMonthWithOrdinal = (d: ISO8601_DATE): string => {
  return format(fromISO8601_DATE(d), "do");
};

const valid = (d: ISO8601_DATE): boolean => {
  const date = fromISO8601_DATE(d);
  return date && !isNaN(date.valueOf());
};

export function formatWeeklyRepeat(date: ISO8601_DATE | null): string {
  if (date === null || !valid(date)) return "Weekly";

  const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const day = days[fromISO8601_DATE(date).getDay()];
  return `Weekly on ${day}`;
}

export function formatMonthlyRepeat(date: ISO8601_DATE | null): string {
  if (date === null || !valid(date)) return "Monthly";

  const dayOfMonth = fromISO8601_DATE(date).getDate();
  if (dayOfMonth === 31) return "Monthly on last day of each month";

  return `Monthly on ${dayOfMonthWithOrdinal(date)} of each month${
    dayOfMonth > 28 ? " (and last day in February)" : ""
  }`;
}

export function formatAnnuallyRepeat(date: ISO8601_DATE | null): string {
  if (date === null || !valid(date)) return "Annually";

  const month = fromISO8601_DATE(date).toLocaleDateString(undefined, {
    month: "long",
  });
  return `Annually on ${dayOfMonthWithOrdinal(date)} ${month}`;
}

export function sortByISO8601(
  a: ISO8601 | null | undefined,
  b: ISO8601 | null | undefined,
): number {
  const aTime = a ? fromISO8601(a).getTime() : new Date().getTime();
  const bTime = b ? fromISO8601(b).getTime() : new Date().getTime();
  return aTime - bTime;
}

export const DEFAULT_ISO8601 = "20220101T00:00:00" as ISO8601;
