import { Alert, AlertTitle, Box, LinearProgress } from "@mui/material";
import { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { EventKey, SupabaseEventService } from "../../modules/event/server/supabase-event-service";
import { Page } from "../../modules/layout/components/page";
import { PageContent } from "../../modules/layout/components/page-content";
import { PageHeader } from "../../modules/layout/components/page-header";
import { PageHeading } from "../../modules/layout/components/page-heading";
import { MomentCardMoment } from "../../modules/moment-card/types";
import { FilterMoments } from "../../modules/moment-filter/components/filter-moments";
import { MomentFilter } from "../../modules/moment-filter/components/moment-filter";
import { SupabaseMomentService } from "../../modules/moment/server/supabase-moment-service";
import { TimelineEngine } from "../../modules/timeline/components/timeline-engine";
import { noNullFields } from "../../server/supabase/base-supabase-service";
import { useSupabase } from "../../server/supabase/hooks";
import { SelectEvent } from "./select-event";

const TimelinePage: FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const [moments, setMoments] = useState<MomentCardMoment[]>();
  const [error, setError] = useState<boolean>(false);
  const [eventKeys, setEventKeys] = useState<EventKey[]>([]);

  // load moments associated with event key
  useSupabase(
    async ({ supabase, account_id }) => {
      const { data, error: supabaseError } = await new SupabaseMomentService(
        supabase,
      ).getEventMomentCardMoments(account_id, id as string);
      if (data) setMoments(data);
      setError(!!supabaseError);
    },
    [id],
  );

  useSupabase(async ({ supabase }) => {
    const { data } = await new SupabaseEventService(supabase).getKeys({ includeLibrary: false });
    if (data && !noNullFields(data)) throw new Error("Bad data");
    if (data) setEventKeys(data.filter((e) => !e.calendar || e.has_schedule_event));
  }, []);

  const handleChange = useCallback(
    (key: string) => {
      setMoments([]);
      navigate(`/timeline/${key}`);
    },
    [navigate],
  );

  return (
    <Page title="Timeline | ChangeEngine">
      <PageHeader>
        <PageHeading
          heading={t("Timeline")}
          subheading={t("All the Moments connected to a specific Event")}
        ></PageHeading>
      </PageHeader>
      <PageContent>
        <Box display="flex" mt={2} gap={2}>
          <Box flexBasis={300}>
            {moments && (
              <SelectEvent id={id} eventKeys={eventKeys} onChange={handleChange} label="Event" />
            )}
          </Box>
          <MomentFilter buttonProps={{ sx: { p: 2 } }} />
        </Box>
        {error && (
          <Alert severity="error">
            <AlertTitle>{t("Something went wrong")}</AlertTitle>
          </Alert>
        )}
        {moments === undefined ? (
          <LinearProgress />
        ) : (
          <FilterMoments moments={moments}>
            {(filtered) => <TimelineEngine moments={filtered} />}
          </FilterMoments>
        )}
      </PageContent>
    </Page>
  );
};

export default TimelinePage;
