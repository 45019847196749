import { UUID } from "./utils/uuid";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getConfig = (env: { [key: string]: string | undefined }) => ({
  sentry: {
    dsn: env.VITE_SENTRY_DSN,
    environment: env.VITE_SENTRY_ENVIRONMENT,
  },
  defaultEmailFrom: "moments@changeengine.com",
  auth0: {
    base_url: env.VITE_AUTH0_BASE_URL,
    issuer_base_domain: env.VITE_AUTH0_ISSUER_BASE_DOMAIN ?? "fake",
    client_id: env.VITE_AUTH0_CLIENT_ID ?? "abc",
    audience: env.VITE_AUTH0_AUDIENCE ?? "https://*-change-engine.vercel.app",
  },
  slack: {
    defaultBotTitle: "Company Slack Bot - Title set in your Slack Org Settings",
    defaultBotMomentPageTitle: "Company Slack Bot",
    defaultSlackAliasId: "00000000-0000-0000-0000-000000000000" as UUID,
    maxBlockLength: 3001,
    maxButtonTextLength: 75,
    bot_scopes_v2: [
      "channels:read",
      "channels:join",
      "channels:manage",
      "im:read",
      "im:write",
      "mpim:read",
      "mpim:write",
      "groups:read",
      "groups:write",
      "chat:write",
      "chat:write.customize",
      "chat:write.public",
      "users:read",
      "users:read.email",
      "users.profile:read",
      "usergroups:read",
      "emoji:read",
      "reactions:read",
    ],
    user_scopes_v2: ["reactions:read"],
  },
  unsplash: {
    accessKey: "L3rzW57AVgckEzpxu9rUvFhWZ1G3_5a6cbinh025n_E",
  },
  optimizely: {
    logLevel: "ERROR",
    sdkKey: env.VITE_OPTIMIZELY_API_KEY ?? "fakefake",
    odpOptions: {
      disabled: true,
    },
  },
  mui_x_pro: {
    licence:
      "a8f38d7e59bef80f14fbf3da46d12af2Tz05Mzg0NSxFPTE3NTIwNTY5OTMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",
  },
  channel: {
    work_email: "235c5762-4293-4a0b-b318-228df765f4be" as UUID,
    personal_email: "235c5762-4293-4a0b-b318-228df765f4be" as UUID,
    slack: "3ea8755d-6155-488b-b244-46791cdbf6f4" as UUID,
    teams: "85ef7d85-b3f8-4788-883e-efcd5034b484" as UUID,
    twilio: "26b2bd00-0613-47d5-ac19-fb9fc30dfec1" as UUID,
  },
  design_huddle: {
    client_id: "b75zb8b0xn8hy0pyovy5",
    domain: "changeengine.designhuddle.com",
    base_template_name: "CE Base Template",
  },
  db_reverse: {
    "data.changeengine.com": "prod-us-ruby",
    "ieiovpwdgwnwxxymgdif.supabase.co": "prod-us-topaz",
    "kapfmcubwfddqezzscqr.supabase.co": "prod-us-ruby",
    "kxcdqzvytayvxbemezmi.supabase.co": "prod-us-pyrite",
    "dtxyivotwcjcwcuqoywr.supabase.co": "staging-us-moissanite",
  } as Record<string, string>,
  showErrors: env.SHOW_ERRORS === undefined,
  Dev: env.DEV,
});

export const config = getConfig(import.meta.env ?? {});
