import { ImageOutlined } from "@mui/icons-material";
import { Box, BoxProps } from "@mui/material";
import { FC, memo } from "react";
import { useTemplateImageDimensions } from "../template-preview/template-image";

interface PreviewImageProps {
  url: string;
  loading: boolean;
}
export const AiRefineImagePreview: FC<PreviewImageProps> = ({ url, loading }) => {
  const dimensions = useTemplateImageDimensions();

  return (
    <Box
      mb={2}
      sx={{
        "--width": "320px",
        position: "relative",
        ...dimensions,
      }}
    >
      {loading && (
        <Loading
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        />
      )}
      {!loading && (
        <Box sx={dimensions}>
          <img data-testid="preview-image" src={url} alt={""} width="100%" height="100%" />
        </Box>
      )}
    </Box>
  );
};

const Loading = memo(function Loading(props: BoxProps) {
  return (
    <Box {...props}>
      <Box
        sx={(theme) => ({
          width: "100%",
          height: "100%",
          bgcolor: theme.palette.neutral[200],
          "@keyframes pulse": {
            "0%": {
              opacity: 0,
            },
            "50%": {
              opacity: 1,
            },
            "100%": {
              opacity: 0,
            },
          },
          animation: "pulse 2s infinite ease",
        })}
      >
        <ImageOutlined
          sx={{
            position: "absolute",
            fontSize: "calc(var(--width) / 2)",
            top: "calc(var(--width) / 4)",
            left: "calc(var(--width) / 4)",
            color: "#00000033",
          }}
        />
      </Box>
    </Box>
  );
});
