import { ContentCopy } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { UUID } from "../../../../utils/uuid";
import { useCopyDialogs } from "./use-copy-dialogs";

export const CopyAction: FC<{
  id: UUID;
  title: string | null;
  clonable: boolean | "warn_first";
  disabled?: { reason: string };
}> = ({ id, title, clonable, disabled }) => {
  const { t } = useTranslation();

  const { handleCopy, dialogControl, CopyDialogComponent } = useCopyDialogs({
    clonable,
    id,
  });

  return (
    <>
      <Tooltip title={disabled ? disabled.reason : t("Copy Moment: {{title}}", { title })}>
        <span>
          <IconButton
            data-analytics-id="moment-card-copy"
            onClick={handleCopy}
            disabled={!!disabled}
          >
            <ContentCopy fontSize="small" sx={{ marginLeft: 0 }} data-testid="moment-copy-icon" />
          </IconButton>
        </span>
      </Tooltip>
      {CopyDialogComponent && (
        <CopyDialogComponent
          id={id}
          title={title}
          open={dialogControl.open}
          onClose={dialogControl.handleClose}
        />
      )}
    </>
  );
};
