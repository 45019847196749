import { ColorizeRounded } from "@mui/icons-material";
import {
  Box,
  BoxProps,
  Button,
  OutlinedInput,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { HexColorPicker } from "react-colorful";
import { useTranslation } from "react-i18next";
import useEyeDropper from "use-eye-dropper";
import * as yup from "yup";
import { usePopover } from "../../../utils/hooks/use-popover";
import { useBrandKit } from "../../brand-kit/hooks/use-brand-kit";
import { BrandKitColor } from "../../brand-kit/server/brand-kit-service";
export const ColourPicker: FC<{
  popover: ReturnType<typeof usePopover<HTMLElement>>;
  onInput?: (color: string) => void;
  onChange?: (color: string) => void;
  colour?: string;
  schema?: yup.StringSchema;
}> = ({ popover, onInput, onChange, colour, schema }) => {
  const [inputValue, setInputValue] = useState<string | undefined>(colour);

  const initialColour = useRef(colour);

  const baseSchema = yup.string().matches(/^#([0-9a-f]{3}|[0-9a-f]{6}|transparent)$/i);
  const hexSchema = schema ? schema.concat(baseSchema) : baseSchema;

  useEffect(() => {
    setInputValue(colour && (colour === "transparent" ? "transparent" : colour.slice(1)));
  }, [colour]);

  const handleClose = useCallback(() => {
    if (initialColour.current) {
      onInput?.(initialColour.current);
    }
    popover.handleClose();
  }, [onInput, popover]);

  const handleConfirm = useCallback(() => {
    initialColour.current = inputValue === "transparent" ? inputValue : "#" + inputValue;
    onChange?.(initialColour.current);
    popover.handleClose();
  }, [inputValue, onChange, popover]);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      if (newValue.match(/^([0-9a-f]{0,6}|(t(r(a(n(s(p(a(r(e(n(t)?)?)?)?)?)?)?)?)?)?))$/i))
        setInputValue(newValue);
      if (hexSchema.isValidSync("#" + newValue)) {
        onInput?.("#" + newValue);
      }
    },
    [hexSchema, onInput],
  );

  const handleChange = useCallback(
    (color: string) => {
      setInputValue(color.slice(1));
      onInput?.(color);
    },
    [onInput],
  );

  const handleBrandColorClick = useCallback(
    (color: BrandKitColor) => {
      setInputValue(color.hex.slice(1));
      onInput?.(color.hex);
    },
    [onInput],
  );

  const { t } = useTranslation();

  return (
    <Popover
      anchorEl={popover.anchorRef.current}
      open={popover.open}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      sx={{ width: "600px" }}
    >
      <Box display="grid" gridTemplateColumns={"1fr 1fr"} gap={1} m={1}>
        <Box>
          <HexColorPicker color={inputValue} onChange={handleChange} />
        </Box>
        <Stack gap={3}>
          <Box display={"flex"} justifyContent={"center"} gap={1}>
            <EyeDropper onChange={handleChange} />
            <OutlinedInput
              inputProps={{ "aria-label": "hex" }}
              value={inputValue}
              startAdornment={
                inputValue?.match(/^(t(r(a(n(s(p(a(r(e(n(t)?)?)?)?)?)?)?)?)?)?)$/) ? null : "#"
              }
              onChange={handleInputChange}
              sx={{
                minWidth: "6em",
                ".MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "inherit !important",
                  borderWidth: "1px",
                },
              }}
              size="small"
              fullWidth
            />
          </Box>
          <BrandColors onClick={handleBrandColorClick} />
          <Box display={"flex"} gap={2} justifyContent={"space-between"}>
            <Button
              data-analytics-id="colour-picker-popover-cancel"
              color="error"
              variant="outlined"
              onClick={handleClose}
            >
              {t("Cancel")}
            </Button>
            <Button
              data-analytics-id="colour-picker-popover-ok"
              variant="outlined"
              onClick={handleConfirm}
            >
              {t("OK")}
            </Button>
          </Box>
        </Stack>
      </Box>
    </Popover>
  );
};

const EyeDropper: FC<{ onChange: (color: string) => void }> = ({ onChange }) => {
  const { open, isSupported } = useEyeDropper();

  const pickColor = useCallback(() => {
    // Using async/await (can be used as a promise as-well)
    const openPicker = async (): Promise<void> => {
      const pickedColor = await open();
      onChange(pickedColor.sRGBHex);
    };
    void openPicker();
  }, [onChange, open]);

  return (
    <>
      {isSupported() && (
        <Button
          data-analytics-id="colour-picker-popover-eye-dropper"
          onClick={pickColor}
          color="primary"
          variant="outlined"
        >
          <ColorizeRounded />
        </Button>
      )}
    </>
  );
};

const BrandColors: FC<{ onClick: (color: BrandKitColor) => void } & Omit<BoxProps, "onClick">> = ({
  onClick,
  ...props
}) => {
  const { brandKit } = useBrandKit();
  const colors = brandKit?.choices?.colors;
  return colors ? (
    <Box
      sx={(theme) => ({
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: "5px",
        py: theme.spacing(2),
        px: theme.spacing(1),
        position: "relative",
      })}
    >
      <Typography variant="body2" mt={"-2em"} bgcolor="background.paper" position="absolute">
        Your brand
      </Typography>
      <Box display={"grid"} gap={1} gridTemplateColumns={"repeat(4, 1fr)"} {...props}>
        {colors.map(
          (color) =>
            color.hex && (
              <Tooltip key={color.hex} title={color.hex} placement="bottom" arrow>
                <Box
                  key={color.hex}
                  sx={(theme) => ({
                    width: "100%",
                    paddingTop: "100%",
                    borderRadius: "5px",
                    backgroundColor: color.hex,
                    cursor: "pointer",
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: theme.palette.grey[300],
                    "&:hover": {
                      borderColor: theme.palette.primary.main,
                    },
                  })}
                  onClick={() => onClick(color)}
                />
              </Tooltip>
            ),
        )}
      </Box>
    </Box>
  ) : null;
};
