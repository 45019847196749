import React from "react";
import { WorkEmailIcon } from "../../../styles/icons/email";
import { SlackIcon } from "../../../styles/icons/slack";
import { TeamsIcon } from "../../../styles/icons/teams";
import { ChannelName } from "../../channels/types";
import { useAccount } from "../../generic/hooks/use-account";

export function useAccountChannels():
  | {
      loading: true;
      channels: null;
    }
  | {
      loading: false;
      channels: {
        name: string;
        channel_name: ChannelName;
        icon: React.JSX.Element;
      }[];
    } {
  const { account, loading } = useAccount();

  if (loading) return { loading: true, channels: null };

  if (!account) return { loading: false, channels: [] };

  const channels = [
    ...(account.enable_slack
      ? [{ name: "Slack", channel_name: "slack" as const, icon: <SlackIcon /> }]
      : []),
    ...(account.enable_teams
      ? [{ name: "Teams", channel_name: "teams" as const, icon: <TeamsIcon /> }]
      : []),
    { name: "Email", channel_name: "work_email" as const, icon: <WorkEmailIcon /> },
  ];

  return { loading: false, channels };
}
