import { Alert, AlertTitle, LinearProgress } from "@mui/material";
import { FC, Suspense, lazy, useState } from "react";
import { useTranslation } from "react-i18next";
import { Page } from "../../modules/layout/components/page";
import { PageContent } from "../../modules/layout/components/page-content";
import { PageHeader } from "../../modules/layout/components/page-header";
import { PageHeading } from "../../modules/layout/components/page-heading";
import {
  Program,
  SupabaseProgramService,
} from "../../modules/programs/server/supabase-program-service";
import { useSupabase } from "../../server/supabase/hooks";

// Needs a HUGE amount of Icons
const ProgramsCrudDataGrid = lazy(
  () => import("../../modules/programs/components/programs-crud-data-grid"),
);

const ManageProgramsPage: FC = () => {
  const { t } = useTranslation();

  const [programs, setPrograms] = useState<Program[]>();
  const [hasError, setHasError] = useState<boolean>(false);

  useSupabase(async ({ supabase }) => {
    const { data, error } = await new SupabaseProgramService(supabase).getAll(null, {
      order: [{ column: "id" }],
    });
    if (!data || error) return setHasError(true);

    setPrograms(data);
  }, []);

  return (
    <Page title={t("Programs | ChangeEngine")}>
      <PageHeader>
        <PageHeading
          heading={t("Manage your Programs")}
          subheading={t("Add new Programs and manage the branding of your existing branding")}
        />
      </PageHeader>
      <PageContent>
        {hasError && (
          <Alert severity="error">
            <AlertTitle>{t("Something went wrong")}</AlertTitle>
          </Alert>
        )}
        <Suspense fallback={<LinearProgress />}>
          {programs === undefined ? (
            <LinearProgress />
          ) : (
            <ProgramsCrudDataGrid programs={programs}></ProgramsCrudDataGrid>
          )}
        </Suspense>
      </PageContent>
    </Page>
  );
};

export default ManageProgramsPage;
