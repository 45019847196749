import { Alert, Box, Link, TableCell, TableRow, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { PersonWithManager } from "../../employees/server/supabase-person-service";
import { PaginatedTable } from "../../generic/components/paginated-table/paginated-table";
import { StringAvatar } from "../../generic/components/string-avatar";
import { formatPersonWithManagerName } from "../utils";

const DEFAULT_PAGE_SIZE = 25;

export const PeopleList: FC<{ people: PersonWithManager[]; rowsPerPage?: number }> = ({
  people,
  rowsPerPage = DEFAULT_PAGE_SIZE,
}) => {
  const { t } = useTranslation();

  if (people.length === 0) return <Alert severity="info">{t("No people found")}</Alert>;

  return (
    <PaginatedTable
      data={people.map((p) => ({
        id: p.id,
        email: p.email,
        employee_id: p.employee_id,
        name: formatPersonWithManagerName(p),
      }))}
      config={{
        headers: [t("Name"), t("Employee ID"), t("Type")],
        defaultRowsPerPage: rowsPerPage,
        search: { keys: ["name", "employee_id", "email"], threshold: 0.0 },
        sort: {
          default: {
            column: "name",
            direction: "asc",
          },
          options: [
            { value: "name", label: t("Name") },
            { value: "employee_id", label: t("Employee ID") },
            { value: "email", label: t("Email") },
          ],
        },
      }}
      renderRow={(person) => (
        <TableRow>
          <TableCell>
            <Box sx={{ alignItems: "center", display: "flex" }}>
              <Link href={`/recipient-activity/${person.id}`}>
                <StringAvatar name={person.name} />
              </Link>
              <Box sx={{ ml: 1 }}>
                <Link
                  href={`/recipient-activity/${person.id}/overview`}
                  color="inherit"
                  variant="subtitle2"
                >
                  {person.name}
                </Link>
                <Typography color="textSecondary" variant="body2">
                  {person.email}
                </Typography>
              </Box>
            </Box>
          </TableCell>
          <TableCell>{person.employee_id}</TableCell>
          <TableCell>
            <Typography color="primary" variant="subtitle2">
              {t("Employee")}
            </Typography>
          </TableCell>
        </TableRow>
      )}
    />
  );
};
