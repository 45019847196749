import { useSetAtom } from "jotai";
import { FC, useEffect } from "react";
import { getTokenAtom } from "./store";
import { useToken } from "./use-token";

export const Token: FC = () => {
  const setGetToken = useSetAtom(getTokenAtom);
  const token = useToken();

  useEffect(() => {
    setGetToken({ getToken: token });
  }, [setGetToken, token]);

  return null;
};
