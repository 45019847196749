import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useAtomValue, useSetAtom } from "jotai";
import { FC, useCallback, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ContentTone } from "../../../modules/brand-kit/server/brand-kit-service";
import { BrandingBar } from "../../../modules/branding-bar/branding-bar";
import { GenerateMomentButton } from "../../../modules/generate/generate-moment-button";
import { useLoadGenerateMomentPage } from "../../../modules/generate/hooks/use-load-generate-moment-page";
import {
  accountIdAtom,
  generatedTemplateAtom,
  generatePageHeadingAtom,
  imageAtom,
} from "../../../modules/generate/store";
import { FixedFooter } from "../../../modules/generic/fixed-footer";
import { Page } from "../../../modules/layout/components/page";
import { PageContent } from "../../../modules/layout/components/page-content";
import { BannerTemplatePreview } from "../../../modules/template-preview/banner-template-preview";
import { useSupabase } from "../../../server/supabase/hooks";
import ThemeProvider from "../../../styles/theme/theme-provider";
import { UUID } from "../../../utils/uuid";

const GenerateMomentPage: FC<{ recipeId?: string }> = ({ recipeId }) => {
  useLoadGenerateMomentPage(recipeId);

  const navigate = useNavigate();

  const setAccountId = useSetAtom(accountIdAtom);
  useSupabase(
    // eslint-disable-next-line @typescript-eslint/require-await
    async ({ account_id }) => {
      setAccountId(account_id);
    },
    [setAccountId],
  );
  const templateLoader = useAtomValue(generatedTemplateAtom);

  const onImageSelect = useSetAtom(imageAtom);

  const [searchParams, setSearchParams] = useSearchParams();
  const [contentTone, setContentTone] = useState<ContentTone | "custom">("professional");

  const brandToneId = searchParams.get("brand_tone");

  const handleBrandToneChange = useCallback(
    (tone: UUID) => setSearchParams((prev) => ({ ...prev, brand_tone: tone })),
    [setSearchParams],
  );
  const { t } = useTranslation();

  if (templateLoader.state === "hasError") {
    console.error(templateLoader.error);
    toast.error((templateLoader.error as Error).toString());
    navigate("/create-moment");
    return null;
  }

  return (
    <ThemeProvider mode="light">
      <Header />
      <Page title={t("Generate Moment")}>
        <PageContent centerContent>
          <BannerTemplatePreview
            loadableTemplateAtom={generatedTemplateAtom}
            store="generate"
            contentTone={contentTone}
            brandTone={brandToneId as UUID}
            onBrandToneChange={handleBrandToneChange}
            onContentToneChange={setContentTone}
            onImageSelect={onImageSelect}
          />
        </PageContent>
        <FixedFooter
          sequenceBarWidth={0}
          sx={{
            "& .fixedFooter--content": {
              display: "flex",
              flexDirection: "row-reverse",
              p: 2,
            },
          }}
        >
          <GenerateMomentButton contentTone={contentTone} />
        </FixedFooter>
      </Page>
    </ThemeProvider>
  );
};

export default GenerateMomentPage;

const Header: FC = () => {
  const headingInfo = useAtomValue(generatePageHeadingAtom);
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="baseline"
      px={4}
      bgcolor="white"
    >
      <Typography
        noWrap
        sx={{ fontSize: "1.1rem", fontWeight: 500, pb: 2, color: "text.primary" }}
        flexGrow={1}
      >
        {headingInfo.heading}
      </Typography>
      <BrandingBar compact imageStyleSelector visualStyleSelector p={1} />
    </Stack>
  );
};
