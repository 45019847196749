import { PostgrestClient } from "@supabase/postgrest-js";
import { Database } from "../../../server/supabase/types/database-definitions";
import {
  UnlayerDesign,
  UnlayerDesignBlock,
} from "../../unlayer-email-content/types/unlayer-design";
import { generateUnlayerDesign } from "../utils";

export class SupabaseUnlayerDesignLoader {
  readonly supabase: PostgrestClient<Database>;
  constructor(supabase: PostgrestClient<Database>) {
    this.supabase = supabase;
  }

  async loadNew(): Promise<UnlayerDesign> {
    const { header, footer } = await this.getBlock();

    return this.generateDesign(header, footer, this.contentPlaceholderBlock());
  }

  async loadFromLibrary(
    moment: Database["public"]["Tables"]["email_moment"]["Row"],
  ): Promise<UnlayerDesign> {
    const design = moment.design;
    const { header, footer } = await this.getBlock();

    return this.generateDesign(header, footer, design?.body?.rows ?? []);
  }

  loadExisting(moment: {
    design: UnlayerDesign | undefined | null;
  }): UnlayerDesign | undefined | null {
    return moment.design;
  }

  async getBlock(): Promise<{
    header: UnlayerDesignBlock[];
    footer: UnlayerDesignBlock[];
  }> {
    const { data } = await this.supabase
      .from("account")
      .select("email_moment_header, email_moment_footer")
      .single();
    if (!data) {
      return { header: [], footer: [] };
    }
    return {
      header: data.email_moment_header ?? [],
      footer: data.email_moment_footer ?? [],
    };
  }

  generateDesign(
    header: UnlayerDesignBlock[],
    footer: UnlayerDesignBlock[],
    design: UnlayerDesignBlock[],
  ): UnlayerDesign {
    return generateUnlayerDesign(header, footer, design);
  }

  contentPlaceholderBlock(): UnlayerDesignBlock[] {
    return [
      {
        id: "WMcNiBSEJD",
        cells: [1],
        columns: [
          {
            id: "efCR0nXLxA",
            contents: [
              {
                id: "kFbIMaPP62",
                type: "heading",
                values: {
                  containerPadding: "9px 30px 40px 31px",
                  anchor: "",
                  headingType: "h1",
                  fontFamily: {
                    label: "Open Sans",
                    value: "'Open Sans',sans-serif",
                    url: "https://fonts.googleapis.com/css?family=Open+Sans:400,700",
                    defaultFont: true,
                  },
                  fontSize: "26px",
                  color: "#023047",
                  textAlign: "center",
                  lineHeight: "170%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_heading_6",
                    htmlClassNames: "u_content_heading",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: "<strong>Insert a title here</strong>",
                },
              },
              {
                id: "iQq3Ue2bj-",
                type: "text",
                values: {
                  containerPadding: "35px 55px 10px",
                  anchor: "",
                  color: "#333333",
                  textAlign: "left",
                  lineHeight: "180%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_text_5",
                    htmlClassNames: "u_content_text",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="font-size: 14px; line-height: 180%;"><strong><span style="font-family: Lato, sans-serif; font-size: 14px; line-height: 25.2px;">Hi [add merge tag here], </span></strong></p>\n<p style="font-size: 14px; line-height: 180%;">&nbsp;</p>\n<p style="font-size: 14px; line-height: 180%;">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.&nbsp;</p>\n<p style="font-size: 14px; line-height: 180%;">&nbsp;</p>\n<p style="font-size: 14px; line-height: 180%;"><strong><span style="font-family: Lato, sans-serif; font-size: 14px; line-height: 25.2px;">Voluptate velit esse cillum dolore eu fugiat nulla pariatur:</span></strong></p>\n<ul>\n<li style="font-size: 14px; line-height: 25.2px;"><strong><span style="font-family: Lato, sans-serif; font-size: 14px; line-height: 25.2px;">Lorem ipsum dolor sit amet, consectetur </span></strong></li>\n<li style="font-size: 14px; line-height: 25.2px;"><strong><span style="font-family: Lato, sans-serif; font-size: 14px; line-height: 25.2px;">Lorem ipsum dolor sit amet, consectetur adipiscing </span></strong></li>\n<li style="font-size: 14px; line-height: 25.2px;"><strong><span style="font-family: Lato, sans-serif; font-size: 14px; line-height: 25.2px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></strong></li>\n</ul>',
                },
              },
              {
                id: "gqmwrGu9he",
                type: "button",
                values: {
                  containerPadding: "20px 10px 30px",
                  anchor: "",
                  href: {
                    name: "web",
                    values: {
                      href: "https://www.google.com",
                      target: "_blank",
                    },
                    attrs: {
                      href: "{{href}}",
                      target: "{{target}}",
                    },
                  },
                  buttonColors: {
                    color: "#FFFFFF",
                    backgroundColor: "#33428d",
                    hoverColor: "#FFFFFF",
                    hoverBackgroundColor: "#3AAEE0",
                  },
                  size: {
                    autoWidth: true,
                    width: "100%",
                  },
                  textAlign: "center",
                  lineHeight: "120%",
                  padding: "20px 70px",
                  border: {},
                  borderRadius: "44px",
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_button_1",
                    htmlClassNames: "u_content_button",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: "<strong><span style=\"font-size: 14px; line-height: 16.8px; font-family: 'Open Sans', sans-serif;\">BUTTON TEXT</span></strong>",
                  calculatedWidth: 236,
                  calculatedHeight: 57,
                },
              },
              {
                id: "MdxL44VP8Q",
                type: "text",
                values: {
                  containerPadding: "10px 55px 40px",
                  anchor: "",
                  color: "#333333",
                  textAlign: "left",
                  lineHeight: "180%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_text_6",
                    htmlClassNames: "u_content_text",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="font-size: 14px; line-height: 180%;">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas.</p>\n<p style="font-size: 14px; line-height: 180%;">&nbsp;</p>\n<p style="font-size: 14px; line-height: 180%;">Molestias excepturi sint occaecati cupiditate non provident.</p>\n<p style="font-size: 14px; line-height: 180%;">&nbsp;</p>\n<p style="font-size: 14px; line-height: 180%;">With Regards,</p>\n<p style="font-size: 14px; line-height: 180%;"><strong>[your name]</strong></p>',
                },
              },
            ],
            values: {
              _meta: {
                htmlID: "u_column_12",
                htmlClassNames: "u_column",
              },
              backgroundColor: "#ffffff",
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: "",
          columnsBackgroundColor: "",
          backgroundImage: {
            url: "",
            fullWidth: true,
            repeat: false,
            center: true,
            cover: false,
          },
          padding: "0px",
          anchor: "",
          hideDesktop: false,
          _meta: {
            htmlID: "u_row_11",
            htmlClassNames: "u_row",
          },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
    ];
  }
}
