import { useAtomValue } from "jotai";
import { config } from "../../config";
import { useSupabaseCallback } from "../../server/supabase/hooks";
import { Json } from "../../server/supabase/types/database-definitions";
import { useAnalytics } from "../analytics/analytics";
import { brandingAtom } from "../brand-kit/store";
import { SupabaseCustomEmailService } from "../channels/email/server/supabase-custom-email-service";
import { isEmailChannel } from "../channels/types";
import { useCreateDesignHuddleProject } from "../design-huddle/use-design-huddle-project-create";
import { getPageNumber, imageURL } from "../design-huddle/utils";
import { toImportMoment } from "../discover/transform";
import { useEmailDesigns } from "../email-design/hooks/use-email-design";
import { sequenceSelectedTemplatesAtom } from "./store";

export function usePrepareTemplatesForImport({
  onChange,
}: {
  onChange?: (status: { step: number; total: number }) => void;
} = {}): () => Promise<Json[] | undefined> {
  const { gaEvent } = useAnalytics();
  const { data: emailDesigns } = useEmailDesigns();
  const createProject = useCreateDesignHuddleProject();
  const branding = useAtomValue(brandingAtom);
  const selectedTemplates = useAtomValue(sequenceSelectedTemplatesAtom);

  return useSupabaseCallback(
    async ({ account_id, supabase }) => {
      if (selectedTemplates.state !== "hasData") {
        return undefined;
      }

      const jsonTemplates: Json[] = [];
      let step = 1;

      const { data: account } = await supabase
        .from("account")
        .select("enable_teams")
        .eq("id", account_id)
        .single();

      for (const template of selectedTemplates.data) {
        if (!template || !template.image) continue;
        onChange?.({ total: selectedTemplates.data.length, step });

        gaEvent("discover_preview", {
          action: "use_template",
          value: template?.slug,
          method: "",
        });

        const projectData = template.dhProject
          ? {
              project_id: template.dhProject.project_id,
              preview_url: template.dhProject.thumbnail_url,
            }
          : await createProject(template.img?.template_code, branding, template.image);

        const projectId = projectData?.project_id ?? null;

        const emailDesignId = emailDesigns?.find((e) => e.is_default)?.id;

        const emailFrom = isEmailChannel(template.channel)
          ? (await new SupabaseCustomEmailService(supabase).getDefault()).data?.address ||
            config.defaultEmailFrom
          : undefined;

        const jsonTemplate = toImportMoment(
          template,
          template.branding.tone,
          emailDesignId,
          projectId
            ? {
                project_id: projectId,
                account_id,
                page_number: getPageNumber(branding),
              }
            : undefined,
          template.channel,
          projectId ? imageURL(account_id, projectId) : undefined,
          emailFrom,
          account?.enable_teams,
        );

        jsonTemplates.push(jsonTemplate);
        step++;
      }

      return jsonTemplates;
    },
    [branding, createProject, emailDesigns, gaEvent, onChange, selectedTemplates],
  );
}
