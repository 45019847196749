import { atom } from "jotai";
import { atomWithReset } from "jotai/utils";
import { UUID } from "../../../utils/uuid";
import { EventKey } from "../../event/server/supabase-event-service";
import { SegmentFormState } from "./moment-form-moment";

const segmentStateDataAtom = atom<SegmentFormState | null>(null);

export const segmentStateAtom = atom(
  (get) => {
    const data = get(segmentStateDataAtom);
    if (!data) return data;

    return {
      ...data,
      isEditable: (segmentId: UUID) => {
        return data?.editable.new === segmentId || data?.editable.existing === segmentId;
      },
    };
  },
  (_, set, segmentState: SegmentFormState) => set(segmentStateDataAtom, segmentState),
);

export const unsplashDownloadUrlAtom = atom<string | undefined>(undefined);

export const eventsAtom = atom<EventKey[]>([]);

export const segmentCountAtom = atomWithReset({
  employee: 0,
  recipient: 0,
  segmentRecipient: [] as {
    display: string;
    timezone: string;
  }[],
});

export const segmentCountSubformAtom = atomWithReset({
  employee: 0,
  recipient: 0,
  segmentRecipient: [] as {
    display: string;
    timezone: string;
  }[],
});
