/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { SupabaseService } from "../../../server/supabase/base-supabase-service";
import { Database } from "../../../server/supabase/types/database-definitions";
import { UUID } from "../../../utils/uuid";

export type SequenceItem = Database["public"]["Tables"]["sequence_item"]["Row"];

export class SupabaseSequenceItemService extends SupabaseService<"sequence_item"> {
  table = "sequence_item" as const;

  async getForMoment(moment_id: UUID) {
    return this.log_errors(
      await this.client.from(this.table).select("*").eq("moment_id", moment_id).maybeSingle(),
    );
  }
}
