export const downloadFile = async ({
  url,
  filename,
}: {
  url: string;
  filename: string;
}): Promise<void> => {
  // Create a new anchor element
  const file = await fetch(url);

  const blob = await file.blob();
  const dowloadUrl = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = dowloadUrl;
  a.download = filename || "download";

  const clickHandler = (): void => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      removeEventListener("click", clickHandler);
    }, 150);
  };

  a.addEventListener("click", clickHandler, false);

  a.click();
};
