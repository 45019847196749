import { marked } from "marked";
import { FC } from "react";

export const Markdown: FC<{ content: string }> = ({ content }) => {
  return (
    <span
      dangerouslySetInnerHTML={{
        // nosemgrep: typescript.react.security.audit.react-dangerouslysetinnerhtml.react-dangerouslysetinnerhtml
        __html: marked(content) as string,
      }}
    />
  );
};
