import { atom } from "jotai";
import { atomWithDefault } from "jotai/utils";
import { BrandKitImageType } from "./server/brand-kit-service";
import { BrandInfo, BrandInfoSelected } from "./types";

export const defaultBrandingAtom = atom<BrandInfoSelected | null>(null);

export const brandingAtom = atomWithDefault<BrandInfoSelected>((get) => {
  const defaultBranding = get(defaultBrandingAtom);
  return (
    defaultBranding ?? {
      branding_type: { type: "standard", value: "bold" },
      default_tone: "professional",
    }
  );
});

export const brandingImageTypeAtom = atom<BrandKitImageType>("square");

export const brandInfoAtom = atom<BrandInfo | null>(null);
