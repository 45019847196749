import { Box } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { GenerateMomentCard } from "../../modules/generate/generate-moment-card";
import { Page } from "../../modules/layout/components/page";
import { PageContent } from "../../modules/layout/components/page-content";
import { PageHeader } from "../../modules/layout/components/page-header";
import { PageHeading } from "../../modules/layout/components/page-heading";
import { useFlag } from "../../server/optimizely";
import { CreateFromScratchCard } from "./cards/create-from-scratch-card";
import { DiscoverCard } from "./cards/discover-card";
import { NewsletterCard } from "./cards/newsletter-card";
import { RecipesCard } from "./cards/recipes-card";

const MomentOptionsPage: FC = () => {
  const { t } = useTranslation();

  const [ai_recipes] = useFlag("ai_recipes");

  return (
    <Page title={t("Create Moment | ChangeEngine")}>
      <PageHeader>
        <PageHeading
          heading={t("Create Moment")}
          subheading={t("How do you want to create your moment?")}
        />
      </PageHeader>

      <PageContent maxWidth={"md"}>
        {/* grid with two rows, first row 2 items, second row 1 item */}
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(3, minmax(300px, 1fr))",
            gap: 4,
            mt: 4,
          }}
        >
          {/* AI */}
          <GenerateMomentCard />
          {/* Recipes */}
          {ai_recipes ? <RecipesCard /> : null}
          <CreateFromScratchCard />
          <DiscoverCard />
          <NewsletterCard />
        </Box>
      </PageContent>
    </Page>
  );
};

export default MomentOptionsPage;
