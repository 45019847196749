import { Box, CssBaseline } from "@mui/material";
import { BoxProps } from "@mui/system";
import { useSetAtom } from "jotai";
import { FC, useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { Outlet, useLocation } from "react-router-dom";
import ThemeProvider from "../../../styles/theme/theme-provider";
import { DisplayTheme, SettingsConsumer } from "../../../utils/contexts/settings-context";
import { ApplicationHeader } from "./application-header";
import { Sidebar } from "./sidebar/sidebar";
import { sidebarOpenAtom } from "./sidebar/store";
import { layoutAtom } from "./store";

export const ApplicationLayout: FC<{
  themeOverride?: DisplayTheme;
  layout?: "sequence" | "default";
  slots?: {
    root?: {
      props?: BoxProps;
    };
    header?: {
      props?: BoxProps;
    };
  };
}> = ({ themeOverride, slots, layout = "default" }) => {
  const setLayout = useSetAtom(layoutAtom);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    setLayout(layout);
    return () => {
      document.body.style.overflow = "auto";
      setLayout("default");
    };
  }, [layout, setLayout]);

  const { pathname } = useLocation();
  const setSidebarOpen = useSetAtom(sidebarOpenAtom);

  useEffect(() => {
    setSidebarOpen(false);
  }, [setSidebarOpen, pathname]);

  return (
    <SettingsConsumer>
      {({ settings }) => (
        <ThemeProvider mode={themeOverride ?? settings.theme}>
          <CssBaseline />
          <Toaster />
          <Sidebar variant="temporary" />
          <AppRoot {...slots?.root?.props}>
            <ApplicationHeader {...slots?.header?.props} />
            <Outlet />
          </AppRoot>
        </ThemeProvider>
      )}
    </SettingsConsumer>
  );
};

const AppRoot: FC<BoxProps> = ({ children, sx = [], ...props }) => (
  <Box
    className="App-root"
    sx={[
      {
        "--sb-thumb-width": "80px",
        bgcolor: "background.paper",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      },
      ...(sx instanceof Array ? sx : [sx]),
    ]}
    {...props}
  >
    {children}
  </Box>
);
