import { FC, useState } from "react";
import { useSupabase } from "../../server/supabase/hooks";
import { config } from "../../config";

const CeAdminPageHeader: FC = () => {
  const [host, setHost] = useState<string>("loading");

  useSupabase<"ce_admin">(
    // eslint-disable-next-line @typescript-eslint/require-await
    async ({ supabase }) => {
      if (!supabase) return;
      setHost(config.db_reverse[new URL(supabase.url).hostname]);
    },
    [],
    "ce_admin",
  );

  return `CE Admin - ${host}`;
};

export default CeAdminPageHeader;
