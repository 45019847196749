import {
  CODE,
  ElementTransformer,
  TRANSFORMERS as LEXICAL_TRANSFORMERS,
  MultilineElementTransformer,
  ORDERED_LIST,
  QUOTE,
  TEXT_FORMAT_TRANSFORMERS,
  TEXT_MATCH_TRANSFORMERS,
  TextMatchTransformer,
  Transformer,
  UNORDERED_LIST,
} from "@lexical/markdown";
import { $isTextNode, TextNode } from "lexical";
import {
  $createMergeTagV2Node,
  $isMergeTagV2Node,
  MergeTagV2Node,
} from "../message/editor/nodes/merge-tag-node-v2";

const CHAT_HEADING: TextMatchTransformer = {
  dependencies: [TextNode],
  export: (node) => {
    if (!$isTextNode(node)) {
      return null;
    }

    return `${node.hasFormat("bold") ? "### " : ""}${node.getTextContent()}`;
  },
  importRegExp: /^#{1,6}\s(.*)/,
  regExp: /^#{1,6}\s(.*)/,
  replace: (node, match) => {
    const [, text] = match;
    node.setFormat("bold");
    node.setTextContent(text);
    return node;
  },
  trigger: "#",
  type: "text-match",
};

export const MERGE_TAG: TextMatchTransformer = {
  dependencies: [MergeTagV2Node],
  export: (node) => {
    if (!$isMergeTagV2Node(node)) {
      return null;
    }

    return `{{${node.getTextContent()}}}`;
  },
  importRegExp: /{{([^}]+)}}/,
  regExp: /{{([^}]+)}}$/,
  replace: (node, match) => {
    const [, text] = match;
    const mergeTagNode = $createMergeTagV2Node(text);
    if (mergeTagNode) {
      node.replace(mergeTagNode);
    } else {
      node.remove();
    }
  },
  trigger: "}",
  type: "text-match",
};

const CHAT_ELEMENT_TRANSFORMERS: Array<ElementTransformer | MultilineElementTransformer> = [
  // Same as ELEMENT_TRANSFORMERS from Lexical but without HEADING
  // https://github.com/facebook/lexical/blob/ed8f85fb5e5106dba237e2a166e57acaa28e8fbe/packages/lexical-markdown/src/index.ts#L40-L46
  QUOTE,
  CODE,
  UNORDERED_LIST,
  ORDERED_LIST,
];

export const TRANSFORMERS = (format: "email" | "chat" | "sms"): Array<Transformer> => {
  switch (format) {
    case "email":
    case "sms":
      return LEXICAL_TRANSFORMERS;
    case "chat":
      return [
        ...CHAT_ELEMENT_TRANSFORMERS,
        ...TEXT_FORMAT_TRANSFORMERS,
        ...TEXT_MATCH_TRANSFORMERS,
        CHAT_HEADING,
      ];
  }
};
