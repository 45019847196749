import { atomFamily, atomWithDefault, loadable } from "jotai/utils";
import { Loadable } from "jotai/vanilla/utils/loadable";
import { isEqual } from "lodash-es";
import { Orientation } from "unsplash-js";
import { CustomTemplates } from "../../../pages/create-content/use-custom-templates";
import { toSlug } from "../../../utils/create-slug";
import { dhTemplateConfigFamily } from "../../content-previews/content-previews";
import { debugLabel } from "../../generate/store";
import { multiformatAtom } from "./multiformat-atom";
import { previewFamily } from "./preview-family";
import { previewUrlFamily } from "./preview-url-family";
import { Layout } from "./types";

type TemplatePreview = {
  preview: Loadable<
    Promise<{
      width?: number;
      height?: number;
      template_code: string;
      customizations: {
        [key: string]: unknown;
      };
      page_number: number;
      title?: string;
    } | null>
  >;
  url: Loadable<Promise<string | null>>;
};

const unsplashResult = (layout: Layout | undefined): number =>
  layout && layout["Unsplash result"] ? layout["Unsplash result"] - 1 : 0;
const orientation = (layout: Layout | undefined): Orientation => {
  return layout &&
    layout.Orientation &&
    ["landscape", "portrait", "squarish"].includes(layout.Orientation.toLowerCase())
    ? (layout.Orientation.toLowerCase() as Orientation)
    : "landscape";
};

export const previewsFamily = atomFamily(
  ({
    contentType,
    answers,
    customTemplates,
    useSelectedLogo,
  }: {
    contentType: string;
    answers: { answer: string }[];
    customTemplates: CustomTemplates | null;
    useSelectedLogo: boolean;
  }) =>
    debugLabel(
      `previewsFamily(${contentType}, ${answers[0].answer})`,
      atomWithDefault((get) => {
        const templateConfig = get(dhTemplateConfigFamily(contentType));
        const layouts = get(multiformatAtom)?.breakdown[contentType];
        const formatId = toSlug(contentType).replace(/-/g, "_");

        if (!templateConfig || !layouts) return;

        let customLayouts: TemplatePreview[] = [];
        if (
          customTemplates &&
          customTemplates[contentType] &&
          customTemplates[contentType].length > 0
        ) {
          customLayouts = customTemplates[contentType].flatMap((customTemplate) =>
            Array.from({ length: Math.min(customTemplate.page_count, layouts.length) }, (_, i) => {
              return {
                preview: get(
                  loadable(
                    previewFamily({
                      formatId,
                      answers,
                      imgNum: layouts[i]["DH Image Number"]?.toString(),
                      templateConfig: {
                        ...templateConfig,
                        templateCode: customTemplate.template.template_code ?? "",
                      },
                      imageType: layouts[i]["image-type"] ?? "Photo",
                      unsplashResult: unsplashResult(layouts[i]),
                      orientation: orientation(layouts[i]),
                      useSelectedLogo,
                    }),
                  ),
                ),
                url: get(
                  loadable(
                    previewUrlFamily({
                      formatId,
                      answers,
                      imgNum: layouts[i]["DH Image Number"]?.toString(),
                      templateConfig: {
                        ...templateConfig,
                        templateCode: customTemplate.template.template_code ?? "",
                      },
                      imageType: layouts[i]["image-type"] ?? "Photo",
                      unsplashResult: unsplashResult(layouts[i]),
                      orientation: orientation(layouts[i]),
                      useSelectedLogo,
                    }),
                  ),
                ),
              };
            }),
          );
        }

        return [
          ...customLayouts,
          ...layouts.map((layout) => ({
            preview: get(
              loadable(
                previewFamily({
                  formatId,
                  answers,
                  imgNum: layout["DH Image Number"]?.toString(),
                  templateConfig,
                  imageType: layout["image-type"] ?? "Photo",
                  unsplashResult: unsplashResult(layout),
                  orientation: orientation(layout),
                  useSelectedLogo,
                }),
              ),
            ),
            url: get(
              loadable(
                previewUrlFamily({
                  formatId,
                  answers,
                  imgNum: layout["DH Image Number"]?.toString(),
                  templateConfig,
                  imageType: layout["image-type"] ?? "Photo",
                  unsplashResult: unsplashResult(layout),
                  orientation: orientation(layout),
                  useSelectedLogo,
                }),
              ),
            ),
          })),
        ];
      }),
    ),
  isEqual,
);
