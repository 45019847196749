import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import { HeadingNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { TextNode } from "lexical";
import { ButtonListNode } from "../nodes/button-list-node";
import { DesignHuddleNode } from "../nodes/design-huddle-node";
import { DividerNode } from "../nodes/divider-node";
import { EmojiNode } from "../nodes/emoji-node";
import { ImageNode } from "../nodes/image-node";
import { MergeTagV2Node } from "../nodes/merge-tag-node-v2";
import { PaperColorNode } from "../nodes/paper-color-node";
import { StylableButtonNode } from "../nodes/stylable-button-node";

// Remember to update EDITOR_NODES
export const EDITOR_NODES_HEADLESS = {
  sms: [EmojiNode, AutoLinkNode, MergeTagV2Node],
  chat: [
    ListNode,
    ListItemNode,
    LinkNode,
    AutoLinkNode,
    TextNode,
    EmojiNode,
    DividerNode,
    ButtonListNode,
    ImageNode,
    DesignHuddleNode,
    MergeTagV2Node,
  ],
  email: [
    HeadingNode,
    ListNode,
    ListItemNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    LinkNode,
    AutoLinkNode,
    TextNode,
    EmojiNode,
    DividerNode,
    ImageNode,
    DesignHuddleNode,
    StylableButtonNode,
    PaperColorNode,
    MergeTagV2Node,
  ],
};
