import { useAuth0 } from "@auth0/auth0-react";
import { useMemo } from "react";
import { OrgUser } from "../../../server/supabase/hooks/types";
import { UUID } from "../../../utils/uuid";

export const useAccountId = (): UUID | null => {
  const { user } = useAuth0<OrgUser>();
  const accountId = useMemo(() => {
    return user ? user["http://changeengine.com/account_id"] : null;
  }, [user]);
  return accountId;
};
