import { yupResolver } from "@hookform/resolvers/yup";
import { Refresh } from "@mui/icons-material";
import { Box, Button, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { sample } from "lodash-es";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormProvider, useController, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDialog } from "../../utils/hooks/use-dialog";
import { UUID } from "../../utils/uuid";
import yup from "../../utils/yup";
import { BrandScriptDialog } from "../brand-kit/components/brand-script-dialog";
import { useBrandTones } from "../brand-kit/hooks/use-brand-tones";
import examples from "./example.json";
import { GeneratePrompt } from "./generate-prompt";
import { GenerateToneSelector } from "./generate-tone-selector";

export const GenerateMomentForm: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const [defaultToneId, setDefaultToneId] = useState<UUID>();

  const addToneDialog = useDialog();

  const { data, loading: brandTonesLoading, reload: reloadBrandTones } = useBrandTones();

  const brandTones = useMemo(() => data ?? [], [data]);

  const onSubmit = useCallback(
    ({ q, tone }: { q: string; tone?: string }) => {
      if (q === "") return;

      navigate({
        pathname: "/generate-moment",
        search: `?${new URLSearchParams({
          q,
          brand_tone: tone ?? "",
        }).toString()}`,
      });
    },
    [navigate],
  );

  const reloadBrandTonesAndSetDefault = useCallback(
    (id?: UUID) => {
      setDefaultToneId(id);
      reloadBrandTones();
    },
    [reloadBrandTones],
  );

  const schema = useMemo(() => {
    return yup.object({
      q: yup.string().required(),
      tone: yup
        .string()
        .oneOf(brandTones.map((brandTone) => brandTone.id))
        .optional(),
    });
  }, [brandTones]);

  const methods = useForm({
    defaultValues: { q: "" } as { q: string; tone?: UUID },
    resolver: yupResolver(schema),
  });
  const { setValue } = methods;

  useEffect(() => {
    if (defaultToneId || brandTones.length === 0) return;

    const newDefault =
      brandTones.length === 1 ? brandTones[0].id : brandTones.find((x) => x.is_default)?.id;

    setDefaultToneId(newDefault);

    setValue("tone", newDefault);
  }, [brandTones, defaultToneId, setValue]);

  const q = useController({ name: "q", control: methods.control });
  const refresh = useRef<SVGSVGElement>(null);

  const handleInspire = useCallback(() => {
    q.field.onChange(sample(examples)?.Prompt);
    refresh.current?.classList.add("active");
    setTimeout(() => refresh.current?.classList.remove("active"), 300);
  }, [q]);

  return (
    <Box>
      <FormProvider {...methods}>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <form onSubmit={methods.handleSubmit(onSubmit)} action="#">
          <Stack sx={{ flexFlow: "column", gap: 2 }}>
            <Box display="flex" alignItems="center" mb={2}>
              <Typography variant="body1" flexGrow={1}>
                {t("Our groundbreaking AI can create an entire Moment in seconds.")}
              </Typography>
              <Button
                data-analytics-id="generate-moment-inspire-me"
                onClick={handleInspire}
                size="small"
                variant="outlined"
                disabled={brandTonesLoading}
                endIcon={
                  <Refresh
                    ref={refresh}
                    sx={{
                      transition: "ease-in-out",
                      "&.active": {
                        animation: "Spin 0.3s linear",
                        animationFillMode: "forwards",
                      },
                      "@keyframes Spin": {
                        "0%": {
                          transform: "rotate(0deg)",
                        },
                        "100%": {
                          transform: "rotate(360deg)",
                        },
                      },
                    }}
                  />
                }
              >
                {t("Inspire me")}
              </Button>
            </Box>
            <GeneratePrompt />

            <Stack direction="row" justifyContent="flex-end" gap={2} alignItems="center">
              <Box flexGrow={1}>
                <GenerateToneSelector
                  loading={brandTonesLoading}
                  brandTones={brandTones}
                  openAddToneDialog={addToneDialog.handleOpen}
                  {...(matches && { size: "small" })}
                />
              </Box>

              <Button
                data-analytics-id="generate-moment-generate"
                type="submit"
                color="primary"
                sx={{ textWrap: "nowrap", width: "10em" }}
                variant="contained"
                disabled={brandTonesLoading}
                {...(matches && { size: "small" })}
              >
                {t("Generate")}
              </Button>
            </Stack>
          </Stack>
        </form>
      </FormProvider>
      <BrandScriptDialog
        open={addToneDialog.open}
        onClose={addToneDialog.handleClose}
        hasExistingTones={brandTones.length > 0}
        reloadTones={reloadBrandTonesAndSetDefault}
      />
    </Box>
  );
};
