import yup from "../../../utils/yup";

export const dividerTemplateSchema = yup.object({
  color: yup.string().required().default("#ffffff"),
  width: yup.number().required().default(1),
  style: yup.string().required().oneOf(["dashed", "dotted", "solid"]).default("solid"),
});

export const dividerSettingsSchema = yup.object({
  primary: dividerTemplateSchema.default({
    color: "#777777",
    width: 2,
    style: "solid" as const,
  }),
  secondary: dividerTemplateSchema.default({
    color: "#333333",
    width: 2,
    style: "dashed" as const,
  }),
});

export type DividerTemplate = yup.InferType<typeof dividerTemplateSchema>;
export type DividerSettings = yup.InferType<typeof dividerSettingsSchema>;
