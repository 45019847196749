import { ChevronLeftTwoTone, ChevronRightTwoTone } from "@mui/icons-material";
import { Box, BoxProps, styled, useTheme } from "@mui/material";
import {
  FC,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

interface CarouselProps<T, E> extends PropsWithChildren {
  gap?: number;
  items: T[];
  itemWidth?: string;
  loading?: boolean;
  Item: FC<CarouselItemProps<T, E>>;
  ItemExtraProps: E;
  Placeholder?: ReactElement;
}

export interface CarouselItemProps<T, E> extends PropsWithChildren {
  i: number;
  value: T;
  extraProps: E;
}

interface CarouselItemContainerProps<T, E> {
  key: string;
  value: T;
  ItemExtraProps: E;
  Item: FC<CarouselItemProps<T, E>>;
  i: number;
  scrollContainer: HTMLDivElement | null;
}

export function Carousel<T extends { key: string }, E>({
  loading = false,
  items,
  Item,
  ItemExtraProps,
  Placeholder,
  sx = [],
  gap = 2,
  itemWidth = "25%", // e.g. 4 items per page. you probably want to set a fixed width
}: CarouselProps<T, E> & BoxProps): React.JSX.Element {
  const NavigationButton = styled("div")(({ theme }) => ({
    position: "absolute",
    top: "0",
    height: "100%",
    width: "var(--carousel-navigation-button-width)",
    padding: theme.spacing(1),
    cursor: "pointer",
    display: "grid",
    color: "grey",
    placeItems: "center",
    transition: "background-color 0.2s linear",
    zIndex: 100,
    "&.Carousel-navigation--left": {
      left: 0,
      background: `linear-gradient(to right, var(--carousel-bgColor), transparent 50%)`,
    },
    "&.Carousel-navigation--right": {
      right: 0,
      background: `linear-gradient(to left, var(--carousel-bgColor), transparent 50%)`,
    },
    "& .MuiSvgIcon-root": {
      fontSize: `calc(var(--carousel-navigation-button-width) - ${theme.spacing(2)})`,
      fontWeight: "bold",
      color: theme.palette.grey[400],
    },
    ":hover": {
      "& .Carousel-navigationIcon": {
        backgroundColor: theme.palette.primary.main,
      },
      "& .MuiSvgIcon-root": {
        color: theme.palette.common.white,
      },
    },
  }));

  const NavigationIcon = styled("div")(() => ({
    borderRadius: "50%",
    // mixBlendMode: "darken",
    backgroundColor: `#333333aa`,
    padding: "5px",
    display: "grid",
    placeItems: "center",
    position: "absolute",
  }));

  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);
  const container = useRef<HTMLDivElement>(null);

  const isScrolledIntoView = useCallback((c: Element, el?: Element | null): boolean => {
    if (!el?.getBoundingClientRect || !c?.getBoundingClientRect) {
      return true;
    }
    const inner = el.getBoundingClientRect();
    const outer = c.getBoundingClientRect();
    return inner.left >= outer.left && inner.right <= outer.right;
  }, []);

  const calculateCanScrollRight = useCallback((): boolean => {
    if (!container.current) return false;
    return !isScrolledIntoView(container.current, container.current.lastElementChild);
  }, [isScrolledIntoView]);

  const handleWindowSizeChange = useCallback(() => {
    setCanScrollRight(calculateCanScrollRight());
  }, [calculateCanScrollRight]);

  useEffect(() => {
    setCanScrollRight(calculateCanScrollRight());

    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [calculateCanScrollRight, handleWindowSizeChange]);

  const handleScroll = useCallback(() => {
    if (!container.current) return;

    setCanScrollLeft(container.current.scrollLeft > 0);
    setCanScrollRight(calculateCanScrollRight());
  }, [calculateCanScrollRight]);

  const theme = useTheme();

  const scroll = useCallback(
    (dir: "left" | "right") => () => {
      if (!container.current || !container.current.offsetParent) return;
      if (!container.current.getBoundingClientRect) return;

      const itemPx = container.current.children[0]?.clientWidth + parseInt(theme.spacing(gap));
      const itemsPerPage = Math.floor(container.current.offsetParent.clientWidth / itemPx);

      let first: number | undefined = undefined;
      let curr = 0;
      let last: number | undefined = undefined;

      for (const item of container.current.children) {
        if (first === undefined && isScrolledIntoView(container.current, item)) {
          first = curr;
          curr += 1;
          continue;
        }
        if (first !== undefined && isScrolledIntoView(container.current, item)) {
          last = curr;
          curr += 1;
          continue;
        }
        curr += 1;
      }

      const target =
        dir === "left"
          ? Math.max(0, (first ?? 0) - itemsPerPage)
          : Math.min(container.current.children.length - 1, (last ?? 0) + 1);

      const targetLeft = container.current.children[target].getBoundingClientRect().left;
      const containerLeft = container.current.getBoundingClientRect().left;

      container.current.scrollBy({
        left: targetLeft - containerLeft - parseInt(theme.spacing(8), 10),
        behavior: "smooth",
      });
    },
    [theme, gap, isScrolledIntoView],
  );

  useEffect(() => {
    handleScroll();
  }, [items, handleScroll]);

  return (
    <Box
      sx={[
        {
          position: "relative",
          "--carousel-navigation-button-width": theme.spacing(8),
          "--carousel-bgColor": theme.palette.background.paper,
        },
        ...(sx instanceof Array ? sx : [sx]),
      ]}
      className="Carousel-root"
    >
      <Box
        onScroll={handleScroll}
        ref={container}
        className="Carousel-scrollContainer"
        sx={{
          position: "relative",
          width: "100%",
          display: "grid",
          gridTemplateColumns: `repeat(${items.length}, ${itemWidth})`,
          gap,
          overflowClipMargin: "15px",
          overflowY: "hidden",
          overflowX: "scroll",
          overflowClipBox: "padding-box",
          overscrollBehaviorX: "contain",
          alignItems: "stretch",
          msOverflowStyle: "none" /* Internet Explorer 10+ */,
          scrollbarWidth: "none" /* Firefox */,
          "::-webkit-scrollbar": {
            display: "none" /* Safari and Chrome */,
          },
        }}
      >
        {!loading &&
          items.map((item, i) => (
            <CarouselItemContainer
              key={item.key}
              value={item}
              ItemExtraProps={ItemExtraProps}
              Item={Item}
              i={i}
              scrollContainer={container.current}
            />
          ))}
        {items.length === 0 && Placeholder}
      </Box>
      {canScrollLeft && (
        <NavigationButton
          onClick={scroll("left")}
          className="Carousel-navigation Carousel-navigation--left"
        >
          <NavigationIcon className="Carousel-navigationIcon">
            <ChevronLeftTwoTone />
          </NavigationIcon>
        </NavigationButton>
      )}
      {canScrollRight && (
        <NavigationButton
          onClick={scroll("right")}
          className="Carousel-navigation Carousel-navigation--right"
        >
          <NavigationIcon className="Carousel-navigationIcon">
            <ChevronRightTwoTone />
          </NavigationIcon>
        </NavigationButton>
      )}
    </Box>
  );
}

function CarouselItemContainer<T, E>({
  value,
  Item,
  ItemExtraProps,
  i,
  scrollContainer,
}: CarouselItemContainerProps<T, E>): ReactElement {
  const ref = useRef<HTMLDivElement>(null);
  const observer = useRef<IntersectionObserver | null>(null);
  const [intersecting, setIntersecting] = useState(false);

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          setIntersecting((prev) => (!prev ? entry.isIntersecting : prev));
        });
      },
      {
        root: scrollContainer,
      },
    );

    observer.current?.observe(ref.current!);
  }, [i, scrollContainer]);

  return (
    <Box
      className="Carousel-item"
      sx={{
        ":hover": {
          zIndex: 10,
        },
      }}
      ref={ref}
    >
      {intersecting && <Item value={value} i={i} extraProps={ItemExtraProps} />}
    </Box>
  );
}
