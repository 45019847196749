import { Search } from "@mui/icons-material";
import {
  InputAdornment,
  OutlinedInput,
  Stack,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import Fuse from "fuse.js";
import { isString, sortBy } from "lodash-es";
import { Fragment, ReactNode, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Scrollbar } from "../scrollbar";

const DEFAULT_PAGE_SIZE = 25;

type PaginatedTableProps<T extends { [key: string]: unknown }> = {
  data: T[];
  renderRow: (row: T) => ReactNode;
  config?: {
    headers?: string[];
    defaultRowsPerPage?: number;
    search?: {
      keys: (keyof T)[];
      threshold: number;
    };
    sort?: {
      default: {
        column: keyof T;
        direction: "asc" | "desc";
      };
      options: { value: keyof T; label: string }[];
    };
  };
};

export function PaginatedTable<T extends { [key: string]: unknown }>({
  data,
  renderRow,
  config,
}: PaginatedTableProps<T>): ReactNode {
  const { t } = useTranslation();

  const [search, setSearch] = useState("");

  const [sort, setSort] = useState<{
    column: keyof T;
    direction: "asc" | "desc";
  } | null>(config?.sort?.default ?? null);

  const [tableState, setTableState] = useState({
    page: 0,
    rowsPerPage: config?.defaultRowsPerPage ?? DEFAULT_PAGE_SIZE,
  });

  const sortOptions = useMemo(
    () =>
      config?.sort?.options
        .filter((option) => isString(option.value))
        .map((option) => [
          { value: `${option.value.toString()}|asc`, label: t(`${option.label} (A-Z)`) },
          { value: `${option.value.toString()}|desc`, label: t(`${option.label} (Z-A)`) },
        ])
        .flat() ?? [],
    [config?.sort?.options, t],
  );

  const handleSortChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const [column, direction] = e.target.value.split("|");
      setSort({
        column: column as keyof T,
        direction: direction as "asc" | "desc",
      });
    },
    [setSort],
  );

  const fuse = useMemo(
    () =>
      config?.search
        ? new Fuse(data, {
            keys: config.search.keys.filter(isString),
            threshold: config.search.threshold,
          })
        : null,
    [config?.search, data],
  );

  const filteredData = useMemo(() => {
    const filtered = search !== "" ? fuse?.search(search).map((result) => result.item) : data;

    return filtered ?? [];
  }, [data, fuse, search]);

  const pagedAndSortedData = useMemo(() => {
    const sorted = sort ? sortBy(filteredData, (row) => row[sort.column]) : (filteredData ?? []);

    if (sort?.direction === "desc") sorted.reverse();

    return sorted.slice(
      tableState.page * (tableState.rowsPerPage ?? DEFAULT_PAGE_SIZE),
      (tableState.page + 1) * (tableState.rowsPerPage ?? DEFAULT_PAGE_SIZE),
    );
  }, [filteredData, sort, tableState.page, tableState.rowsPerPage]);

  return (
    <Stack direction="column" spacing={2}>
      {(fuse || sort) && (
        <Stack alignItems="center" direction="row" spacing={3} sx={{ p: 3 }}>
          {fuse && (
            <OutlinedInput
              value={search}
              sx={{ flexGrow: 1 }}
              onChange={(e) => setSearch(e.target.value)}
              fullWidth
              inputProps={{ "data-testid": "peopleFilter" }}
              placeholder={t("Search")}
              startAdornment={
                <InputAdornment position="start">
                  <SvgIcon>
                    <Search />
                  </SvgIcon>
                </InputAdornment>
              }
            />
          )}
          {sort && isString(sort.column) && (
            <TextField
              label="Sort By"
              name="sort"
              onChange={handleSortChange}
              select
              SelectProps={{ native: true }}
              value={`${sort.column}|${sort.direction}`}
            >
              {sortOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          )}
        </Stack>
      )}
      <Scrollbar>
        <Table sx={{ minWidth: 700 }}>
          {config?.headers && (
            <TableHead>
              <TableRow>
                {config.headers.map((key) => (
                  <TableCell key={key}>{key}</TableCell>
                ))}
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {pagedAndSortedData.map((row, i) => (
              <Fragment key={i}>{renderRow(row)}</Fragment>
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
      <TablePagination
        component="div"
        count={filteredData.length}
        onPageChange={(_, page) => setTableState((current) => ({ ...current, page }))}
        onRowsPerPageChange={(e) => {
          setTableState((current) => ({ ...current, rowsPerPage: parseInt(e.target.value) }));
        }}
        page={tableState.page}
        rowsPerPage={tableState.rowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 50]}
      />
    </Stack>
  );
}
