import { Typography } from "@mui/material";
import { FC, useCallback } from "react";
import { useFormContext, useFormState, useWatch } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useToken } from "../../../../../server/auth/use-token";
import { ImagesService } from "../../../../../server/cloudflare/images-service";
import { getImageDimensions } from "../../../../../utils/image-dimensions";
import { resizeImage } from "../../../../../utils/resize-image";
import { uuidv4 } from "../../../../../utils/uuid";
import { FilePicker } from "../../../../generic/components/file-picker/file-picker";
import { SlackAliasForm } from "../../types";

export const SlackAliasImageUpload: FC = () => {
  const { t } = useTranslation();

  const { control, setValue, getValues } = useFormContext<SlackAliasForm>();

  useWatch({ control, name: "image" });
  const image = getValues("image");

  const { errors } = useFormState({ control });

  const getToken = useToken();

  const handleUpload = useCallback(
    async (file: File) => {
      const name = uuidv4();

      const imagesService = new ImagesService();
      const result = await imagesService.upload(file, name, await getToken());

      if (!result.success || !result.url) {
        toast.error(t("Something went wrong, please try again"));
        return;
      }

      setValue("image", result.url, { shouldValidate: true });

      return file;
    },
    [getToken, t, setValue],
  );

  return (
    <>
      <FilePicker
        value={image}
        handleUpload={handleUpload}
        accept={{ "image/*": [".png", ".jpeg", ".gif", ".jpg"] }}
        text={{
          add: t("Add Image"),
          remove: t("Remove Image"),
          uploadError: t("Only one image can be uploaded at a time"),
        }}
        transform={async (file: File) => {
          const dims = await getImageDimensions(URL.createObjectURL(file));
          if (dims.width === dims.height && dims.width >= 512 && dims.height <= 1024) {
            return file;
          }

          return await resizeImage(file, { width: 1024, height: 1024 });
        }}
      />
      {errors.image && (
        <Typography variant="caption" color="error">
          {errors.image.message}
        </Typography>
      )}
    </>
  );
};
