import { Alert, List, ListItem, ListItemText, Stack } from "@mui/material";
import { FC } from "react";
import { HrisConfig } from "../../integrations/server/supabase-hris-service";

type SyncStatusResponse = {
  model_name: string | null;
  last_sync_start: string | null;
  next_sync_start: string | null;
  is_initial_sync: boolean;
  status: string;
};

export const HrisIntegrationExtraInfo: FC<{
  integration: HrisConfig;
  syncStatus: SyncStatusResponse | null;
  error: Error | null;
}> = ({ integration, syncStatus, error }) => {
  return (
    <Stack spacing={1}>
      {error && <Alert severity="error">Unable to load HRIS data.</Alert>}

      {syncStatus && (
        <List dense={true}>
          <ListItem>
            <ListItemText
              primary="HRIS read at"
              secondary={
                syncStatus.last_sync_start
                  ? fmtDate(syncStatus.last_sync_start)
                  : "No sync completed"
              }
              primaryTypographyProps={{ variant: "subtitle2" }}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Next sync will start at"
              secondary={
                syncStatus.next_sync_start
                  ? fmtDate(syncStatus.next_sync_start)
                  : "No sync scheduled"
              }
              primaryTypographyProps={{ variant: "subtitle2" }}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Current HRIS read is"
              secondary={syncStatus.status}
              primaryTypographyProps={{ variant: "subtitle2" }}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Last successful sync"
              secondary={fmtDate(integration.last_sync_time)}
              primaryTypographyProps={{ variant: "subtitle2" }}
            />
          </ListItem>
        </List>
      )}
    </Stack>
  );
};

const fmtDate = (dateStr: string | null): string => {
  if (!dateStr) return "No sync completed";

  const parsed = new Date(dateStr);
  return parsed.toLocaleString();
};
