import { useAsync } from "@react-hookz/web";
import { DependencyList, useCallback } from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type UseAsyncStateCallback<T, U extends any[] = []> =
  | {
      data: T;
      loading: false;
      error: null;
      load: (...args: U) => Promise<T>;
    }
  | {
      data: null;
      loading: true;
      error: null;
      load: (...args: U) => Promise<T>;
    }
  | {
      data: null;
      loading: false;
      error: Error;
      load: (...args: U) => Promise<T>;
    }
  | {
      data: null;
      loading: false;
      error: null;
      load: (...args: U) => Promise<T>;
    };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useAsyncStateCallback<T, U extends any[]>(
  saverFn: (...args: U) => Promise<T>,
  deps: DependencyList,
): UseAsyncStateCallback<T, U> {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cb = useCallback(saverFn, deps); // Do not add saverFn as dep

  const [state, actions] = useAsync(cb);

  if (state.status === "not-executed")
    return { data: null, loading: false, error: null, load: actions.execute };

  if (state.status === "success" && state.result !== undefined)
    return { data: state.result, loading: false, error: null, load: actions.execute };

  if (state.status === "error" && state.error !== undefined)
    return { data: null, loading: false, error: state.error, load: actions.execute };

  return { data: null, loading: true, error: null, load: actions.execute };
}
