import { Box, BoxProps, Typography } from "@mui/material";
import { FC, ReactNode } from "react";
import { ProgramIcon } from "../../programs/components/program-icon";

interface PageHeadingProps extends BoxProps {
  heading: string | ReactNode;
  icon?: string;
  colour?: string;
  subheading?: string | ReactNode;
  children?: ReactNode;
}

export const PageHeading: FC<PageHeadingProps> = ({
  icon,
  colour,
  heading,
  subheading,
  ...props
}) => (
  <Box {...props}>
    <Box display="flex">
      {icon && (
        <ProgramIcon
          data={icon}
          sx={{
            color: colour,
            width: "2em",
            height: "2em",
            display: "inline-block",
            pr: 2,
          }}
        />
      )}
      <Box
        sx={(theme) => ({
          fontWeight: 600,
          opacity: 0.7,
          fontSize: "1.2rem",
          lineHeight: "2em",
          [theme.breakpoints.between("sm", "xl")]: {
            fontSize: "1.8rem",
            lineHeight: "2em",
          },
          [theme.breakpoints.only("xl")]: {
            fontSize: "2rem",
            lineHeight: "2em",
          },
        })}
        role="heading"
      >
        {heading}
      </Box>
    </Box>
    <Subheading subheading={subheading} />
  </Box>
);

const Subheading: FC<Pick<PageHeadingProps, "subheading">> = ({ subheading }) => {
  if (!subheading) return null;

  if (typeof subheading === "string") {
    return (
      <Typography variant="h6" fontWeight={400}>
        {subheading}
      </Typography>
    );
  }

  return subheading;
};
