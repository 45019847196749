import { useAuth0 } from "@auth0/auth0-react";
import { FC } from "react";

const LogoutPage: FC = () => {
  const { logout } = useAuth0();
  void logout({
    logoutParams: { returnTo: `${window.location.protocol}//${window.location.host}/` },
  }).catch(console.error);
  return <></>;
};

export default LogoutPage;
