import { LinearProgress } from "@mui/material";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useSupabase } from "../../../../server/supabase/hooks";
import { SerialisedEditorDesign } from "../../../message/editor/types/serialised-editor-design";
import { SupabaseMomentService } from "../../server/supabase-moment-service";
import { MomentFormMoment } from "../../types/moment-form-moment";

export const CopyMoment: FC<{
  moment: MomentFormMoment;
  noMessage: boolean;
  onError: () => void;
  newTitle: string | null;
}> = ({ moment, noMessage, onError, newTitle }) => {
  const navigate = useNavigate();

  useSupabase(
    async ({ supabase }) => {
      const momentService = new SupabaseMomentService(supabase);

      const { data, error } = await momentService.copyMoment(moment.id, newTitle);
      if (error) {
        console.log("Failed to copy moment", { error });
        return onError();
      }

      if (noMessage) {
        await momentService.update(data, {
          use_lexical_editor: true,
          email_moment_id: null,
          chat_moment_id: null,
          body: null,
          design: {} as SerialisedEditorDesign,
        });
      }
      navigate(`/moments/${data}`, { replace: true });
    },
    [navigate, noMessage, moment.id, onError, newTitle],
  );

  return <LinearProgress />;
};
