import { Box, Paper, Tooltip } from "@mui/material";
import { FC, ReactElement } from "react";
import { ChannelName } from "../types";
import { prettyChannelName } from "../utils";

export interface ChannelProps {
  name: ChannelName;
  iconSmall: (width?: number, height?: number) => ReactElement;
  buttonText: string;
  onClick?: () => void;
  disabled?: boolean;
  selected?: boolean;
}

export const ChannelCard: FC<ChannelProps> = ({ onClick, disabled, selected, iconSmall, name }) => {
  const size = 130;
  const iconSize = 0.8 * size;

  return (
    <Tooltip title={prettyChannelName(name)}>
      <Paper
        variant="outlined"
        sx={{
          borderColor: selected ? "primary.main" : null,
          borderWidth: selected ? 3 : null,
          height: size,
          width: size,
          opacity: disabled ? 0.5 : null,
          "&:hover": {
            cursor: disabled ? null : "pointer",
            opacity: disabled ? null : 0.75,
          },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        data-testid={name && "channel-card-" + name}
        data-selected={selected}
        onClick={() => !disabled && onClick && onClick()}
      >
        <Box>{iconSmall && iconSmall(iconSize, iconSize)}</Box>
      </Paper>
    </Tooltip>
  );
};
