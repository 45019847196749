import { Autocomplete, Chip, TextField } from "@mui/material";
import { FC } from "react";
import { UsableAccountPersonField } from "../server/supabase-segment-field-service";
import { SegmentCondition } from "../types";

export const AudiencesSelect: FC<{
  options: UsableAccountPersonField[];
  audience: SegmentCondition | undefined;
  handleAudienceChange: (audience: string | null) => void;
  disableAudienceClearable: boolean;
  disabled?: boolean;
  errorMessage?: string;
}> = ({
  options,
  audience,
  handleAudienceChange,
  disableAudienceClearable,
  disabled,
  errorMessage,
}) => {
  const option = options.find((o) => o.key === audience?.field);

  const width = (audience?.field.length ?? 30) * 6;

  return (
    <Autocomplete
      size="small"
      id="audiences-select"
      options={options.filter((x) => x.usable || x.key === option?.key)}
      disableClearable={disableAudienceClearable}
      disabled={disabled}
      value={option}
      onChange={(_, value) => {
        handleAudienceChange(value?.display_name ?? null);
      }}
      getOptionLabel={(o) => o.display_name ?? ""}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((o, index) => (
          <Chip
            {...getTagProps({ index })}
            key={o.display_name}
            label={o.display_name}
            size="small"
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          sx={{ minWidth: width }}
          error={Boolean(errorMessage)}
          helperText={errorMessage}
          InputProps={{
            ...params.InputProps,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore https://github.com/mui/material-ui/issues/20160#issuecomment-600277849
            "data-testid": "audiences-value-input",
          }}
        />
      )}
    />
  );
};
