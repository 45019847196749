import { CircularProgress } from "@mui/material";
import { isNil } from "lodash-es";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useCreateAssetCallback,
  useUpdateAssetCallback,
} from "../../modules/assets/hooks/use-save-asset-callback";
import { Asset } from "../../modules/assets/server/digital-asset-service";
import { TemplateCustomizationObject } from "../../modules/design-huddle/brand-info-customizations/types";
import { useCreateDesignHuddleProject } from "../../modules/design-huddle/use-create-design-huddle-conent-project";
import { useDesignHuddle } from "../../modules/design-huddle/use-design-huddle";
import { EditContent, SaveAssetHandler } from "../../modules/edit-content/edit-content";
import { LoadingModal } from "../../modules/edit-content/loading-modal";
import { Page } from "../../modules/layout/components/page";
import { DhTemplateConfig } from "../../modules/multiformat/store/types";
import { useFlag } from "../../server/optimizely";
import { fromBase64 } from "../../utils/base64";

type LocationState = {
  customizations: TemplateCustomizationObject;
  templateConfig: DhTemplateConfig;
  page: number;
  contentType: string;
  projectId?: string;
};

const CreateContentEditPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [multiFormat, , multiFormatLoaded] = useFlag("multi_format");
  const [searchParams] = useSearchParams();
  const title = searchParams.get("title") ?? "";
  const projectIdOverride = searchParams.get("projectId") ?? "";

  const [projectId, setProjectId] = useState<string>(projectIdOverride);
  const { customizations, templateConfig, page, contentType } = useMemo(() => {
    const d = searchParams.get("d");
    const decoded = fromBase64(d || "");
    return JSON.parse(decoded) as LocationState;
  }, [searchParams]);

  const designHuddleReady = useDesignHuddle([]);

  const createDesignHuddleProject = useCreateDesignHuddleProject();

  useEffect(() => {
    if (
      !designHuddleReady ||
      isNil(templateConfig) ||
      isNil(customizations) ||
      isNil(page) ||
      projectId
    )
      return;

    void createDesignHuddleProject({ templateCode: templateConfig.templateCode, customizations })
      .then(setProjectId)
      .catch((e) => {
        console.error(e);
      });
  }, [
    createDesignHuddleProject,
    customizations,
    designHuddleReady,
    page,
    projectId,
    templateConfig,
  ]);

  const [ready, setReady] = useState(false);
  const handleReady = useCallback(() => setReady(true), []);

  useEffect(() => {
    if (multiFormatLoaded && !multiFormat) {
      navigate("/404");
    }
  }, [multiFormat, multiFormatLoaded, navigate]);

  const [asset, setAsset] = useState<Asset | null>(null);

  const createAsset = useCreateAssetCallback();
  const updateAsset = useUpdateAssetCallback();

  const handleSaveAsset: SaveAssetHandler = useCallback(
    async (opts) => {
      if (!projectId) return Promise.reject(new Error("Project ID not found"));

      const newAsset = asset
        ? await updateAsset({
            asset,
            ...opts,
          })
        : await createAsset({
            projectId,
            contentType,
            ...opts,
          });

      setAsset(newAsset);
      return newAsset;
    },
    [asset, contentType, createAsset, projectId, updateAsset],
  );

  if (!multiFormatLoaded) return <CircularProgress />;

  return (
    <Page title={t("Edit Content | ChangeEngine")} sx={{ height: "100%" }}>
      {projectId && (
        <EditContent
          projectId={projectId}
          page={page}
          onReady={handleReady}
          title={title}
          onSave={handleSaveAsset}
        />
      )}
      {projectId !== "dummy" && <LoadingModal open={!ready} />}
    </Page>
  );
};

export default CreateContentEditPage;
