import { UUID } from "../../utils/uuid";

export interface TemplateInitialContext {
  response: {
    title: string;
    what: string;
    subject_line: string;
  };
  chatId: UUID;
}

export interface RecipeQuery {
  recipeId: string;
  answers: string[];
}

export interface ContextQuery extends TemplateInitialContext {
  recipeId: string;
  answers: string[];
  prompt: string;
}

export type GenerateQuery = RecipeQuery | ContextQuery;

export function isContextQuery(q: GenerateQuery): q is ContextQuery {
  return "response" in q;
}
export function isRecipeQuery(q: GenerateQuery): q is RecipeQuery {
  return !("response" in q);
}
