import { FC, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { uuidv4 } from "../../utils/uuid";

const NewMomentPage: FC<{ newsletter?: boolean }> = ({ newsletter }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const route = `/moments/${uuidv4()}${newsletter ? "/newsletter" : ""}?${searchParams.toString()}`;

  useEffect(() => navigate(route, { replace: true }), [route, navigate]);

  return null;
};

export default NewMomentPage;
