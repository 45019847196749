import { Alert, LinearProgress } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";
import { useAnalytics } from "../../../modules/analytics/analytics";
import { Page } from "../../../modules/layout/components/page";
import { PageContent } from "../../../modules/layout/components/page-content";
import { PageHeader } from "../../../modules/layout/components/page-header";
import { PageHeading } from "../../../modules/layout/components/page-heading";
import { CopyMoment } from "../../../modules/moment/component/copy/copy";
import { useMomentClonable } from "../../../modules/moment/component/copy/utils";
import { useMoment } from "../../../modules/moment/hooks/use-moment";
import { UUID } from "../../../utils/uuid";
import NotFound from "../../not-found";

const CopyMomentPage: FC = () => {
  const { id } = useParams<{ id: UUID }>();
  const [params] = useSearchParams();

  const noMessage = params.get("no_message") === "1";

  const newTitle = params.get("new_title");

  const { t } = useTranslation();
  const [displayError, setDisplayError] = useState(false);
  const { gaEvent } = useAnalytics();
  useEffect(() => gaEvent("copy_moment", { moment_id: id }), [id, gaEvent]);
  const { error: momentError, data: moment } = useMoment(id);

  const { clonable, loading } = useMomentClonable(moment ?? undefined);

  const onError = useCallback((): void => setDisplayError(true), []);

  if (!loading && !clonable) return <NotFound />;

  return (
    <Page title={t("Copy Moment | ChangeEngine")}>
      <PageHeader>
        <PageHeading heading={t("Copy Moment")} subheading={t("Copying moment")} />
      </PageHeader>
      <PageContent>
        {momentError || displayError ? (
          <Alert severity="error">
            <Trans>Something went wrong</Trans>
          </Alert>
        ) : moment && !loading ? (
          <CopyMoment moment={moment} onError={onError} noMessage={noMessage} newTitle={newTitle} />
        ) : (
          <LinearProgress />
        )}
      </PageContent>
    </Page>
  );
};

export default CopyMomentPage;
