import { PostgrestResponse, PostgrestSingleResponse } from "@supabase/postgrest-js";
import { SupabaseService } from "../../../server/supabase/base-supabase-service";
import { Database } from "../../../server/supabase/types/database-definitions";
import { UUID } from "../../../utils/uuid";

export type EmailDesign = Database["public"]["Tables"]["email_design"]["Row"];

export class SupabaseEmailDesignService extends SupabaseService<"email_design"> {
  table = "email_design" as const;

  async setDefault(email_design_id: UUID): Promise<PostgrestResponse<EmailDesign>> {
    return this.log_errors(await this.client.rpc("set_default_email_design", { email_design_id }));
  }

  async archive(
    email_design_id: UUID,
    new_email_design_id: UUID,
  ): Promise<PostgrestSingleResponse<boolean>> {
    return this.log_errors(
      await this.client.rpc("archive_email_design", {
        email_design_id_: email_design_id,
        new_email_design_id,
      }),
    );
  }
}
