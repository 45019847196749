export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

type NonNullableProperty<T, K extends keyof T> = T & Record<K, NonNullable<T[K]>>;

export function propertyNotEmpty<T, K extends keyof T>(
  property: K,
): (obj: T) => obj is NonNullableProperty<T, K> {
  return (obj: T): obj is NonNullableProperty<T, K> => {
    return obj[property] !== null && obj[property] !== undefined;
  };
}
