import { Link } from "@mui/material";
import { FC } from "react";
import { formatDatetime, fromISO8601 } from "../../../utils/iso8601";
import { UUID, uuidv4 } from "../../../utils/uuid";
import { CrudDataGrid } from "../../generic/components/crud-data-grid/crud-data-grid";
import { Columns, Row } from "../../generic/components/crud-data-grid/types";
import { OutboxRecipient } from "../server/supabase-outbox-service";

type OutboxRecipientHack = OutboxRecipient & { id: UUID };

export const OutboxDataGrid: FC<{
  recipients: OutboxRecipient[];
}> = ({ recipients }) => {
  const processedRecipients = recipients.map((x) => ({ ...x, id: uuidv4() }));
  const columns: Columns<OutboxRecipientHack> = {
    moment_id: {
      headerName: "Moment ID",
      flex: 1,
      editable: false,
      valueFormatter: (value) => value ?? "-",
    },
    moment_title: {
      headerName: "Moment Title",
      flex: 2,
      editable: false,
      valueGetter: (_, row) => row.moment_title ?? "-",
      renderCell: (params) => (
        <Link href={`/moments/${params.row.moment_id!}`} target="_blank">
          {params.row.moment_title ?? "-"}
        </Link>
      ),
    },
    first_name: {
      headerName: "First Name",
      flex: 1,
      editable: false,
      valueGetter: (_, row) => row.first_name ?? "-",
    },
    last_name: {
      headerName: "Last Name",
      flex: 1,
      editable: false,
      valueGetter: (_, row) => row.last_name ?? "-",
    },
    email: {
      headerName: "Work Email",
      flex: 2,
      editable: false,
      valueGetter: (_, row) => row.email ?? "-",
    },
    channel: {
      headerName: "Channel",
      flex: 1,
      editable: false,
      valueGetter: (_, row) => row.channel ?? "-",
    },
    send_time: {
      headerName: "Send Time",
      flex: 3,
      editable: false,
      valueFormatter: (value, row) =>
        value ? formatDatetime(fromISO8601(value), row.timezone) : "-",
    },
    is_test: {
      headerName: "Is Test?",
      flex: 1,
      type: "boolean",
      editable: false,
    },
  };

  return (
    <>
      <CrudDataGrid<Row<OutboxRecipientHack>>
        initialRows={processedRecipients}
        columns={columns}
        hideActions={true}
        gridOverrides={{
          isCellEditable: () => false,
          initialState: {
            sorting: {
              sortModel: [
                {
                  field: "send_time",
                  sort: "desc",
                },
              ],
            },
          },
        }}
      />
    </>
  );
};
