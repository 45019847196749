import { useAtom, useSetAtom } from "jotai";
import { merge } from "lodash-es";
import { FC } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useSupabase } from "../../../server/supabase/hooks";
import { UUID } from "../../../utils/uuid";
import { SupabaseSequenceMomentsService } from "../../moment/server/supabase-moment-service";
import { useSequenceItem } from "../../sequence/hooks/use-sequence-item";
import { ApplicationLayout } from "./application-layout";
import { Layout } from "./layout";
import { SequenceBarMoment, currentSequenceAtom, sequenceMomentsAtom } from "./store";

/* This should be a *temporary component* - Jan2024

  Our goal is to use ApplicationLayout for all Moments,
 but to avoid any resistance to rolling out ApplicationLayout
 for non-sequence Moments initially, those will retain use
 of the existing Layout for now.

 All Sequence Moments will use ApplicationLayout.
 All Non-Sequence Moments will use the existing Layout.
  */
export const MomentLayout: FC = () => {
  const { id } = useParams<{ id: UUID }>();
  const [searchParams] = useSearchParams();

  const { loading, error: sequenceItemError, data: sequenceItemData } = useSequenceItem(id);
  const [currentSequence, setCurrentSequence] = useAtom(currentSequenceAtom);
  const sequenceId =
    sequenceItemData?.sequence_id ?? (searchParams.get("sequence_id") as UUID) ?? null;
  const setSequenceMoments = useSetAtom(sequenceMomentsAtom(sequenceId));

  useSupabase(
    async ({ supabase }) => {
      setCurrentSequence(sequenceId);

      if (sequenceId) {
        const { data, error } = await new SupabaseSequenceMomentsService(supabase).getForSequence(
          sequenceId,
        );
        if (error) throw error;

        if (data && !data.find((x) => x.id === id)) {
          data.push({
            id,
            sequence_id: sequenceId as UUID,
            barStatus: "new",
          } as SequenceBarMoment);
        }

        setSequenceMoments((prev) => merge(prev, data));
      }
    },
    [id, sequenceId, setSequenceMoments, setCurrentSequence],
  );

  if (sequenceItemError) throw sequenceItemError;
  if (loading) return null;

  return currentSequence ? <ApplicationLayout layout="sequence" /> : <Layout />;
};
