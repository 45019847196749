import { Autocomplete, Box, Chip, TextField } from "@mui/material";
import { FC, useMemo } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Program } from "../../../programs/server/supabase-program-service";
import { PermissionsFormType } from "../../types";

export const ProgramSelect: FC<{
  type: "read_only_programs" | "editable_programs";
  programs: Program[];
}> = ({ type, programs }) => {
  const { t } = useTranslation();

  const { control, getValues } = useFormContext<PermissionsFormType>();

  useWatch({
    control,
    name: ["type", "read_only_programs", "editable_programs"],
  });
  const [readOnlyPrograms, editablePrograms] = getValues([
    "read_only_programs",
    "editable_programs",
  ]);

  const allowedPrograms = useMemo(
    () =>
      type === "read_only_programs"
        ? programs.filter((x) => !editablePrograms?.includes(x.id))
        : programs.filter((x) => !readOnlyPrograms?.includes(x.id)),
    [type, programs, readOnlyPrograms, editablePrograms],
  );

  return (
    <Controller
      name={type}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          multiple={true}
          id={`${type}-select`}
          value={allowedPrograms.filter((s) => field.value?.includes(s.id))}
          options={[{ title: t("Select All"), id: "All" }].concat(allowedPrograms)}
          renderOption={(renderProps, option) => (
            <Box component="li" {...renderProps} key={option.id}>
              {option.title}
            </Box>
          )}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip {...getTagProps({ index })} key={option.id} label={option.title} />
            ))
          }
          renderInput={(params) => (
            <TextField
              label={t(`${type === "editable_programs" ? "Editable" : "Read-only"} Programs`)}
              {...params}
              sx={{ mt: 2 }}
              helperText={error?.message}
              error={!!error}
              InputProps={{
                ...params.InputProps,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore https://github.com/mui/material-ui/issues/20160#issuecomment-600277849
                "data-testid": `${type}-select`,
              }}
            />
          )}
          getOptionLabel={(option) => option.title}
          onChange={(_, v) => {
            const value = v.map((s) => s.id);
            field.onChange(value.includes("All") ? allowedPrograms.map((p) => p.id) : value);
          }}
        />
      )}
    />
  );
};
