export function getImageDimensions(url: string): Promise<{ height: number; width: number }> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve({ height: img.height, width: img.width });
    img.onerror = reject;
    img.src = url;
  });
}

export const getImageDimensionsFromFile = async (
  file: File | Blob,
): Promise<{ width: number; height: number }> => {
  const url = URL.createObjectURL(file);

  return getImageDimensions(url);
};

export const getRelativeMaxDimensions = async (
  url: string,
  max: { width: number; height: number },
): Promise<{ width: number; height: number }> => {
  const dimensions = await getImageDimensions(url);
  return getRelativeMaxDimensionsLocal(dimensions, max);
};

export const getRelativeMaxDimensionsLocal = (
  { width, height }: { width: number; height: number },
  max: { width: number; height: number },
): { width: number; height: number } => {
  const scale = Math.min(1, max.width / width, max.height / height);
  return { width: width * scale, height: height * scale };
};

export const scaleForHeight = (
  url: string,
  { maxHeight }: { maxHeight: number },
): Promise<{ width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const ratio = img.width / img.height;
      const newHeight = Math.min(maxHeight, img.height);

      resolve({ width: newHeight * ratio, height: newHeight });
    };
    img.onerror = reject;
    img.src = url;
  });
};
