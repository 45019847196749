import { CircularProgress, Stack } from "@mui/material";
import { sortBy, toPairs } from "lodash-es";
import { FC, forwardRef, useMemo, useRef } from "react";
import { FieldErrorsImpl, useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { UUID } from "../../../utils/uuid";
import { MomentFormMoment } from "../../moment/types/moment-form-moment";
import { UsableAccountPersonField } from "../server/supabase-segment-field-service";
import { useSegmentInvalidRecipientTypeKeys } from "../server/use-segment-invalid-recipient-keys";
import { Segment, SegmentCondition } from "../types";
import { AddFilterButton } from "./manager/add-filter-button";
import { SegmentConditionView } from "./segment-condition";
import { ChildError } from "./top-level-error-alert";

export const BuildSegmentFilters: FC<{
  disabled?: boolean;
  showValidationErrors: boolean;
  segmentFields: UsableAccountPersonField[];
}> = ({ disabled: rootDisabled, showValidationErrors, segmentFields }) => {
  const methods = useFormContext<MomentFormMoment>();
  const {
    control,
    formState: { errors },
    getValues,
    trigger,
  } = methods;

  const { remove, update } = useFieldArray({ control, name: "segment.conditions" });

  useWatch({
    control,
    name: ["segment.conditions", "segment.operation", "segment.recipient_type_key"],
  });
  const [conditions, operation, recipientTypeKey] = getValues([
    "segment.conditions",
    "segment.operation",
    "segment.recipient_type_key",
  ]);

  const { data: invalidSegmentFields, loading } = useSegmentInvalidRecipientTypeKeys({
    recipient_type_key: recipientTypeKey,
  });

  const disabled = rootDisabled || loading;

  const useableSegmentFields = useMemo(
    () =>
      segmentFields
        .filter((item) => !invalidSegmentFields?.includes(item.key!))
        .filter((item) => !item.key?.startsWith("recipient_type")),
    [segmentFields, invalidSegmentFields],
  );

  const hasNonRecipientConditions = useMemo(
    () =>
      conditions.filter((condition) => !condition.field.startsWith("recipient_type")).length > 0,
    [conditions],
  );

  const containerRef = useRef<HTMLDivElement>(null);

  if (loading) return <CircularProgress />;

  return (
    <>
      <Stack gap={3}>
        {hasNonRecipientConditions && (
          <RootSegmentView
            ref={containerRef}
            operation={operation}
            conditions={conditions}
            updateArrayValue={(value) => {
              const index = conditions.findIndex((c) => c.id === value.id);
              update(index, value);
              void trigger("segment.conditions");
            }}
            errors={sortBy(toPairs(conditions), ([index]) => Number(index)).map(
              ([index]) =>
                errors.segment?.conditions?.[Number(index)] as
                  | ChildError<FieldErrorsImpl<SegmentCondition>>
                  | undefined,
            )}
            removeItem={(id) => {
              const index = conditions.findIndex((c) => c.id === id);
              remove(index);
            }}
            disabled={disabled}
            showValidationErrors={showValidationErrors}
            segmentFields={useableSegmentFields}
          />
        )}
        {!disabled && <AddFilterButton containerRef={containerRef} />}
      </Stack>
    </>
  );
};

const RootSegmentView = forwardRef<
  HTMLDivElement,
  {
    operation: Segment["operation"];
    conditions: SegmentCondition[];
    updateArrayValue: (value: SegmentCondition) => void;
    removeItem: (id: UUID) => void;
    errors?: (ChildError<FieldErrorsImpl<SegmentCondition>> | undefined)[];
    disabled?: boolean;
    showValidationErrors: boolean;
    segmentFields: UsableAccountPersonField[];
    recipientTypeKey?: string | null;
  }
>(function RootSegmentView(
  {
    operation,
    conditions,
    updateArrayValue,
    errors,
    removeItem,
    disabled,
    showValidationErrors,
    segmentFields,
  },
  ref,
) {
  return (
    <Stack gap={2} maxHeight="50vh" overflow="auto" ref={ref} mb={1}>
      {conditions.map((condition, index) => {
        if (condition.field.startsWith("recipient_type")) return null;

        const handleFieldChange = (value: SegmentCondition): void => {
          updateArrayValue(value);
        };

        return (
          <SegmentConditionView
            key={condition.id}
            condition={condition}
            handleFieldChange={handleFieldChange}
            error={showValidationErrors ? errors?.[index] : undefined}
            removeItem={conditions.length > 1 ? () => removeItem(condition.id) : undefined}
            disabled={disabled}
            showValidationErrors={showValidationErrors}
            segmentFields={segmentFields}
            operation={index === 0 ? null : operation}
          />
        );
      })}
    </Stack>
  );
});
