import { Edit, Restore } from "@mui/icons-material";
import { Button, Divider, IconButton, Stack, StackProps, Tooltip } from "@mui/material";
import { useAtomValue } from "jotai";
import { isEqual } from "lodash-es";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { usePermissions } from "../../server/auth/use-permissions";
import { BrandKitColor, BrandKitLogo } from "../brand-kit/server/brand-kit-service";
import { brandingAtom } from "../brand-kit/store";
import { BrandInfoSelected, BrandKitManager } from "../brand-kit/types";
import { BrandColorSelect } from "./brand-color-select";
import { BrandLogoSelect } from "./brand-logo-select";
import { ImageStyle } from "./image-style";

export interface CompactBrandingBarProps {
  manager: BrandKitManager;
  branding: unknown;
  defaultBranding?: BrandInfoSelected;
  brandKit?: {
    choices: {
      colors: BrandKitColor[];
      logos: BrandKitLogo[];
    };
  };
  visualStyleSelector?: boolean;
  imageStyleSelector?: boolean;
  hasBranding: boolean;
  useSelectedLogo?: boolean;
  onReset?: () => void;
}

export const CompactBrandingBar: FC<CompactBrandingBarProps & StackProps> = ({
  defaultBranding,
  brandKit,
  manager,
  hasBranding,
  visualStyleSelector = false,
  useSelectedLogo = true,
  onReset,
  imageStyleSelector = false,
  ...props
}) => {
  const selectedBranding = useAtomValue(brandingAtom);
  const { t } = useTranslation();
  const { super_admin } = usePermissions();
  const showEdit = hasBranding && super_admin;
  const showReset = !isEqual(selectedBranding, defaultBranding);

  return hasBranding ? (
    <Stack direction="row" gap={1} {...props}>
      <ImageStyle
        visualStyleSelector={visualStyleSelector}
        imageTypeSelector={imageStyleSelector}
        manager={manager}
      />

      <Stack direction="column" gap={0.5}>
        {showEdit && (
          <Tooltip title={t("Edit Brand")} placement="right" arrow>
            <Link to="/manage/brand/brand_kit">
              <IconButton
                data-analytics-id="branding-bar-edit"
                size="small"
                data-testid="branding-bar-edit-button"
              >
                <Edit fontSize="small" />
              </IconButton>
            </Link>
          </Tooltip>
        )}

        {showReset && (
          <Tooltip title={t("Restore default")} placement="right" arrow>
            <IconButton
              data-analytics-id="branding-bar-compact-reset"
              onClick={onReset}
              size="small"
              data-testid="branding-bar-reset-button"
            >
              <Restore fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
      {(showReset || showEdit) && <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />}
      <BrandColorSelect
        selected={selectedBranding.color}
        colors={brandKit?.choices.colors ?? []}
        manager={manager}
      />
      <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
      <BrandLogoSelect
        selected={selectedBranding.logo}
        logos={brandKit?.choices.logos ?? []}
        manager={manager}
        defaultBranding={defaultBranding}
        useSelectedLogo={useSelectedLogo}
      />
    </Stack>
  ) : (
    <Link to="/manage/brand/brand_kit">
      <Button data-analytics-id="branding-bar-link-add" sx={{ my: 2 }}>
        {t("Add Branding")}
      </Button>
    </Link>
  );
};
