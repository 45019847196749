import { CSSObject } from "@emotion/react";
import {
  CssBaselineProps,
  Direction,
  PaletteColor,
  PaletteColorOptions,
  Theme,
  createTheme as createMuiTheme,
  responsiveFontSizes,
} from "@mui/material";
import { MomentStatus } from "../../modules/moment-card/types";
import { baseThemeOptions } from "./base-theme-options";
import { darkThemeOptions } from "./dark-theme-options";
import { lightThemeOptions } from "./light-theme-options";

interface Neutral {
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
}

export interface SidebarColorOptions {
  hover: string;
  divider: string;
}

export type StatusColorOptions = Record<MomentStatus, string>;

declare module "@mui/material/styles" {
  interface Palette {
    neutral: Neutral;
    disabled: PaletteColor;
    sidebar: Partial<SidebarColorOptions>;
    status: StatusColorOptions;
  }

  interface TypeText {
    sidebar: string;
    discreet: string;
  }

  interface PaletteOptions {
    neutral?: Neutral;
    disabled: PaletteColorOptions;
    sidebar: Partial<SidebarColorOptions>;
    status: StatusColorOptions;
  }

  interface Components {
    PageHeader?: {
      defaultProps?: Partial<CssBaselineProps>;
      styleOverrides?: CSSObject;
    };
    MomentEditorSidebar?: {
      defaultProps?: Partial<CssBaselineProps>;
      styleOverrides?: CSSObject;
    };
    MomentEditor?: {
      defaultProps?: Partial<CssBaselineProps>;
      sidebar?: Components["MomentEditorSidebar"];
    };
  }

  interface TypeBackground {
    active: string;
    default: string;
    paper: string;
    contrast: string;
    baseTrack: string;
    switchTrack: string;
    sidebar: string;
    drawer: string;
    selected: string;
  }

  interface ZIndex {
    footer: number;
  }
}

export interface ThemeConfig {
  direction?: Direction;
  responsiveFontSizes?: boolean;
  mode: "light" | "dark" | "ce-admin";
}

export const createTheme = (config: ThemeConfig): Theme => {
  let theme = createMuiTheme(
    baseThemeOptions,
    config.mode === "dark" ? darkThemeOptions : lightThemeOptions,
    {
      direction: config.direction,
    },
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
