import { FC } from "react";
import { useTranslation } from "react-i18next";
import { usePopover } from "../../utils/hooks/use-popover";
import { TemplateImageButton } from "../template-preview/template-image-button";
import { AiRefineImageModal } from "./ai-refine-image-modal";

export const AiRefineImage: FC = () => {
  const { open, handleOpen, handleClose } = usePopover<HTMLButtonElement>();
  const { t } = useTranslation();

  return (
    <>
      <TemplateImageButton onClick={handleOpen} aria-label={t("Edit")}>
        {t("Edit")}
      </TemplateImageButton>
      <AiRefineImageModal open={open} onClose={handleClose} />
    </>
  );
};
