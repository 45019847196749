import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { SegmentCondition } from "../../types";
import { Operator, filterFieldOperators, mapOperator } from "./operators";

export const AutocompleteOpSelect: FC<{
  condition: SegmentCondition;
  handleFieldChange: (value: SegmentCondition) => void;
  disabled?: boolean;
  fieldType: "TEXT" | "COUNTRY";
}> = ({ condition, handleFieldChange, disabled, fieldType }) => {
  const { t } = useTranslation();

  return (
    <Select
      id={`operator-${condition.id}`}
      variant="standard"
      fullWidth
      name={`segment_condition.${condition.id}.operator`}
      label={t("Operator")}
      onChange={(event: SelectChangeEvent) => {
        const value = event.target.value as Operator;
        const isEmpty = value === "IS BLANK" || value === "IS NOT BLANK";
        handleFieldChange({
          ...condition,
          invert: !(value === "IS" || value === "IS BLANK"),
          operation: isEmpty ? "IS EMPTY" : "IS",
          values: isEmpty ? [] : condition.values,
        });
      }}
      labelId="operator-label"
      value={mapOperator(condition)}
      disabled={disabled}
    >
      {filterFieldOperators[fieldType].operators.map((operatorInfo) => (
        <MenuItem key={operatorInfo.operator} value={operatorInfo.operator}>
          {operatorInfo.label(t)}
        </MenuItem>
      ))}
    </Select>
  );
};
