import { createContext, FC, ReactNode, useCallback, useRef, useState } from "react";
import { config } from "../../config";
import { MomentCardDropData } from "../../modules/moment-card/types/moment-card-drop-data";
import { useFlag } from "../../server/optimizely";
import { UUID } from "../uuid";

type MomentCardContextValue =
  | {
      postMessage: (dragId: UUID, data: MomentCardDropData | undefined) => void;
      loaded: true;
    }
  | { loaded: false; postMessage: null };

export const MomentCardDragAndDropContext = createContext<MomentCardContextValue>({
  loaded: false,
  postMessage: null,
});

export const MomentCardDragAndDropProvider: FC<{
  children?: ReactNode;
}> = (props) => {
  const { children } = props;
  const pipeRef = useRef<HTMLIFrameElement>(null);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [drag_and_drop] = useFlag("drag_and_drop");

  const postMessage = useCallback(
    (dragId: UUID, data: MomentCardDropData | undefined) => {
      if (!iframeLoaded) return;
      pipeRef.current?.contentWindow?.postMessage(
        {
          type: "moment_drag",
          dragId,
          data,
        },
        { targetOrigin: config.auth0.base_url },
      );
    },
    [iframeLoaded],
  );

  if (!drag_and_drop) return children;

  return (
    <MomentCardDragAndDropContext.Provider
      value={iframeLoaded ? { postMessage, loaded: true } : { postMessage: null, loaded: false }}
    >
      <>
        {children}
        <iframe
          title="pipe"
          ref={pipeRef}
          src={`${config.auth0.base_url}/moment-card-copy`}
          width="0"
          height="0"
          style={{ visibility: "hidden" }}
          onLoad={() => setIframeLoaded(true)}
        ></iframe>
      </>
    </MomentCardDragAndDropContext.Provider>
  );
};
