import { Tooltip } from "@mui/material";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Pencil } from "../../../../../styles/icons/pencil";
import { SlackAlias, SlackAliasWithDefault } from "../../../../slack/server/slack-supabase-service";
import { SlackAliasForm } from "./alias-form";

export const EditAction: FC<{
  alias: SlackAliasWithDefault;
  onComplete: (alias: SlackAlias) => void;
}> = ({ alias, onComplete }) => {
  const { t } = useTranslation();
  const [openEdit, setOpenEdit] = useState(false);

  return (
    <>
      <Tooltip title={t("Edit this alias")} placement="top" sx={{ cursor: "pointer", mr: 1 }}>
        <Pencil fontSize="small" color={"info"} onClick={() => setOpenEdit(true)} />
      </Tooltip>
      <SlackAliasForm
        onComplete={onComplete}
        existing={alias}
        open={openEdit}
        close={() => setOpenEdit(false)}
      />
    </>
  );
};
