import { PostgrestClient } from "@supabase/postgrest-js";
import { Database } from "../../../server/supabase/types/database-definitions";
import { ZERO_INTERVAL } from "../../../utils/interval";
import { ISO8601_TIME } from "../../../utils/iso8601";
import { UUID } from "../../../utils/uuid";
import { SupabaseScheduleService } from "./supabase-schedule-service";

export class ScheduleLoader {
  private readonly supabase: PostgrestClient<Database>;
  constructor(supabase: PostgrestClient<Database>) {
    this.supabase = supabase;
  }

  async load(scheduleId: UUID | null | undefined): Promise<
    | {
        data: Omit<Database["public"]["Tables"]["schedule"]["Row"], "account_id" | "created_at">;
        error?: never;
      }
    | { data?: never; error: string }
  > {
    if (!scheduleId) {
      return { error: "Schedule Id is empty" };
    }
    const { error, data, status } = await new SupabaseScheduleService(this.supabase).get(
      scheduleId,
    );
    if (status === 406) {
      return {
        data: {
          id: scheduleId,
          send_at: "12:00" as ISO8601_TIME,
          event_key: "",
          event_initial: true,
          event_repeat: false,
          on_event_key: null,
          dow: {
            sunday: 0,
            monday: 0,
            tuesday: 0,
            wednesday: 0,
            thursday: 0,
            friday: 0,
            saturday: 0,
          },
          interval: ZERO_INTERVAL,
          interval_json: null,
        },
      };
    }
    if (error || !data) return { error: error?.message };

    return { data: { ...data, interval: data.interval ?? ZERO_INTERVAL } };
  }
}
