import { MenuItem, Select, SelectChangeEvent, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { MomentFormMoment } from "../../../moment/types/moment-form-moment";
import { UsableAccountPersonField } from "../../server/supabase-segment-field-service";
import { BuildSegmentAudience } from "../build-segment-audience";

export const SegmentOperation: FC<{
  disabled?: boolean;
  fields: UsableAccountPersonField[];
  disableAudienceClearable: boolean;
}> = ({ disabled, fields, disableAudienceClearable }) => {
  const { t } = useTranslation();
  const { control } = useFormContext<MomentFormMoment>();

  return (
    <Stack direction={"row"} gap={2} alignItems={"center"}>
      {<Typography>{t("Select")}</Typography>}
      <BuildSegmentAudience
        disabled={disabled}
        segmentFields={fields}
        disableAudienceClearable={disableAudienceClearable}
      />
      <Typography id="root-operator-label">{t("that match")}</Typography>
      <Controller
        name="segment.operation"
        control={control}
        render={({ field }) => (
          <Trans>
            <Select
              id="root-operation"
              name="segment.group.operation"
              onChange={(event: SelectChangeEvent) => {
                const value = event.target.value;
                field.onChange(value);
              }}
              labelId="root-operator-label"
              value={field.value ? field.value : "AND"}
              disabled={disabled}
              size="small"
              variant="standard"
            >
              <MenuItem value={"AND"}>{t("All")}</MenuItem>
              <MenuItem value={"OR"}>{t("Any")}</MenuItem>
            </Select>
          </Trans>
        )}
      />
      <Typography id="root-operator-label">{t("of the following:")}</Typography>
    </Stack>
  );
};
