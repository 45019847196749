import { Box, Tab, Tabs } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import { PageContent, PageContentProps } from "../../layout/components/page-content";

type RouteTab =
  | {
      type: "inferred";
      component: React.JSX.Element;
      data: {
        name: string;
      };
    }
  | {
      type: "explicit";
      component: React.JSX.Element;
      data: {
        name: string | React.JSX.Element;
        route: string;
      };
    };

interface RouteTabsProps extends PageContentProps {
  routeDepth: number;
  defaultPage: React.JSX.Element;
  tabs: RouteTab[];
}
export const RouteTabs: FC<RouteTabsProps> = ({
  defaultPage,
  tabs,
  routeDepth,
  ...pageContentProps
}) => {
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <PageContent {...pageContentProps}>
      <Tabs
        indicatorColor="primary"
        scrollButtons="auto"
        textColor="primary"
        value={location.pathname.split("/")[routeDepth]?.toUpperCase() ?? "/"}
        variant="scrollable"
        sx={{ mt: 3 }}
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.type === "inferred" ? tab.data.name : tab.data.route}
            style={{ pointerEvents: "auto" }}
            label={tab.type === "inferred" ? t(tab.data.name) : tab.data.name}
            value={(tab.type === "inferred"
              ? normalizeName(tab.data.name)
              : tab.data.route
            ).toUpperCase()}
            sx={{ ml: 2 }}
            component={Link}
            to={(tab.type === "inferred"
              ? normalizeName(tab.data.name)
              : tab.data.route
            ).toLowerCase()}
          />
        ))}
      </Tabs>
      <Box sx={{ mt: 4 }}>
        <Routes>
          <Route path="/" element={defaultPage} />
          {tabs.map((tab) => (
            <Route
              key={tab.type === "inferred" ? tab.data.name : tab.data.route}
              path={`${(tab.type === "inferred"
                ? normalizeName(tab.data.name)
                : tab.data.route
              ).toLowerCase()}/*`}
              element={tab.component}
            />
          ))}
        </Routes>
      </Box>
    </PageContent>
  );
};

function normalizeName(name: string): string {
  return encodeURIComponent(name.split(" ").join("_"));
}
