import { Add, History, Language } from "@mui/icons-material";
import { Button, ButtonGroup, ButtonProps, Link, Popover } from "@mui/material";
import { FC, MouseEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { UUID } from "../../utils/uuid";

interface NewMomentButtonProps extends ButtonProps {
  title?: string;
  discoverHref: string;
  newMomentHref: string;
}

export const NewMomentButton: FC<NewMomentButtonProps> = ({
  title,
  discoverHref,
  newMomentHref,
  ...props
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLElement>): void => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  const buttonStyles = { justifyContent: "flex-start" };

  return (
    <>
      <Button
        data-analytics-id="tabbed-programs-new-moment-button"
        onClick={handleClick}
        color="primary"
        variant="contained"
        {...props}
      >
        {title ?? t("New Moment")}
      </Button>
      <Popover
        open={open}
        id={id}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        transformOrigin={{ horizontal: "left", vertical: -5 }}
      >
        <ButtonGroup orientation="vertical">
          <Button
            data-analytics-id="tabbed-programs-new-moment-button-from-scratch"
            variant="contained"
            color="primary"
            LinkComponent={Link}
            href={newMomentHref}
            sx={buttonStyles}
          >
            <Add sx={{ mr: 1 }} />
            {t("From Scratch")}
          </Button>
          <Button
            data-analytics-id="tabbed-programs-new-moment-button-discover"
            variant="contained"
            color="primary"
            LinkComponent={Link}
            href={discoverHref}
            sx={buttonStyles}
          >
            <Language sx={{ mr: 1 }} />
            {t("Discover a Moment")}
          </Button>
        </ButtonGroup>
      </Popover>
    </>
  );
};

export const SentMomentsButton: FC<{ programId?: UUID }> = ({ programId }) => {
  const { t } = useTranslation();
  return (
    <Button
      data-analytics-id="tabbed-programs-sent-moments"
      LinkComponent={Link}
      href={programId !== undefined ? `/moments/history?program=${programId}` : "/moments/history"}
      variant="outlined"
      color="primary"
      sx={{ ml: 2 }}
    >
      <History sx={{ mr: 1 }} />
      {t("Sent Moments")}
    </Button>
  );
};
