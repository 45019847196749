import { useSupabaseLoader } from "../../../server/supabase/hooks";
import {
  PopulatedProgram,
  SupabaseProgramService,
} from "../../programs/server/supabase-program-service";

export const usePrograms = (): ReturnType<typeof useSupabaseLoader<PopulatedProgram[]>> => {
  return useSupabaseLoader(async ({ supabase }) => {
    const { data: programsData, error: programsError } = await new SupabaseProgramService(
      supabase,
    ).getAllPopulated();

    if (programsError || !programsData) throw programsError || new Error("No programs found");

    return programsData;
  }, []);
};
