import { useSupabaseCallback } from "../../../server/supabase/hooks";

import { SegmentStateService } from "../server/segment-state-service";
import { SupabaseSegmentService } from "../server/supabase-segment-service";
import { Segment } from "../types";

export function useSegmentService(): (segment: Segment | null) => Promise<void | undefined> {
  return useSupabaseCallback(async ({ supabase }, segment: Segment | null) => {
    if (!segment) return;

    const service = new SegmentStateService(supabase);
    await service.saveSegment(segment);

    const supabaseSegmentService = new SupabaseSegmentService(supabase);

    const { data: newSegment, error: newSegmentError } =
      await supabaseSegmentService.getSegmentData(segment.id);

    if (newSegmentError || !newSegment) throw new Error("Invalid Segment");
  }, []);
}
