import { TextField, TextFieldProps } from "@mui/material";
import { FC, useEffect, useRef } from "react";

export const TextFieldWithAutoFocus: FC<TextFieldProps> = (params) => {
  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    setTimeout(() => {
      ref.current?.focus();
    }, 100);
  }, []);

  return <TextField {...params} inputRef={ref} />;
};
