import { useSupabaseLoader } from "../../../server/supabase/hooks";
import { AccountLimitKey, SupabaseAccountLimitService } from "../server/supabase-account-service";

export function useAccountLimit(
  key: AccountLimitKey,
  defaultValue = 0,
): ReturnType<typeof useSupabaseLoader<number>> {
  return useSupabaseLoader(
    async ({ supabase }) => {
      const { data, error } = await new SupabaseAccountLimitService(supabase).getKey(key);

      if (error) throw new Error(error.message);

      return data?.count ?? defaultValue;
    },
    [key, defaultValue],
  );
}
