import { Card, CardActionArea, CardContent, CardMedia, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";

export const RecipesCard: FC = () => {
  const { t } = useTranslation();

  return (
    <Card raised>
      <CardActionArea href="/recipes" sx={{ height: "100%" }} draggable={false}>
        <CardMedia
          image="https://images.changeengine.com/ce-cards/create-with-ai.png"
          alt=""
          component="img"
          height="150px"
          draggable={false}
        />

        <CardContent>
          <Typography variant="h6">{t("Use a Recipe")}</Typography>
          <Typography variant="body1" mt={2}>
            {t("Our Recipes will help you create a Moment in moments")}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
