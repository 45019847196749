import { Stack, StackProps } from "@mui/material";
import { FC } from "react";

export const SequenceHeader: FC<StackProps> = ({ children, ...props }) => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" mx={4} {...props}>
      {children}
    </Stack>
  );
};
