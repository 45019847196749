import { BaseSelectProps, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useAtomValue } from "jotai";
import { FC } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { BrandKitStyle, isStandardBrandKitStyle } from "../brand-kit/server/brand-kit-service";
import { brandingAtom } from "../brand-kit/store";

interface VisualStyleSelectProps extends Omit<BaseSelectProps, "onChange"> {
  onChange: (style: BrandKitStyle) => void;
}

export const VisualStyleSelect: FC<VisualStyleSelectProps> = ({ onChange, ...props }) => {
  const { t } = useTranslation();
  const { branding_type: brandKitStyle } = useAtomValue(brandingAtom);
  const label = t("Image style");
  return (
    <FormControl sx={{ width: "13em" }}>
      <InputLabel htmlFor="visual-style-select">{label}</InputLabel>
      <Select
        id="visual-style-select"
        value={brandKitStyle.value ?? "bold"}
        onChange={(e) => {
          const val = e.target.value as string;

          if (!isStandardBrandKitStyle(val)) {
            toast.error(t("Invalid Visual Style"));
            return;
          }
          onChange({ type: "standard", value: val });
        }}
        label={label}
        inputProps={{
          "aria-label": label,
        }}
        {...props}
      >
        <MenuItem value="bold">{t("Bold")}</MenuItem>
        <MenuItem value="sophisticated">{t("Sophisticated")}</MenuItem>
        <MenuItem value="organic">{t("Organic")}</MenuItem>
        <MenuItem value="formal">{t("Formal")}</MenuItem>
        <MenuItem value="minimalist">{t("Minimalist")}</MenuItem>
      </Select>
    </FormControl>
  );
};
