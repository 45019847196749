import { useAuth0 } from "@auth0/auth0-react";
import { useCallback } from "react";

export const useToken = (): (() => Promise<string>) => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  return useCallback(async () => {
    try {
      return await getAccessTokenSilently();
    } catch {
      await loginWithRedirect();
    }
    return "";
  }, [getAccessTokenSilently, loginWithRedirect]);
};
