import { Card, CardActionArea, CardContent, CardMedia, CardProps, Divider } from "@mui/material";
import React, { FC } from "react";
import { Account } from "../../generic/server/supabase-account-service";
import { UseMomentProperties } from "../types";
import { Draggable } from "./drag-and-drop/moment-card-draggable";
import { MomentCardActions } from "./moment-card-actions";
import { MomentCardImage } from "./moment-card-image";
import { SegmentAndChannel } from "./moment-card-segment-and-channel";
import { MomentCardTitle } from "./moment-card-title";
import { When } from "./moment-card-when";
import { MomentPill } from "./moment-pill";

export interface MomentStatus {
  editable: boolean;
  publishable: boolean;
  pausable: boolean;
  sent: boolean;
}

export interface MomentCardProps extends CardProps {
  reload?: () => void;
  moment: UseMomentProperties;
  showProgram?: boolean;
  showStatus?: boolean;
  account?: Pick<Account, "id" | "name">;
  draggable: boolean;
}

export const MomentCard: FC<MomentCardProps> = ({
  moment,
  reload,
  showProgram = false,
  showStatus = true,
  account,
  draggable,
  ...cardProps
}) => {
  const { cover_image_path, title, program } = moment;

  const theme_colour = program?.theme_colour ?? undefined;
  const theme_icon = program?.theme_icon ?? undefined;
  const programTitle = program?.title ?? undefined;

  const card = (
    <Card data-testid="moment-card" {...cardProps}>
      <CardActionArea
        href={`/moments/${moment.id || ""}`}
        {...(!moment?.id && { disabled: true })}
        draggable={false}
      >
        <CardMedia sx={{ height: 200 }} draggable={false}>
          <div
            style={{
              position: "relative",
              width: "100%",
              height: "100%",
              backgroundColor: theme_colour,
            }}
          >
            {showStatus && (
              <MomentPill
                sx={{ zIndex: "2", position: "absolute", m: 1.5 }}
                status={moment.status}
              />
            )}
            <MomentCardImage
              cover_image_path={cover_image_path || undefined}
              title={title || undefined}
              theme_colour={theme_colour}
              theme_icon={theme_icon}
            />
            <MomentCardTitle
              title={moment.title}
              showProgram={showProgram}
              programTitle={programTitle}
              themeColour={theme_colour ?? ""}
            />
          </div>
        </CardMedia>
      </CardActionArea>
      <CardContent sx={{ pt: 0, marginTop: 3, mb: -2.6 }}>
        <When moment={moment} />
        <SegmentAndChannel moment={moment} />
        <Divider sx={{ my: 1 }} />
        <MomentCardActions moment={moment} reload={reload} showSequenceIcon />
      </CardContent>
    </Card>
  );

  if (!draggable) return card;

  return draggable && account && moment ? (
    <Draggable account={account} momentId={moment?.id ?? undefined}>
      {card}
    </Draggable>
  ) : (
    card
  );
};

export const MomentCardMemo = React.memo(MomentCard);
