import { Alert, Divider, LinearProgress, Stack, Typography } from "@mui/material";
import { FC, Suspense, lazy, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { EmailDomainConfigurationTable } from "../../modules/channels/email/components/email-domain-table";
import { SupabaseCustomEmailDomainService } from "../../modules/channels/email/server/supabase-custom-email-domain-service";
import { SlackAlias } from "../../modules/channels/slack/components/slack-alias/alias";
import { SlackCard } from "../../modules/channels/slack/components/slack-card";
import { RouteTabs } from "../../modules/generic/components/route-tabs";
import { useAccountLimit } from "../../modules/generic/hooks/use-account-limit";
import { Page } from "../../modules/layout/components/page";
import { PageHeader } from "../../modules/layout/components/page-header";
import { PageHeading } from "../../modules/layout/components/page-heading";
import { usePermissions } from "../../server/auth/use-permissions";
import { useSupabase } from "../../server/supabase/hooks";
import { Database } from "../../server/supabase/types/database-definitions";
import { UUID } from "../../utils/uuid";
import { Teams } from "./teams";

// The MIME encoding is HUGE
const EmailConfigurationTable = lazy(
  () => import("../../modules/channels/email/components/email-configuration-table"),
);

const DefaultPage: FC<{ channel?: "slack" | "email" }> = ({ channel }) => {
  const navigate = useNavigate();
  useEffect(() => channel && navigate(`/manage/channels/${channel}`, { replace: true }));
  return null;
};

export type SlackConfig = { id: UUID; name: string | null; slack_app_id: UUID | null };

const Slack: FC = () => {
  const [slackConfigs, setSlackConfigs] = useState<SlackConfig[]>([]);

  const { data: slackConnections, loading } = useAccountLimit("slack_connections", 1);

  useSupabase(async ({ supabase }) => {
    const { data } = await supabase.from("slack_config").select("id,name,slack_app_id");

    if (data) setSlackConfigs(data);
  }, []);

  if (loading) return null;

  const multiSlack = slackConfigs.length < (slackConnections ?? 1);
  return (
    <>
      <Stack direction="row" spacing={2}>
        {slackConfigs.map((slackConfig) => (
          <SlackCard
            key={slackConfig.id}
            slackConfigs={slackConfigs}
            setSlackConfigs={setSlackConfigs}
            slackConfigId={slackConfig.id}
            slackConfigName={slackConfig.name}
            multiSlack={multiSlack}
          />
        ))}
        {multiSlack ? (
          <SlackCard
            slackConfigs={slackConfigs}
            setSlackConfigs={setSlackConfigs}
            multiSlack={multiSlack}
          />
        ) : null}
      </Stack>
      {slackConfigs.length > 0 && (
        <>
          <Divider sx={{ mt: 3, mb: 3 }} />
          <SlackAlias />
        </>
      )}
    </>
  );
};

export const Email: FC = () => {
  const { t } = useTranslation();
  const { ce_admin } = usePermissions();

  const [customDomains, setCustomDomains] = useState<
    Database["public"]["Tables"]["custom_email_domain"]["Row"][] | "LOADING" | undefined
  >("LOADING");

  useSupabase(async ({ supabase, account_id }) => {
    const supabaseDomainService = new SupabaseCustomEmailDomainService(supabase);
    const { data } = await supabaseDomainService.getAll(account_id, { order: [{ column: "id" }] });
    data && data.length > 0 ? setCustomDomains(data) : setCustomDomains(undefined);
  }, []);

  return (
    <>
      {customDomains === "LOADING" ? (
        <LinearProgress />
      ) : (
        <>
          <Stack direction="column">
            <Typography variant="h6">{t("Configure Custom Domains")}</Typography>
            <Typography component={"div"} sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              {t("Configure the domains we're authorized to send email from")}
            </Typography>
          </Stack>
          <EmailDomainConfigurationTable defaultCustomDomains={customDomains} />
          <Divider sx={{ my: 3 }} />
          <Stack direction="column" spacing={0}>
            <Typography variant="h6">{t("Configure Email")}</Typography>
            <Typography component={"div"} sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              {t("Configure the email addresses we're authorized to send email from")}
            </Typography>
            {customDomains !== undefined ? (
              <Suspense fallback={<LinearProgress />}>
                <EmailConfigurationTable />
              </Suspense>
            ) : ce_admin ? (
              <Alert severity="info">
                <Typography variant="body2">
                  {t("You must first configure a custom email domain to add an email address")}
                </Typography>
              </Alert>
            ) : (
              <Alert severity="info">
                <Typography variant="body2">
                  {t(
                    "To configure an email address, please contact support to configure a custom domain",
                  )}
                </Typography>
              </Alert>
            )}
          </Stack>
        </>
      )}
    </>
  );
};

const ManageChannelsPage: FC = () => {
  const { t } = useTranslation();

  const { ce_admin } = usePermissions();

  return (
    <Page title={t("Channels | ChangeEngine")}>
      <PageHeader>
        <PageHeading
          heading={t("Channels")}
          subheading={t("Manage your message delivery channels")}
        />
      </PageHeader>
      <RouteTabs
        routeDepth={3}
        defaultPage={<DefaultPage channel="slack" />}
        tabs={[
          { type: "inferred", data: { name: "Slack" }, component: <Slack /> },
          { type: "inferred", data: { name: "Email" }, component: <Email /> },
          ...(ce_admin
            ? [{ type: "inferred" as const, data: { name: "Teams" }, component: <Teams /> }]
            : []),
        ]}
      />
    </Page>
  );
};

export default ManageChannelsPage;
