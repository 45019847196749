import { useSupabaseLoader } from "../../../server/supabase/hooks";
import { UUID } from "../../../utils/uuid";
import { SequenceItem, SupabaseSequenceItemService } from "../server/sequence-item-service";

export const useSequenceItem = (
  id: UUID | undefined | null,
): ReturnType<typeof useSupabaseLoader<SequenceItem | undefined>> => {
  return useSupabaseLoader(
    async ({ supabase }) => {
      if (!id) return;

      const { data, error } = await new SupabaseSequenceItemService(supabase).getForMoment(id);

      if (!data) return;
      if (error) throw error;

      return data;
    },
    [id],
  );
};
