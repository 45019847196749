import { FC } from "react";
import { useFlag } from "../../server/optimizely";
import { BrandKitManager } from "../brand-kit/types";
import { CustomStyleSelect } from "./custom-style-select";
import { ImageShapeSelect } from "./image-shape-select";
import { VisualStyleSelect } from "./visual-style-select";

export const ImageStyle: FC<{
  visualStyleSelector?: boolean;
  imageTypeSelector?: boolean;
  manager: BrandKitManager;
}> = ({ visualStyleSelector = false, imageTypeSelector = false, manager }) => {
  const [enabled, , ready] = useFlag("brand_specific_templates_enabled");

  if (!ready) return null;

  return (
    <>
      {imageTypeSelector && visualStyleSelector && enabled && (
        <CustomStyleSelect manager={manager} data-testid="custom-style-select" />
      )}
      {imageTypeSelector && visualStyleSelector && !enabled && (
        <>
          <VisualStyleSelect
            onChange={manager.style.setSelected}
            data-testid="visual-style-select"
          />
          <ImageShapeSelect
            onChange={manager.imageType.setSelected}
            data-testid="image-type-select"
          />
        </>
      )}
      {visualStyleSelector && !imageTypeSelector && (
        <VisualStyleSelect onChange={manager.style.setSelected} data-testid="visual-style-select" />
      )}
    </>
  );
};
