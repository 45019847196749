import { TFunction } from "i18next";
import { isEmpty } from "lodash-es";
import { FC } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useSupabaseCallback } from "../../../server/supabase/hooks";
import { yupErrors } from "../../../utils/yup-errors";
import {
  AccountPersonField,
  SupabaseAccountPersonFieldService,
} from "../../event/server/supabase-account-person-field-service";
import { eventKeyValidator } from "../../event/types";
import { CrudDataGrid } from "../../generic/components/crud-data-grid/crud-data-grid";
import { Columns, Row } from "../../generic/components/crud-data-grid/types";

export const FieldsCrudDataGrid: FC<{
  fields: AccountPersonField[];
}> = ({ fields }) => {
  const { t } = useTranslation();

  const onAdd = useSupabaseCallback(
    async ({ supabase }, field: AccountPersonField) => {
      const { data, error } = await new SupabaseAccountPersonFieldService(supabase).insert({
        ...field,
        merge_sample: field.merge_sample === "" ? field.fallback_value : field.merge_sample,
        connection_merge_sample:
          field.connection_merge_sample === ""
            ? field.merge_sample === ""
              ? field.fallback_value
              : field.merge_sample
            : field.connection_merge_sample,
      });

      if (!data || error) {
        toast.error(t("Something went wrong"));
        return;
      }
      toast.success(t("Saved"));
      return data;
    },
    [t],
  );

  const onUpdate = useSupabaseCallback(
    async ({ supabase }, field: AccountPersonField) => {
      const { data, error } = await new SupabaseAccountPersonFieldService(supabase).update(
        field.id,
        {
          ...field,
          merge_sample: field.merge_sample === "" ? field.fallback_value : field.merge_sample,
          connection_merge_sample:
            field.connection_merge_sample === ""
              ? field.merge_sample === ""
                ? field.fallback_value
                : field.merge_sample
              : field.connection_merge_sample,
        },
      );

      if (!data || error) {
        toast.error(t("Something went wrong"));
        return;
      }
      toast.success(t("Saved"));
      return data;
    },
    [t],
  );

  return (
    <CrudDataGrid<Row<AccountPersonField>>
      initialRows={fields}
      columns={columns(t)}
      initialData={
        {
          display_name: "",
          key: "",
          merge_sample: "",
          connection_merge_sample: "",
          merge_field: true,
          connection_merge_field: false,
          channel_merge_field: false,
          segmentation_field: false,
          field_type: "TEXT",
          fallback_value: "",
        } as AccountPersonField
      }
      onAdd={onAdd}
      onUpdate={onUpdate}
      gridOverrides={{
        isCellEditable: (params) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const row: Row<AccountPersonField> = params.row;
          if (params.colDef?.field === "key") return row.isNew || isEmpty(row.key);
          if (params.colDef?.field === "channel_merge_field")
            return row.isNew || !row.channel_merge_field;
          if (params.colDef?.field === "merge_field") return row.isNew || !row.merge_field;
          if (params.colDef?.field === "connection_merge_field")
            return row.isNew || !row.connection_merge_field;
          return true;
        },
      }}
    />
  );
};

const columns = (t: TFunction): Columns<AccountPersonField> => ({
  display_name: {
    headerName: t("Display Name"),
    flex: 1,
    editable: true,
    valueGetter: (_, row) => row.display_name ?? "-",
  },
  key: {
    headerName: t("Key/Merge Tag"),
    flex: 1,
    editable: true,
    options: {
      validator: async (value) =>
        await eventKeyValidator
          .validate(value)
          .then(() => undefined)
          .catch(yupErrors),
    },
  },
  merge_sample: {
    headerName: t("Merge Sample"),
    flex: 1,
    editable: true,
  },
  connection_merge_sample: {
    headerName: t("Connection Merge Sample"),
    flex: 1,
    editable: true,
  },
  merge_field: {
    headerName: t("Merge Field"),
    flex: 1,
    type: "boolean",
    editable: true,
  },
  connection_merge_field: {
    headerName: t("Connection Merge Field"),
    flex: 1,
    type: "boolean",
    editable: true,
  },
  channel_merge_field: {
    headerName: t("Channel Merge Field"),
    flex: 1,
    type: "boolean",
    editable: true,
  },
  segmentation_field: {
    headerName: t("Segmentation Field"),
    flex: 1,
    type: "boolean",
    editable: true,
  },
  fallback_value: {
    headerName: t("Fallback Value"),
    flex: 1,
    editable: true,
  },
});
