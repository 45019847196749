import { UnfoldLess, UnfoldMore } from "@mui/icons-material";
import { IconButton, IconButtonProps, Tooltip } from "@mui/material";
import { ReactElement, useState } from "react";

interface Props extends Omit<IconButtonProps, "onClick"> {
  onClick: (isExpanded: boolean) => void;
  initialState?: boolean;
  expandedMessage?: string;
  minimizedMessage?: string;
}

export const ExpandButton = ({
  onClick,
  initialState,
  expandedMessage = "Minimize",
  minimizedMessage = "Expand",
  ...props
}: Props): ReactElement => {
  const [expanded, setExpanded] = useState(initialState);

  const handleClick = (): void => {
    const newState = !expanded;
    setExpanded(newState);
    onClick(newState);
  };

  return (
    <Tooltip title={expanded ? expandedMessage : minimizedMessage}>
      <IconButton
        data-analytics-id={`expand-button-${expanded ? expandedMessage : minimizedMessage}`}
        data-testid="expandButton"
        onClick={handleClick}
        {...props}
      >
        {expanded ? <UnfoldLess data-testid="lessIcon" /> : <UnfoldMore data-testid="moreIcon" />}
      </IconButton>
    </Tooltip>
  );
};
