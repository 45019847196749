import { Paper, Stack } from "@mui/material";
import { useAtomValue, useSetAtom } from "jotai";
import { FC, useEffect } from "react";
import { contentRefineAtoms } from "../ai-refine/store";
import { imageAtom } from "../generate/store";
import { TemplateContent } from "./template-content";
import { TemplateImages } from "./template-images";
import { TemplatePreviewProps } from "./template-preview";

export const BannerTemplatePreview: FC<TemplatePreviewProps> = ({
  loadableTemplateAtom,
  contentTone,
  onContentToneChange,
  onBrandToneChange,
  onChannelChange,
  store,
  brandTone,
  designHuddle = false,
}) => {
  const templateLoader = useAtomValue(loadableTemplateAtom);
  const onImageSelect = useSetAtom(imageAtom);

  const setContentRefineAtoms = useSetAtom(contentRefineAtoms);

  useEffect(() => {
    if (templateLoader.state === "hasData" && templateLoader.data) {
      setContentRefineAtoms({ store, content: templateLoader.data.content });
    }
  }, [templateLoader, store, setContentRefineAtoms]);

  return (
    <Stack direction="column" gap={2} mt={2}>
      <TemplateImages
        loadableTemplateAtom={loadableTemplateAtom}
        onSelect={onImageSelect}
        mb={2}
        carousel
      />
      <Paper
        elevation={1}
        sx={(theme) => ({
          px: 2,
          bgcolor: theme.palette.background.paper,
        })}
      >
        <TemplateContent
          loadableTemplateAtom={loadableTemplateAtom}
          contentTone={contentTone}
          brandTone={brandTone}
          designHuddle={designHuddle}
          onContentToneChange={onContentToneChange}
          onBrandToneChange={onBrandToneChange}
          onChannelChange={onChannelChange}
        />
      </Paper>
    </Stack>
  );
};
