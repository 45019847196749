import {
  Box,
  Button,
  Chip,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useDialog } from "../../../../utils/hooks/use-dialog";
import { usePermissionsTableData } from "../../hooks/use-permissions-table-data";
import { PermissionsForm } from "./permissions-form";
import { PermissionsTableRow } from "./permissions-table-row";

export const PermissionsTable: FC = () => {
  const { t } = useTranslation();
  const { open, handleClose, handleOpen } = useDialog();

  const { data: permissionsState, loading, reload } = usePermissionsTableData();

  return (
    <>
      {permissionsState && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            my: 2,
          }}
        >
          <Button
            data-analytics-id="permissions-table-add-new"
            color="primary"
            size="large"
            variant="contained"
            onClick={handleOpen}
          >
            {t("Add New")}
          </Button>
          <PermissionsForm
            open={open}
            close={handleClose}
            employees={permissionsState.availableEmployees}
            capacity={permissionsState.capacity}
            programs={permissionsState.programs}
            segments={permissionsState.segments}
            custom_emails={permissionsState.custom_emails}
            onComplete={reload}
          />
          <Box>
            <Chip
              label={`Contributors ${Math.min(
                permissionsState.limits.contributors,
                permissionsState.contributors?.length ?? 0,
              )}/${permissionsState.limits.contributors}`}
            />
            <Chip
              sx={{ ml: 1 }}
              label={`Super Admins ${Math.min(
                permissionsState.limits.super_admins,
                permissionsState.superAdmins?.length ?? 0,
              )}/${permissionsState.limits.super_admins}`}
            />
          </Box>
        </Box>
      )}

      <TableContainer component={Paper}>
        {loading && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width="35%">{t("Name")}</TableCell>
              <TableCell width="15%">{t("Permission Type")}</TableCell>
              <TableCell width="10%">{t("Read-only Programs")}</TableCell>
              <TableCell width="10%">{t("Editable Programs")}</TableCell>
              <TableCell width="10%">{t("Restricted Segments")}</TableCell>
              <TableCell width="10%">{t("Restricted Senders")}</TableCell>
              <TableCell align="right">{t("Actions")}</TableCell>
            </TableRow>
          </TableHead>
          {permissionsState && (
            <TableBody>
              {permissionsState.permissions.map((perm) => (
                <PermissionsTableRow
                  key={perm.id}
                  permission={perm}
                  rawPrograms={permissionsState.programs}
                  employees={permissionsState.availableEmployees.concat(
                    permissionsState.employees.filter((e) => e.person_id === perm.id),
                  )}
                  capacity={permissionsState.capacity}
                  programs={permissionsState.programMap}
                  segments={permissionsState.segments}
                  custom_emails={permissionsState.custom_emails}
                  refresh={reload}
                />
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  );
};
