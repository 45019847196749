import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { FC, Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSupabase } from "../../../server/supabase/hooks";
import { UnreachableCaseError } from "../../../utils/errors/unreachable";
import { ISO8601_DATE, fromISO8601_DATE, toISO8601_DATE } from "../../../utils/iso8601";
import { notEmpty } from "../../../utils/not-empty";
import { SupabasePersonService } from "../../employees/server/supabase-person-service";
import { UsableAccountPersonField } from "../server/supabase-segment-field-service";
import { SegmentCondition } from "../types";
import { AutocompleteValueSelect } from "./segment-value-field-autocomplete";

function monthDayToRegExpExecArray(monthDay: string): RegExpExecArray | null {
  return new RegExp(/\((\d+),(\d+)\)/g).exec(monthDay);
}

function daysIn(monthDay: string): number {
  const r = monthDayToRegExpExecArray(monthDay);
  return r && r[2] ? parseInt(r[2]) : 1;
}

function monthsIn(monthDay: string): number {
  const r = monthDayToRegExpExecArray(monthDay);
  return r && r[1] ? parseInt(r[1]) : 1;
}

function toMonthDay(months: string | number, days: string | number): string {
  return `(${months},${days})`;
}

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const SegmentValueField: FC<{
  condition: SegmentCondition;
  handleFieldChange: (value: string[]) => void;
  error?: string;
  disabled?: boolean;
  fieldType?: UsableAccountPersonField["field_type"];
  segmentField?: UsableAccountPersonField;
}> = ({ condition, handleFieldChange, error, disabled, fieldType, segmentField }) => {
  const { t } = useTranslation();
  const hidden = condition.operation === "IS EMPTY";

  switch (fieldType) {
    case undefined:
      return <TextField label="Value" hidden={hidden} disabled={true} variant="standard" />;
    case "BOOLEAN":
      return null;
    case "TEXT":
      return (
        <AutocompleteValueSelect
          condition={condition}
          handleFieldChange={handleFieldChange}
          error={error}
          segmentField={segmentField}
          disabled={disabled}
          hidden={hidden}
        />
      );
    case "MONTH_DAY":
      return (
        <Box hidden={hidden}>
          <Select
            disabled={disabled}
            variant="outlined"
            sx={{ width: 160, mr: 1 }}
            value={monthsIn(condition.values[0])}
            onChange={(event) => {
              const r = monthDayToRegExpExecArray(condition.values[0]);
              const value = toMonthDay(event.target.value, r && r[2] ? r[2] : 1);
              handleFieldChange([value]);
            }}
          >
            {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
              <MenuItem value={month} key={month}>
                {t(months[month - 1])}
              </MenuItem>
            ))}
          </Select>
          <Select
            disabled={disabled}
            variant="outlined"
            sx={{ width: 80 }}
            value={daysIn(condition.values[0])}
            onChange={(event) => {
              const r = monthDayToRegExpExecArray(condition.values[0]);
              const value = toMonthDay(r && r[1] ? r[1] : 1, event.target.value);
              handleFieldChange([value]);
            }}
          >
            {Array.from({ length: 31 }, (_, i) => i + 1).map((day) => (
              <MenuItem value={day} key={day}>
                {day}
              </MenuItem>
            ))}
          </Select>
        </Box>
      );
    case "DATE":
      return (
        <Box hidden={hidden || condition.values[0] === "TODAY"}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={
                condition.values ? fromISO8601_DATE(condition.values[0] as ISO8601_DATE) : null
              }
              onChange={(value: Date | null) => {
                handleFieldChange(value ? [toISO8601_DATE(value)!] : []);
              }}
              slotProps={{
                textField: {
                  label: segmentField?.display_name,
                  error: Boolean(error),
                  helperText: error,
                  variant: "standard",
                  hidden: hidden,
                },
              }}
              disabled={disabled}
            />
          </LocalizationProvider>
        </Box>
      );
    case "COUNTRY":
      return (
        <CountryAutocompleteValueSelect
          condition={condition}
          handleFieldChange={handleFieldChange}
          error={error}
          segmentField={segmentField}
          disabled={disabled}
          hidden={hidden}
        />
      );
    case "ADDRESS":
      return (
        <TextField
          hidden={hidden}
          disabled={true}
          placeholder="Value"
          variant="standard"
        ></TextField>
      );
    default:
      throw new UnreachableCaseError();
  }
};

const CountryAutocompleteValueSelect: FC<{
  condition: SegmentCondition;
  segmentField?: UsableAccountPersonField;
  handleFieldChange: (value: string[]) => void;
  error?: string;
  disabled?: boolean;
  hidden?: boolean;
}> = ({ condition, handleFieldChange, error, disabled, hidden, segmentField }) => {
  const [selectedFieldOptionsState, setSelectedFieldOptionsState] = useState<{
    options: string[];
    countryInfo: Record<string, string>;
    loading: boolean;
  }>({ options: [], countryInfo: {}, loading: true });

  useSupabase(
    async ({ supabase, account_id }) => {
      if (!disabled && segmentField) {
        const { data: uniqueFieldsData } = await new SupabasePersonService(
          supabase,
        ).getUniqueValuesForCountryType(account_id, segmentField.key ?? "");

        if (uniqueFieldsData) {
          setSelectedFieldOptionsState({
            loading: false,
            options: uniqueFieldsData
              .filter((field) => Boolean(field.value) && field.key === segmentField.key)
              .map((field) => field.value)
              .filter(notEmpty)
              .sort(),
            countryInfo: uniqueFieldsData.reduce(
              (prev, val) => ({ ...prev, [val.value!]: val.name }),
              {},
            ),
          });
        }
      } else {
        setSelectedFieldOptionsState((prev) => ({ ...prev, options: [], loading: false }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [disabled, JSON.stringify(segmentField)],
  );

  return (
    <Autocomplete
      multiple
      size="small"
      id={`value-${condition.id}`}
      options={selectedFieldOptionsState.options}
      loading={selectedFieldOptionsState.loading}
      hidden={hidden}
      disabled={
        disabled ||
        selectedFieldOptionsState.loading ||
        selectedFieldOptionsState.options.length === 0
      }
      sx={{ minWidth: 200 }}
      value={condition.values ? condition.values : []}
      data-testid="value-autocomplete"
      onChange={(event, value) => {
        handleFieldChange(value ? value.slice() : []);
      }}
      getOptionLabel={(option) => selectedFieldOptionsState.countryInfo[option]}
      renderOption={(props, option) => (
        <Box component="li" {...props} key={option}>
          <Box sx={{ mr: 1, flexShrink: 0 }}>
            <img
              width="20"
              height="11"
              src={`https://flagcdn.com/w20/${option.toLowerCase()}.png`}
              alt=""
            />
          </Box>
          {selectedFieldOptionsState.countryInfo[option]}
        </Box>
      )}
      renderTags={(tagValue, getTagProps, state) =>
        tagValue.map((option, index) => (
          <Chip {...getTagProps({ index })} key={option} label={state.getOptionLabel(option)} />
        ))
      }
      renderInput={(params) => (
        <Box sx={{ mt: 0.4 }}>
          <TextField
            {...params}
            sx={{ "& label": { mt: -1.4 } }}
            error={Boolean(error)}
            helperText={error}
            label={segmentField?.display_name}
            variant="standard"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Fragment>
                  {selectedFieldOptionsState.loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </Fragment>
              ),
            }}
          />
        </Box>
      )}
    />
  );
};
