import yup from "../../../utils/yup";

export const buttonTemplateSchema = yup.object({
  color: yup.string().required().default("#ffffff"),
  background_color: yup.string().required().default("#000000"),
  font_size: yup.number().required().default(12),
  border: yup.number().required().default(1),
  border_radius: yup.number().required().default(0),
});

export const emailButtonSettingsSchema = yup.object({
  primary_button: buttonTemplateSchema.default({
    color: "#ffffff",
    background_color: "#0D1035",
    font_size: 16,
    border: 0,
    border_radius: 44,
  }),
  secondary_button: buttonTemplateSchema.default({
    color: "#0D1035",
    background_color: "#ffffff",
    font_size: 16,
    border: 1,
    border_radius: 44,
  }),
});
export type EmailButtonSettings = yup.InferType<typeof emailButtonSettingsSchema>;
export type ButtonTemplateSettings = yup.InferType<typeof buttonTemplateSchema>;
