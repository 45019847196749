import { map } from "lodash-es";
import { serviceURL } from "../../../utils/service-url";
import { AtlasSearchResult } from "./types";

export class AtlasV2Service {
  async search(
    getToken: () => Promise<string>,
    q: string,
    filters?: {
      program?: string[];
      status?: string[];
      channel?: string[];
    },
  ): Promise<{ data: AtlasSearchResult["moment"][]; error: null } | { data: null; error: Error }> {
    const query = new URLSearchParams({ q: q.trim() });

    map(filters ?? {}, (values, key) => values?.forEach((value) => query.append(key, value)));

    const response = await fetch(`${serviceURL("atlas-v2")}moment/search?${query.toString()}`, {
      headers: {
        Authorization: `Bearer ${await getToken()}`,
      },
    });
    if (!response.ok)
      return { data: null, error: new Error((await response.text()) ?? "Unknown error") };

    const data = (await response.json()) as { results: AtlasSearchResult[] };
    return {
      data: data.results.map((x) => x.moment),
      error: null,
    };
  }
}
