import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from "@mui/material";
import { ChangeEvent, ReactElement } from "react";

interface FilterSectionProps {
  title: string;
  selectedItems: string[];
  options: { [key: string]: string };
  onChange: (selectedItems: string[]) => void;
}
export const FilterSection = ({
  title,
  options,
  onChange,
  selectedItems,
}: FilterSectionProps): ReactElement => {
  const selectAll =
    // filter "" so we remove the situation where nothing is selected
    selectedItems.filter((item) => item !== "").length === Object.keys(options).length;

  const renderFilterOption = ([key, value]: [key: string, value: string]): ReactElement => {
    return (
      <FormControlLabel
        key={key}
        control={
          <Checkbox
            checked={selectedItems.includes(key)}
            onChange={(e: ChangeEvent<HTMLInputElement>): void => {
              let newSelectedItems = [...selectedItems];

              if (e.currentTarget.checked) {
                newSelectedItems.push(key);
              } else {
                newSelectedItems = newSelectedItems.filter((item) => item !== key);
              }

              onChange(newSelectedItems);
            }}
            name={key}
            id={value + "-checkbox"}
          />
        }
        label={value}
      />
    );
  };

  return (
    <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
      <FormLabel component="legend">{title}</FormLabel>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={selectAll}
              onChange={() => onChange(selectAll ? [""] : [])}
              value={"all"}
            />
          }
          label={"All"}
          sx={{
            flexGrow: 1,
            mr: 0,
          }}
        />
        {Object.entries(options).map(renderFilterOption)}
      </FormGroup>
    </FormControl>
  );
};
