import { isEmpty } from "lodash-es";
import { useToken } from "../../../server/auth/use-token";
import { useSupabaseLoader } from "../../../server/supabase/hooks";
import { UseMomentProperties } from "../../moment-card/types";
import { useMomentQueryFilters } from "../../moment-filter/hooks/use-server-filtered-moments";
import { SupabaseMomentCardMomentService } from "../../moment/server/supabase-moment-service";
import { PopulatedProgram } from "../../programs/server/supabase-program-service";
import { AtlasV2Service } from "../../search/atlas/client";
import { usePrograms } from "./use-programs";

type Values = { programs: PopulatedProgram[]; moments: UseMomentProperties[] };

export const useCombinedSearch = (): ReturnType<typeof useSupabaseLoader<Values>> => {
  const getToken = useToken();

  const programInfo = usePrograms();
  const { data: programs, loading: programsLoading } = programInfo;

  const filters = useMomentQueryFilters({
    allowedFilters: ["q", "program", "status", "channel"],
    debounceInterval: 0,
  });

  const loader = useSupabaseLoader(
    async ({ supabase, account_id }) => {
      if (!programs || programsLoading) return { programs: [], moments: [] };

      const { q, other } = filters?.rawFilters ?? {};

      const results = q
        ? new AtlasV2Service().search(getToken, q, {
            program: other?.program ?? [],
            status: other?.status ?? [],
            channel: other?.channel ?? [],
          })
        : new SupabaseMomentCardMomentService(supabase).getAll(account_id, {
            limit: 50,
            filter: filters?.filters,
            order: [{ column: "id" }],
          });

      const { data, error } = await results;
      if (error) throw error;

      return {
        programs: programs.filter((program) => {
          const filterPrograms = other?.program ?? [];

          if (program.id && !isEmpty(filterPrograms) && !filterPrograms.includes(program.id)) {
            return false;
          }

          return program.title?.toLowerCase().includes(q?.toLowerCase() ?? "");
        }),
        moments: data,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getToken, programs, JSON.stringify(filters), programsLoading],
  );

  if (programsLoading) return programInfo;

  return loader;
};
