import { useCallback } from "react";
import { useToken } from "../../server/auth/use-token";
import { huddle } from "../huddle/huddle";

export function useDesignHuddlePublish(): (projectId: string) => Promise<string> {
  const getToken = useToken();
  return useCallback(
    async (projectId) => {
      const token = await getToken();

      const response = await huddle.POST("/project/{projectId}/publish", {
        params: { path: { projectId } },
        headers: { Authorization: `Bearer ${token}` },
        body: {},
      });

      return response.data!.templateCode;
    },
    [getToken],
  );
}
