import { serviceURL } from "../../../utils/service-url";
import { TemplateSearchResult } from "../types";
import { AtlasDiscoverRow } from "./supabase-atlas-service";

export async function atlasV2Search(
  q: string,
  token: string,
  unstable?: boolean,
): Promise<TemplateSearchResult[]> {
  const params = new URLSearchParams();
  params.append("q", q);
  params.append("count", "30");
  if (unstable) params.append("unstable", "1");

  const resp = await fetch(`${serviceURL("atlas-v2")}discover/search?${params.toString()}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!resp.ok) throw new Error("Failed to search templates");

  const data = (
    (await resp.json()) as {
      results: { metadata: NonNullable<AtlasDiscoverRow["metadata"]> }[];
    }
  ).results;

  return data.map((d) => ({
    slug: d.metadata.template.data.data.slug,
    program: d.metadata.program.data.slug,
    sequence: d.metadata.sequence?.data.slug,
  }));
}
