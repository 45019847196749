import { FormatListBulleted, ViewModule } from "@mui/icons-material";
import { ToggleButton, ToggleButtonGroup, ToggleButtonGroupProps } from "@mui/material";
import { FC } from "react";

export type View = "grid" | "list";

export type ViewToggleProps = {
  value: View;
  onChange: (view: View) => void;
} & Omit<ToggleButtonGroupProps, "value" | "onChange">;

export const ViewToggle: FC<ViewToggleProps> = ({ value = "grid", onChange, ...props }) => {
  const handleChange = (event: React.MouseEvent<HTMLElement>, newValue: View): void => {
    onChange(newValue);
    event.preventDefault();
  };

  return (
    <ToggleButtonGroup value={value} onChange={handleChange} exclusive color="primary" {...props}>
      <ToggleButton data-analytics-id="tabbed-programs-toggle-grid" value="grid" title="Grid view">
        <ViewModule />
      </ToggleButton>
      <ToggleButton data-analytics-id="tabbed-programs-toggle-list" value="list" title="List view">
        <FormatListBulleted />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
