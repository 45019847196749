import { useSearchParams } from "react-router-dom";
import { ISO8601 } from "../../../utils/iso8601";
import { UUID } from "../../../utils/uuid";

export interface SearchAttributesServer extends Record<string, unknown> {
  id: UUID | null;
  title?: string | null;
  program_id?: string | null;
  status?: string | null;
  chat_moment_id?: UUID | null;
  email_moment_id?: UUID | null;
  when?: ISO8601 | null;
  channel?: string | null;
}

export interface SearchAttributes extends SearchAttributesServer {
  schedule?: { event_key: string | null } | null;
  segment?: { id: UUID | null } | null;
}

export type FilteredMomentInfo<T> = {
  setQueryParams: (params: { [key: string]: undefined | string | string[] }) => void;
  refresh: () => void;
  moments?: T[];
  loading?: boolean;
  error?: boolean;
};

export type FilteredMomentState<T> = Omit<FilteredMomentInfo<T>, "setQueryParams" | "refresh"> & {
  refresh: boolean;
};

export function makeArrayString(value: string[]): string {
  return `(${value
    .filter((x) => x)
    .map((val) => `"${val}"`)
    .join(",")})`;
}

export function useSetQueryParams(): (params: {
  [key: string]: undefined | string | string[];
}) => void {
  const [searchParams, setSearchParams] = useSearchParams();
  return (params: { [key: string]: undefined | string | string[] }): void => {
    Object.entries(params).forEach(([key, value]) => {
      if (typeof value === "string") {
        searchParams.set(key, value);
      } else {
        searchParams.delete(key);
        value?.forEach((val) => searchParams.append(key, val));
      }
    });
    setSearchParams(searchParams);
  };
}
