import { Box, BoxProps, useTheme } from "@mui/material";
import { FC } from "react";

export const PageHeader: FC<BoxProps> = ({ children, ...props }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        px: "var(--page-px)",
        pb: theme.spacing(6),
        pt: theme.spacing(2),
        [theme.breakpoints.only("xs")]: {
          pt: theme.spacing(1),
          pb: theme.spacing(2),
        },
        [theme.breakpoints.up("md")]: {
          pt: theme.spacing(2),
          pb: theme.spacing(4),
        },
        [theme.breakpoints.up("lg")]: {
          pt: theme.spacing(3),
          pb: theme.spacing(5),
        },

        ...theme.components?.PageHeader?.styleOverrides,
      }}
      {...props}
    >
      {children}
    </Box>
  );
};
