import { Skeleton, TextField, useMediaQuery, useTheme } from "@mui/material";
import { useAtomValue } from "jotai";
import { FC } from "react";
import { useController, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { defaultRecipeAtom } from "./store";

export const GeneratePrompt: FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const { control } = useFormContext<{ q: string }>();
  const q = useController({ name: "q", control });

  const recipe = useAtomValue(defaultRecipeAtom);

  if (recipe.state !== "hasData" || !recipe.data)
    return <Skeleton variant="rounded" height={theme.spacing(7)} />;

  return (
    <TextField
      label={t(recipe.data.user_input[0].question!)}
      fullWidth
      {...(matches && { size: "small" })}
      {...q.field}
      autoComplete="off"
      error={!!q.fieldState.error}
      multiline
    />
  );
};
