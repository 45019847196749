import { AutoFixHigh } from "@mui/icons-material";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Divider,
  Typography,
  useTheme,
} from "@mui/material";
import { useAtomValue } from "jotai";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { SeverityPill } from "../generic/components/severity-pill";
import { MomentCardImage } from "../moment-card/components/moment-card-image";
import { MomentCardTitle } from "../moment-card/components/moment-card-title";
import { When } from "../moment-card/components/moment-card-when";
import { amcMomentFamily } from "./store";

export const AIMomentCard: FC<{ query?: string }> = ({ query = "" }) => {
  const amcMomentLoader = useAtomValue(amcMomentFamily(query));
  const { t } = useTranslation();

  const theme = useTheme();
  const colour = theme.palette.primary.main;

  const data = amcMomentLoader.state === "hasData" ? amcMomentLoader.data : null;
  const image = data?.image.state === "hasData" ? data.image.data : null;
  const url = image?.url.state === "hasData" ? image.url.data : undefined;

  if (!data) {
    return null;
  }

  return (
    <Card>
      <CardActionArea
        href={`/generate-moment?initialContext=${encodeURIComponent(JSON.stringify(data.contextQuery))}`}
        draggable={false}
      >
        <CardMedia sx={{ height: 200 }} draggable={false}>
          <div
            style={{
              position: "relative",
              width: "100%",
              height: "100%",
            }}
          >
            <Box sx={{ zIndex: "2", position: "absolute", m: 1.5 }}>
              <SeverityPill color="primary" sx={{ p: 1 }}>
                <AutoFixHigh />
              </SeverityPill>
            </Box>
            <MomentCardImage
              cover_image_path={url}
              title={data.initialContext?.response.title}
              theme_colour={colour}
            />

            <MomentCardTitle
              title={data.initialContext?.response.title ?? ""}
              showProgram={false}
              themeColour={colour}
            />
          </div>
        </CardMedia>
        <CardContent sx={{ pt: 0, marginTop: 3, mb: -2.6 }}>
          <When moment={{ immediate: null, published_at: null, timezone: null }} />
          <Typography variant="body2" color="text.secondary" mt={2}>
            {data.initialContext?.response.what}
          </Typography>

          <Divider sx={{ my: 2 }} />
          <Box display="flex" gap={1} alignItems={"center"}>
            <AutoFixHigh />
            <Typography variant="caption">{t("Generated by ChangeEngine AI")}</Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
