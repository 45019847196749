import { FC, useCallback, useEffect } from "react";
import { LocalStorageService } from "../../../../utils/local-storage";
import { UUID } from "../../../../utils/uuid";
import { MomentCardDropData } from "../../types/moment-card-drop-data";

type MomentDrag = {
  dragId: UUID;
  data: MomentCardDropData;
};

const delay = (ms: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, ms));
const waitForExport = async (
  storage: LocalStorageService,
  id: UUID,
  attempts = 0,
): Promise<MomentCardDropData | undefined> => {
  if (attempts > 10) return undefined;

  const momentExport = storage.get(id);
  if (!momentExport) {
    await delay(500);
    return waitForExport(storage, id, attempts + 1);
  } else {
    return JSON.parse(momentExport) as MomentCardDropData;
  }
};

const MomentCardCopyPage: FC = () => {
  const messageCallback = useCallback(
    (
      e:
        | MessageEvent<{ type: "moment_drag" } & MomentDrag>
        | MessageEvent<{ type: "moment_drop"; dragId: UUID }>,
    ) => {
      if (
        import.meta.env.VITE_SERVICE_ENVIRONMENT !== "local" &&
        !e.origin.endsWith("changeengine.com")
      ) {
        return;
      }
      const storage = new LocalStorageService();
      if (e.data.type === "moment_drag") {
        storage.set(e.data.dragId, JSON.stringify(e.data));
      }
      if (e.data.type === "moment_drop") {
        waitForExport(storage, e.data.dragId)
          .then((data) => {
            e.source?.postMessage(
              {
                ...(data ? data : {}),
                type: "moment_exported",
              },
              { targetOrigin: e.origin },
            );
            storage.remove(e.data.dragId);
          })
          .catch(console.error);
      }
    },
    [],
  );

  useEffect(() => {
    window.addEventListener("message", messageCallback);
    return () => window.removeEventListener("message", messageCallback);
  }, [messageCallback]);

  return <></>;
};

export default MomentCardCopyPage;
