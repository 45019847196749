import { MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { SegmentCondition } from "../../types";
import { Operator, filterFieldOperators, mapDateOperator } from "./operators";

export const DateOpSelect: FC<{
  condition: SegmentCondition;
  handleFieldChange: (value: SegmentCondition) => void;
  disabled?: boolean;
}> = ({ condition, handleFieldChange, disabled }) => {
  const { t } = useTranslation();

  return (
    <>
      <Select
        variant="standard"
        id={`operator-${condition.id}`}
        name={`segment_condition.${condition.id}.operator`}
        label={t("Operator")}
        onChange={(event: SelectChangeEvent) => {
          handleFieldChange({
            ...condition,
            ...mapToOperatorAndCondition(
              event.target.value as Exclude<Operator, "IS NOT" | "IS TRUE" | "IS FALSE">,
              condition,
            ),
          });
        }}
        labelId="operator-label"
        value={mapDateOperator(condition)}
        disabled={disabled}
      >
        {filterFieldOperators.DATE.operators.map((operatorInfo) => (
          <MenuItem key={operatorInfo.operator} value={operatorInfo.operator}>
            {operatorInfo.label(t)}
          </MenuItem>
        ))}
      </Select>
      {condition.values[0] === "TODAY" ? (
        <Typography variant="caption" color="textSecondary" fontSize={10}>
          {t("* relative to send date")}
        </Typography>
      ) : null}
    </>
  );
};

function mapToOperatorAndCondition(
  operator: Exclude<Operator, "IS NOT" | "IS TRUE" | "IS FALSE">,
  condition: SegmentCondition,
): SegmentCondition {
  switch (operator) {
    case "IS BLANK":
      return { ...condition, operation: "IS EMPTY", values: [], invert: false };
    case "IS NOT BLANK":
      return { ...condition, operation: "IS EMPTY", values: [], invert: true };
    case "IS FUTURE":
      return { ...condition, operation: "AFTER", values: ["TODAY"], invert: false };
    case "IS PAST":
      return { ...condition, operation: "BEFORE", values: ["TODAY"], invert: false };
    case "IS FUTURE OR TODAY":
      return { ...condition, operation: "BEFORE", values: ["TODAY"], invert: true };
    case "IS PAST OR TODAY":
      return { ...condition, operation: "AFTER", values: ["TODAY"], invert: true };
    case "ON OR AFTER":
      return { ...condition, operation: "BEFORE", values: condition.values, invert: true };
    case "ON OR BEFORE":
      return { ...condition, operation: "AFTER", values: condition.values, invert: true };
    case "AFTER":
    case "BEFORE":
      return {
        ...condition,
        operation: operator,
        values: condition.values[0] === "TODAY" ? [] : condition.values,
        invert: false,
      };
    default:
      return { ...condition, operation: operator, values: condition.values, invert: false };
  }
}
