import { ControllerFieldState, FieldError } from "react-hook-form";

export const validationError = (
  fieldState: ControllerFieldState,
  submitted: boolean,
): FieldError | undefined => {
  if (!fieldState.error) return;

  return submitted || fieldState.isTouched ? fieldState.error : undefined;
};
