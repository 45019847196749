import { Box, BoxProps } from "@mui/material";
import { FC, memo } from "react";
import { Carousel, CarouselItemProps } from "../discover/carousel/carousel";
import { LoadableTemplateImage } from "../generic/atoms/types/template";
import { TemplateCarouselImage } from "./template-carousel-image";
import { useTemplateImageDimensions } from "./template-image";

export const TemplateImagesCarousel: FC<
  Omit<BoxProps, "onSelect"> & {
    images: LoadableTemplateImage[];
    selectedImage?: number;
    onSelect?: (n: number) => void;
  }
> = ({ images, selectedImage, onSelect, ...props }) => {
  const dimensions = useTemplateImageDimensions();
  // calculate width/height ratio
  const ratio = dimensions.width / dimensions.height;
  const width = 111 * ratio;
  const height = width / ratio;

  return (
    <Box {...props}>
      <Carousel
        items={
          images?.map((image, i) => ({
            ...image,
            key: i.toString(),
          })) ?? []
        }
        Item={Item}
        ItemExtraProps={{
          onSelect,
          selected: (i: number) => selectedImage === i,
          opacity: (i: number) => (selectedImage === i ? 1 : 0.8),
          width: `${width}px`,
          height: `${height}px`,
        }}
        itemWidth={`${width}px`}
        gap={2}
        sx={(theme) => ({
          "--carousel-bgColor": theme.palette.grey[100],
        })}
      />
    </Box>
  );
};

interface ExtraProps {
  onSelect?: (n: number) => void;
  selected: (i: number) => boolean;
  opacity: (i: number) => number;
  width: string;
  height: string;
}

const Item = memo(function Item({
  value: image,
  i,
  extraProps: { onSelect, selected, opacity, width, height },
  ...props
}: CarouselItemProps<LoadableTemplateImage, ExtraProps>) {
  return (
    <TemplateCarouselImage
      key={`img-${i}`}
      image={image}
      selected={selected(i)}
      onClick={() => onSelect?.(i)}
      sx={{
        opacity: opacity(i),
        width,
        height,
        ":hover": {
          opacity: 1,
        },
      }}
      {...props}
    />
  );
});
