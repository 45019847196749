import { useMergeLink } from "@mergeapi/react-merge-link";
import { LoadingButton } from "@mui/lab";
import { FC, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useApiService } from "../../../server/api/client";
import { useSupabaseCallback } from "../../../server/supabase/hooks";
import { HRISIntegrationService } from "../../integrations/client/hris-integration-service";
import { SupabaseSuperAdminHRISService } from "../../integrations/server/supabase-hris-service";

export const HrisIntegrationConnectButton: FC<{
  reloadIntegration: () => void;
  onConnect?: () => Promise<void>;
}> = ({ reloadIntegration, onConnect }) => {
  const { t } = useTranslation();

  const [linkToken, setLinkToken] = useState("");
  const [loading, setLoading] = useState(true);

  const onSuccess = useSupabaseCallback(
    async ({ supabase }, link_token: string) => {
      const { error, data } = await new SupabaseSuperAdminHRISService(supabase).connect(link_token);

      if (error || !data) toast.error(t("Something went wrong"));
      else {
        await onConnect?.();
        reloadIntegration();
      }

      setLoading(false);
    },
    [onConnect, reloadIntegration, t],
    "super_admin",
  );

  useApiService(
    HRISIntegrationService,
    async ({ apiService }) => {
      const { data, error } = await apiService.getLocalToken();
      if (!data || error) toast.error(t("Something went wrong"));
      else setLinkToken(data.link_token);

      setLoading(false);
    },
    [],
  );

  const config = {
    linkToken,
    onSuccess: (v: string) => void onSuccess(v).catch(console.error),
    onExit: () => setLoading(false),
  };

  const { open, isReady } = useMergeLink(config);

  return (
    <LoadingButton
      data-analytics-id="hris-integration-connect"
      loading={loading}
      disabled={!isReady || linkToken === ""}
      onClick={() => {
        setLoading(true);
        open();
      }}
      variant="outlined"
      color="success"
    >
      {t("Connect")}
    </LoadingButton>
  );
};
