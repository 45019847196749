import { usePermissions } from "../../../server/auth/use-permissions";
import { useFlag } from "../../../server/optimizely";

export type AnalyticsPerms = "loading" | "analytics-only" | "both" | "none";

export const useAnalyticsPerms = (): AnalyticsPerms => {
  const [hrisInsightsEnabled, hrisInsightsEnabledLoaded] = useFlag("hris_insights");
  const [analyticsEnabled, , analyticsEnabledLoaded] = useFlag("cumulio");
  const perms = usePermissions();

  if (!perms.super_admin) return "none";

  if (!analyticsEnabledLoaded || !hrisInsightsEnabledLoaded) return "loading";

  if (analyticsEnabled && hrisInsightsEnabled) return "both";

  if (analyticsEnabled) return "analytics-only";

  return "none";
};
