import { Alert } from "@mui/material";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { Page } from "../../modules/layout/components/page";
import { PageContent } from "../../modules/layout/components/page-content";
import { PageHeader } from "../../modules/layout/components/page-header";
import { PageHeading } from "../../modules/layout/components/page-heading";
import { OutboxDataGrid } from "../../modules/outbox/components/data-grid";
import {
  OutboxRecipient,
  SupabaseOutboxRecipientsService,
} from "../../modules/outbox/server/supabase-outbox-service";
import { useSupabase } from "../../server/supabase/hooks";

const OutboxRecipientPage: FC = () => {
  const [recipients, setRecipients] = useState<OutboxRecipient[]>();
  const { t } = useTranslation();

  useSupabase(async ({ supabase, account_id }) => {
    const { data, error } = await new SupabaseOutboxRecipientsService(supabase).getAll(account_id, {
      order: [{ column: "moment_id" }],
    });
    if (!data || error) return;

    setRecipients(data);
  }, []);

  return (
    <Page title={t("Outbox | ChangeEngine")}>
      <PageHeader>
        <PageHeading
          heading={t("Outbox")}
          subheading={t("Moments which have been added to the Outbox.")}
        />
      </PageHeader>
      <PageContent>
        <Alert severity="warning" sx={{ mb: 1 }}>
          Note: Moments being added to the outbox does not guarantee that they are delivered, only
          that the schedule/segment was processed.
        </Alert>
        {recipients && <OutboxDataGrid recipients={recipients} />}
      </PageContent>
    </Page>
  );
};

export default OutboxRecipientPage;
