import {
  Box,
  BoxProps,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { FC, useCallback } from "react";
import { Recipe } from "../self-driving/types";

export const RecipeGridItem: FC<
  { recipe: Recipe; onClick: (recipe: Recipe) => void } & Omit<BoxProps, "onClick">
> = ({ recipe, onClick, ...boxProps }) => {
  const handleClick = useCallback(() => {
    onClick(recipe);
  }, [onClick, recipe]);
  return (
    <Box {...boxProps}>
      <CardActionArea sx={{ height: "100%" }}>
        <Card key={recipe.id} raised onClick={handleClick} sx={{ height: "100%" }}>
          <CardMedia>
            <Box
              sx={{
                height: "100px",
                width: "100%",
                backgroundImage: `url(${recipe.thumbnail})`,
                backgroundSize: "cover",
              }}
            ></Box>
          </CardMedia>
          <CardContent>
            <Typography variant="h6" mb={2}>
              {recipe.name}
            </Typography>
            <Typography variant="body1">{recipe.description}</Typography>
          </CardContent>
        </Card>
      </CardActionArea>
    </Box>
  );
};
