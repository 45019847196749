import { Box, BoxProps } from "@mui/material";
import { ErrorBoundary } from "@sentry/react";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";

interface PageProps extends Omit<BoxProps, "title"> {
  title: string | null;
}

export const Page: FC<PageProps> = ({ children, title, ...props }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (title) {
      document.title = title;
    } else {
      document.title = "ChangeEngine";
    }
  }, [title]);

  return (
    <Box
      sx={(theme) => ({
        "--page-px": `min(min(${theme.spacing(10)}, 5vw), 10vw)`,
        overflow: "auto",
      })}
      {...props}
    >
      <ErrorBoundary
        fallback={
          <Box>
            {t(
              "There was an error rendering this page. If the issue persists, please contact customer support.",
            )}
          </Box>
        }
      >
        {children}
      </ErrorBoundary>
    </Box>
  );
};
