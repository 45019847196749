import { Orientation } from "unsplash-js";
import { Full } from "unsplash-js/dist/methods/photos/types";
import { Photos } from "unsplash-js/dist/methods/search/types/response";
import { Workers } from "../../utils/service-url";
import { ApiResponse, useApiServiceCallback } from "./client";
import { WorkerApiService } from "./worker";

export class UnsplashService extends WorkerApiService {
  module = "unsplash" as Workers;

  async search(query: string, orientation?: Orientation): Promise<ApiResponse<{ photos: Photos }>> {
    const settings = new URLSearchParams();
    settings.set("query", query);
    if (orientation) settings.set("orientation", orientation);

    return await this.get(`search?${settings.toString()}`);
  }

  async ackDownload(url: string): Promise<ApiResponse<{ success: boolean }>> {
    return await this.post("download", { url });
  }

  async getId(id: string): Promise<ApiResponse<{ full: Full }>> {
    return await this.get(`get/${id}`);
  }
}

export const useUnsplashSearch = (): ((
  query: string,
  orientation: Orientation,
) => Promise<ApiResponse<{ photos: Photos }>>) => {
  return useApiServiceCallback(
    UnsplashService,
    async ({ apiService }, query: string, orientation: Orientation) =>
      await apiService.search(query, orientation),
    [],
  );
};
