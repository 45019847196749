import { useState } from "react";
import { useSupabase } from "../../../server/supabase/hooks";
import { notEmpty } from "../../../utils/not-empty";
import { SupabasePersonService } from "../../employees/server/supabase-person-service";
import { UsableAccountPersonField } from "../server/supabase-segment-field-service";

export function useSegmentFieldValueOptions(
  segmentField?: UsableAccountPersonField,
  disabled?: boolean,
): {
  options: string[];
  loading: boolean;
} {
  const [selectedFieldOptionsState, setSelectedFieldOptionsState] = useState<{
    options: string[];
    loading: boolean;
  }>({ options: [], loading: true });

  useSupabase(
    async ({ supabase, account_id }) => {
      if (!disabled && segmentField) {
        const { data: uniqueFieldsData } = await new SupabasePersonService(
          supabase,
        ).getUniqueValues(account_id, segmentField.key ?? "");

        if (uniqueFieldsData) {
          setSelectedFieldOptionsState({
            loading: false,
            options: uniqueFieldsData
              .map((field) => field.value)
              .filter(notEmpty)
              .sort(),
          });
        }
      } else {
        setSelectedFieldOptionsState({ options: [], loading: false });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [disabled, JSON.stringify(segmentField)],
  );

  return selectedFieldOptionsState;
}
