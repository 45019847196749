import { useCallback } from "react";
import { useToken } from "../../server/auth/use-token";
import { huddle } from "../huddle/huddle";

export function useDesignHuddleProjectClone(): (project_id: string) => Promise<string> {
  const getToken = useToken();

  return useCallback(
    async (projectId: string) => {
      const token = await getToken();

      const response = await huddle.POST("/project/{projectId}/clone", {
        params: { path: { projectId } },
        headers: { Authorization: `Bearer ${token}` },
        body: {},
      });

      return response.data!.projectId;
    },
    [getToken],
  );
}
