import { Box, CardProps, styled } from "@mui/material";
import { FC, ReactNode } from "react";
import { BrandInfoSelected } from "../../brand-kit/types";
import { TemplateType } from "../../generic/atoms/types/template";
import { DiscoverTemplate } from "../types";
import { DiscoverCardActionArea } from "./discover-card-action-area";
import { DiscoverCardContent } from "./discover-card-content";
import { DiscoverCardImage } from "./discover-card-image";

export interface DiscoverTemplateCardProps extends Omit<CardProps, "onClick"> {
  template: DiscoverTemplate;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, template: DiscoverTemplate) => void;
  brandKitSelections?: BrandInfoSelected;
  badge?: ReactNode;
  subheadingGetter?: (t: DiscoverTemplate) => string | null;
}

const CardContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  flexGrow: 1,
  width: "100%",
  transition: "padding .3s ease-in-out",
}));
// eslint-disable-next-line deprecation/deprecation
CardContent.defaultProps = { className: "Card-content" };

export const DiscoverCard: FC<DiscoverTemplateCardProps> = ({
  template,
  onClick,
  brandKitSelections,
  badge,
  subheadingGetter,
  ...boxProps
}) => {
  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    onClick?.(e, template);
  };

  return (
    <Box
      {...boxProps}
      onClick={handleClick}
      sx={{
        maxWidth: "320px",
        overflow: "visible",
        ...boxProps?.sx,
      }}
    >
      <DiscoverCardActionArea
        {...(template.type === TemplateType.sequence && { className: "Card-Sequence" })}
      >
        <Box sx={{ position: "relative", width: "100%", paddingBottom: "100%", flexGrow: 0 }}>
          {badge}
          <DiscoverCardImage template={template} brandKitSelections={brandKitSelections} />
        </Box>
        <CardContent>
          <DiscoverCardContent template={template} subheadingGetter={subheadingGetter} />
        </CardContent>
      </DiscoverCardActionArea>
    </Box>
  );
};
