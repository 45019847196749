import { FC } from "react";
import { useTranslation } from "react-i18next";
import { usePopover } from "../../../utils/hooks/use-popover";
import yup from "../../../utils/yup";
import { ColourPicker } from "../../generic/components/colour-picker";
import { BrandKitColor } from "../server/brand-kit-service";
import { BrandKitManager } from "../types";
import { tooWhite } from "../utils";
import { BrandKitChoiceRow } from "./choice-row";

export const BrandKitColorChoiceRow: FC<{
  colors: BrandKitColor[];
  colorManager: BrandKitManager["color"];
  selected?: BrandKitColor;
  selectedText?: string;
  showTitle?: boolean;
  showDefaultIndicator?: boolean;
}> = ({
  colors,
  colorManager,
  selected,
  selectedText,
  showTitle = true,
  showDefaultIndicator = true,
}) => {
  const popover = usePopover<HTMLButtonElement>();

  const { t } = useTranslation();

  const tooWhiteSchema = yup
    .string()
    .ensure()
    .test(
      "too-white",
      t("This color is too light to work with our templates. Please select a different color."),
      (value) => !tooWhite(value),
    )
    .required();

  return (
    <BrandKitChoiceRow
      title={showTitle ? t("Colors") : undefined}
      subtitle={t(
        "Manually add brand colors. These are used to generate customized images in Discover.",
      )}
      type="color"
      existing={colors ?? []}
      selected={selected}
      onClick={colorManager.add && popover.handleOpen}
      remove={colorManager.remove}
      setSelected={colorManager.setSelected}
      selectedText={selectedText}
      showDefaultIndicator={showDefaultIndicator}
      anchorRef={popover.anchorRef}
    >
      {colorManager.add && (
        <ColourPicker popover={popover} onChange={colorManager.add} schema={tooWhiteSchema} />
      )}
    </BrandKitChoiceRow>
  );
};
