import { Box, BoxProps, Skeleton, Stack } from "@mui/material";
import { FC } from "react";

export const SEQUENCE_BAR_WIDTH = 306;

export const SequenceBar: FC<BoxProps> = ({ children, ref, sx = [], ...props }) => {
  return (
    <Box
      ref={ref}
      sx={[
        (t) => ({
          p: 2,
          pt: 3,
          bgcolor: t.palette.background.default,
          overflowY: "auto",
          width: SEQUENCE_BAR_WIDTH,
        }),
        ...(sx instanceof Array ? sx : [sx]),
      ]}
      {...props}
    >
      <Stack
        direction="column"
        gap={2}
        sx={(theme) => ({
          "--thumbnail-width": `${SEQUENCE_BAR_WIDTH - parseInt(theme.spacing(4), 10)}px`,
        })}
      >
        {children ??
          [1, 2, 3, 4, 5].map((_, i) => (
            <Skeleton
              variant="rectangular"
              key={i}
              sx={{
                borderRadius: "8px",
                width: "var(--thumbnail-width)",
                height: "var(--thumbnail-width)",
              }}
            />
          ))}
      </Stack>
    </Box>
  );
};
