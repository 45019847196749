import { UseSupabaseLoaderState } from "../../../server/supabase/hooks/types";
import { useBrandTones } from "../../brand-kit/hooks/use-brand-tones";

type BrandToneOption = { label: string; value: string; default?: boolean };

export const useBrandToneOptions = (): UseSupabaseLoaderState<BrandToneOption[]> => {
  const { data, loading, error, ...rest } = useBrandTones();

  if (loading) return { data: null, loading, error, ...rest };
  if (error) return { data: null, loading: false, error, ...rest };

  const defaultToneId = data.length === 1 ? data[0].id : data.find((x) => x.is_default)?.id;

  return {
    data: data.map((brandTone) => ({
      label: brandTone.name,
      value: brandTone.id,
      default: brandTone.id === defaultToneId,
    })),
    loading,
    error,
    ...rest,
  };
};
