import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { FC, useCallback, useState } from "react";
import { toast } from "react-hot-toast";
import { Trans, useTranslation } from "react-i18next";
import { useToken } from "../../../server/auth/use-token";
import { ImagesService } from "../../../server/cloudflare/images-service";
import { uuidv4 } from "../../../utils/uuid";
import { CeHelpButton } from "../../generic/components/ce-help-button";
import { FilePicker } from "../../generic/components/file-picker/file-picker";
import { BrandKitLogo } from "../server/brand-kit-service";
import { BrandKitManager } from "../types";

export const BrandKitFilePicker: FC<{
  open: boolean;
  handleClose: () => void;
  onChange: BrandKitManager["logo"]["add"];
}> = ({ open, handleClose, onChange }) => {
  const [currentValue, setCurrentValue] = useState<
    Pick<BrandKitLogo, "src" | "format" | "type" | "theme">
  >({
    src: "",
    format: "",
    type: "logo",
    theme: "primary",
  });

  const { t } = useTranslation();
  const getToken = useToken();

  const handleUpload = useCallback(
    async (file: File) => {
      const name = uuidv4();

      const imagesService = new ImagesService();
      const result = await imagesService.upload(file, name, await getToken());

      if (!result.success || !result.url) {
        toast.error(t("Something went wrong, please try again"));
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, format] = file.type.split("/");

      setCurrentValue((prev) => ({ ...prev, src: result.url, format }));
      return file;
    },
    [getToken, t, setCurrentValue],
  );

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          handleClose();
        }}
      >
        <DialogTitle>{t("Upload logo")}</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <FilePicker
              value={currentValue?.src ?? ""}
              handleUpload={handleUpload}
              accept={{ "image/*": [".png", ".jpeg", ".gif", ".jpg", ".svg"] }}
              text={{
                add: t("Add Image"),
                remove: t("Remove Image"),
                uploadError: t("Only one image can be uploaded at a time"),
              }}
              handleRemove={useCallback(
                () =>
                  setCurrentValue({
                    src: "",
                    format: "",
                    type: "logo",
                    theme: "primary",
                  }),
                [],
              )}
            />
            <Stack direction="row" gap={1}>
              <FormControl fullWidth>
                <InputLabel htmlFor="brand-kit-logo-picker-type">{t("Image format")}</InputLabel>
                <Select
                  inputProps={{ id: "brand-kit-logo-picker-type", "aria-label": t("Image format") }}
                  value={currentValue.type}
                  onChange={(e) => {
                    setCurrentValue((prev) => ({
                      ...prev,
                      type: e.target.value,
                      theme: ["logo", "symbol"].includes(e.target.value)
                        ? (currentValue.theme ?? "primary")
                        : null,
                    }));
                  }}
                  label={t("Image format")}
                >
                  <MenuItem value="logo">{t("Logo")}</MenuItem>
                  <MenuItem value="icon">{t("Icon")}</MenuItem>
                  <MenuItem value="symbol">{t("Symbol")}</MenuItem>
                  <MenuItem value="other">{t("Other")}</MenuItem>
                </Select>
              </FormControl>
              <CeHelpButton
                variant="tooltip"
                content={
                  <Stack p={2} gap={1} bgcolor="primary" maxWidth="300px">
                    <Trans>
                      <Typography variant="caption">Logo: Your primary company logo.</Typography>
                      <Typography variant="caption">
                        Symbol: Simplified graphic symbol of your brand, often without text.
                      </Typography>
                      <Typography variant="caption">
                        Icon: Small, simplified version of your logo or symbol, ideal for app icons,
                        favicons, LinkedIn or social media.
                      </Typography>
                      <Typography variant="caption">
                        Other: Anything else that doesn’t fit into the categories above.
                      </Typography>
                    </Trans>
                  </Stack>
                }
              />
            </Stack>
            {["logo", "symbol"].includes(currentValue.type) && (
              <Stack direction="row" gap={1}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="brand-kit-logo-picker-theme">{t("Image style")}</InputLabel>
                  <Select
                    inputProps={{
                      id: "brand-kit-logo-picker-theme",
                      "aria-label": t("Image style"),
                    }}
                    value={currentValue.theme}
                    onChange={(e) => {
                      setCurrentValue((prev) => ({
                        ...prev,
                        theme: e.target.value,
                      }));
                    }}
                    label={t("Image style")}
                  >
                    <MenuItem value="primary">{t("Primary")}</MenuItem>
                    <MenuItem value="light">{t("Light")}</MenuItem>
                    <MenuItem value="dark">{t("Dark")}</MenuItem>
                  </Select>
                </FormControl>

                <CeHelpButton
                  variant="tooltip"
                  content={
                    <Stack p={2} gap={1} maxWidth="300px">
                      <Trans>
                        <Typography variant="caption">
                          Primary: Your primary full-color company image.
                        </Typography>
                        <Typography variant="caption">
                          Light: version for display on dark backgrounds.
                        </Typography>
                        <Typography variant="caption">
                          Dark: version for display on light or white backgrounds.
                        </Typography>
                      </Trans>
                    </Stack>
                  }
                />
              </Stack>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack direction={"row"} spacing={1}>
            <Button
              data-analytics-id="brand-kit-logo-picker-cancel"
              variant="outlined"
              onClick={(e) => {
                e.stopPropagation();
                handleClose();
              }}
            >
              {t("Cancel")}
            </Button>
            <Button
              data-analytics-id="brand-kit-logo-picker-ok"
              variant="contained"
              onClick={() => {
                if (currentValue.src) {
                  onChange?.(currentValue);
                  handleClose();
                  setCurrentValue({
                    src: "",
                    format: "",
                    type: "logo",
                    theme: "primary",
                  });
                }
              }}
            >
              {t("OK")}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
};
