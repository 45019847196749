import { Box } from "@mui/material";
import { FC } from "react";
import { AssetCard } from "./asset-card";
import { Asset } from "./server/digital-asset-service";

export const AssetGrid: FC<{ assets: Asset[]; onClick?: (asset: Asset) => void }> = ({
  assets,
  onClick,
}) => {
  return (
    <Box display="grid" gap={4} gridTemplateColumns="repeat(auto-fit, minmax(200px, 305px))">
      {assets.map((asset) => (
        <AssetCard
          key={asset.id}
          asset={asset}
          data-testid="asset"
          onClick={() => onClick?.(asset)}
        />
      ))}
    </Box>
  );
};
