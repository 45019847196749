import { Sha1 } from "./sha1";

enum UUID_ {
  _ = "",
}
export type UUID = string & UUID_;

export const NameSpaceURL = "6ba7b811-9dad-11d1-80b4-00c04fd430c8" as UUID;

export function toHex(uuid: Uint8Array): UUID {
  const hex = [...uuid].map((i) => (i + 0x100).toString(16).substring(1).toLowerCase()).join("");
  return [
    hex.slice(0, 8),
    hex.slice(8, 12),
    hex.slice(12, 16),
    hex.slice(16, 20),
    hex.slice(20, 32),
  ].join("-") as UUID;
}

export function fromHex(s: UUID): Uint8Array {
  let v: number;
  const uuid = new Uint8Array(16);

  v = parseInt(s.slice(0, 8), 16);
  uuid[0] = v >>> 24;
  uuid[1] = (v >>> 16) & 0xff;
  uuid[2] = (v >>> 8) & 0xff;
  uuid[3] = v & 0xff;

  v = parseInt(s.slice(9, 13), 16);
  uuid[4] = v >>> 8;
  uuid[5] = v & 0xff;

  v = parseInt(s.slice(14, 18), 16);
  uuid[6] = v >>> 8;
  uuid[7] = v & 0xff;

  v = parseInt(s.slice(19, 23), 16);
  uuid[8] = v >>> 8;
  uuid[9] = v & 0xff;

  v = parseInt(s.slice(24, 36), 16);
  uuid[10] = (v / 0x10000000000) & 0xff;
  uuid[11] = (v / 0x100000000) & 0xff;
  uuid[12] = (v >>> 24) & 0xff;
  uuid[13] = (v >>> 16) & 0xff;
  uuid[14] = (v >>> 8) & 0xff;
  uuid[15] = v & 0xff;

  return uuid;
}

export function uuidv4(): UUID {
  //TODO: on modern JS: return crypto.randomUUID() as UUID;
  const uuid = new Uint8Array(16);
  crypto.getRandomValues(uuid);
  uuid[6] = (uuid[6] & 0x0f) | 0x40;
  uuid[8] = (uuid[8] & 0x3f) | 0x80;
  return toHex(uuid);
}

export function uuidv5(namespace: typeof NameSpaceURL, name: string): UUID {
  const uuid = new Sha1()
    .update(fromHex(namespace))
    .update(new TextEncoder().encode(name))
    .digest();
  uuid[6] = (uuid[6] & 0x0f) | 0x50;
  uuid[8] = (uuid[8] & 0x3f) | 0x80;
  return toHex(uuid);
}

export function isUUID(val: undefined | string | string[]): val is UUID {
  return (
    typeof val === "string" &&
    /^(?:[0-9a-f]{8}-?[0-9a-f]{4}-?[1-5][0-9a-f]{3}-?[89ab][0-9a-f]{3}-?[0-9a-f]{12}|00000000-0000-0000-0000-000000000000)$/i.test(
      val,
    )
  );
}
