import { FC, useCallback, useEffect, useRef, useState } from "react";
import { config } from "../../../config";
import { UUID } from "../../../utils/uuid";
import { Login } from "../types";

export const PipeIFrame: FC<{
  name: string;
  account_id: UUID;
  setLogins: (value: { [k: string]: Login }) => void;
}> = ({ name, account_id, setLogins }) => {
  const [loaded, setLoaded] = useState(false);
  const pipeRef = useRef<HTMLIFrameElement>(null);
  const messageCallback = useCallback(
    (
      e: MessageEvent<{
        type: "logins";
        logins: { [k: string]: Login };
      }>,
    ) => {
      if (!e.origin.endsWith("changeengine.com")) return;
      if (e.data.type === "logins") setLogins(e.data.logins);
    },
    [setLogins],
  );

  useEffect(() => {
    if (!name || !account_id || !pipeRef || !loaded) return;
    window.addEventListener("message", messageCallback);
    pipeRef.current?.contentWindow?.postMessage(
      {
        type: "login_add",
        id: account_id,
        name,
        timestamp: new Date().toISOString(),
        url: `https://${location.host}/`,
      },
      { targetOrigin: config.auth0.base_url },
    );
    return () => window.removeEventListener("message", messageCallback);
  }, [account_id, name, pipeRef, loaded, messageCallback]);

  return (
    <iframe
      title="pipe"
      ref={pipeRef}
      src={`${config.auth0.base_url}/pipe`}
      width="0"
      height="0"
      style={{ visibility: "hidden" }}
      onLoad={() => setLoaded(true)}
    ></iframe>
  );
};
