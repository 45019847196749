import { AddCircleOutline, Cancel } from "@mui/icons-material";
import {
  Badge,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { FC, ReactNode, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { UUID } from "../../../utils/uuid";
import { BrandKitColor, BrandKitLogo } from "../server/brand-kit-service";

type BrandKitChoiceRowProps = {
  title?: string;
  subtitle?: string;
  onClick?: () => void;
  remove?: (index: number) => void;
  setSelected: (id?: UUID) => void;
  selectedText?: string;
  children?: ReactNode;
  showDefaultIndicator?: boolean;
  anchorRef?: React.MutableRefObject<HTMLButtonElement | null>;
} & (
  | {
      type: "logo";
      selected?: BrandKitLogo;
      existing: BrandKitLogo[];
    }
  | {
      type: "color";
      selected?: BrandKitColor;
      existing: BrandKitColor[];
    }
);

export const BrandKitChoiceRow: FC<BrandKitChoiceRowProps> = ({
  title,
  subtitle,
  type,
  existing,
  selected,
  onClick,
  remove,
  setSelected,
  selectedText,
  children,
  showDefaultIndicator = true,
  anchorRef,
}) => {
  return (
    <Box>
      {title && <Typography variant="h6">{title}</Typography>}
      {onClick && (
        <Typography variant="caption" color="text.secondary">
          {subtitle}
        </Typography>
      )}
      <Box sx={{ mt: (title || subtitle) && 4, display: "flex", gap: 2, flexWrap: "wrap" }}>
        {type === "logo"
          ? existing.map((value, index) => (
              <BrandKitBox
                key={index}
                selected={value.id === selected?.id}
                type="logo"
                remove={remove && (() => remove(index))}
                setSelected={() => setSelected(value.id === selected?.id ? undefined : value.id)}
                selectedText={selectedText}
                showDefaultIndicator={showDefaultIndicator}
              >
                <img
                  src={value.src}
                  width="75px"
                  height="75px"
                  alt=""
                  style={{ objectFit: "contain" }}
                />
              </BrandKitBox>
            ))
          : existing.map((value, index) => (
              <BrandKitBox
                key={value.hex}
                selected={value.id === selected?.id}
                type="color"
                remove={remove && (() => remove(index))}
                setSelected={() => setSelected(value.id === selected?.id ? undefined : value.id)}
                selectedText={selectedText}
                showDefaultIndicator={showDefaultIndicator}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    data-testid="color-box"
                    sx={{
                      width: "100%",
                      flexGrow: 1,
                      bgcolor: value.hex,
                    }}
                  />
                  <Box
                    sx={{
                      backgroundColor: "background.paper",
                      height: "1.6em",
                      textAlign: "center",
                    }}
                  >
                    <Typography variant="caption">{value.hex}</Typography>
                  </Box>
                </Box>
              </BrandKitBox>
            ))}
        {onClick && (
          <IconButton
            data-analytics-id={`brand-kit-add-${type}`}
            ref={anchorRef}
            sx={{ width: 100, height: 100, borderRadius: 1 }}
            onClick={onClick}
            type="button"
            data-testid={`add-${type}`}
          >
            <AddCircleOutline sx={{ width: 75, height: 75, borderRadius: 1 }} color="primary" />
          </IconButton>
        )}
        {children}
      </Box>
    </Box>
  );
};

const BrandKitBox: FC<{
  type: "color" | "logo" | "button";
  selected: boolean;
  remove?: () => void;
  setSelected: () => void;
  selectedText?: string;
  children: ReactNode;
  width?: number;
  height?: number;
  showDefaultIndicator?: boolean;
}> = ({
  type,
  selected,
  remove,
  setSelected,
  selectedText,
  children,
  width = 100,
  height = 100,
  showDefaultIndicator = true,
}) => {
  const { t } = useTranslation();

  const [dialogOpen, setDialogOpen] = useState(false);
  // Only show delete badge on hover
  const [hover, setHover] = useState(false);

  const title = selected ? t("Remove default") : t("Set as default");

  return (
    <Stack
      direction="column"
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Badge
        overlap="rectangular"
        badgeContent={
          <Cancel
            color="disabled"
            sx={{
              borderRadius: "100%",
              backgroundColor: "white",
              fontSize: 20,
              cursor: "pointer",
              display: remove && hover ? "block" : "none",
              ":hover": {
                color: (theme) => theme.palette.primary.main,
              },
            }}
            onClick={(e) => {
              e.stopPropagation();
              setDialogOpen(true);
            }}
          />
        }
      >
        <Box
          onClick={setSelected}
          role="button"
          sx={(theme) => ({
            width: width,
            height: height,
            borderRadius: theme.spacing(1),
            overflow: "hidden",
            border: selected ? "3px solid" : "1px solid",
            borderColor: selected ? "primary.main" : "neutral.300",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "neutral.100",
            cursor: "pointer",
            ":hover": {
              borderColor: "neutral.400",
            },
          })}
          title={title}
          data-analytics-id="branding-bar-logo"
          
        >
          {children}
        </Box>
      </Badge>
      {remove && (
        <Dialog
          open={dialogOpen}
          onClose={(e: MouseEvent) => {
            e.stopPropagation();
            setHover(false);
            setDialogOpen(false);
          }}
        >
          <DialogTitle>{t("Are you sure?")}</DialogTitle>
          <DialogContent>
            <Trans>
              You&apos;re about to remove a {type}. It will no longer be available for use in any
              draft or future designs. Sent moments will be unaffected.
            </Trans>
          </DialogContent>
          <DialogActions>
            <Stack direction="row" spacing={1}>
              <Button
                data-analytics-id={`brand-kit-remove-${type}-dialog-cancel`}
                variant="outlined"
                onClick={(e) => {
                  e.stopPropagation();
                  setHover(false);
                  setDialogOpen(false);
                }}
              >
                {t("Cancel")}
              </Button>
              <Button
                data-analytics-id={`brand-kit-remove-${type}-dialog-confirm`}
                variant="contained"
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  remove();
                  setHover(false);
                  setDialogOpen(false);
                }}
              >
                {t("Yes, delete")}
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>
      )}
      {selected && showDefaultIndicator && (
        <Typography variant="caption" color="primary.main" sx={{ textAlign: "center" }}>
          {t(selectedText ?? "Default")}
        </Typography>
      )}
    </Stack>
  );
};
