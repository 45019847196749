import { useSupabaseLoader } from "../../../server/supabase/hooks";
import {
  SegmentWithMoments,
  SupabaseSegment,
  SupabaseSegmentService,
  SupabaseSegmentsWithMomentsService,
} from "./supabase-segment-service";

export function useSegmentsWithRelatedMoments({
  is_recipient_type,
  include_deleted = true,
}: {
  is_recipient_type: boolean;
  include_deleted?: boolean;
}): ReturnType<typeof useSupabaseLoader<SegmentWithMoments[]>> {
  return useSupabaseLoader(
    async ({ supabase, account_id }) => {
      const { data, error } = await new SupabaseSegmentsWithMomentsService(supabase).getAll(
        account_id,
        makeParams({ is_recipient_type, include_deleted }),
      );

      if (error || !data) return [];
      return data;
    },
    [is_recipient_type, include_deleted],
  );
}

export function useSegments({
  is_recipient_type,
  include_deleted = true,
}: {
  is_recipient_type: boolean;
  include_deleted?: boolean;
}): ReturnType<typeof useSupabaseLoader<SupabaseSegment[]>> {
  return useSupabaseLoader(
    async ({ supabase, account_id }) => {
      const { data, error } = await new SupabaseSegmentService(supabase).getAll(
        account_id,
        makeParams({ is_recipient_type, include_deleted }),
      );

      if (error || !data) return [];
      return data;
    },
    [is_recipient_type, include_deleted],
  );
}

const makeParams = ({
  is_recipient_type,
  include_deleted,
}: {
  is_recipient_type: boolean;
  include_deleted: boolean;
}): {
  order: [{ column: "id" }];
  filter: (
    | { key: "is_recipient_type"; operator: "is"; value: boolean }
    | { key: "is_default"; operator: "not.is"; value: true }
    | { key: "deleted_at"; operator: "is"; value: null }
  )[];
} => ({
  order: [{ column: "id" as const }],
  filter: [
    { key: "is_recipient_type", operator: "is", value: is_recipient_type },
    { key: "is_default", operator: "not.is", value: true },
    ...(include_deleted
      ? []
      : [{ key: "deleted_at" as const, operator: "is" as const, value: null }]),
  ],
});
