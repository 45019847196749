import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { FC, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { Trans, useTranslation } from "react-i18next";
import { useSupabaseCallback } from "../../../../../server/supabase/hooks";
import {
  SlackAlias,
  SlackAliasWithDefault,
  SupabaseSlackAliasService,
} from "../../../../slack/server/slack-supabase-service";
import { SlackAliasForm as SlackAliasFormType, slackAliasSchema } from "../../types";
import { SlackAliasImageUpload } from "./alias-image-upload";

export const SlackAliasForm: FC<{
  onComplete: (alias: SlackAlias) => void;
  open: boolean;
  close: () => void;
  existing?: SlackAliasWithDefault;
}> = ({ onComplete, existing, open, close }) => {
  const { t } = useTranslation();
  const [saving, setSaving] = useState(false);

  const methods = useForm({
    defaultValues: { name: existing?.name ?? "", image: existing?.image ?? "" },
    resolver: yupResolver(slackAliasSchema),
  });

  const { reset } = methods;

  const onSubmit = useSupabaseCallback(
    async ({ supabase }, { name, image }: SlackAliasFormType): Promise<void> => {
      setSaving(true);

      const service = new SupabaseSlackAliasService(supabase);

      const { data, error } = await (existing && existing.id
        ? service.update(existing.id, { name, image })
        : service.insert({ name, image }));

      setSaving(false);

      if (error || !data) {
        toast.error(t(existing ? "Failed to update alias" : "Failed to create alias"));
        return;
      }

      toast.success(t(existing ? "Alias Updated" : "Alias created"));
      onComplete(data);
      close();
      reset();
    },
    [t, onComplete, existing, close, reset],
  );

  return (
    <Dialog open={open} onClose={() => !saving && close()}>
      <DialogTitle>{t(existing ? "Update Slack Alias" : "Add Slack Alias")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Trans>
            Set a name and image for your alias. Note that images will be automatically resized to
            fit Slack specifications. We recommend using a square image between 512x512 and
            1024x1024.
          </Trans>
        </DialogContentText>
        <FormProvider {...methods}>
          {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
          <form id="slack-alias-create-form" onSubmit={methods.handleSubmit(onSubmit)}>
            <Controller
              name="name"
              control={methods.control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  margin="dense"
                  id="name"
                  label={t("Name")}
                  type="text"
                  fullWidth
                  variant="standard"
                  value={field.value}
                  placeholder={t("e.g. Holiday Bot")}
                  onChange={field.onChange}
                  helperText={error?.message}
                  error={Boolean(error)}
                />
              )}
            />
            <SlackAliasImageUpload />
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button
          data-analytics-id={`slack-alias-dialog-${existing ? "update" : "create"}-add`}
          disabled={saving}
          type="submit"
          form="slack-alias-create-form"
        >
          {saving ? <CircularProgress size={20} /> : t(existing ? "Update" : "Add")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
