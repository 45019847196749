import { TableCell, TableRow, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { PaginatedTable } from "../../generic/components/paginated-table/paginated-table";
import { UnfoundConnection } from "../server/supabase-unfound-connections-service";

export const UnfoundConnectionsGrid: FC<{
  unfoundConnections: UnfoundConnection[];
}> = ({ unfoundConnections }) => {
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="h6" fontWeight={600} sx={{ my: 2 }}>
        {t("Invalid Connection References")}
      </Typography>
      <PaginatedTable
        data={unfoundConnections}
        config={{
          headers: [
            t("key"),
            t("Reference Value"),
            t("Source Employee ID"),
            t("Source Employee Email"),
          ],
        }}
        renderRow={({ connection_reference, key, subject_employee_id, subject_work_email }) => (
          <TableRow data-testid="unfound-connections-grid-row">
            <TableCell>{key}</TableCell>
            <TableCell>{connection_reference}</TableCell>
            <TableCell>{subject_employee_id}</TableCell>
            <TableCell>{subject_work_email}</TableCell>
          </TableRow>
        )}
      />
    </>
  );
};
