import { useAtomValue } from "jotai";
import { useMemo } from "react";
import { StandardBrandKitStyle } from "../server/brand-kit-service";
import { brandingAtom } from "../store";
import { BrandInfo, BrandInfoSelected } from "../types";
import { useBrandKitManager } from "./use-brand-kit-manager";

type BrandKit = {
  loadBrandKit: () => void;
  branding: BrandInfoSelected;
  brandKit?: BrandInfo;
  defaultBranding?: BrandInfoSelected;
};

export type UseBrandKit = ({ loading: false } & BrandKit) | ({ loading: true } & Partial<BrandKit>);

export const useBrandKit = (): UseBrandKit => {
  const { loading, data, reload } = useBrandKitManager();
  const selectedBranding = useAtomValue(brandingAtom);

  const branding = useMemo(() => {
    if (!selectedBranding) {
      return (
        data?.defaults ?? {
          branding_type: { type: "standard" as const, value: "bold" as const },
          default_tone: "professional" as const,
          color: undefined,
          logo: undefined,
        }
      );
    }

    const { brandKit, defaults } = data ?? {};
    const { color: selectedColor, logo: selectedLogo } = selectedBranding;

    return {
      color: brandKit?.choices.colors.find((c) => c.hex === selectedColor?.hex) ?? defaults?.color,
      logo: brandKit?.choices.logos.find((l) => l.src === selectedLogo?.src) ?? defaults?.logo,
      branding_type: selectedBranding.branding_type ?? {
        type: "standard" as const,
        value: "bold" as const,
      },
      default_tone: selectedBranding.default_tone ?? data?.defaults?.default_tone ?? "professional",
    };
  }, [data, selectedBranding]);

  if (loading) {
    return { loading: true, loadBrandKit: reload };
  }

  return {
    loading,
    loadBrandKit: reload,
    branding,
    brandKit: data?.brandKit,
    defaultBranding: data?.defaults,
  };
};

export const brandKitStyles: StandardBrandKitStyle[] = [
  "bold",
  "sophisticated",
  "organic",
  "formal",
  "minimalist",
];

export const getBrandKitStyle = (num: number): StandardBrandKitStyle => brandKitStyles[num];
