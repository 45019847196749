import {
  Alert,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LightBulb } from "../../styles/icons/light-bulb";

const tips = [
  "Make it yours with custom photos! 🎨",
  "Pop in social icons to stay connected! 📱",
  "Play with colors for a fresh vibe! 🎨",
  "Add in the perfect image using Pexels! 💎",
  "Write your favorite slogans 🖊️",
];

export const LoadingModal: FC<{
  open: boolean;
}> = ({ open }) => {
  const { t } = useTranslation();
  const [tipIndex, setTipIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTipIndex((prevIndex) => (prevIndex + 1) % tips.length);
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Dialog open={open}>
      <Box sx={{ width: "600px", my: 1 }}>
        <DialogTitle component="div" mt={2}>
          <Typography variant="h6">{t("Loading image editor")}</Typography>
        </DialogTitle>
        <DialogContent>
          <Stack gap={2} my={2}>
            <DialogContentText sx={{ color: "text.primary" }}>
              {t("We're getting your content ready for you to edit.")}
            </DialogContentText>
            <Alert
              icon={<LightBulb fontSize="inherit" sx={{ color: "text.primary" }} />}
              sx={{ backgroundColor: "background.active", color: "text.primary" }}
            >
              <span>{t("Tip")}</span>: &nbsp;
              <span>{t(tips[tipIndex])}</span>
            </Alert>
            <LinearProgress sx={{ mt: 1 }} />
          </Stack>
        </DialogContent>
      </Box>
    </Dialog>
  );
};
