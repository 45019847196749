import { useMemo } from "react";
import { useToken } from "../../../server/auth/use-token";
import { useSupabaseCallback } from "../../../server/supabase/hooks";
import { UUID } from "../../../utils/uuid";
import { ChannelName } from "../../channels/types";
import { UseMomentProperties } from "../../moment-card/types";
import {
  GetMoments,
  UseServerFilteredMoments,
  useServerFilteredMoments,
} from "../../moment-filter/hooks/use-server-filtered-moments";
import { SupabaseMomentCardMomentService } from "../../moment/server/supabase-moment-service";
import { AtlasV2Service } from "../../search/atlas/client";

export type ServerFilter = {
  program?: UUID;
  status?: string;
  title?: string;
};

export type ClientFilter = {
  channel?: ChannelName;
  segment?: UUID;
  eventKey?: string;
};

export const useMoments: (props?: {
  filters?: ServerFilter;
  clientFilter?: ClientFilter;
}) => UseServerFilteredMoments<UseMomentProperties> = ({ filters, clientFilter } = {}) => {
  const serverFilter = { ...filters };
  const getToken = useToken();

  const callback: GetMoments<UseMomentProperties> = useSupabaseCallback(
    async ({ supabase, account_id }, { filter: { filters: searchFilters, rawFilters } }) => {
      if (rawFilters.q) {
        return await new AtlasV2Service().search(getToken, rawFilters.q, {
          program: rawFilters.other.program ?? [],
          status: rawFilters.other.status ?? [],
          channel: rawFilters.other.channel ?? [],
        });
      }

      return await new SupabaseMomentCardMomentService(supabase).getAll(account_id, {
        order: [{ column: "id" }],
        filter: searchFilters,
      });
    },
    [getToken],
  );

  const { data, ...rest } = useServerFilteredMoments(callback, {
    allowedFilters: ["program", "channel", "status", "q"],
    debounceInterval: 200,
    extraFilters: serverFilter,
  });

  const filteredMoments = useMemo(
    () => (data !== null ? filterMoments(data, clientFilter ?? {}) : null),
    [data, clientFilter],
  );

  if (rest.loading || rest.error) return { ...rest, data: null };

  return { ...rest, data: filteredMoments };
};

const filterMoments = (
  moments: UseMomentProperties[],
  filter: ClientFilter,
): UseMomentProperties[] => {
  const { channel, segment, eventKey } = filter;

  return moments.filter((moment) => {
    if (channel && (!moment.channel || moment?.channel !== channel)) {
      return false;
    }

    if (segment && (!moment.segment || moment?.segment?.id !== segment)) {
      return false;
    }

    if (eventKey && (!moment.schedule || moment.schedule.event_key !== eventKey)) {
      return false;
    }

    return true;
  });
};
