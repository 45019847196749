import { groupBy } from "lodash-es";
import { useSupabaseLoader } from "../../../server/supabase/hooks";
import { UUID } from "../../../utils/uuid";
import {
  SupabaseCustomEmail,
  SupabaseCustomEmailService,
} from "../../channels/email/server/supabase-custom-email-service";
import { SupabaseAccountLimitService } from "../../generic/server/supabase-account-service";
import { Program, SupabaseProgramService } from "../../programs/server/supabase-program-service";
import {
  SupabaseSegment,
  SupabaseSegmentService,
} from "../../segment/server/supabase-segment-service";
import {
  Permissions,
  PersonTextDataJson,
  SupabasePermissionsService,
  SupabasePersonTextDataJsonService,
} from "../server/supabase-person-service";

type PermissionsTableData = {
  programs: Program[];
  permissions: Permissions[];
  employees: PersonTextDataJson[];
  limits: { contributors: number; super_admins: number };
  programMap: { [k: string]: Program };
  superAdmins: Permissions[] | null;
  contributors: Permissions[] | null;
  capacity: { contributors: number; super_admins: number };
  availableEmployees: PersonTextDataJson[];
  segments: SupabaseSegment[];
  custom_emails: SupabaseCustomEmail[];
};

export function usePermissionsTableData(): ReturnType<
  typeof useSupabaseLoader<PermissionsTableData>
> {
  return useSupabaseLoader(async ({ supabase, account_id }) => {
    const [
      { data: programData, error: programError },
      { data: permsData, error: permsError },
      { data: personTextData, error: personTextError },
      { data: limitData, error: limitError },
      { data: segmentData, error: segmentError },
      { data: emailData, error: emailError },
    ] = await Promise.all([
      new SupabaseProgramService(supabase).getAll(null, { order: [{ column: "id" }] }),
      new SupabasePermissionsService(supabase).getAll(account_id, { order: [{ column: "id" }] }),
      new SupabasePersonTextDataJsonService(supabase).getAll(account_id, {
        order: [{ column: "person_id" }],
      }),
      new SupabaseAccountLimitService(supabase).getKeys(["contributors", "super_admins"]),
      new SupabaseSegmentService(supabase).getAll(account_id, { order: [{ column: "id" }] }),
      new SupabaseCustomEmailService(supabase).getAll(account_id, { order: [{ column: "id" }] }),
    ]);

    if (programError || permsError || personTextError || limitError || segmentError || emailError)
      return {
        programs: [] as Program[],
        permissions: [] as Permissions[],
        employees: [] as PersonTextDataJson[],
        limits: { contributors: 0, super_admins: 0 },
        programMap: {} as Record<UUID, Program>,
        superAdmins: null,
        contributors: null,
        capacity: { contributors: 0, super_admins: 0 },
        availableEmployees: [],
        segments: [],
        custom_emails: [],
      };

    const programs = programData ?? [];
    const permissions = permsData ?? [];
    const { superAdmins, contributors } = groupBy(permissions, (p) =>
      p.super_admin ? "superAdmins" : "contributors",
    );
    const limits = {
      contributors: limitData?.find((d) => d.key === "contributors")?.count ?? 0,
      super_admins: limitData?.find((d) => d.key === "super_admins")?.count ?? 0,
    };
    const employees = personTextData ?? [];

    return {
      programs,
      permissions,
      employees,
      limits,
      programMap: Object.fromEntries(programs.map((p) => [p.id, p]) ?? []),
      superAdmins,
      contributors,
      capacity: {
        contributors: Math.max(0, limits.contributors - (contributors?.length ?? 0)),
        super_admins: Math.max(0, limits.super_admins - (superAdmins?.length ?? 0)),
      },
      availableEmployees: employees.filter(
        ({ person_id }) => !permissions.find((p) => p.id === person_id),
      ),
      segments: segmentData ?? [],
      custom_emails: emailData ?? [],
    };
  }, []);
}
