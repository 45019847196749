import { FC } from "react";
import { ApplicationLayout } from "./application-layout";

export const GenerateLayout: FC = () => {
 
  return <ApplicationLayout
  layout="sequence"
  slots={{ root: { props: { sx: { bgcolor: "neutral.100" } } } }}
/>
};
