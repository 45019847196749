import { Alert, AlertTitle, LinearProgress } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { EventCrudDataGrid } from "../../modules/event/components/event-crud-data-grid";
import { EventCrudDataGridPerson } from "../../modules/event/components/event-crud-data-grid-person";
import {
  AccountPersonField,
  SupabaseAccountPersonFieldService,
} from "../../modules/event/server/supabase-account-person-field-service";
import {
  SupabaseEventsWithMomentsCalendarService,
  SupabaseEventsWithMomentsPersonService,
} from "../../modules/event/server/supabase-event-service";
import { RouteTabs } from "../../modules/generic/components/route-tabs";
import { Page } from "../../modules/layout/components/page";
import { PageHeader } from "../../modules/layout/components/page-header";
import { PageHeading } from "../../modules/layout/components/page-heading";
import { FieldsCrudDataGrid } from "../../modules/merge-tag/components/fields-crud-data-grid";
import { useSupabase } from "../../server/supabase/hooks";
import { Database } from "../../server/supabase/types/database-definitions";

const DefaultPage: FC<{ tab?: "events" | "merge_tags" }> = ({ tab }) => {
  const navigate = useNavigate();
  useEffect(() => tab && navigate(`/manage/employee_data/${tab}`, { replace: true }));
  return null;
};

const Events: FC = () => {
  const { t } = useTranslation();
  const [events, setEvents] =
    useState<Database["public"]["Views"]["events_with_related_moments_calendar"]["Row"][]>();
  const [hasError, setHasError] = useState<boolean>(false);

  useSupabase(async ({ supabase, account_id }) => {
    const { data, error } = await new SupabaseEventsWithMomentsCalendarService(supabase).getAll(
      account_id,
      { order: [{ column: "id" }] },
    );
    if (!data || error) return setHasError(true);

    setEvents(data);
  }, []);

  return (
    <>
      {hasError && (
        <Alert severity="error">
          <AlertTitle>{t("Something went wrong")}</AlertTitle>
        </Alert>
      )}
      {events === undefined ? <LinearProgress /> : <EventCrudDataGrid events={events} />}
    </>
  );
};

const PersonEvents: FC = () => {
  const { t } = useTranslation();
  const [events, setEvents] =
    useState<Database["public"]["Views"]["events_with_related_moments_person"]["Row"][]>();
  const [hasError, setHasError] = useState<boolean>(false);

  useSupabase(async ({ supabase, account_id }) => {
    const { data, error } = await new SupabaseEventsWithMomentsPersonService(supabase).getAll(
      account_id,
      { order: [{ column: "id" }] },
    );
    if (!data || error) return setHasError(true);

    setEvents(data);
  }, []);

  return (
    <>
      {hasError && (
        <Alert severity="error">
          <AlertTitle>{t("Something went wrong")}</AlertTitle>
        </Alert>
      )}
      {events === undefined ? <LinearProgress /> : <EventCrudDataGridPerson events={events} />}
    </>
  );
};

const MergeTags: FC = () => {
  const { t } = useTranslation();

  const [fields, setFields] = useState<AccountPersonField[]>();
  const [hasError, setHasError] = useState<boolean>(false);

  useSupabase(async ({ supabase, account_id }) => {
    const { data, error } = await new SupabaseAccountPersonFieldService(supabase).getAll(
      account_id,
      { order: [{ column: "id" }] },
    );
    if (!data || error) return setHasError(true);

    setFields(data);
  }, []);

  return (
    <>
      {hasError && (
        <Alert severity="error">
          <AlertTitle>{t("Something went wrong")}</AlertTitle>
        </Alert>
      )}
      {fields === undefined ? <LinearProgress /> : <FieldsCrudDataGrid fields={fields} />}
    </>
  );
};

const ManageEventsAndTagsPage: FC = () => {
  const { t } = useTranslation();

  return (
    <Page title="Manage Events & Tags | ChangeEngine">
      <PageHeader>
        <PageHeading
          heading={t("Events & Merge Tags")}
          subheading={t("Manage your events and merge tags")}
        />
      </PageHeader>
      <RouteTabs
        routeDepth={3}
        defaultPage={<DefaultPage tab={"events"} />}
        tabs={[
          {
            type: "explicit",
            data: { name: "Calendar Events", route: "events" },
            component: <Events />,
          },
          { type: "inferred", data: { name: "Person Events" }, component: <PersonEvents /> },
          { type: "inferred", data: { name: "Merge Tags" }, component: <MergeTags /> },
        ]}
      />
    </Page>
  );
};

export default ManageEventsAndTagsPage;
