import { LinearProgress, Tabs as MuiTabs, Stack, Tab } from "@mui/material";
import { styled } from "@mui/system";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { Atom, useAtomValue } from "jotai";
import { Loadable } from "jotai/vanilla/utils/loadable";
import { FC, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { ContentTone } from "../brand-kit/server/brand-kit-service";
import { BrandTone } from "../brand-kit/server/brand-tone-service";
import { Template } from "../generic/atoms/types/template";

export const TemplateTones: FC<{
  loadableTemplateAtom: Atom<Loadable<Template | null>>;
  contentTone: ContentTone | "custom";
  onContentToneChange?: (value: ContentTone | "custom") => void;
  selectedBrandTone?: BrandTone | null;
  customBrandTones?: BrandTone[] | null;
  handleClick: (event: MouseEvent<Element>) => void;
}> = ({
  loadableTemplateAtom,
  contentTone,
  onContentToneChange,
  selectedBrandTone,
  customBrandTones,
  handleClick,
}) => {
  const { t } = useTranslation();

  const template = useAtomValue(loadableTemplateAtom);

  const ready = template?.state === "hasData" && template.data;

  return (
    <Tabs
      value={contentTone ?? "professional"}
      onChange={(_, v: ContentTone | "custom") => {
        onContentToneChange?.(v);
      }}
      variant="scrollable"
      scrollButtons="auto"
    >
      <Tab
        label={
          <TabLabel
            loading={ready && template.data?.content.professional.state !== "hasData"}
            name={t("Professional")}
          />
        }
        value={"professional"}
      />
      <Tab
        label={
          <TabLabel
            loading={ready && template.data?.content.casual.state !== "hasData"}
            name={t("Casual")}
          />
        }
        value={"casual"}
      />
      <Tab
        label={
          <TabLabel
            loading={ready && template.data?.content.direct.state !== "hasData"}
            name={t("Direct")}
          />
        }
        value={"direct"}
      />
      {selectedBrandTone && (
        <Tab
          sx={{ ml: 3 }}
          label={
            <TabLabel
              loading={ready && template.data?.content.custom?.state !== "hasData"}
              name={selectedBrandTone.name ?? t("Custom")}
            />
          }
          value={"custom"}
          onClick={(e) => {
            customBrandTones && customBrandTones.length > 1 && contentTone === "custom"
              ? handleClick(e)
              : null;
          }}
          icon={customBrandTones && customBrandTones.length > 1 ? <ArrowDropDownIcon /> : <></>}
          iconPosition="end"
        />
      )}
    </Tabs>
  );
};

const Tabs = styled(MuiTabs)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

const TabLabel: FC<{ loading?: boolean | null; name: string }> = ({ loading, name }) => {
  return (
    <Stack>
      {name}
      {loading && <LinearProgress color="inherit" />}
    </Stack>
  );
};
