import { atom, useAtomValue, useSetAtom } from "jotai";
import { get } from "lodash-es";
import { useState } from "react";
import { useSupabase } from "../../../server/supabase/hooks";
import { BrandTone } from "../../brand-kit/server/brand-tone-service";
import { BrandInfoSelected } from "../../brand-kit/types";
import { DiscoverCarousel } from "../carousel/discover-carousel";
import { programSubheadingOverrides } from "../carousel/discover-carousels";
import { SupabaseManageDiscoverService } from "../server/supabase-discover-service";
import { discoverFixedProgramNamesAtom, discoverFixedProgramsAtom } from "../store";

export const useFixedProgramCarousels = ({
  brandKitSelections,
  brandTone,
  loading,
}: {
  loading?: boolean;
  brandKitSelections?: BrandInfoSelected;
  brandTone?: BrandTone;
}): React.JSX.Element[] => {
  const setProgramNames = useSetAtom(discoverFixedProgramNamesAtom);
  const programs = useAtomValue(discoverFixedProgramsAtom);
  const [loadingPrograms, setLoadingPrograms] = useState(true);

  useSupabase(
    async ({ supabase }) => {
      const { data, error } = await new SupabaseManageDiscoverService(supabase).get(42);
      setLoadingPrograms(false);

      if (!data || error) return;

      setProgramNames(data.program_carousel_order);
    },
    [setProgramNames],
  );

  return programs.map((libraryProgram) => (
    <DiscoverCarousel
      loading={loading && loadingPrograms}
      key={libraryProgram.name}
      title={libraryProgram.name}
      brandKitSelections={brandKitSelections}
      slug={libraryProgram.slug}
      templatesAtom={atom(libraryProgram.templates)}
      brandTone={brandTone}
      subheadingGetter={get(programSubheadingOverrides, libraryProgram.slug)}
    />
  ));
};
