import {
  GRID_TREE_DATA_GROUPING_FIELD,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";
import { ReactElement } from "react";
import {
  fullBar,
  fullCircle,
  hollowCircle,
  stripedBar,
  tickedCircle,
} from "../moment-card/components/moment-icons";
import { MomentPopover } from "../moment/component/moment-popover/moment-popover";
import { MomentTableProps } from "./types";

export const initialState: GridInitialStatePro = {
  sorting: {
    sortModel: [{ field: GRID_TREE_DATA_GROUPING_FIELD, sort: "asc" }],
  },
};

/** This function quantizes the date to the padding so orchestrator items are positioned in a cell relative to the date it goes out */
export const quantizePadding = (day: number): number => {
  const rangeMin = 1;
  const rangeMax = 30;

  const minPadding = 0;
  const maxPadding = 8;
  const dateRange = rangeMax - rangeMin;
  const paddingRange = maxPadding - minPadding;
  const padding = ((day - rangeMin) * paddingRange) / dateRange + minPadding;

  // limit value between min and max padding so it is always displayed within cell
  return Math.max(Math.min(padding, maxPadding), minPadding);
};

export const renderCell = (row: MomentTableProps, cellDate: Date): ReactElement => {
  if (row.type === "moment") {
    const { date: momentDateString, data, scheduleType } = row;

    const momentDate = new Date(Date.parse(momentDateString ?? ""));
    const shouldRender =
      scheduleType === "variable" ||
      momentDate.toLocaleString("default", { month: "long", year: "numeric" }) ===
        cellDate.toLocaleString("default", { month: "long", year: "numeric" });

    if (!shouldRender) {
      return <></>;
    }

    if (row.scheduleType === "variable") {
      switch (row.status) {
        case "draft":
        case "paused":
          return !data.immediate ? stripedBar(data) : <></>;
        case "active":
          return fullBar(data);
        case "finished":
        default:
          return <></>;
      }
    } else if (row.scheduleType === "specific") {
      switch (row.status) {
        case "draft":
        case "paused":
          return hollowCircle(data, {
            marginLeft: quantizePadding(momentDate.getDate()),
            width: "20px",
            height: "20px",
          });
        case "active":
          return fullCircle(data, {
            marginLeft: quantizePadding(momentDate.getDate()),
            width: "20px",
            height: "20px",
          });
        case "finished":
          return tickedCircle(data, {
            marginLeft: quantizePadding(momentDate.getDate()),
            width: "24px",
            height: "24px",
          });
        default:
          return <></>;
      }
    }
  }

  return <></>;
};

export function getFirstOfMonth(date: Date): Date {
  const firstOfMonth = new Date(date.getTime());
  firstOfMonth.setDate(1);
  return firstOfMonth;
}

export const columns: GridColDef<MomentTableProps>[] = Array.from(Array(12).keys()).map(
  (offset) => {
    const firstOfThisMonth = getFirstOfMonth(new Date());

    // date used to define start of cell
    const cellDate = new Date(firstOfThisMonth.setMonth(firstOfThisMonth.getMonth() + offset));
    const monthName = cellDate.toLocaleString("default", { month: "long" });

    // unique id used internally by list
    const field = cellDate.toLocaleString("default", { year: "numeric", month: "long" });

    return {
      field,
      headerAlign: "center",
      headerName: monthName,
      headerClassName: "cell",
      sortable: false,
      resizable: false,
      renderCell: ({
        row,
      }: GridRenderCellParams<MomentTableProps, MomentTableProps, MomentTableProps>) =>
        row.type === "moment" && (
          <MomentPopover moment={row.data} momentCardType={"user_moment"} noButton>
            {renderCell(row, cellDate)}
          </MomentPopover>
        ),
      cellClassName: ({
        row,
      }: GridCellParams<MomentTableProps, MomentTableProps, MomentTableProps>): string => {
        const { type, status, scheduleType } = row;
        const classes: string[] = ["cell", type];
        // only style moment cells
        if (type === "moment") {
          classes.push(scheduleType);

          if (scheduleType === "variable" && ["paused", "draft"].includes(status)) {
            classes.push("disabled");
          }
        }

        return classes.join(" ");
      },
    };
  },
);
