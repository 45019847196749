import { useSetAtom } from "jotai";
import { useEffect, useState } from "react";
import { useSupabase } from "../../../server/supabase/hooks";
import { toSlug } from "../../../utils/create-slug";
import { isUUID, UUID } from "../../../utils/uuid";
import { discoverProgramSlugAtom } from "../../discover/store";
import { PopulatedProgram, SupabaseProgramService } from "../server/supabase-program-service";

export function usePopulatedProgramFromProgramId(
  programId?: UUID | string,
): PopulatedProgram | null {
  const [program, setProgram] = useState<PopulatedProgram | null>(null);
  const setProgramSlug = useSetAtom(discoverProgramSlugAtom);

  useSupabase(
    async ({ supabase }) => {
      if (!programId) return;

      if (isUUID(programId)) {
        const { data } = await new SupabaseProgramService(supabase).getPopulated(programId);
        setProgram(data);
      } else {
        const { data } = await new SupabaseProgramService(supabase).getPopulatedByTitle(programId);
        setProgram(data);
      }
    },
    [programId],
  );

  useEffect(
    () => setProgramSlug(program ? toSlug(program.title) : null),
    [programId, setProgramSlug, program],
  );

  return program;
}
