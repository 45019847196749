import {
  assertNoNullFields,
  SupabaseServiceView,
} from "../../../server/supabase/base-supabase-service";
import { Database } from "../../../server/supabase/types/database-definitions";

export type ConnectionKey = Database["public"]["Views"]["connection_key"]["Row"];

export type ConnectionCategoryBase = {
  key: string;
  display_name: string;
};
// Assert that the object is a ConnectionCategoryBase
function isConnectionCategory(obj: unknown): obj is ConnectionCategoryBase {
  return !!obj && typeof obj === "object" && "key" in obj && "display_name" in obj;
}

export class SupabaseConnectionKeysService extends SupabaseServiceView<"connection_key"> {
  table = "connection_key" as const;

  async getCategories(): Promise<ConnectionCategoryBase[]> {
    try {
      const { data } = await this.getAll(null, {
        order: [{ column: "key", dir: "asc" }],
      });
      assertNoNullFields(data);
      // The display name is eventually stored in the DB as the user can customise it.
      const categoriesRaw = (data || []).map(({ display_name, key }) => ({
        key,
        display_name,
      }));

      return categoriesRaw.filter(isConnectionCategory);
    } catch (e) {
      console.error("Error loading connection categories", e);
      return [];
    }
  }
}
