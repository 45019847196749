import {
  FormControl,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Atom, useAtomValue } from "jotai";
import { Loadable } from "jotai/vanilla/utils/loadable";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ChannelIcon } from "../channels/components/channel-icon";
import { ChannelName } from "../channels/types";
import { recommendationToChannel } from "../discover/transform";
import { channelsAtom } from "../generate/store";
import { Channel, Template } from "../generic/atoms/types/template";

export const TemplateChannel: FC<{
  loadableTemplateAtom: Atom<Loadable<Template | null>>;
  onChannelChange: (channel: ChannelName) => void;
}> = ({ loadableTemplateAtom, onChannelChange }) => {
  const { t } = useTranslation();
  const configuredChannels = useAtomValue(channelsAtom);
  const template = useAtomValue(loadableTemplateAtom);

  const ready = template?.state === "hasData" && template.data;

  const channel = ready ? template.data?.channel : undefined;
  const channelRecommendation = ready ? template.data?.recommendations.channel : undefined;

  return (
    <>
      <Typography variant="body2" color="text.secondary">
        {t("Channel")}:
      </Typography>
      <Typography variant="body1" sx={{ mt: 1 }}>
        {channel && (
          <FormControl variant="standard" sx={{ minWidth: 150 }}>
            <Select
              onChange={(e) => {
                const val = e.target.value;
                onChannelChange(val as ChannelName);
              }}
              value={channel}
              renderValue={(v) => {
                const channel_ = configuredChannels.find((x) => x.channel_name === v);
                return channel_ && <ChannelItem channel={channel_} />;
              }}
              SelectDisplayProps={{
                style: { display: "flex", alignItems: "center", padding: 3 },
              }}
            >
              {configuredChannels.map((channel_) => (
                <MenuItem key={channel_.channel_name} value={channel_.channel_name}>
                  <ChannelItem
                    channel={channel_}
                    secondaryText={
                      channelRecommendation &&
                      recommendationToChannel(
                        channelRecommendation,
                        undefined,
                        configuredChannels.map((x) => x.channel_name),
                      )?.channel === channel_.channel_name
                        ? "(recommended)"
                        : ""
                    }
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Typography>
    </>
  );
};

const ChannelItem: FC<{ channel: Channel; secondaryText?: string }> = ({
  channel,
  secondaryText,
}) => {
  return (
    <>
      <ListItemIcon>
        <ChannelIcon channel={channel.channel_name} />
      </ListItemIcon>
      <ListItemText primary={channel.name} secondary={secondaryText} />
    </>
  );
};
