import { useAuth0 } from "@auth0/auth0-react";
import { useAtomValue } from "jotai";
import { useToken } from "../../server/auth/use-token";
import { OrgUser } from "../../server/supabase/hooks/types";
import { brandingImageTypeAtom } from "../brand-kit/store";
import { BrandInfoSelected, TemplateInfo } from "../brand-kit/types";
import { brandInfoToCustomizations } from "./brand-info-customizations/brand-info-customizations";
import { useDesignHuddleCallback } from "./use-design-huddle-callback";
import { copyImage, getPageNumber, imageURL } from "./utils";

export function useCreateDesignHuddleProject(): (
  template_id?: string,
  settings?: BrandInfoSelected,
  templateInfo?: TemplateInfo,
) => Promise<{ project_id: string; preview_url: string } | null> {
  const { user } = useAuth0<OrgUser>();
  const getToken = useToken();

  const image_type = useAtomValue(brandingImageTypeAtom);

  return useDesignHuddleCallback(
    [],
    (
      template_code?: string,
      settings?: BrandInfoSelected,
      templateInfo?: TemplateInfo,
    ): Promise<{ project_id: string; preview_url: string } | null> => {
      if (!user) return Promise.resolve(null);

      return new Promise((resolve) => {
        DSHDLib.createProject(
          {
            media_type: "digital",
            dimensions: template_code
              ? undefined
              : {
                  width: 1024,
                  height: 1024,
                  unit_type: "px",
                },
            template_code: templateInfo?.template_code ?? template_code,
            customizations: brandInfoToCustomizations({
              brandInfoSelected: settings,
              templateInfo,
            }),
          },
          (err, { project_id }) => {
            if (err) {
              console.log(err);
              return resolve(null);
            }

            if (!template_code)
              return void copyImage(getToken, "/static/placeholder.png", project_id)
                .then(() => {
                  resolve({
                    project_id,
                    preview_url: imageURL(user["http://changeengine.com/account_id"], project_id),
                  });
                })
                .catch(console.error);

            const dimensions =
              image_type === "banner" ? { width: 600, height: 200 } : { width: 800, height: 800 };

            DSHDLib.getVariableTemplatePreviewURL(
              {
                template_code,
                customizations: brandInfoToCustomizations({
                  brandInfoSelected: settings,
                  templateInfo,
                }),
                page_number: templateInfo?.page_number ?? getPageNumber(settings),
                ...dimensions,
              },
              (previewErr, preview_url) => {
                if (previewErr) {
                  console.log(previewErr);
                  return resolve(null);
                }

                void copyImage(getToken, preview_url, project_id)
                  .then(() => {
                    resolve({
                      project_id,
                      preview_url: imageURL(user["http://changeengine.com/account_id"], project_id),
                    });
                  })
                  .catch(console.error);
              },
            );
          },
        );
      });
    },
    [user, image_type],
  );
}
