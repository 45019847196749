import { GridComparatorFn } from "@mui/x-data-grid-pro";
import { UseMomentProperties, isProgram } from "../moment-card/types";

export const whenComparator: GridComparatorFn<
  UseMomentProperties | UseMomentProperties["program"] | undefined
> = (v1, v2) => {
  if (isProgram(v1)) {
    if (isProgram(v2)) {
      // both programs, we have no opinion
      return 0;
    } else {
      // v1 is a program, v2 is a moment. programs go first.
      return -1;
    }
  }

  if (isProgram(v2)) {
    // v1 is a moment, v2 is a program. programs go first.
    return 1;
  }

  const d1 = v1?.status === "finished" ? v1?.published_at : v1?.schedule?.when;
  const d2 = v2?.status === "finished" ? v2?.published_at : v2?.schedule?.when;

  return dateComparator(d1, d2);
};

const dateComparator = (a: string | void | null, b: string | void | null): number => {
  if (!a) {
    return -1;
  }
  if (!b) {
    return 1;
  }

  const d1 = new Date(a);
  const d2 = new Date(b);

  if (d1.getTime() < d2.getTime()) {
    return -1;
  }
  if (d1.getTime() > d2.getTime()) {
    return 1;
  }
  return 0;
};
